<template>
    <Layout>
    
        <Sheet :back="this.route('2.payments.index')">
            <template v-slot:menu>
                <AppMenu>
                    <AppMenuItem :href="route('2.payments.fortnox.redirect')" :external="true">{{ $t('Reconnect Fortnox') }}</AppMenuItem>
                    <AppMenuItem :href="route('2.payments.fortnox.delete')">{{ $t('Disable Fortnox') }}</AppMenuItem>
                </AppMenu>
            </template>
            
            <SheetIcon :transparent="true"><FortnoxIcon /></SheetIcon>
            <SheetTitle>{{ $t('Invoice payments with Fortnox') }}</SheetTitle>
    
            <div v-if="!isConnected">
                <p class="tracking-normal mb-8 text-center">{{ $t("Invoices payments with Fortnox enables Octany to send invoices automatically on your Fortox account") }}</p>
                
                <SheetButton :href="route('2.payments.fortnox.redirect')" :external="true">{{ $t('Connect Fortnox') }}</SheetButton>
            </div>
    
            <div v-if="isConnected && isLegacy">
                <p class="tracking-normal mb-8">{{ $t("You are connected to Fortnox. Please reach out to the support team if you need assistance with your Fortnox connection") }}</p>
            </div>
            <div v-else-if="isConnected">
                <form @submit.prevent="form.post(this.route('2.payments.fortnox.store'))">
    
                    <div class="mb-2"><AppCheckbox v-model="form.bookkeep">{{ $t('Bookkeep invoices') }}</AppCheckbox></div>
                    <div class="mb-4"><AppCheckbox v-model="form.send_email_invoices">{{ $t('Send invoices via email automatically') }}</AppCheckbox></div>
                    
                    <AppInput class="mb-1" :label="$t('Revenue account for swedish customers')" v-model="form.income_se"></AppInput>
                    <AppInput class="mb-1" :label="$t('Revenue account for EU customers')" v-model="form.income_eu"></AppInput>
                    <AppInput class="mb-1" :label="$t('Revenue account for non-EU customers')" v-model="form.income_outside_eu"></AppInput>
<!--                    <AppInput class="mb-1" :label="$t('Invoice fee for postal delivery')" v-model="form.postal_mail_fee"></AppInput>-->
<!--                    <AppInput class="mb-1" :label="$t('Invoice fee for email delivery')" v-model="form.postal_email_fee"></AppInput>-->
                    <AppInput class="mb-1" :label="$t('Days until due')" v-model="form.due_days"></AppInput>
                    
                    <FormError />
                    <FormButton :loading="form.processing">
                        <template v-if="mode === 'active'">{{ $t('Save') }}</template>
                        <template v-else>{{ $t('Activate Fortnox') }}</template>
                    </FormButton>
                </form>
            </div>
            
        </Sheet>
        
    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout"
import AppInput from "../../../Shared/AppInput"
import AppCheckbox from "../../../Shared/AppCheckbox"
import FormButton from "../../../Shared/FormButton"
import { useForm } from '@inertiajs/vue3'
import FortnoxIcon from "../../../Shared/Icons/FortnoxIcon";
import AppButtonOutline from "../../../Shared/AppButtonOutline";
import Sheet from "../../../Shared/Sheet";
import SheetTitle from "../../../Shared/SheetTitle";
import SheetIcon from "../../../Shared/SheetIcon";
import AppMenu from "../../../Shared/AppMenu";
import AppMenuItem from "../../../Shared/AppMenuItem";
import FormError from "../../../Shared/FormError";
import SheetButton from "../../../Shared/SheetButton";

export default {
    props: ['isConnected', 'isLegacy', 'fortnox', 'mode'],
    components: {
        SheetButton,
        FormError,
        AppMenuItem,
        AppMenu,
        SheetIcon,
        SheetTitle,
        Sheet,
        AppButtonOutline,
        FortnoxIcon,
        FormButton,
        AppCheckbox,
        AppInput,
        Layout
    },
    data() {
        return {
            form: useForm({
                bookkeep: this.fortnox.bookkeep === '1',
                send_email_invoices: this.fortnox.send_email_invoices === '1',
                income_se: this.fortnox.income_se,
                income_eu: this.fortnox.income_eu,
                income_outside_eu: this.fortnox.income_outside_eu,
                due_days: this.fortnox.due_days,
                // postal_email_fee: this.fortnox.postal_email_fee ? this.fortnox.postal_email_fee/100 : 0,
                // postal_mail_fee: this.fortnox.postal_mail_fee ? this.fortnox.postal_mail_fee/100 : 0,
            }),
        }
    },
    methods: {
    }
}
</script>