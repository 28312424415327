<template>
    <LayoutWithoutNavigation>
    
        <form @submit.prevent="form.post(this.route('2.accounts.onboarding.store'))">
            <div class="mt-24 max-w-xl mx-auto shadow-lg rounded-t p-16">
                
                <div class="text-center">
                    <CartIcon class="inline-block"></CartIcon>
                </div>
                
                <div class="text-lg font-medium text-blue-400 text-center p-8">
                    {{ $t('Confirm your merchant settings') }}
                </div>
        
                <div class="pb-16">
                    <div class="mb-0.5">
                        <AppInput :label="$t('Visible company name')" v-model="form.app_name" :error="form.errors.app_name"></AppInput>
                    </div>
                    <div class="flex justify-between mb-0.5">
                        <div class="w-1/2 mr-[1px]"><AppInput :label="$t('Legal company name')" v-model="form.merchant_company_name" :error="form.errors.merchant_company_name"></AppInput></div>
                        <div class="w-1/2 ml-[1px]"><AppInput :label="$t('EU VAT ID')" v-model="form.merchant_vat_number" :error="form.errors.merchant_vat_number"></AppInput></div>
                    </div>
                    <div class="mb-1">
                        <AppInput :label="$t('Address')" v-model="form.location.line1"></AppInput>
                    </div>
                    <div class="mb-1">
                        <div class="flex space-x-1">
                            <div class="w-1/3"><AppInput :label="$t('Postal code')" v-model="form.location.zip"></AppInput></div>
                            <div class="w-2/3"><AppInput :label="$t('City/Town')" v-model="form.location.city"></AppInput></div>
                        </div>
                    </div>
                    <div>
                        <AppSelect v-model="form.country" :error="form.errors.country">
                            <option v-for="(country, iso) in countries" :value="iso">{{ country.name }}</option>
                        </AppSelect>
                    </div>
                </div>
                
            </div>
    
            <div
                v-if="Object.keys($page.props.errors).length > 0"
                class="max-w-xl mx-auto bg-red-400 text-white flex items-center p-5 justify-center"
            >
                <div class="mr-2">
                    <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12.997" cy="12" r="7.5" stroke="#fff"/><circle cx="12.997" cy="16" r="1" fill="#fff"/><path d="M12.068 7.997a.931.931 0 111.858 0L13.497 14h-1l-.429-6.003z" fill="#fff"/></svg>
                </div>
                <div class="font-medium">
                    {{ $page.props.errors[Object.keys($page.props.errors)[0]] }}
                </div>
            </div>
            
            <div class="max-w-xl mx-auto shadow-lg">
                <AppButton :loading="form.processing" type="submit" class="block rounded-b w-full px-5 py-5">{{ $t('Save') }}</AppButton>
            </div>
        </form>
        
    </LayoutWithoutNavigation>
</template>

<script>
import LayoutWithoutNavigation from '../../../Shared/LayoutWithoutNavigation'
import AppInput from '../../../Shared/AppInput'
import AppTextArea from '../../../Shared/AppTextArea'
import AppSelect from '../../../Shared/AppSelect'
import AppSpinner from '../../../Shared/AppSpinner';
import AppButton from '../../../Shared/AppButton';
import { useForm } from '@inertiajs/vue3';
import CartIcon from "../../../Shared/Icons/CartIcon";

export default {
    props: ['countries', 'settings'],
    data: (instance) => {
        return {
            form: useForm({
                app_name: instance.settings.app_name,
                merchant_company_name: instance.settings.merchant_company_name,
                merchant_vat_number: instance.settings.merchant_vat_number,
                merchant_address: instance.settings.merchant_address,
                merchant_country: instance.settings.merchant_country,
            }),
            saving: false
        }
    },
    components: {
        CartIcon,
        AppButton,
        AppSpinner,
        LayoutWithoutNavigation,
        AppInput,
        AppTextArea,
        AppSelect,
    },
    methods: {
        save() {
            this.saving = true
            
            this.$inertia.post(this.route('2.accounts.onboarding.store'), this.form)
        }
    }
}
</script>
