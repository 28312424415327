<template>
    <a
        class="
            border-l-2 border-transparent hover:border-lilac-300
            bg-white shadow flex items-center
            p-5 md:px-10
            transition-all hover:translate-x-0.5
            min-h-[116px]
        ">
            <span class="block flex-1">
                <span class="text-xl font-semibold text-blue-400 mb-1 block"><slot name="title" /></span>
                <span class="block"><slot name="text" /></span>
            </span>
            <span class="ml-4 block">
                <slot name="icon" />
            </span>
    </a>
</template>