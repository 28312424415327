<template>
    <HqLayout>
        
        <div>
            <h1 class="text-2xl font-semibold text-center">{{ $t('Failed Orders') }}</h1>
            <p class="text-sm text-gray-600 mb-4 text-center max-w-md mx-auto">
                {{ $t('In {currentMonth} we have {currentMonthFailed} failed orders, in {previousMonth} we had {previousMonthFailed} failed orders. We have {older} older failed orders.', {
                    currentMonth: failedOrdersCount.currentMonth.name,
                    currentMonthFailed: failedOrdersCount.currentMonth.count,
                    previousMonth: failedOrdersCount.previousMonth.name,
                    previousMonthFailed: failedOrdersCount.previousMonth.count,
                    older: failedOrdersCount.older.count,
                }) }}
                
                
            </p>
        </div>
        
        <div v-for="failedOrder in failedOrders" class="bg-white shadow mb-1 p-4 flex items-center">
            <div class="w-2/6">
                <div class="">{{ failedOrder.order.id }} &ndash; {{ failedOrder.order.account.name }}</div>
                <div class="text-xs text-gray-600">{{ date(failedOrder.order.created_at) }}</div>
            </div>
            <div class="w-2/6">
                <p class="text-sm text-blue-300">
                    {{ failedOrder.order.billing_method.person?.first_name }} {{ failedOrder.order.billing_method.person?.last_name }}<br>
                    {{ failedOrder.order.billing_method.person?.email }}<br>
                    
                    <template v-if="failedOrder.order.billing_method.type === 'billecta' && failedOrder.order.billing_method.remote_data.bank">
                        {{ banks[failedOrder.order.billing_method.remote_data.bank] }}
                    </template>
                </p>
            </div>
            <div class="w-2/6">
                <p class="text-sm text-blue-300">{{ failedOrder.reason }}</p>
            </div>
            <div class="w-1/6 flex justify-end">
                <AppMenu>
                    <AppMenuItem :href="route('customers.show', { account: failedOrder.order.account, id: failedOrder.order.customer_id })" :external="true">{{ $t('View') }}</AppMenuItem>
<!--                    <AppMenuItem :href="route('2.orders.show', { account: failedOrder.order.account, order: failedOrder.order })">{{ $t('View order') }}</AppMenuItem>-->
<!--                    <AppMenuItem :href="route('2.hq.failed-orders.retry', { order: failedOrder.order })">{{ $t('Retry') }}</AppMenuItem>-->
                </AppMenu>
            </div>
        </div>
        
    </HqLayout>
</template>
<script>
import HqLayout from "../HqLayout.vue";
import AppMenu from "../../../Shared/AppMenu.vue";
import AppMenuItem from "../../../Shared/AppMenuItem.vue";
export default {
    props: ['failedOrders', 'failedOrdersCount', 'banks'],
    components: {
        AppMenuItem,
        AppMenu,
        HqLayout
    }
}
</script>