<template>
    <LayoutWithoutNavigation class-list="pt-0">

        <template #top>
            <template v-if="success.show">
                <div class="bg-green-400 text-white p-6 text-center">
                    <div class="font-bold mb-1">{{ success.title }}</div>
                    <div class="font-medium">{{ success.message }}</div>
                </div>
            </template>

            <LayoutHeaderSnowfireIntegration
                :hide-search="true"
                :hide-hub="true"
                v-if="$page.props.integration && usingSnowfireIntegration()"
            >
                <template #breadcrumbs>
                    <a :href="route('2.close', { reason: 'abort' })">{{ $t('Back') }}</a>
                </template>
            </LayoutHeaderSnowfireIntegration>
        </template>

        <WelcomePreviewModal v-if="showModal" :modal="modal" :locale="locale" :on-trial="onTrial" @close="showModal = false"></WelcomePreviewModal>

        <ProductKinds @select-product="createAccount" :locale="locale"></ProductKinds>
        
    </LayoutWithoutNavigation>
</template>
<script>
import LayoutWithoutNavigation from "../../Shared/LayoutWithoutNavigation";
import ProductKindPanel from "../../Shared/ProductKindPanel";
import WelcomePreviewModal from "./WelcomePreviewModal";
import LayoutHeaderSnowfireIntegration from "../../Shared/LayoutHeaderSnowfireIntegration.vue";
import axios from "axios"
import ProductKinds from "../../Shared/ProductKinds.vue";

export default {
    props: ['continueUrl', 'modal', 'success', 'onTrial', 'onboardingId'],
    components: {ProductKinds, LayoutHeaderSnowfireIntegration, WelcomePreviewModal, ProductKindPanel, LayoutWithoutNavigation},
    data() {
        return {
            locale: this.$attrs.locale,
            showModal: false,
        }
    },
    methods: {
        createAccount(type) {
            if (this.modal.show) {
                axios.post(this.route('2.integrations.accounts.welcome', { id: this.onboardingId }), { type: type })

                this.showModal = true

                return
            }

            this.$inertia.visit(this.continueUrl + '&type=' + type)
        },
        text(en, sv) {
            return this.locale === 'sv' ? sv : en
        },
        usingSnowfireIntegration() {
            if (this.$page.props.integration.identifier === 'snowfire') {
                return true;
            }

            if (this.$page.props.integration.identifier === 'snowfire-staging') {
                return true;
            }

            return false;
        }
    }
}
</script>