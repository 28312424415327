<template>
    <slot name="top"></slot>

    <main class="bg-blue-50 text-blue-300 min-h-screen" :class="classList">
        <!-- Main -->
        <div class="max-w-7xl mx-auto pb-24">
<!--            <div class="flex-1 mr-4">-->
                <Toaster />
                <slot />
<!--            </div>-->
        </div>
        
        <FlashMessage></FlashMessage>
    </main>
    
    <template v-if="$page.props.user.signedIn">
        <div class="absolute bottom-0 right-0 m-4 text-blue-200">
            {{ $t('Signed in as') }} <a :title="$page.props.user.email">{{ $page.props.user.name }}</a> · <a :href="route('2.auth.logout')" class="hover:underline">Sign out</a>
        </div>
    </template>
</template>

<script>
import FlashMessage from './FlashMessage';
import Toaster from "./Toaster";
import LayoutHeaderSnowfireIntegration from "./LayoutHeaderSnowfireIntegration.vue";

export default {
    props: {
        classList: { type: String, default: 'pt-24' }
    },
    components: {LayoutHeaderSnowfireIntegration, Toaster, FlashMessage },
    methods: {
        redirectTo() {
            if (typeof $page !== 'undefined' && $page.props.integration.redirect_to) {
                window.location = $page.props.integration.redirect_to
            }

            const queryString = new URL(window.location.href).search
            const params = new URLSearchParams(queryString)

            const url = params.get('return_url')

            if (url === 'close') {
                window.close()
            }
        }
    }
}
</script>
