<template>
    <Layout>

        <Breadcrumbs class="mb-1">
            <Breadcrumb :href="route('2.products.index')">{{ $t('Products') }}</Breadcrumb>
            <Breadcrumb :href="route('2.products.edit', { product })">{{ product.name }}</Breadcrumb>
            <Breadcrumb :active="true">{{ $t('Confirmations') }}</Breadcrumb>
        </Breadcrumbs>

        <TableRow
            class="min-h-[96px] flex items-center w-full"
            v-for="mail in mails"
        >
            <TableColumn class="w-2/3">
                <span class="text-lg font-bold block" :class="{ 'opacity-50': !mail.active }">
                    {{ $t('Mail') }} &ndash; {{ mail.name }}
                </span>
                <span class="text-sm" :class="{ 'opacity-50': !mail.active }">{{ mail.description }}</span>
            </TableColumn>
            <TableColumn class="w-1/3">
                <div class="flex justify-end items-center">
                    <span v-if="! mail.active" class="text-sm mr-8">
                        {{ $t('Inactive') }}
                    </span>
                    <span v-else-if="mail.custom" class="text-sm mr-8">
                        {{ $t('Last changed {date}', { date: date(mail.custom.updated_at).toLowerCase() }) }}
                    </span>
        
                    <AppMenu>
                        <AppMenuItem :href="route('2.products.mails.edit', { product: product, type: mail.type_without_namespace })">{{ $t('Edit') }}</AppMenuItem>
                        <AppMenuItem :href="route('2.products.mails.show', { product: product, type: mail.type_without_namespace })" :external="true">{{ $t('Show') }}</AppMenuItem>
                        <AppMenuItem :href="route('2.products.mails.preview', { product: product, type: mail.type_without_namespace })">{{ $t('Send preview') }}</AppMenuItem>
                        <AppMenuItem v-if="mail.custom" :href="route('2.products.mails.delete', { product: product, mail: mail.custom.id })">{{ $t('Use default email') }}</AppMenuItem>
                        <AppMenuItem :href="route('2.products.mails.toggleActivation', { product: product, type: mail.type_without_namespace })">
                            <template v-if="mail.active">{{ $t('Disable') }}</template>
                            <template v-else>{{ $t('Activate') }}</template>
                        </AppMenuItem>
                    </AppMenu>
                </div>
            </TableColumn>
        </TableRow>

        <TableRow class="min-h-[96px] flex items-center w-full">
            <TableColumn class="w-2/3">
                <span class="text-lg font-bold block"   >
                    {{ $t('Screen') }} &ndash; {{ $t('After purchase') }}
                </span>
                <span class="text-sm">{{ $t('Displayed on the screen after someone buys this product.') }}</span>
            </TableColumn>
            <TableColumn class="w-1/3">
                <div class="flex justify-end items-center">
                    <span v-if="defaultConfirmation.updated_at" class="text-sm mr-8">{{ $t('Last changed {date}', { date: date(defaultConfirmation.updated_at).toLowerCase() }) }}</span>

                    <AppMenu>
                        <AppMenuItem :href="route('2.products.confirmations.edit', { product: product })">{{ $t('Edit') }}</AppMenuItem>
                        <AppMenuItem v-if="defaultConfirmation.created_at" :href="route('2.products.confirmations.delete', { product: product })">{{ $t('Restore default') }}</AppMenuItem>
                    </AppMenu>
                </div>
            </TableColumn>
        </TableRow>

    </Layout>
</template>

<script>
import Layout from '../../../Shared/Layout'
import TableRow from "../../../Shared/TableRow.vue";
import TableColumn from "../../../Shared/TableColumn.vue";
import AppMenu from "../../../Shared/AppMenu.vue";
import AppMenuItem from "../../../Shared/AppMenuItem.vue";
import Breadcrumb from "../../../Shared/Breadcrumb.vue";
import Breadcrumbs from "../../../Shared/Breadcrumbs.vue";

export default {
    props: ['product', 'mails', 'defaultConfirmation'],
    components: {
        Breadcrumbs, Breadcrumb,
        AppMenuItem,
        AppMenu,
        TableColumn,
        TableRow,
        Layout,
    },
    created() {
    },
    methods: {
        customMail(type) {
            return this.mails.find(mail => mail.type === type)
        }
    }
}

</script>