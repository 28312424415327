<template>
    <div class="max-w-3xl mx-auto">
        <div class="mt-6">
            
            <div v-for="subscription in subscriptions" class="mb-1">
                <inertia-link
                    :href="route('2.subscriptions.show', { subscription: subscription.view.id })"
                    class="
                        block p-8 bg-white rounded shadow
                        border-l-2 border-white
                        hover:bg-lilac-100 hover:border-lilac-300 hover:rounded-none hover:rounded-r
                    ">
                    <span class="flex justify-between items-center">
                        <span class="block">
                            <SubscriptionStatus :subscription="subscription"></SubscriptionStatus>
                            <span class="block text-sm text-blue-400 font-bold tracking-wider">{{ subscription.product.name }}</span>
                            <span class="text-sm text-blue-300"><Reference :subscription="subscription"></Reference></span>
                        </span>
                        <span class="block text-sm text-blue-400 font-bold tracking-wider mr-4">{{ subscription.view.price }}</span>
                    </span>
                </inertia-link>
            </div>
            
        </div>
    </div>
</template>

<script>
import TableHeader from "./TableHeader";
import TableHeaderColumn from "./TableHeaderColumn";
import TableColumn from "./TableColumn";
import TableRow from "./TableRow";
import Reference from "./Reference";
import AppMenu from "./AppMenu";
import AppMenuItem from "./AppMenuItem";
import SubscriptionIcon from "./Icons/SubscriptionIcon";
import SubscriptionStatus from "./SubscriptionStatus";

export default {
    components: {
        SubscriptionStatus,
        SubscriptionIcon, AppMenuItem, AppMenu, Reference, TableRow, TableColumn, TableHeaderColumn, TableHeader},
    props: ['subscriptions']
}
</script>