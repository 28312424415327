<template>
    <div
        class="shadow-lg -mx-[64px] -mb-16 mt-16"
        :class="{
            'max-w-xl': size === 'md',
            'max-w-3xl': size === 'lg'
        }"
    >
        <InertiaLink
            v-if="!external"
            v-bind="$attrs"
            :class="classes"
        ><slot /></InertiaLink>
        <a
            v-if="external"
            v-bind="$attrs"
            :class="classes"
        ><slot /></a>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        external: { default: false },
        size: { default: 'md' },
    },
    data() {
        return {
            'classes': {},
        }
    },
    created() {
        this.classes['block w-full px-5 py-5 text-center rounded-b'] = true;
        this.classes['bg-lilac-300 px-5 py-5 text-white text-lg font-semibold'] = true;
        this.classes['transition duration-300'] = true;
        this.classes['hover:bg-opacity-90'] = true;
    }
}
</script>