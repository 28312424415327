<template>
    <Layout>
        <Sheet>
            <SheetTitle class="pt-0">{{ $t('My profile') }}</SheetTitle>
            
            <form @submit.prevent="submit">
                <div class="flex items-center mb-1 space-x-1 mt-6">
                    <div class="w-1/2"><AppInput :label="$t('First name')" v-model="form.first_name" /></div>
                    <div class="w-1/2"><AppInput :label="$t('Last name')" v-model="form.last_name" /></div>
                </div>
                
                <AppInput :label="$t('Email')" v-model="form.email" type="email" class="mb-1" />
                
                <div class="flex items-center space-x-1 mb-1">
                    <div class="w-1/2">
                        <AppSelect :label="$t('Timezone')" v-model="form.time_zone">
                            <option v-for="timeZone in timeZones" :value="timeZone">{{ timeZone }}</option>
                        </AppSelect>
                    </div>
                    <div class="w-1/2">
                        <AppSelect :label="$t('Language')" v-model="form.locale">
                            <option value="sv">{{ $t('Swedish') }}</option>
                            <option value="en">{{ $t('English') }}</option>
                        </AppSelect>
                    </div>
                </div>
                
                <div class="mt-8">
                    <SheetTitle>{{ $t('Notifications') }}</SheetTitle>
                </div>
                
                <AppCheckboxGroup>
                    <AppCheckbox v-model="form.notifications.subscription_new" :label="$t('New subscriptions')" />
                    <AppCheckbox v-model="form.notifications.order_new" :label="$t('New orders')" />
                </AppCheckboxGroup>
                
            </form>
            
            <FormError />
            
            <SheetButton @click="submit" :loading="form.processing">{{ $t('Save') }}</SheetButton>
        
        </Sheet>
    </Layout>
</template>

<script>
import Sheet from "../../Shared/Sheet.vue";
import AppInput from "../../Shared/AppInput.vue";
import {useForm} from "@inertiajs/vue3";
import SheetButton from "../../Shared/SheetButton.vue";
import AppSelect from "../../Shared/AppSelect.vue";
import AppCheckbox from "../../Shared/AppCheckbox.vue";
import FormError from "../../Shared/FormError.vue";
import Layout from "../../Shared/Layout.vue";
import SheetTitle from "../../Shared/SheetTitle.vue";
import AppCheckboxGroup from "../../Shared/AppCheckboxGroup.vue";

export default {
    props: ['currentUser', 'timeZones', 'notifications'],
    components: {
        AppCheckboxGroup,
        SheetTitle,
        Layout,
        FormError,
        AppCheckbox,
        AppSelect,
        SheetButton,
        AppInput,
        Sheet,
    },
    data() {
        return {
            form: useForm({
                first_name: this.currentUser.first_name,
                last_name: this.currentUser.last_name,
                email: this.currentUser.email,
                time_zone: this.currentUser.time_zone,
                locale: this.currentUser.locale,
                notifications: this.notifications,
            })
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('2.users.store', { user: this.user }));
        }
    }
};
</script>