<template>
    <div v-if="isVisible" class="fixed bg-black bg-opacity-20 h-full w-full z-50">
        
        <div class="bg-blue-50 rounded-xl max-w-xl shadow-lg mt-48 mx-auto p-6">
            <div class="flex text-blue-300 pt-2 pb-6 border-b border-blue-200 mb-2">
                <div><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"/></svg></div>
                <input
                    type="text" :placeholder="$t('Quick search for anything')"
                    v-model="query"
                    @keydown.down.prevent="selectArrowEvent"
                    @keydown.up.prevent="selectArrowEvent"
                    ref="input"
                    class="w-full focus:outline-none ml-4 text-blue-300 bg-blue-50"
                >
                <button
                    @click="hide()"
                    class="block text-blue-300 text-sm leading-5 py-0.5 px-1.5 border border-blue-200 rounded-md"
                >
                    <kbd class="font-sans">esc</kbd>
                </button>
            </div>
            <div>
                <div v-if="searching">
                    <div class="flex justify-around py-4">
                        <span class="relative inline-flex rounded-md shadow-sm">
                            <span class="flex absolute h-4 w-4 top-0 right-0">
                                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-lilac-200 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-4 w-4 bg-lilac-200"></span>
                            </span>
                        </span>
                    </div>
                </div>
                <div v-if="showingRecent && results.length > 0">
                    <span class="text-blue-300 text-sm font-semibold tracking-wider pt-8 pb-2 block">{{ $t('Recently visited') }}</span>
                </div>
                <div v-if="searchFailure">
                    <span class="text-center text-red-400 pt-4 block">{{ $t('Search is currently not available') }}</span>
                </div>
                <div v-if="results" v-for="result in results">
                    <a
                        @click.prevent="show(result)"
                        href=""
                        class="
                            bg-white rounded shadow text-blue-500 border-l-2
                            flex p-6 mb-1 rounded-md items-center
                            transition transform
                            hover:translate-x-1
                        "
                        :class="{
                            'rounded-tr rounded-br rounded-tl-none rounded-bl-none': result.selected,
                            'border-lilac-300': result.selected,
                            'border-transparent': !result.selected,
                        }"
                    >
                        <span>
                            <OrderIcon class="text-lilac-300" />
<!--                            <svg v-if="result.category === 'customer'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" /></svg>-->
<!--                            <svg v-if="result.category === 'subscription'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" /></svg>-->
<!--                            <svg v-if="result.category === 'section'" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clip-rule="evenodd" /></svg>-->
                        </span>
                        <span class="ml-4">
                            <span class="block text-blue-400 font-medium">{{ result.title }}</span>
                            <span class="block text-blue-300 text-sm" v-html="result.metaTitle"></span>
                        </span>
                    </a>
                </div>
            </div>
        </div>
    
    </div>
</template>

<script>
import throttle from 'lodash/throttle'
import axios from 'axios'
import OrderIcon from "./Activities/OrderIcon.vue";

export default {
    components: {OrderIcon},
    props: {
        visible: false,
    },
    mounted() {
        window.addEventListener('keydown', (e) => {
            if (e.metaKey && e.key === 'k') {
                this.toggle()
            }
            
            if (this.isVisible && e.key === 'Escape') {
                this.hide()
            }
    
            if (this.isVisible && e.key === 'Enter') {
                this.show(this.results[this.index])
            }
        })
        
        if (this.$refs.input) {
            this.$refs.input.focus()
        }
    },
    created() {
        this.isVisible = this.visible
        
        this.search()
    },
    data: () => {
        return {
            isVisible: false,
            searching: false,
            query: '',
            results: {},
            index: 0,
            showingRecent: false,
            searchFailure: false,
        }
    },
    watch: {
        query: {
            deep: true,
            handler: throttle(function() {
                this.search()
            }, 150),
        },
    },
    methods: {
        open() {
            this.isVisible = true
            this.reset()
        },
        toggle() {
            this.isVisible = !this.isVisible
            this.reset()
    
            if (this.isVisible) {
                setTimeout(() => {
                    if (this.$refs.input) {
                        this.$refs.input.focus()
                    }
                }, 250)
            }
        },
        reset() {
            this.query = null
            this.index = 0
    
            this.results = this.getRecents()
        },
        async search() {
            this.results = {}
            this.showingRecent = false
            this.searchFailure = false
            
            if (!this.query || this.query.length === 0) {
                this.results = this.getRecents()
                this.showingRecent = true
                
                return false
            }
            
            if (this.query.length < 3) {
                return false
            }
    
            this.searching = true
            let response = null
            
            try {
                response = await axios.get(this.route('2.search', {q: this.query}))
            } catch (e) {
                this.searchFailure = true
            }
            
            this.searching = false
            
            this.results = response.data.data.results
            this.index = 0
        },
        selectArrowEvent(e) {
            this.results[this.index].selected = false
            
            if (e.keyCode === 40) {
                this.index = this.index + 1;
            } else if (e.keyCode === 38) {
                this.index =  this.index - 1;
            }
            
            if (this.index >= this.results.length) {
                this.index = 0
            }
            
            if (this.index < 0) {
                this.index = this.results.length - 1
            }
            
            this.results[this.index].selected = true
        },
        saveAsRecent(result) {
            let recent = localStorage.getItem('anythingSearchRecent-' + this.$page.props.account.id)
            recent = recent ? JSON.parse(recent) : [];
    
            // Remove this item from recent if it already exists in recent to avoid duplicates
            if (recent.some(e => e.url === result.url)) {
                recent = recent.filter(item => item.url !== result.url)
            }
            
            if (! result) {
                return
            }
            
            result.selected = false
            
            recent = recent.reverse()
            recent.push(result)
            recent = recent.reverse()
    
            if (recent.length > 5) {
                recent.splice(5)
            }
            
            localStorage.setItem('anythingSearchRecent-' + this.$page.props.account.id, JSON.stringify(recent))
        },
        getRecents() {
            let recents = localStorage.getItem('anythingSearchRecent-' + this.$page.props.account.id)
            recents = recents ? JSON.parse(recents) : [];
            
            if (recents[0]) {
                recents[0].selected = true
            }
            
            return recents
        },
        show(result) {
            this.saveAsRecent(result)
            this.isVisible = false
            this.$inertia.visit(result.url)
        },
        hide() {
            this.isVisible = false
        }
    },
}
</script>
