<template>
    <template v-if="href">
        <inertia-link :href="href" v-bind="$attrs">
            <slot></slot>
        </inertia-link>
    </template>
    <template v-else>
        <span v-bind="$attrs">
            <slot></slot>
        </span>
    </template>
</template>

<script>
export default {
    props: {
        href: null
    }
}
</script>