<template>
    <Layout>

        <div class="max-w-lg mx-auto">
        <Breadcrumbs class="mb-1">
            <Breadcrumb :href="route('2.products.index')">{{ $t('Products') }}</Breadcrumb>
            <Breadcrumb :href="route('2.products.edit', { product })">{{ product.name }}</Breadcrumb>
            <Breadcrumb :href="route('2.products.confirmations.index', { product })" :active="true">{{ $t('Confirmations') }}</Breadcrumb>
        </Breadcrumbs>
        </div>

        <Sheet>
            <SheetTitle>{{ $t('Edit confirmation') }}</SheetTitle>

            <p class="mb-6 text-sm text-center">{{ $t('This affects all confirmations for {name}', { name: name }) }}</p>

            <form @submit.prevent="submit">
                <AppInput :label="$t('Title')" v-model="form.title" :error="$attrs.errors.title" class="mb-1"></AppInput>
                <AppTextArea :label="$t('Text') " v-model="form.text" :error="$attrs.errors.text" class="mb-1"></AppTextArea>
                <AppInput v-if="hasMedia" :label="$t('Button text')" v-model="form.button" :error="$attrs.errors.button"></AppInput>

                <FormError />

                <FormButton :loading="form.processing">{{ $t('Save') }}</FormButton>
            </form>

        </Sheet>

    </Layout>
</template>
<script>
import Layout from "../../../Shared/Layout.vue";
import Sheet from "../../../Shared/Sheet.vue";
import SheetTitle from "../../../Shared/SheetTitle.vue";
import AppTextArea from "../../../Shared/AppTextArea.vue";
import {useForm} from "@inertiajs/vue3";
import FormButton from "../../../Shared/FormButton.vue";
import AppInput from "../../../Shared/AppInput.vue";
import FormError from "../../../Shared/FormError.vue";
import Breadcrumb from "../../../Shared/Breadcrumb.vue";
import Breadcrumbs from "../../../Shared/Breadcrumbs.vue";

export default {
    props: ['confirmation', 'product', 'name', 'hasMedia'],
    components: {
        Breadcrumbs, Breadcrumb,
        FormError,
        AppInput,
        FormButton,
        AppTextArea,
        SheetTitle,
        Sheet,
        Layout,
    },
    data() {
        return {
            form: useForm({
                title: this.confirmation.title,
                text: this.confirmation.text,
                button: ! this.confirmation.button && this.hasMedia ? this.$t('Download') : this.confirmation.button,
            })
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('2.products.confirmations.update', { product: this.product }))
        }
    }
}
</script>