<template>
    <div class="relative">
        <input
            :id="id"
            ref="input"
            v-bind="$attrs"
            :type="type" :value="modelValue"
            @input="input($event)"
            class="
                appearance-none
                pt-7 pb-3 px-4 bg-white shadow rounded
                peer w-full placeholder-transparent
                text-blue-400
                border
                focus:outline-none focus:border-blue-200
                focus:pt-7 focus:pb-3
                transition duration-300
                disabled:bg-blue-100
                disabled:border-blue-100
            "
            :class="{
                'border-red-400': error,
                'border-white': !error,
                'focus:border-red-400': error,
                'bg-red-400': error,
                'bg-opacity-10': error,
             }"
            placeholder="empty"/>
        <label :for="id" class="
            absolute left-4 top-2 text-blue-300 text-sm transition-all
            peer-placeholder-shown:text-base peer-placeholder-shown:text-blue-300 peer-placeholder-shown:top-[23px]
            peer-focus:top-2 peer-focus:text-blue-300 peer-focus:text-sm
        "
           :class="{ 'peer-placeholder-shown:text-red-400': error }"
        >{{ label }}</label>
    </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid"

export default {
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            default() {
                return `text-input-${uuidv4()}`
            },
        },
        type: {
            type: String,
            default: 'text',
        },
        modelValue: String,
        label: String,
        error: String,
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        },
        select() {
            this.$refs.input.select()
        },
        input($event) {
            this.$emit('update:modelValue', $event.target.value)
        }
    },
}
</script>
