<template>
    <LayoutWithoutNavigation>
        <Sheet>
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>{{ $t('Choose a new password') }}</SheetTitle>
            <form @submit.prevent="submit">
                <AppInput :label="$t('Password')" v-model="form.password" type="password" :error="$attrs.errors.password" />
                <FormError />
                <FormButton>{{ $t('Save') }}</FormButton>
            </form>
        </Sheet>
    </LayoutWithoutNavigation>
</template>
<script>
import LayoutWithoutNavigation from "../../../Shared/LayoutWithoutNavigation";
import Sheet from "../../../Shared/Sheet";
import SheetIcon from "../../../Shared/SheetIcon";
import SheetTitle from "../../../Shared/SheetTitle";
import CartIcon from "../../../Shared/Icons/CartIcon";
import AppInput from "../../../Shared/AppInput";
import FormError from "../../../Shared/FormError";
import FormButton from "../../../Shared/FormButton";

export default {
    props: ['token'],
    components: {FormButton, FormError, AppInput, CartIcon, SheetTitle, SheetIcon, Sheet, LayoutWithoutNavigation},
    data() {
        return {
            form: this.$inertia.form({
                password: null,
            })
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('2.auth.amnesia.update', { token: this.token }))
        }
    }
}
</script>