<template>
    <Layout>

        <div class="text-center mb-4">
            <div class="text-xl font-semibold text-blue-400 mb-1">{{ $t('Products') }}</div>
        </div>

        <div class="flex justify-center mb-12">
            <InertiaLink :href="route('2.products.create')">
                <AppButtonOutline>
                    <template #icon><ProductIcon /></template>
                    {{ $t('New product') }}
                </AppButtonOutline>
            </InertiaLink>
        </div>

        <div v-for="(products, type) in groupedProducts" :key="type" class="mb-12">
            <div class="flex items-center justify-between font-medium mb-3">
                <div><ProductType :type="type" /></div>
<!--                <div class="flex items-center">-->
<!--                    <AppMenu>-->
<!--                        <AppMenuItem>{{ $t('Edit') }}</AppMenuItem>-->
<!--                    </AppMenu>-->
<!--                </div>-->
            </div>

            <TableRow
                :href="`${route('2.products.edit', { product: product  })}`"
                v-for="product in products"
                class="min-h-[96px] flex items-center w-full"
            >
                <TableColumn class="w-1/3">
                    <span class="block text-blue-400 text-lg font-semibold">{{ product.name }}</span>
                    <span class="block text-blue-300">
                      <template v-if="product.is_donation && product.recurring">{{ $t('Recurring donation') }}</template>
                      <template v-else-if="product.is_donation">{{ $t('Donation') }}</template>
                      <template v-else-if="product.recurring">{{ $t('Subscription') }}</template>
                      <template v-else>{{ $t('One time purchase') }}</template>
                  </span>
                </TableColumn>
                <TableColumn class="w-1/3">
                    <InertiaLink
                        v-if="product.recurring"
                        :href="route('2.subscriptions.index', { 'product': product, 'status': 'active' })"
                        class="underline"
                    >
                        <template v-if="product.active_subscriptions === 1">{{ $t('1 active subscriber') }}</template>
                        <template v-else-if="product.active_subscriptions > 1">
                            {{ $t('{count} active subscribers', {count: product.active_subscriptions}) }}
                        </template>
                    </InertiaLink>
                    <InertiaLink
                        v-else
                        :href="route('2.orders.index', { 'filters[product]': product  })"
                        class="underline"
                    >
                        <template v-if="product.orders === 1">{{ $t('1 sale', {count: product.orders}) }}</template>
                        <template v-else-if="product.orders > 1">{{ $t('{count} sales', {count: product.orders}) }}</template>
                    </InertiaLink>
                </TableColumn>
                <TableColumn class="w-1/3 text-blue-300 font-bold tracking-widest">
                    <div class="flex justify-end items-center">
                        <div class="mr-8 text-lg font-semibold tracking-tight text-right">
                            <template v-if="product.price > 0">
                                <template v-if="product.show_price_including_vat">{{ centsToMoney(product.price_with_vat, product.currency) }}</template>
                                <template v-else>{{ centsToMoney(product.price, product.currency) }}</template>
                                <span class="font-medium text-base" v-if="product.recurring">/{{product.interval_i18n_short }}</span>
                                <span v-if="product.trial_days > 0" class="block text-sm font-normal tracking-normal">
                                  {{
                                        $t('{days} days trial for {amount}', {
                                            days: product.trial_days,
                                            amount: centsToMoney(product.trial_fee, product.currency)
                                        })
                                    }}
                              </span>
                            </template>
                        </div>

                        <AppMenu>
                            <AppMenuItem :href="route('2.products.edit', { product: product })">{{ $t('Edit product') }}</AppMenuItem>
                            <AppMenuItem :href="route('2.products.confirmations.index', { product: product })">{{ $t('Purchase confirmations') }}</AppMenuItem>
                            <AppMenuItem v-if="product.is_donation" :href="route('2.products.embed.donation.index', { product: product.id })">{{ $t('Add to website') }}</AppMenuItem>
                            <AppMenuItem v-if="product.is_donation" :href="route('2.products.embed.donation.open', { product: product })" :external="true">{{ $t('Payment link') }}</AppMenuItem>
                            <AppMenuItem v-if="!product.is_donation" :href="route('pay.checkout.product', { product: product })" :external="true">{{ $t('Payment link') }}
                            </AppMenuItem>
                            <AppMenuItem :href="route('2.products.delete', { product: product })">{{ $t('Delete') }}
                            </AppMenuItem>
                        </AppMenu>
                    </div>
                </TableColumn>
            </TableRow>
        </div>

    </Layout>
</template>

<script>
import Layout from '../../Shared/Layout';
import Breadcrumbs from "../../Shared/Breadcrumbs";
import Breadcrumb from "../../Shared/Breadcrumb";
import TableHeader from "../../Shared/TableHeader";
import TableHeaderColumn from "../../Shared/TableHeaderColumn";
import TableRow from "../../Shared/TableRow";
import TableColumn from "../../Shared/TableColumn";
import AppButton from "../../Shared/AppButton";
import AppMenu from "../../Shared/AppMenu";
import AppMenuItem from "../../Shared/AppMenuItem";
import ProductIcon from "../../Shared/Icons/ProductIcon";
import AppButtonOutline from "../../Shared/AppButtonOutline";
import ProductType from "../../Shared/ProductType.vue";
import ExportIcon from "../../Shared/Icons/ExportIcon.vue";

export default {
    components: {
        ExportIcon,
        ProductType,
        AppButtonOutline,
        ProductIcon,
        AppMenuItem,
        AppMenu,
        AppButton,
        TableColumn, TableRow, TableHeaderColumn, TableHeader, Breadcrumb, Breadcrumbs, Layout
    },
    props: ['groupedProducts'],
}
</script>
