<template>
    <div class="text-center mb-2">
        <div class="text-xl font-semibold text-blue-400 mb-1">{{ $t('Developer') }}</div>
    </div>
    
    <p class="text-center pb-8">{{ $t('Use the Octany API to create integrations with other systems.') }} </p>
    
    <AppTabs>
        <AppTab :first="true" :active="active === 'api'" @click="open('api')">{{ $t('REST API') }}</AppTab>
        <AppTab :last="true" :active="active === 'webhooks'" @click="open('webhooks')">{{ $t('Webhooks') }}</AppTab>
    </AppTabs>
</template>

<script>
import AppTabs from "../../Shared/AppTabs.vue";
import AppTab from "../../Shared/AppTab.vue";

export default {
    props: {
        active: {
            type: String,
            default: ''
        }
    },
    components: {
        AppTab,
        AppTabs
    },
    methods: {
        open(tab) {
            switch (tab) {
                case 'api':
                    this.$inertia.visit(this.route('2.developers.tokens.index'))
                    break
                case 'webhooks':
                    this.$inertia.visit(this.route('2.developers.webhooks.endpoints.index'))
                    break
            }
        }
    }
}
</script>