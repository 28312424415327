<template>
    <div class="w-8 border border-b-2 border-lilac-100" :class="classes"></div>
</template>

<script>
    export default {
        props: {
            size: {
                type: String,
                default: 'sm',
            },
        },
        data() {
            return {
                classes: ''
            }
        },
        created() {
            switch (this.size) {
                case 'sm':
                    this.classes = 'mb-2 mt-2'
                    break;

                default:
                    this.classes = 'mb-8 mt-8'
                    break;
            }
        }
    }
</script>