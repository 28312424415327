<template>
    <SubNavigation :items="items"></SubNavigation>
</template>

<script>
import SubNavigation from "../SubNavigation.vue"

export default {
    components: {
        SubNavigation,
    },
    data() {
        return {
            items: [
                {
                    label: 'Subscriptions',
                    route: route('2.hq.subscriptions.index'),
                },
                {
                    label: 'Duplicates',
                    route: route('2.hq.subscriptions.duplicates.index'),
                },
            ],
        }
    },
}
</script>
