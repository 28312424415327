<template>
    <div
        v-if="show"
        class="bg-red-400 text-white flex items-center p-5 justify-center"
        :class="{
            'mt-16 -mb-16': !inline,
            '-mx-16': !inline,
        }"
    >
        <div class="mr-2">
            <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12.997" cy="12" r="7.5" stroke="#fff"/><circle cx="12.997" cy="16" r="1" fill="#fff"/><path d="M12.068 7.997a.931.931 0 111.858 0L13.497 14h-1l-.429-6.003z" fill="#fff"/></svg>
        </div>
        <div class="font-medium">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        error: {
            type: Object,
            required: false,
            default: null
        },
        inline: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => {
        return {
            show: false,
            errorMessage: '',
        }
    },
    created() {
        if (this.error) {
            // Assume this is an error catch from axios
            this.show = !!this.error
            if (this.error.response && this.error.response.data && this.error.response.data.errors) {
                const errors = this.error.response.data.errors;
                const firstErrorKey = Object.keys(errors)[0];

                this.errorMessage = errors[firstErrorKey][0];
            } else {
                this.errorMessage = 'An unexpected error occurred.';
            }
        } else {
            // Default to inertia page errors
            this.show = Object.keys(this.$page.props.errors).length > 0
            this.errorMessage = this.$page.props.errors[Object.keys(this.$page.props.errors)[0]]
        }
    },
    watch: {
        '$page.props.errors': {
            handler() {
                this.show = Object.keys(this.$page.props.errors).length > 0
                this.errorMessage = this.$page.props.errors[Object.keys(this.$page.props.errors)[0]]
            },
            deep: true
        }
    }
}
</script>