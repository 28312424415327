<template>
    <div
        class="mx-auto shadow-lg sm:-mx-16 -mb-16 mt-16"
        :class="{
            'max-w-xl': size === 'md',
            'max-w-3xl': size === 'lg'
        }"
    >
        <button
            v-bind="$attrs"
            class="
                block w-full px-5 py-5
                bg-lilac-300 text-white text-lg font-semibold
                transition duration-300
                hover:bg-opacity-90
            "
            :disabled="loading"
            :class="classes"
        >
            <AppSpinner v-if="loading" color="#ffffff"></AppSpinner>
            <span v-else><slot></slot></span>
        </button>
    </div>
</template>

<script>
import AppSpinner from './AppSpinner';

export default {
    components: {AppSpinner},
    inheritAttrs: false,
    props: {
        loading: Boolean,
        size: { default: 'md' },
    },
    data() {
        return {
            'classes': {},
        }
    },
    created() {
        let classes = this.$attrs.class
        
        if (! classes) {
            classes = ''
        }
        
        // Add default rounding if we don't apply any specific rounding
        if (! classes.includes('rounded')) {
            this.classes['rounded sm:rounded-none sm:rounded-b'] = true
        }
    
        if (! classes.includes('block')) {
            this.classes['inline-block'] = true
        }
        
        if (! classes.includes('px-')) {
            this.classes['px-12'] = true
        }
    
        if (! classes.includes('py-')) {
            this.classes['py-4'] = true
        }
    }
}
</script>