<template>
    <Layout>
        <Sheet :back="route('2.payments.index')">
            <SheetIcon :transparent="true"><TrustlyIcon /></SheetIcon>
            <SheetTitle>{{ $t('Direct Debit payments with Trustly') }}</SheetTitle>
    
            <form @submit.prevent="form.post(this.route('2.payments.trustly.store'))">
                <AppInput :label="$t('Username')" v-model="form.username" class="mb-2" :error="$attrs.errors.username"></AppInput>
                <AppInput :label="$t('Password')" v-model="form.password" class="mb-2" :error="$attrs.errors.password"></AppInput>
                <AppInput :label="$t('Shopper statement')" v-model="form.statement" class="mb-2" :error="$attrs.errors.statement"></AppInput>
    
                <label class="block mt-2">
                    <span class="block mb-2">
                        <span v-if="!hasCertificate">{{ $t('Upload certificate (PEM)') }}</span>
                        <span v-else>{{ $t('Replace certificate (PEM)') }}</span>
                    </span>
                    <input type="file" @input="form.certificate = $event.target.files[0]" class="
                        block w-full text-sm text-blue-400
                        file:mr-4 file:py-2 file:px-4
                        file:rounded-full file:border-0
                        file:text-sm file:font-semibold
                        file:bg-lilac-100 file:text-violet-700
                        hover:file:bg-violet-100
                    "/>
                </label>
    
                <div v-if="active" class="mt-16 -mb-10 text-center">
                    <a href="" @click.prevent="confirmDelete" class="font-medium underline">
                        {{ $t('Disable Trustly payments') }}
                    </a>
        
                    <AppModalAlert ref="modal" @confirm="confirm">
                        <template v-slot:title>{{ $t('Disable Trustly payments') }}</template>
                        {{ $t('Are you sure you want to disable direct debit payments via Trustly for {accountName}?', { name: accountName }) }}
                        <template v-slot:confirm>{{ $t('Disable direct debit payments') }}</template>
                    </AppModalAlert>
                </div>
                
                <FormError />
                
                <FormButton :loading="form.processing">
                    <template v-if="active">{{ $t('Save') }}</template>
                    <template v-else>{{ $t('Activate') }}</template>
                </FormButton>
            </form>
        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout";
import Sheet from "../../../Shared/Sheet";
import SheetIcon from "../../../Shared/SheetIcon";
import SheetTitle from "../../../Shared/SheetTitle";
import AppModalAlert from "../../../Shared/AppModalAlert";
import TrustlyIcon from "../../../Shared/Icons/TrustlyIcon";
import AppInput from "../../../Shared/AppInput";
import FormButton from "../../../Shared/FormButton";
import {useForm} from "@inertiajs/vue3";
import FormError from "../../../Shared/FormError";

export default {
    props: ['username', 'password', 'statement', 'active', 'accountName', 'hasCertificate'],
    components: {
        FormError,
        FormButton,
        AppInput, TrustlyIcon, AppModalAlert, SheetTitle, SheetIcon, Sheet, Layout
    },
    data() {
        return {
            form: useForm({
                username: this.username,
                password: this.password,
                statement: this.statement,
                certificate: null,
            })
        }
    },
    methods: {
        confirmDelete() {
            this.$refs.modal.isOpen = true
        },
        confirm() {
            this.$inertia.visit(this.route('2.payments.trustly.delete'), { method: 'delete' })
        }
    }
}
</script>