<template>
    <Layout>
        <Sheet>
            <SheetIcon><BillectaIcon /></SheetIcon>
            <SheetTitle>Billecta</SheetTitle>
    
            {{ $t('By activating Direct Debit for {company} ({number}) you are creating an account at our partner Billecta.', { company: merchant.company_name, number: merchant.vat_number }) }}
            
            <form @submit.prevent="form.post(this.route('2.payments.billecta.activate.store'))">
                <div class="mt-8 mb-2 font-medium">{{ $t('Primary contact') }}</div>
                
                <div class="flex space-x-1 mb-1">
                    <AppInput :label="$t('First name')" v-model="form.first_name" :error="$attrs.errors.first_name"></AppInput>
                    <AppInput :label="$t('Last name')" v-model="form.last_name" :error="$attrs.errors.last_name"></AppInput>
                </div>
                <AppInput :label="$t('Email')" v-model="form.email" :error="$attrs.errors.email"></AppInput>
    
                <div class="mt-8 mb-2 font-medium">{{ $t('Company address') }}</div>
                <AppInput :label="$t('Address')" v-model="form.line1" class="mb-1" :error="$attrs.errors.line1"></AppInput>
                <AppInput :label="$t('Line 2')" v-model="form.line2" class="mb-1"></AppInput>
                <div class="flex space-x-1 mb-8">
                    <AppInput :label="$t('Postal code')" v-model="form.zip" :error="$attrs.errors.zip"></AppInput>
                    <AppInput :label="$t('City')" v-model="form.city" :error="$attrs.errors.city"></AppInput>
                </div>
                
                <AppInput :label="$t('Bankgiro number')" v-model="form.bankgiro" class="mb-1" :error="$attrs.errors.bankgiro"></AppInput>
                
                <AppSelect :label="$t('Organization type')" v-model="form.type" :error="$attrs.errors.type">
                    <option v-for="(value, key) in types" :value="key">{{ value }}</option>
                </AppSelect>
                
                <AppCheckboxGroup class="mt-1">
                    <AppCheckbox v-model="form.accept_terms" :error="$attrs.errors.accept_terms"><span v-html="$t('accept_terms_billecta', { termsUrl: '/docs/billecta-allmanna-villkor-sp-20211214.pdf'})"></span></AppCheckbox>
                </AppCheckboxGroup>
                
                <FormError />
    
                <FormButton :loading="form.processing" type="submit">{{ $t('Continue') }}</FormButton>
                
            </form>
    
<!--            <SheetButton v-if="! showCreateForm" @click="showCreateForm = true">{{ $t('Continue') }}</SheetButton>-->
        </Sheet>
        <div class="text-center mt-4">
            <InertiaLink class="text-lilac-300 underline" :href="route('2.payments.billecta.activate.existing')">{{ $t('We already have a Billecta account') }}</InertiaLink>
        </div>
        
    </Layout>
</template>

<script>
import Layout from "../../../../Shared/Layout.vue";
import Sheet from "../../../../Shared/Sheet.vue";
import SheetIcon from "../../../../Shared/SheetIcon.vue";
import CartIcon from "../../../../Shared/Icons/CartIcon.vue";
import SheetTitle from "../../../../Shared/SheetTitle.vue";
import SheetButton from "../../../../Shared/SheetButton.vue";
import {useForm} from "@inertiajs/vue3";
import AppInput from "../../../../Shared/AppInput.vue";
import AppSelect from "../../../../Shared/AppSelect.vue";
import AppCheckbox from "../../../../Shared/AppCheckbox.vue";
import AppCheckboxGroup from "../../../../Shared/AppCheckboxGroup.vue";
import FormButton from "../../../../Shared/FormButton";
import FormError from "../../../../Shared/FormError";
import BillectaIcon from "../../../../Shared/Icons/BillectaIcon.vue";

export default {
    props: ['merchant', 'types', 'form_values'],
    components: {
        BillectaIcon,
        FormError,
        FormButton,
        AppCheckboxGroup, AppCheckbox, AppSelect, AppInput, SheetButton, SheetTitle, CartIcon, SheetIcon, Sheet, Layout},
    data() {
        return {
            showCreateForm: false,
            form: useForm({
                first_name: this.form_values?.first_name,
                last_name: this.form_values?.last_name,
                email: this.form_values?.email,
                line1: null,
                line2: null,
                zip: null,
                city: null,
                bankgiro: this.form_values?.bankgiro,
                type: 'non-profit',
                accept_terms: false,
            })
        }
    },
}
</script>