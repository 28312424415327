<template>
    <div class="
        bg-blue-100 text-blue-300 rounded text-blue-300 flex text-sm tracking-wider
    ">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
    
    }
}
</script>