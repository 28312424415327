<template>
    <LayoutWithoutNavigation>
        <Sheet>
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>{{ $t('Choose account') }}</SheetTitle>
            <p class="text-blue-300 mb-4">{{ $t('It looks like you already got an Octany account. Do you want to create a new one, or use an existing account?') }}</p>
        
            <div class="space-y-1">
                <LinkPanel :href="createUrl" size="md" class="mt-8">
                    <span class="font-semibold">{{ $t('Create new account') }}</span>
                </LinkPanel>
                <LinkPanel :href="account.url" v-for="account in accounts" size="md">
                    <span class="block w-1/2">
                        <span class="block font-semibold">{{ account.name }}</span>
                        <span class="block text-sm">{{ account.vat_number }}</span>
                    </span>
                    <span class="block">
                        <span class="block text-sm">ID {{ account.id }}</span>
                        <span class="block text-sm">{{ date(account.created_at) }}</span>
                    </span>
                </LinkPanel>
            </div>
        </Sheet>
    </LayoutWithoutNavigation>
</template>

<script>
import Sheet from "../../Shared/Sheet";
import SheetIcon from "../../Shared/SheetIcon";
import CartIcon from "../../Shared/Icons/CartIcon";
import SheetTitle from "../../Shared/SheetTitle";
import LinkPanel from "../../Shared/LinkPanel";
import LayoutWithoutNavigation from "../../Shared/LayoutWithoutNavigation";

export default {
    props: ['accounts', 'createUrl'],
    components: {LayoutWithoutNavigation, LinkPanel, SheetTitle, CartIcon, SheetIcon, Sheet}
}
</script>