<template>
    <VueTailwindDatePicker
        v-model="internalDateValue"
        :formatter="formatter"
        :placeholder="$t('Select a period')"
        :options="options"
        i18n="sv"
        input-classes="
            appearance-none
            pt-3 pb-3 px-4 bg-white rounded
            w-full
            text-blue-300
            border border-lilac-100
            focus:outline-none focus:border-lilac-300
            transition duration-300
            disabled:bg-blue-100
            disabled:border-blue-100
            z-60
        "
        @change="handleChange"
    >
        <template #inputIcon="{ value }">
            <span v-if="value" class="text-lilac-300">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" class="fill-current"><path fill-rule="evenodd" d="M12 .954 11.062 0 5.955 5.198.957.11 0 1.086l4.998 5.086L.21 11.047l.937.953 4.788-4.874 4.687 4.77.957-.975-4.687-4.769L12 .954Z" clip-rule="evenodd"/></svg>
            </span>
            <span v-else></span>
        </template>
    </VueTailwindDatePicker>
</template>

<script>
import VueTailwindDatePicker from 'vue-tailwind-datepicker'

export default {
    components: { VueTailwindDatePicker },
    props: {
        modelValue: {
            type: [Date, Array],
            default: () => [],
        },
    },
    data() {
        return {
            formatter: {
                date: 'D MMM YYYY',
                month: 'MMM',
            },
            options: {
                shortcuts: {
                    today: this.$t('Today'),
                    yesterday: this.$t('Yesterday'),
                    past: period => `${this.$t('Last {period} days', { period })}`,
                    currentMonth: this.$t('This month'),
                    pastMonth: this.$t('Last month'),
                },
                footer: {
                    apply: this.$t('Apply'),
                    cancel: this.$t('Cancel'),
                },
            }
        }
    },
    computed: {
        internalDateValue: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value)
                this.$emit('change', [
                    this.convertStringDateToDefaultFormat(value[0]),
                    this.convertStringDateToDefaultFormat(value[1]),
                ])
            },
        },
    },
    methods: {
        convertStringDateToDefaultFormat(value) {
            if (! value) {
                return null
            }

            const parts = value.match(/(\d+).(\S+).(\d+)/)

            if (parts) {
                const months = {jan: '01', feb: '02', mar: '03', apr: '04', maj: '05', jun: '06', jul: '07', aug: '08', sep: '09', okt: '10', nov: '11', dec: '12'}
                const month = months[parts[2].toLowerCase().substring(0, 3)]

                return `${parts[3]}-${month}-${parts[1].padStart(2, '0')}`
            }

            return null
        },
        handleChange(value) {
            this.$emit('change', value); // Forwarding the change event
        },
    },
    watch: {
        modelValue(newVal) {
            // Optional: React to external changes in v-model
        },
    },
}
</script>
