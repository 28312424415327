<template>
    <Layout>
        <Sheet>
            <SheetIcon :transparent="true"><SparIcon /></SheetIcon>
            <SheetTitle>{{ $t('Statens personadressregister') }}</SheetTitle>
            <p class="leading-6 mb-4">
                {{ $t('This add-on enables individuals to provide their Swedish social security number during a purchase or donation. Through SPAR, their name and address details are retrieved. Enter your agreement with SPAR here to get started.') }}
            </p>
            <form @submit.prevent="store">
                <AppUpload
                    class="mb-1"
                    :url="route('2.settings.spar.certificate')"
                    :multiple="false"
                    :accept="['.pem']"
                    :show-progress="true"
                    visibility="private"
                    
                    @upload-start="uploading = true"
                    @upload-complete="onCertificateUploadComplete"
                >
                    <span class="shadow p-5 rounded shadow flex items-center border border-transparent transition hover:bg-white hover:border-lilac-300">
                        <span class="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g clip-path="url(#a)"><path fill="#808CFA" fill-rule="evenodd" d="M14.76 6.82c1.006.516 1.601 1.22 1.686 1.985l.07.639.602-.226c1.046-.392 1.92-.123 2.35.323.212.221.322.486.318.763-.004.276-.122.617-.46.98l-.335.362.356.34c.994.951 1.404 1.792 1.469 2.486.064.682-.195 1.31-.73 1.87-1.1 1.148-3.29 1.908-5.586 1.908v1c2.454 0 4.952-.802 6.307-2.217.692-.721 1.101-1.624 1.005-2.654-.086-.912-.562-1.85-1.451-2.791.276-.407.419-.839.425-1.27a2.068 2.068 0 0 0-.599-1.471c-.65-.674-1.72-.985-2.874-.728-.317-.954-1.128-1.692-2.097-2.189-1.168-.599-2.656-.902-4.12-.836-1.464.066-2.952.504-4.084 1.443-1.035.859-1.737 2.108-1.866 3.777-.926-.02-1.708.428-2.239 1.117-.628.817-.909 1.968-.74 3.126.172 1.168.802 2.355 2.009 3.242 1.203.885 2.949 1.451 5.324 1.451v-1c-2.22 0-3.742-.528-4.732-1.256-.987-.726-1.478-1.672-1.61-2.582-.136-.92.097-1.793.541-2.37.434-.563 1.06-.85 1.816-.68l.61.139v-.626c0-1.673.62-2.817 1.526-3.568.92-.764 2.179-1.155 3.49-1.214 1.31-.059 2.623.216 3.618.727ZM12 11.75l3 3h-2.25v4.5h-1.5v-4.5H9l3-3Z" clip-rule="evenodd"/></g><defs><clipPath id="a"><path fill="#fff" d="M2 5h20v14.25H2z"/></clipPath></defs></svg></span>
                        <span v-if="! form.certificate">{{ $t('Upload certificate .PEM') }}</span>
                        <span v-else>{{ $t('Replace certificate .PEM') }}</span>
                    </span>
                </AppUpload>
                <AppInput v-model="form.kundNrLeveransMottagare" class="mb-1" label="kundNrLeveransMottagare"></AppInput>
                <AppInput v-model="form.kundNrSlutkund" class="mb-1" label="kundNrSlutkund"></AppInput>
                <AppInput v-model="form.uppdragId" class="mb-1" label="uppdragId"></AppInput>
                <AppInput v-model="form.slutAnvandarId" class="mb-1" label="slutAnvandarId"></AppInput>
                <AppSelect v-model="form.mode" label="Mode">
                    <option value="active">{{ $t('Active') }}</option>
                    <option value="inactive">{{ $t('Inactive') }}</option>
<!--                    <option value="test">{{ $t('Test') }}</option>-->
                </AppSelect>
                
                <FormButton :loading="form.processing || uploading">{{ $t('Save') }}</FormButton>
            </form>
        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout.vue";
import Sheet from "../../../Shared/Sheet.vue";
import SheetIcon from "../../../Shared/SheetIcon.vue";
import SheetTitle from "../../../Shared/SheetTitle.vue";
import SparIcon from "../../../Shared/Icons/SparIcon.vue";
import AppUpload from "../../../Shared/AppUpload.vue";
import AppInput from "../../../Shared/AppInput.vue";
import AppSelect from "../../../Shared/AppSelect.vue";
import {useForm} from "@inertiajs/vue3";
import FormButton from "../../../Shared/FormButton.vue";

export default {
    props: ['settings'],
    components: {FormButton, AppSelect, AppInput, AppUpload, SparIcon, SheetTitle, SheetIcon, Sheet, Layout},
    data() {
        return {
            uploading: false,
            
            form: useForm({
                kundNrLeveransMottagare: this.settings.kundNrLeveransMottagare,
                kundNrSlutkund: this.settings.kundNrSlutkund,
                uppdragId: this.settings.uppdragId,
                slutAnvandarId: this.settings.slutAnvandarId,
                mode: this.settings.mode,
                certificate: this.settings.certificate,
            })
        }
    },
    methods: {
        onCertificateUploadComplete(response) {
            this.uploading = false
            this.form.certificate = response.data.data.file_name
        },
        store() {
            this.form.post(this.route('2.settings.spar.store'))
        }
    }
}
</script>