<template>
    <Layout>
        <Sheet>
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>Billecta</SheetTitle>
            
            <p class="mb-6 leading-6">{{ $t('This will connect your Billecta account to Octany. Before going forward, please reach out to Billecta and ask them to attach your account to Octany.') }}</p>
            <p class="mb-6 leading-6">{{ $t('Then go ahead and fill in your Billecta Creditor ID below. This is your Billecta account number.') }}</p>
           
            <form @submit.prevent="form.post(this.route('2.payments.billecta.activate.existing'))" class="mt-8">
                <AppInput :label="$t('Billecta Creditor Public Id')" v-model="form.creditor_id" :error="$attrs.errors.creditor_id" class="mb-1"></AppInput>
                
                <AppSelect :label="$t('Organization type')" v-model="form.type" :error="$attrs.errors.type">
                    <option v-for="(value, key) in types" :value="key">{{ value }}</option>
                </AppSelect>
                
                <FormError />
                
                <FormButton :loading="form.processing" type="submit">{{ $t('Continue') }}</FormButton>
            </form>
        </Sheet>
    
    </Layout>
</template>

<script>
import Layout from "../../../../Shared/Layout.vue";
import Sheet from "../../../../Shared/Sheet.vue";
import SheetIcon from "../../../../Shared/SheetIcon.vue";
import CartIcon from "../../../../Shared/Icons/CartIcon.vue";
import SheetTitle from "../../../../Shared/SheetTitle.vue";
import SheetButton from "../../../../Shared/SheetButton.vue";
import {useForm} from "@inertiajs/vue3";
import AppInput from "../../../../Shared/AppInput.vue";
import FormButton from "../../../../Shared/FormButton";
import FormError from "../../../../Shared/FormError";
import AppSelect from "../../../../Shared/AppSelect";

export default {
    props: ['types'],
    components: {
        AppSelect,
        FormError,
        FormButton,
        AppInput, SheetButton, SheetTitle, CartIcon, SheetIcon, Sheet, Layout},
    data() {
        return {
            form: useForm({
                creditor_id: null,
                type: 'non-profit',
            })
        }
    },
}
</script>