<template>
    <div class="relative">
        <div class="mb-8">
            <template v-if="this.currentImageUrl">
                <div
                    @click="$refs.upload.selectFiles"
                    class="mx-auto h-24 w-24 shadow rounded-tr-3xl rounded-bl-3xl border border-8 border-white flex items-center justify-center"
                >
                    <img :src="this.currentImageUrl" :width="150" :height="150" alt="" class="rounded-tr-2xl rounded-bl-2xl">
                </div>
            </template>
            <div v-else @click="$refs.upload.selectFiles">
                <slot name="default" />
            </div>
            <div v-if="uploadingImage" class="absolute ml-auto mr-auto w-full mt-1">
                <AppSpinner></AppSpinner>
            </div>
        </div>

        
        <div class="absolute top-0 left-0 ml-64 mt-4 text-left">
            
            <AppInfo class="inline-block" :content="$t('Image thumbnail is displayed on download screen. Use a square image JPG/PNG (300x300)')"></AppInfo>
            
            <div class="relative group cursor-pointer w-full">
                <AppUpload
                    ref="upload"
                    :url="uploadUrl"
                    :allowed-types="['image/jpeg', 'image/png', 'image/jpg']"
                    :max-file-size-mb="5"
                    visibility="public-read"
                    @upload-start="uploadingImage = true"
                    @upload-complete="onProductImageUploadComplete"
                >
                    <span class="block mt-1 underline text-xs  tracking-wider font-medium group-hover:text-black cursor-pointer">
                        <template v-if="hasImage">{{ $t('Change image') }}</template>
                        <template v-else>{{ $t('Choose image') }}</template>
                    </span>
                </AppUpload>
            </div>
            
            <a v-if="hasImage" href="" class="block mt-2 underline text-xs tracking-wider font-medium hover:text-black cursor-pointer" @click.prevent="deleteImage">
                {{ $t('Remove') }}
            </a>
        </div>
    </div>
</template>

<script>
import ProductImage from "../../../Shared/ProductImage.vue"
import AppInfo from "../../../Shared/AppInfo.vue";
import AppSpinner from "../../../Shared/AppSpinner.vue";
import AppUpload from "../../../Shared/AppUpload.vue";
import axios from "axios";

export default {
    props: ['uploadUrl', 'imageUrl', 'deleteUrl'],
    components: {
        AppUpload,
        AppSpinner,
        AppInfo,
        ProductImage
    },
    data() {
        return {
            uploadProgress: 0,
            uploadingImage: false,
            currentImageUrl: this.imageUrl,
        }
    },
    computed: {
        hasImage() {
            return this.currentImageUrl
        }
    },
    methods: {
        async deleteImage() {
            this.uploadingImage = true
            await axios.post(this.deleteUrl)
            this.uploadingImage = false
            this.currentImageUrl = null
        },
        onProductImageUploadComplete(response) {
            this.uploadingImage = false
            this.currentImageUrl = response.data.image.url
        }
    },
}
</script>