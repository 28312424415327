<template>
    <Layout>
        <Sheet size="xl">
            <SheetIcon>
                <BillectaIcon />
            </SheetIcon>
            <SheetTitle>
                {{ $t('Prepare your Direct Debit import') }}

                <template v-slot:description>
                    {{ $t('Now we need to know what the file contains') }}
                </template>
            </SheetTitle>

            <form @submit.prevent="submit">
                <div v-for="(value, index) in headers" :key="index" class="mt-4 mb-8">
                    <div class="flex">
                        <div class="w-1/2">
                            <div class="text-sm font-bold uppercase mb-2">{{ $t('In your file') }} →</div>
                            <div>{{ value }}</div>
                            <div>
                                <div v-for="row in rows" class="text-xs text-blue-300">
                                    {{ row[value] }}
                                </div>
                                <div class="text-xs text-blue-300">
                                    ...
                                </div>
                            </div>
                        </div>

                        <div class="w-1/2">
                            <div class="text-sm font-bold uppercase mb-2">{{ $t('Import to Octany field') }}</div>

                            <AppSelect v-model="form.mapping[index]">
                                <option value="skip">{{ $t('Skip this column') }}</option>
                                <option v-for="(field, value) in octanyFields" :value="value">{{ field }}</option>
                            </AppSelect>
                        </div>
                    </div>
                </div>

                <div class="mb-2">
                    <AppSelect v-model="form.product" :label="$t('Product')">
                        <option v-for="product in products" :value="product.id">{{ product.pretty_name }}</option>
                    </AppSelect>
                </div>

                <div class="mb-2">
                    <AppSelect v-model="form.locale" :label="$t('Language')">
                        <option v-for="(locale, key) in locales" :value="key">{{ locale }}</option>
                    </AppSelect>
                </div>

                <div class="mb-2">
                    <AppInput v-model="form.starts_at" :label="$t('Next debit day')" />
                </div>

                <template v-if="! hasPayerNumberMapping">
                    <AppCheckboxGroup>
                        <AppCheckbox v-model="form.requestNewMandates" :label="$t('I understand a new mandate will be created since no payer number is selected')" />
                    </AppCheckboxGroup>
                </template>

                <FormError></FormError>

                <FormButton size="lg" :loading="form.processing" type="submit">
                    {{ $t('Start import') }}
                </FormButton>
            </form>

        </Sheet>

    </Layout>
</template>

<script>
import Layout from '../../../Shared/Layout';
import Sheet from "../../../Shared/Sheet.vue";
import SheetTitle from "../../../Shared/SheetTitle.vue";
import AppSelect from "../../../Shared/AppSelect.vue";
import SheetIcon from "../../../Shared/SheetIcon.vue";
import BillectaIcon from "../../../Shared/Icons/BillectaIcon.vue";
import FormButton from "../../../Shared/FormButton.vue";
import FormError from "../../../Shared/FormError.vue";
import AppInput from "../../../Shared/AppInput.vue";
import AppCheckbox from '../../../Shared/AppCheckbox.vue'
import AppCheckboxGroup from '../../../Shared/AppCheckboxGroup.vue'

export default {
    components: {
        AppCheckboxGroup,
        AppCheckbox,
        AppInput, FormError, FormButton, BillectaIcon, SheetIcon, AppSelect, SheetTitle, Sheet, Layout},
    props: ['import', 'rows', 'headers', 'octanyFields', 'products', 'locales', 'defaultLocale', 'startsAt'],
    data() {
        return {
            form: this.$inertia.form({
                requestNewMandates: false,
                mapping: [],
                product: this.products[0].id,
                locale: this.defaultLocale,
                starts_at: this.startsAt,
            }),
        }
    },
    computed: {
        hasPayerNumberMapping() {
            return Object.values(this.form.mapping).includes('payer_number')
        }
    },
    created() {
        const defaultHeaders = Object.assign({}, this.headers);
        Object.keys(defaultHeaders).forEach(key => {
            defaultHeaders[key] = "skip";
        });
        this.form.mapping = defaultHeaders;
    },
    methods: {
        async submit() {
            this.$inertia.post(this.route('2.import.billecta.map', { import: this.import }), this.form)
            //window.location = this.route('2.import.billecta.begin', { import: this.import });
        }
    }
}
</script>
