<template>
    <a
        @click.prevent="$emit('click')"
        href=""
        class="
            border-t border-b border-lilac-300 py-2
            text-sm font-medium
            transition-all duration-50
        "
        :class="{
            'border-l border-r pl-7 pr-5 rounded-l-full': first,
            'border-r pr-7 pl-5 rounded-r-full': last,
            'pl-5 pr-5 border-r': !first && !last,
            'bg-lilac-300 text-white hover:text-white hover:bg-lilac-300': active,
            'hover:bg-lilac-50': !active
        }"
    >
        <slot />
    </a>
</template>

<script>
export default {
    props: {
        first: {
            type: Boolean,
            default: false
        },
        last: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>