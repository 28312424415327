<template>
    <div v-if="activities.length > 0" class="max-w-3xl mx-auto">
        
        <div class="text-center">
            <div class="bg-lilac-100 inline-block rounded p-4 mb-2 line-below">
                <div class="text-lilac-500">
                    <svg class="fill-current" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 10.956L8.5 4H18l-4.857 4.695L20 9.113 4 20l5.551-9.044H4z" /></svg>
                </div>
            </div>
        </div>
        
        <OptionalLink
            v-for="activity in activities"
            class="p-4 lg:p-9 bg-white shadow rounded-r-xl rounded-bl-xl mb-4 flex flex-wrap items-center line-below line-below-without-last transition duration-200 hover:shadow-lg"
            :href="activity.link"
        >
            <span class="
                block
                mr-3 lg:mr-6
                text-lilac-300
            ">
                <NoteIcon class="mx-auto" v-if="activity.action === 'note'"></NoteIcon>
                <OrderIcon class="mx-auto" v-else-if="activity.subject_type === 'order'"></OrderIcon>
                <SubscriptionIcon class="mx-auto" v-else-if="activity.subject_type === 'subscription'"></SubscriptionIcon>
                <CustomerIcon class="mx-auto" v-else-if="activity.subject_type === 'customer'"></CustomerIcon>
            </span>
            <span class="flex-1 text-blue-300 mr-6">
                <template v-if="activity.action === 'external_invoice'">
                    <template v-if="activity.data.payment_method === 'direct_debit' && activity.data.due">
                        <span v-html="$t('activity.external_invoice.direct_debit.due', { 'nr': activity.subject.billing_method_remote_data.billecta_invoice_number, 'provider': activity.data.provider, customer: activity.subject.billing_method.person.name, due: date(activity.data.due) })"></span>
                    </template>
                    <template v-else-if="activity.data.payment_method === 'direct_debit'">
                        <span v-html="$t('activity.external_invoice.direct_debit', { 'nr': activity.subject.billing_method_remote_id, 'provider': activity.data.provider, customer: activity.subject.billing_method.person.name })"></span>
                    </template>
                    <template v-else>
                        <span v-if="activity.data.delivery_method === 'email'" v-html="$t('activity.external_invoice.as_email', { 'nr': activity.subject.billing_method_remote_id, 'provider': activity.data.provider, customer: activity.subject.customer.name, 'delivery_email': activity.data.delivery_email })"></span>
                        <span v-else v-html="$t('activity.external_invoice', { 'nr': activity.subject.billing_method_remote_id, 'provider': activity.data.provider, customer: activity.subject.billing_method.person.name, 'delivery_method': activity.data.delivery_method, 'delivery_email': activity.data.delivery_email })"></span>
                    </template>
                </template>
                
                <template v-else-if="activity.action === 'renewed'">
                    <span v-html="$t('activity.renewed', { product: activity.product?.name, start: activity.data.new_period_start, end: activity.data.new_period_end, customer: activity.subject?.customer.name } )"></span>
                </template>
    
                <template v-else-if="activity.action === 'note'">
                    <span class="block mb-4" v-html="$t('activity.note', { person: activity.performer_short_name, subject: activity.subject?.name })"></span>
                    <span v-html="activity.data.note"></span>
                </template>
    
                <template v-else-if="activity.action === 'changed_billing_method'">
                    <span v-html="$t('activity.changed_billing_method', { person: activity.performer_short_name, new: activity.data.new_method_type, old: activity.data.old_method_type, subscription: activity.subject.uniq_id, product: activity.subject.product.name, customer: activity.subject.billing_method.person.name })"></span>
                </template>
    
                <template v-else-if="activity.action === 'card_payment_update'">
                    <span v-html="$t('activity.card_payment_update', { name: activity.performer.name, subscription: activity.subject.uniq_id, product: activity.subject.product.name })"></span>
                </template>
                
                <template v-else-if="activity.action === 'sent_card_payment_update'">
                    <span v-html="$t('activity.sent_card_payment_update', { email: activity.data.email, product: activity.subject.product.name, customer: activity.subject.billing_method.person.name })"></span>
                </template>
    
                <template v-else-if="activity.action === 'sent_renewal_request' && activity.data.type === 'SMS'">
                    <span v-html="$t('activity.sent_renewal_request_sms', { phone: activity.data.phone, product: activity.subject.product?.name, customer: activity.subject.customer?.name })"></span>
                </template>

                <template v-else-if="activity.action === 'charged'">
                    <span v-if="activity.data.amount > 0" v-html="$t('activity.charged', { amount: activity.data.amount_with_vat ? activity.data.amount_with_vat : activity.amount, order: activity.subject.uniq_id, customer: activity.subject.billing_method.person.name, provider: activity.data.provider })"></span>
                    <span v-else v-html="$t('activity.charged.refund', { amount: activity.amount, order: activity.subject.uniq_id, customer: activity.subject.billing_method.person.name, provider: activity.data.provider })"></span>
                </template>
    
                <template v-else-if="activity.action === 'changed_product'">
                    <span v-if="activity.performer_short_name" v-html="$t('activity.changed_product', { name: activity.performer_short_name, old: activity.data.old_name, new: activity.data.new_name, subscription: activity.subject.uniq_id, product: activity.subject.product.name, customer: activity.subject.billing_method.person.name  })"></span>
                    <span v-else v-html="$t('activity.changed_product.api', { name: activity.performer_short_name, old: activity.data.old_name, new: activity.data.new_name, subscription: activity.subject.uniq_id, product: activity.subject.product.name, customer: activity.subject.billing_method.person.name  })"></span>
                </template>
    
                <template v-else-if="activity.action === 'updated_subscription_renewal_date'">
                    <span v-html="$t('activity.updated_subscription_renewal_date', { name: activity.performer_short_name, old: activity.data.old_date, new: activity.data.new_date, subscription: activity.subject.uniq_id, product: activity.subject.product.name, customer: activity.subject.billing_method.person.name  })"></span>
                </template>
    
                <template v-else-if="activity.action === 'updated_subscription_amount'">
                    <span v-html="$t('activity.updated_subscription_amount', { name: activity.performer_short_name, old: activity.old_amount, new: activity.new_amount, subscription: activity.subject.uniq_id, product: activity.subject.product.name, customer: activity.subject.billing_method.person.name  })"></span>
                </template>
    
                <template v-else-if="activity.action === 'external_refund'">
                    <span v-if="activity.performer_short_name" v-html="$t('activity.external_refund', { name: activity.performer_short_name, amount: activity.amount, order: activity.subject.uniq_id, reason: activity.data.reason, customer: activity.subject.billing_method.person.name  })"></span>
                    <span v-else v-html="$t('activity.external_refund_without_user', { amount: activity.amount, order: activity.subject.uniq_id, customer: activity.subject.billing_method.person.name  })"></span>
                </template>
    
                <template v-else-if="activity.action === 'update_customer'">
                    <span v-html="$t('activity.update_customer', { name: activity.performer_short_name, customer: activity.subject.name  })"></span>
                </template>
    
                <template v-else-if="activity.action === 'reactivated'">
                    <span v-html="$t('activity.reactivated', { name: activity.performer_short_name, subscription: activity.subject.uniq_id, product: activity.subject.product.name, customer: activity.subject.billing_method.person.name })"></span>
                </template>
    
                <template v-else-if="activity.action === 'canceled'">
                    <span v-if="activity.performer_short_name" v-html="$t('activity.canceled', { name: activity.performer_short_name, subscription: activity.subject.uniq_id, product: activity.product.name, customer: activity.subject.billing_method.person.name })"></span>
                    <span v-else v-html="$t('activity.canceled.api', { subscription: activity.subject.uniq_id, product: activity.product.name, customer: activity.subject.billing_method.person.name })"></span>
                </template>

                <template v-else-if="activity.action === 'deleted'">
                    <span v-html="$t('activity.deleted', { subscription: activity.subject.uniq_id, product: activity.product.name, customer: activity.subject.billing_method.person.name })"></span>
                </template>

                <template v-else-if="activity.action === 'subscribed'">
                    <span v-html="$t('activity.subscribed', { name: activity.subject.billing_method.person.name, subscription: activity.subject.uniq_id, product: activity.product.name })"></span>
                </template>
                
                <template v-else-if="activity.action === 'new_order'">
                    <span v-html="$t('activity.new_order', { order: activity.subject.uniq_id, amount:  activity.amount, provider: activity.data.billing_method, customer: activity.subject.billing_method.person.name })"></span>
                </template>
                
                <template v-else-if="activity.action === 'marked_as_failed'">
                    <span v-if="activity.data.provider === 'Fortnox'" v-html="$t('activity.marked_as_failed_fortnox', { order: activity.subject.uniq_id, amount:  activity.amount, provider: activity.data.provider, customer: activity.subject.billing_method.person.name })"></span>
                    <span v-else v-html="$t('activity.marked_as_failed', { order: activity.subject.uniq_id, amount:  activity.amount, provider: activity.data.provider, customer: activity.subject.billing_method.person.name })"></span>
                </template>
                
                <template v-else-if="activity.action === 'signed'">
                    <span v-html="$t('activity.signed', { name: activity.data.name, product: activity.product.name, browser: activity.data.browser, platform: activity.data.platform })"></span>
                </template>
                
                <template v-else-if="activity.action === 'sent_email'">
                    <span v-html="$t('activity.sent_email', { subject: activity.data.subject, to: activity.data.to[0].address })"></span>
                </template>
                
                <template v-else-if="activity.action === 'billecta_autogiro_cancelled_withdrawal'">
                    {{ activity.data.title }}. {{ activity.data.content }}
                </template>
                
                <template v-else-if="activity.action === 'billecta_autogiro_withdrawal'">
                    {{ activity.data.title }}. {{ activity.data.content }}
                </template>

                 <template v-else-if="activity.action === 'fulfillment_shipped'">
                    <span v-html="$t('activity.fulfillment.shipped', { name: activity.performer_short_name, order: activity.subject.uniq_id, customer: activity.subject.billing_method.person.name })"></span>
                </template>

                 <template v-else-if="activity.action === 'fulfillment_cancelled'">
                    <span v-html="$t('activity.fulfillment.cancelled', { name: activity.performer_short_name, order: activity.subject.uniq_id, customer: activity.subject.billing_method.person.name })"></span>
                </template>

                <template v-else>
                    {{ activity.data }}
                    <br><br>
                    {{ activity.action }}
                    <br><br>
                    {{ activity }}
                </template>

            </span>
            <div class="
                text-sm text-blue-300
                w-full lg:w-auto
                mt-3 lg:mt-0
                ml-[36px] lg:ml-0
            ">
                {{ activity.created_at }}
            </div>
        </OptionalLink>
        
    </div>
</template>

<script>
import OrderIcon from "./Activities/OrderIcon";
import SubscriptionIcon from "./Activities/SubscriptionIcon";
import NoteIcon from "./Activities/NoteIcon";
import CustomerIcon from "./Activities/CustomerIcon";
import OptionalLink from "./OptionalLink";
export default {
    components: {OptionalLink, CustomerIcon, NoteIcon, SubscriptionIcon, OrderIcon},
    props: ['activities']
}
</script>

<style>
    .line-below {
        position: relative;
    }
    .line-below:after {
        content: '';
        position: absolute;
        border-left: 1px solid #E1E4FF;
        height: 16px;
        bottom: -16px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 1px;
    }
    .line-below-without-last:last-child:after {
        display: none;
    }
</style>
