<template>
    <Layout>
        <DevelopersHeader ></DevelopersHeader>
    </Layout>
</template>
<script>
import Layout from "../../Shared/Layout.vue";
import AppTabs from "../../Shared/AppTabs.vue";
import AppTab from "../../Shared/AppTab.vue";
import DevelopersHeader from "./DevelopersHeader.vue";

export default {
    components: {
        DevelopersHeader,
        AppTab,
        AppTabs,
        Layout
    }
}
</script>