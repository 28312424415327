<template>
    <div class="max-w-3xl mx-auto">
        <div class="shadow mt-6">
            <TableHeader>
                <TableHeaderColumn>
                    {{ $t('Order') }}
                </TableHeaderColumn>
            </TableHeader>
            <div class="p-7">
                <div>
                    <span class="block text-sm text-blue-300"><OrderStatus :order="order"></OrderStatus></span>
                    <span class="block text-sm text-blue-400 font-semibold">Order {{ order.id }}</span>
                    <span class="block text-sm text-blue-400">{{ date(order.created_at) }}</span>
                    <span><OrderBillingMethod :order="order"></OrderBillingMethod></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableHeaderColumn from "./TableHeaderColumn";
import TableHeader from "./TableHeader";
import OrderBillingMethod from "./OrderBillingMethod";
import OrderStatus from "./OrderStatus";

export default {
    components: {OrderStatus, OrderBillingMethod, TableHeader, TableHeaderColumn},
    props: ['order']
}
</script>