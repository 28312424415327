<template>
    <Layout>
        <div class="text-center mb-8">
            <div class="text-xl font-semibold text-blue-400 mb-1">{{ $t('Reports for {year}', { year: year }) }}</div>
        </div>
        
        <div class="max-w-3xl mx-auto relative">
            <div class="font-medium mb-3">{{ $t('Payouts') }}</div>
            <div v-if="hasAnyProvider" class="absolute right-0 -mt-14">
                <AppMenu>
                    <AppMenuItem v-if="hasProvider('trustly')" :href="route('2.reports.generator.show', { provider: 'trustly', year: year })">{{ $t('Yearly report for {provider}', { provider: 'Trustly' })}}</AppMenuItem>
                    <AppMenuItem v-if="hasProvider('swish')" :href="route('2.reports.generator.show', { provider: 'swish', year: year })">{{ $t('Yearly report for {provider}', { provider: 'Swish' })}}</AppMenuItem>
                </AppMenu>
            </div>
        </div>
        
        <div class="max-w-3xl mx-auto">
            <LinkPanel target="_blank" :href="route('2.reports.download', { report: report })" v-for="report in reports" class="mb-1">
                {{ report.pretty_name }}
            </LinkPanel>
        </div>
        
    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import LinkPanel from "../../Shared/LinkPanel.vue";
import AppMenu from '../../Shared/AppMenu.vue'
import AppMenuItem from '../../Shared/AppMenuItem.vue'

export default {
    components: {AppMenuItem, AppMenu, LinkPanel, Layout},
    props: ['year', 'reports', 'providers'],
    methods: {
        hasProvider(provider) {
            return this.providers.includes(provider)
        },
        hasAnyProvider() {
            return this.providers.length > 0
        }
    }
}
</script>