<template>
    <Layout>
        <Sheet>
            <SheetIcon :transparent="true">
                <LetterAvatar :username="billingMethod.person.first_name+' '+billingMethod.person.last_name" />
            </SheetIcon>

            <div v-if="subscription">
                <SheetTitle>{{ $t('Edit subscription billing details') }}</SheetTitle>
                <SubscriptionInfo :subscription="subscription" />
            </div>

            <div v-else>
                <SheetTitle>{{ $t('Edit order billing details') }}</SheetTitle>
            </div>

            <form @submit.prevent="form.post(this.route('2.billing-methods.store', { billingMethod: billingMethod }))">
                <h2 class="mt-8 text-lg font-medium text-blue-400 pt-6 text-center pb-6">
                    {{ $t('Primary contact') }}
                </h2>

                <div class="flex space-x-2 mb-2">
                    <div class="w-full"><AppInput :label="$t('First name')" v-model="form.first_name" :error="$attrs.errors.first_name"></AppInput></div>
                    <div class="w-full"><AppInput :label="$t('Last name')" v-model="form.last_name" :error="$attrs.errors.last_name"></AppInput></div>
                </div>

                <AppInput :label="$t('Email')" v-model="form.email" :error="$attrs.errors.email" class="mb-2"></AppInput>
                <AppInput :label="$t('Phone')" v-model="form.phone" :error="$attrs.errors.phone" class="mb-2"></AppInput>
                <AppInput :label="$t('Personal Identity Number')" v-model="form.personal_identity_number" :error="$attrs.errors.personal_identity_number" class="mb-2"></AppInput>

                <div class="mb-2">
                    <AppSelect :label="$t('Language')" v-model="form.locale" :error="form.errors.locale">
                        <option value="sv">{{ $t('Swedish') }}</option>
                        <option value="en">{{ $t('English') }}</option>
                    </AppSelect>
                </div>

                <div v-if="subscription" class="pt-4 mb-2">
                    <AppCheckbox v-model="form.receives_receipts">{{ $t('Receives payments receipts on auto renewals') }}</AppCheckbox>
                </div>

                <template v-if="isCompany">
                    <h2 class="mt-8 text-lg font-medium text-blue-400 pt-6 text-center pb-6">
                        {{ $t('Company') }}
                    </h2>

                    <AppInput :label="$t('Company/Organization')" v-model="form.company.name" :error="$attrs.errors.company?.name" class="mb-2"></AppInput>
                    <AppInput :label="$t('VAT number')" v-model="form.company.vat_number" :error="$attrs.errors.company?.vat_number" class="mb-2"></AppInput>
                </template>

                <h2 class="mt-8 text-lg font-medium text-blue-400 pt-6 text-center pb-6">
                    {{ $t('Billing address') }}
                </h2>

                <AppInput :label="$t('Line 1')" v-model="form.address.line1" :error="$attrs.errors.address?.line1" class="mb-2"></AppInput>
                <AppInput :label="$t('Line 2')" v-model="form.address.line2" :error="$attrs.errors.address?.line2" class="mb-2"></AppInput>

                <div class="flex space-x-2">
                    <div class="w-1/2">
                        <AppInput :label="$t('Postal code')" v-model="form.address.zip" :error="$attrs.errors.address?.zip" class="mb-2"></AppInput>
                    </div>
                    <div class="w-1/2">
                        <AppInput :label="$t('City')" v-model="form.address.city" :error="$attrs.errors.address?.city" class="mb-2"></AppInput>
                    </div>
                </div>

                <AppSelect :label="$t('Country')" v-model="form.address.country" :error="$attrs.errors.address?.country" class="mt-2 mb-2">
                    <option v-for="(country, code) in countries" :value="code">{{ country.name }}</option>
                </AppSelect>

                <template v-if="billingMethod.type === 'fortnox'">
                    <h2 class="mt-8 text-lg font-medium text-blue-400 pt-6 text-center pb-6">
                        {{ $t('Invoices') }}
                    </h2>

                    <div class="mb-2">
                        <AppSelect :label="$t('Delivery method')" v-model="form.invoice_delivery">
                            <option value="email">{{ $t('Send email invoice') }}</option>
                            <option value="post">{{ $t('Send physical invoice') }}</option>
                        </AppSelect>
                    </div>

                    <AppInput :label="$t('Invoice reference')" v-model="form.invoice_reference" :error="$attrs.errors.invoice_reference" class="mb-2"></AppInput>

                    <AppInput v-if="form.invoice_delivery === 'email'" :label="$t('Send invoice to')" v-model="form.invoice_email" :error="$attrs.errors.invoice_email"></AppInput>

                </template>

                <FormError />
                
                <FormButton :loading="form.processing">
                    {{ $t('Save') }}
                </FormButton>
            </form>
        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout";
import Sheet from "../../Shared/Sheet";
import SheetIcon from "../../Shared/SheetIcon";
import SheetTitle from "../../Shared/SheetTitle";
import AppInput from "../../Shared/AppInput";
import FormButton from "../../Shared/FormButton";
import FormError from "../../Shared/FormError";

import {useForm} from "@inertiajs/vue3";
import AppSelect from "../../Shared/AppSelect.vue";
import AppCheckbox from "../../Shared/AppCheckbox.vue";
import LetterAvatar from "../../Shared/LetterAvatar.vue";
import SubscriptionIcon from "../../Shared/Activities/SubscriptionIcon.vue";
import SubscriptionRenews from "../../Shared/SubscriptionRenews.vue";
import SubscriptionName from "../../Shared/SubscriptionName.vue";
import SubscriptionInfo from "../../Shared/SubscriptionInfo.vue";

export default {
    props: ['billingMethod', 'subscription', 'countries', 'address'],
    components: {
        SubscriptionInfo,
        SubscriptionName,
        SubscriptionRenews,
        SubscriptionIcon,
        LetterAvatar,
        AppCheckbox,
        AppSelect,
        FormError,
        FormButton,
        AppInput, SheetTitle, SheetIcon, Sheet, Layout
    },
    computed: {
        isCompany() {
            return this.$page.props.billingMethod.company !== null
        }
    },
    created() {
        if (this.isCompany) {
            this.form.company = {
                name: this.billingMethod.company.name,
                vat_number: this.billingMethod.company.vat_number,
            }
        }
    },
    data() {
        return {
            form: useForm({
                first_name: this.billingMethod.person.first_name,
                last_name: this.billingMethod.person.last_name,
                email: this.billingMethod.person.email,
                phone: this.billingMethod.person.phone,
                personal_identity_number: this.billingMethod.person.personal_identity_number,
                receives_receipts: this.billingMethod.person.receives_receipts,
                locale: this.billingMethod.person.locale,

                invoice_delivery: this.billingMethod.invoice_delivery,
                invoice_email: this.billingMethod.email,
                invoice_reference: this.billingMethod.invoice_reference,

                company: null,
                address: this.billingMethod.address ? this.billingMethod.address : {
                    line1: '',
                    line2: '',
                    zip: '',
                    city: '',
                    country: 'SE',
                },
            })
        }
    },
}
</script>
