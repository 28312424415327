<template>
    <div
        class="max-w-[125px] px-4"
        :class="{
            'onboarding-line': !last
        }"
    >
        <div class="flex justify-center mb-2">
            <div
                class="relative z-10 h-6 w-6 rounded-full text-white flex justify-center items-center text-sm font-semibold"
                :class="{
                    'bg-green-400': complete,
                    'bg-lilac-200': active,
                    'bg-lilac-100': !active,
                }"
            >
                <template v-if="complete">
                    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m14.034 3.466-7.94 7.94L3.175 8.49a.362.362 0 0 0-.513 0l-.853.854a.362.362 0 0 0 0 .512l4.027 4.028c.142.141.371.141.513 0l9.05-9.05a.362.362 0 0 0 0-.513l-.854-.854a.362.362 0 0 0-.512 0Z" fill="#fff"/></svg>
                </template>
                <template v-else>
                    {{ number }}
                </template>
            </div>
        </div>
        <div
            class="text-center leading-4 text-sm"
            :class="{
                'text-blue-300': active,
                'text-blue-200': !active,
            }"
        ><slot /></div>
    </div>
</template>

<style scoped>
    .onboarding-line {
        position: relative;
    }
    
    .onboarding-line:after {
        content: "";
        width: 100%;
        background-color: #e6e1de;
        position: absolute;
        height: 1px;
        left: 50%;
        top: 11px;
        z-index: 1;
    }
</style>

<script>
export default {
    props: {
        complete: { default: false },
        active: { default: false },
        number: {},
        last: { default: false }
    }
}
</script>