<template>
    <HqLayout size="lg">
        <template #header><SubscriptionsNavigation /></template>

        <SubscriptionFilters :accounts="accounts" :filters="filters" :providers="providers" />

        <SubscriptionList :subscriptions="subscriptions" :pagination="pagination" :filters="filters" />
    </HqLayout>
</template>

<script>
import HqLayout from '../HqLayout.vue'
import OrderStatus from '../../../Shared/OrderStatus.vue'
import OrderBillingMethod from '../../../Shared/OrderBillingMethod.vue'
import Pagination from '../../../Shared/Pagination.vue'
import AppDatePicker from '../../../Shared/AppDatePicker.vue'
import AppMenu from '../../../Shared/AppMenu.vue'
import AppMenuItem from '../../../Shared/AppMenuItem.vue'
import SubNavigation from '../SubNavigation.vue'
import SubscriptionFilters from './SubscriptionFilters.vue'
import SubscriptionList from './SubscriptionList.vue'
import SubscriptionsNavigation from './SubscriptionsNavigation.vue'

export default {
    props: ['subscriptions', 'pagination', 'accounts', 'providers', 'filters'],
    components: {
        SubscriptionsNavigation,
        SubscriptionList,
        SubscriptionFilters,
        SubNavigation,
        AppMenuItem, AppMenu, AppDatePicker, Pagination, OrderBillingMethod, OrderStatus, HqLayout,
    },
    methods: {},
}
</script>
