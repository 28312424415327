<template>
    <Layout>
        <OnboardingSteps :step="2"></OnboardingSteps>
        
        <Sheet>
        
            <div class="flex items-center justify-center min-h-[230px]">
                <div class="text-center">
                    <div class="inline-block mb-4">
                        <SuccesCheckbox />
                    </div>
                    <h1 class="text-blue-400 text-xl font-semibold mb-2">{{ $t('Nice one!') }}</h1>
                    <p class="text-blue-400">{{ $t('Your first product has been created') }}</p>
                </div>
            </div>
            
            <SheetButton :href="continueUrl">{{ $t('Continue') }}</SheetButton>
    
        </Sheet>
    </Layout>
</template>

<script>
    import Sheet from "../../Shared/Sheet";
    import LayoutWithoutNavigation from "../../Shared/LayoutWithoutNavigation";
    import Layout from "../../Shared/Layout";
    import SheetButton from "../../Shared/SheetButton";
    import SuccesCheckbox from "../../Shared/Icons/SuccesCheckbox";
    import OnboardingSteps from "../../Shared/OnboardingSteps";
    
    export default {
        props: ['continueUrl'],
        components: {OnboardingSteps, SuccesCheckbox, SheetButton, Layout, LayoutWithoutNavigation, Sheet}
    }
</script>