<template>
    <div class="bg-white shadow mb-1 rounded">
        <div class="flex space-x-4 p-4">
            <!-- Account -->
            <select v-model="filters.account" @change="applyFilters" class="px-4 py-2 max-w-[200px]">
                <option :value="null">{{ $t('Account') }}</option>
                <option v-for="account in accounts" :key="account.id" :value="account.id">{{ account.name }}</option>
            </select>

            <!-- Tid -->
            <AppDatePicker @change="setDateFilter" v-model="dateFilter" />

            <!-- Provider -->
            <select v-model="filters.provider" @change="applyFilters" class="px-4 py-2">
                <option :value="null">{{ $t('Provider') }}</option>
                <option v-for="(value, key) in providers" :value="key">{{ value }}</option>
            </select>

            <!-- Status -->
            <select v-model="filters.heartbeat" @change="applyFilters" class="px-4 py-2">
                <option :value="null">{{ $t('Status') }}</option>
                <option value="active">{{ $t('Active') }}</option>
                <option value="cancelled">{{ $t('Cancelled') }}</option>
                <option value="expired">{{ $t('Expired') }}</option>
                <option value="unpaid">{{ $t('Unpaid') }}</option>
            </select>
        </div>
    </div>
</template>

<script>
import AppDatePicker from '../../../Shared/AppDatePicker.vue'
import dayjs from 'dayjs'

export default {
    components: {AppDatePicker},
    props: ['filters', 'accounts', 'providers'],
    created() {
        if (! this.filters.from || ! this.filters.to) {
            this.dateFilter = []

            return
        }

        let from = dayjs(this.filters.from)
            .locale('sv')
            .format('D MMM YYYY')

        let to = dayjs(this.filters.to)
            .locale('sv')
            .format('D MMM YYYY')


        this.dateFilter = [from, to]
    },
    data() {
        return {
            dateFilter: [],
        }
    },
    methods: {
        setDateFilter(value) {
            this.filters.from = value[0]
            this.filters.to = value[1]

            this.applyFilters()
        },
        applyFilters() {
            this.$inertia.get(
                route('2.hq.subscriptions.index'),
                this.filters,
                {
                    preserveState: true,
                    preserveScroll: true,
                    replace: true
                }
            )
        }
    }
}
</script>
