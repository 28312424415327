<template>
    <div class="inline-block">
        <div
            class="flex rounded-full text-lg text-white"
            :style="{
                  backgroundColor: backgroundColor,
                  width: this.size + 'px',
                  height: this.size + 'px',
            }"
        >
            <span class="m-auto">{{ initials }}</span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LetterAvatar',
    props: {
        username: { type: String, required: true },
        size: { type: String, default: "48", required: false },
    },
    data() {
        return {
        }
    },
    computed: {
        initials() {
            return this.getInitials(this.username)
        },
        backgroundColor() {
            return this.getColor()
        },
    },
    methods: {
        getInitials(username) {
            const usernameParts = username.split(' ')
            
            const initials = usernameParts.map((e) => {
                return e.slice(0, 1).toUpperCase()
            })
            
            if (initials.length > 2) return initials.slice(0, 2).join('')
            
            return initials.join('')
        },
        getColor() {
            return this.HSLtoString(this.generateHSL(this.username, [60, 80], [40, 60]))
        },
        getHashOfString(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash)
            }
            hash = Math.abs(hash)
            
            return hash;
        },
        normalizeHash(hash, min, max) {
            return Math.floor((hash % (max - min)) + min)
        },
        generateHSL(name, saturationRange, lightnessRange) {
            const hash = this.getHashOfString(name)
            const h = this.normalizeHash(hash, 0, 360)
            const s = this.normalizeHash(hash, saturationRange[0], saturationRange[1])
            const l = this.normalizeHash(hash, lightnessRange[0], lightnessRange[1])
         
            return [h, s, l]
        },
        HSLtoString(hsl) {
            return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`
        },
    },
});
</script>