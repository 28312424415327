<template>
    <Layout :header="!returnTo">
        <OnboardingSteps v-if="$page.props.onboarding" :step="2"></OnboardingSteps>

        <Sheet :back="backLink()">
            <div class="text-center mx-auto mb-4">
                <ProductImageEdit :product="product"></ProductImageEdit>
            </div>
            <SheetTitle>
                {{ product.name }}
                <template #description>{{ product.pretty_type }}</template>
            </SheetTitle>
            
            <AppModal :open="createGroup.show" @confirm="newGroup" @cancel="createGroup.show = false">
                <div>
                    <AppInput :label="$t('Group name')" v-model="createGroup.name" @keydown.enter="newGroup"></AppInput>
                </div>
                <div class="mt-1" v-if="selectableGroupsForNesting.length > 0">
                    <p v-if="! createGroup.nest" class="mt-2"><a href="" @click.prevent="createGroup.nest = true" class="underline text-blue-300 text-sm">{{ $t('Nest group under another group') }}</a></p>
                    <AppSelect v-if="createGroup.nest" :label="$t('Nest group under')" v-model="createGroup.parent">
                        <option :value="null">{{ $t(`Choose group`) }}</option>
                        <option v-for="group in selectableGroupsForNesting" :value="group.id">
                            {{ group.name }}
                        </option>
                    </AppSelect>
                </div>
                
                <template #confirm>{{ $t('Create group') }}</template>
            </AppModal>
            
            <AppTabs v-if="supportsDigitalDelivery" class="-mt-3">
                <AppTab :first="true" @click="tab = 'product'" :active="tab === 'product'">{{ $t('Product') }}</AppTab>
                <AppTab :last="true" @click="tab = 'delivery'" :active="tab === 'delivery'">{{ $t('Delivery') }}</AppTab>
            </AppTabs>

            <form @submit.prevent="submit">
                <template v-if="tab === 'product'" >
                    <AppInput :label="$t('Name')" v-model="form.name" :error="$attrs.errors.name"></AppInput>

                    <div v-if="product.is_donation" class="mt-6">
                        <p class="text-sm mb-1">{{ $t('Three visible example donations') }}</p>

                        <div class="flex space-x-1">
                            <AppInput :label="$t('Amount')" v-model="form.predefined_values[0]"></AppInput>
                            <AppInput :label="$t('Amount')" v-model="form.predefined_values[1]"></AppInput>
                            <AppInput :label="$t('Amount')" v-model="form.predefined_values[2]"></AppInput>
                        </div>

                        <div class="mt-1">
                            <AppInput :label="$t('Minimum donation amount')" v-model="form.minimum_donation_amount" :error="$attrs.errors.minimum_donation_amount"></AppInput>
                        </div>

                        <div class="mt-1">
                            <AppSelect v-model="form.currency" :error="$attrs.errors.currency" :label="$t('Currency')">
                                <option v-for="(value, type) in currencies" :value="type">{{ value }}</option>
                            </AppSelect>
                        </div>
                    </div>

                    <div v-if="! product.is_donation" class="mt-1">
                        <div class="flex justify-between">
                            <div class="w-2/3 mr-0.5">
                                <AppInput :label="$t('Price')" v-model="form.price" class="mb-1" :error="$attrs.errors.price"></AppInput>
                            </div>

                            <div class="w-1/3 ml-0.5">
                                <AppSelect v-model="form.currency" :error="$attrs.errors.currency" :label="$t('Currency')">
                                    <option v-for="(value, type) in currencies" :value="type">{{ value }}</option>
                                </AppSelect>
                            </div>
                        </div>
                    </div>

                    <div v-if="! product.is_donation">
                        <div class="flex">
                            <div class="relative w-1/3">
                                <AppInput :label="$t('VAT')" v-model="form.vat_rate" :error="$attrs.errors.vat"></AppInput>
                                <div class="absolute right-0 top-0 mt-7 mr-4">
                                    %
                                </div>
                            </div>
                            <div class="ml-1 w-2/3">
                                <AppSelect v-model="form.is_vat_included_in_price" :label="$t('Does the price include VAT?')">
                                    <option :value="true">{{ $t('Yes') }}</option>
                                    <option :value="false">{{ $t('No') }}</option>
                                </AppSelect>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="product.type === 'App\\Products\\PhysicalDeliveryProduct'">
                        <AppInput :label="$t('Weight')" v-model="form.weight" class="mt-1" :error="$attrs.errors.currency"></AppInput>
                    </div>

                    <template v-if="product.recurring && Object.keys(product.intervals).length > 1">
                        <div class="mt-8 mb-1">
                            <AppSelect :label="$t('Payment interval')" v-model="form.interval">
                                <option v-for="(key, value) in product.intervals" :value="value">{{ key }}</option>
                            </AppSelect>
                        </div>
                    </template>

                    <div v-if="!requireShipping" class="bg-white mt-1 p-5 rounded shadow">
                        <AppCheckbox v-model="form.ask_for_postal_address">{{ $t('Ask for postal address') }}</AppCheckbox>
                    </div>
                    
                    <div class="bg-white shadow p-4 mb-1 rounded mt-1" @click="$refs.editor.focus()">
                        <div class="text-sm -mt-1 mb-2">{{ $t('Product description') }}</div>
                        <Editor
                            ref="editor"
                            class="text-blue-400 min-h-[64px]"
                            placeholder=""
                            :value="form.description"
                            @update="onUpdateProductDescription"
                            :undo="false"
                            :exclude="['headings']"
                        ></Editor>
                    </div>
                    
                    <div v-if="product.type === 'App\\Products\\PhysicalDeliveryProduct'">
                        <div class="flex justify-between space-x-1">
                            <div class="flex-1">
                                <AppCheckboxGroup class="min-h-[63px] flex items-center">
                                    <AppCheckbox v-model="form.stock_tracking">{{ $t('Track quantity') }}</AppCheckbox>
                                </AppCheckboxGroup>
                            </div>
                            <div class="w-1/2" v-if="form.stock_tracking">
                                <AppInput :label="$t('Stock quantity')" v-model="form.stock_quantity" :error="$attrs.errors.stock_quantity"></AppInput>
                            </div>
                        </div>
                    </div>

                    <div class="mt-6 relative">
                        <template v-if="selectableGroups.length > 0">
                            <AppMultiSelect v-model="groupIds" :options="selectableGroups" :label="$t('Select group')" parent="parent_id"></AppMultiSelect>
                            <div class="mt-1 text-right"><a href="" @click.prevent="createGroup.show = true" class="text-sm underline">+ {{ $t('New group') }}</a></div>
                        </template>
                        <template v-else>
                            <div class="text-center"><a href="" @click.prevent="createGroup.show = true" class="text-sm underline">+ {{ $t('Add to group') }}</a></div>
                        </template>
                        
                    </div>

                    <div v-if="form.interval === 'month' || form.interval === 'year' || form.interval === 'half-year'" class="mt-6">
                        <div v-if="!showAdvancedBillingOptions" class="text-center">
                            <a @click.prevent="showAdvancedBillingOptions = true" href="" class="text-sm text-blue-300 underline">{{ $t('Set advanced billing options') }}</a>
                        </div>

                        <div v-if="showAdvancedBillingOptions && form.interval === 'month'"  class="mb-1">
                            <AppSelect :label="$t('Billing day')" v-model="form.billing_anchor_day">
                                <option :value="null">{{ $t('Same day as they sign up') }}</option>
                                <option value="1">{{ $t('1st of the month') }}</option>
                                <option v-for="day in 27" :value="day + 1">{{ day + 1 }}</option>
                            </AppSelect>
                        </div>

                        <div v-if="showAdvancedBillingOptions && form.interval === 'year'"  class="mb-1">
                            <AppSelect :label="$t('Billing day')" v-model="form.billing_anchor_day">
                                <option :value="null">{{ $t('Same day as they sign up') }}</option>
                                <option value="1">{{ $t('1st of the month') }}</option>
                                <option v-for="day in 27" :value="day + 1">{{ day + 1 }}</option>
                            </AppSelect>
                        </div>

                        <div v-if="showAdvancedBillingOptions && form.interval === 'year'"  class="mb-1">
                            <AppSelect :label="$t('Billing month')" v-model="form.billing_anchor_month">
                                <option value="null">{{ $t('Same month as they sign up') }}</option>
                                <option value="1">{{ $t('January') }}</option>
                                <option value="2">{{ $t('February') }}</option>
                                <option value="3">{{ $t('March') }}</option>
                                <option value="4">{{ $t('April') }}</option>
                                <option value="5">{{ $t('May') }}</option>
                                <option value="6">{{ $t('June') }}</option>
                                <option value="7">{{ $t('July') }}</option>
                                <option value="8">{{ $t('August') }}</option>
                                <option value="9">{{ $t('September') }}</option>
                                <option value="10">{{ $t('October') }}</option>
                                <option value="11">{{ $t('November') }}</option>
                                <option value="12">{{ $t('December') }}</option>
                            </AppSelect>
                        </div>

                        <div v-if="showAdvancedBillingOptions" class="mt-4">
                            <AppCheckboxGroup class="mb-1">
                                <AppCheckbox v-model="offerTrial">{{ $t('Offer a paid trial') }}</AppCheckbox>
                            </AppCheckboxGroup>

                            <div class="flex space-x-1" v-if="offerTrial">
                                <div class="w-1/2">
                                    <AppInput v-model="form.trial_days" :label="$t('Trial days')" class="mb-1"></AppInput>
                                </div>

                                <div class="w-1/2 relative">
                                    <AppInput v-model="form.trial_fee" :label="$t('Trial fee')" class="mb-1"></AppInput>
                                    <div class="absolute right-0 top-0 mt-7 mr-4 uppercase">
                                        {{ form.currency }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <Media
                    class="mt-8"
                    v-if="tab === 'delivery' && supportsDigitalDelivery"
                    :product="currentProduct"
                    @is-editing="onIsEditingDigitalDelivery"
                    @update="currentProduct = $event"
                />
                
                <FormError />
                <FormButton :loading="form.processing" v-if="showSaveButton" type="submit">
                    <template v-if="$page.props.onboarding || tab !== 'product'">{{ $t('Continue') }}</template>
                    <template v-else>{{ $t('Save') }}</template>
                </FormButton>
            </form>
        </Sheet>
    </Layout>
</template>

<script>
import Layout from '../../Shared/Layout';
import Breadcrumbs from "../../Shared/Breadcrumbs";
import Breadcrumb from "../../Shared/Breadcrumb";
import TableHeader from "../../Shared/TableHeader";
import TableHeaderColumn from "../../Shared/TableHeaderColumn";
import TableRow from "../../Shared/TableRow";
import TableColumn from "../../Shared/TableColumn";
import AppButton from "../../Shared/AppButton";
import AppInput from "../../Shared/AppInput";
import AppSelect from "../../Shared/AppSelect";
import AppMultiSelect from "../../Shared/AppMultiSelect";
import FormError from "../../Shared/FormError";
import AppCheckbox from "../../Shared/AppCheckbox";
import FormButton from "../../Shared/FormButton";
import Sheet from "../../Shared/Sheet";
import SheetIcon from "../../Shared/SheetIcon";
import CartIcon from "../../Shared/Icons/CartIcon";
import SheetTitle from "../../Shared/SheetTitle";
import OnboardingSteps from "../../Shared/OnboardingSteps";
import ProductImage from "../../Shared/ProductImage.vue";
import ProductImageEdit from "./Edit/ProductImageEdit.vue";
import Media from "./Edit/Media.vue";
import AppTabs from "../../Shared/AppTabs.vue";
import AppTab from "../../Shared/AppTab.vue";
import AppCheckboxGroup from "../../Shared/AppCheckboxGroup.vue";
import AppModal from "../../Shared/AppModal.vue";
import Editor from "../../Shared/Editor.vue";
import {useForm} from "@inertiajs/vue3";

export default {
    components: {
        Editor,
        AppModal,
        AppCheckboxGroup,
        AppTab,
        AppTabs,
        Media,
        ProductImageEdit,
        ProductImage,
        OnboardingSteps,
        SheetTitle,
        CartIcon,
        SheetIcon,
        Sheet,
        FormButton,
        AppCheckbox,
        FormError,
        AppSelect,
        AppMultiSelect,
        AppInput,
        AppButton,
        TableColumn, TableRow, TableHeaderColumn, TableHeader, Breadcrumb, Breadcrumbs, Layout
    },
    props: ['product', 'currencies', 'returnTo', 'supportsDigitalDelivery', 'requireShipping', 'groups'],
    data() {
        return {
            selectableGroups: this.groups.map(group => ({ id: group.id, parent_id: group.parent_id, name: group.name })),
            selectableGroupsForNesting: this.groups.map(group => ({ id: group.id, parent_id: group.parent_id, name: group.name })).filter(group => group.parent_id === null),
            tab: 'product',
            showSaveButton: true,
            currentProduct: this.product,
            showAdvancedBillingOptions: this.product.billing_anchor_day || this.product.billing_anchor_month || this.product.trial_days > 0,
            offerTrial: this.product.trial_days > 0,
            group: null,
            nextTempGroupId: -1,
            
            createGroup: {
                show: false,
                name: null,
                parent: null,
                nest: false,
            },

            form: useForm({
                name: this.product.name,
                currency: this.product.currency,
                ask_for_postal_address: this.product.require_address,
                description: this.product.description,

                // Sometimes
                predefined_values: this.product.predefined_values,
                minimum_donation_amount: this.product.minimum_donation_amount/100,
                is_vat_included_in_price: this.product.show_price_including_vat,
                price: this.getPrice(),
                vat_rate: this.product.vat_rate,
                interval: this.product.interval,
                billing_anchor_day: this.product.billing_anchor_day,
                billing_anchor_month: this.product.billing_anchor_month,
                trial_days: this.product.trial_days,
                trial_fee: this.product.trial_fee/100,
                groups: this.product.groups ? this.product.groups.map(group => ({ id: group.id, name: group.name })) : [],
                stock_tracking: this.product.stock_tracking,
                stock_quantity: this.product.stock_quantity,
                weight: this.product.weight,
            })
        }
    },
    created() {
        const queryParams = new URLSearchParams(window.location.search)
        const tabFromUrl = queryParams.get('tab')
        
        if (tabFromUrl) {
            this.tab = tabFromUrl
        }
    },
    computed: {
        groupIds: {
            get() {
                return this.form.groups.map(group => group.id)
            },
            set(newIds) {
                this.form.groups = this.selectableGroups.filter(group => newIds.includes(group.id))
            }
        },
        showOfferTrial() {
            return this.product.trial_days > 0
        },
    },
    methods: {
        submit() {
            let route = this.route('2.products.update', { product: this.product, return_to: this.returnTo })
            
            this.form.post(route)
        },
        getPrice() {
            let price = this.product.price / 100

            if (this.product.show_price_including_vat) {
                price = this.product.price_with_vat / 100;
            }

            return price > 0 ? price : null
        },
        backLink() {
            if (this.$page.props.onboarding) {
                return false
            }

            if (this.returnTo) {
                return this.route(this.returnTo)
            }

            return this.route('2.products.index')
        },
        onIsEditingDigitalDelivery(isEditing) {
            this.showSaveButton = ! isEditing
        },
        newGroup() {
            const newGroup = {
                id: this.nextTempGroupId--, // decrement to get a new unique temp id
                name: this.createGroup.name,
                parent_id: this.createGroup.parent
            };
            
            this.selectableGroups.push(newGroup)
            
            this.groupIds = [...this.groupIds, newGroup.id]
            
            this.createGroup.show = false
            this.createGroup.name = null
            this.createGroup.parent = null
            this.createGroup.nest = false
        },
        onUpdateProductDescription(value) {
            this.form.description = value
        }
    },
    watch: {
        'offerTrial': function (val) {
            if (val) {
                this.form.trial_days = 14
                this.form.trial_fee = this.form.currency === 'sek' ? 100 : 10
            } else {
                this.form.trial_days = null
                this.form.trial_fee = null
            }
        },
        'tab': function (val) {
            const queryParams = new URLSearchParams(window.location.search)
            queryParams.set('tab', val)
            window.history.pushState({}, '', '?' + queryParams.toString());
        }
    }
}
</script>
