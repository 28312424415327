<template>
    <Layout>

        <div class="text-center mb-8">
            <div class="text-xl font-semibold text-blue-400 mb-1">{{ $t('Settings') }}</div>
        </div>

        <div class="max-w-3xl mx-auto">
            <div class="font-medium mb-3">{{ $t('Receive payments') }}</div>
        </div>

        <div class="max-w-3xl mx-auto">
            <LinkPanel :href="route('2.payments.index')" class="mb-1">
                <span class="flex items-center">
                    <span class="flex items-center w-48 text-lilac-500">
                        <span class="mr-3"><PaymentIcon /></span>
                        <span>{{ $t('Payment')}}</span>
                    </span>
                    <span class="block max-w-md">
                        {{ $t('Manage payment gateways (Stripe/Trustly/Billecta/Fortnox/Swish)') }}
                    </span>
                </span>
            </LinkPanel>
<!--            <LinkPanel :href="route('2.settings.checkout.index')" class="mb-1">-->
<!--                <span class="flex items-center">-->
<!--                    <span class="flex items-center w-48 text-lilac-500">-->
<!--                        <span class="mr-3"><CheckoutIcon /></span>-->
<!--                        <span>{{ $t('Checkout')}}</span>-->
<!--                    </span>-->
<!--                    <span class="block max-w-md">-->
<!--                        {{ $t('Sell products on your website') }}-->
<!--                    </span>-->
<!--                </span>-->
<!--            </LinkPanel>-->
            <LinkPanel :href="route('2.products.embed.donation.index')" class="mb-1">
                <span class="flex items-center">
                    <span class="flex items-center w-48 text-lilac-500">
                        <span class="mr-3"><DonationIcon /></span>
                        <span>{{ $t('Donations')}}</span>
                    </span>
                    <span class="block max-w-md">
                        {{ $t('Receive donations on your website')}}
                    </span>
                </span>
            </LinkPanel>
            <LinkPanel :href="route('2.shipping.index')" class="mb-1">
                <span class="flex items-center">
                    <span class="flex items-center w-48 text-lilac-500">
                        <span class="mr-3"><ShippingIcon /></span>
                        <span>{{ $t('Shipping')}}</span>
                    </span>
                    <span class="block max-w-md">
                        {{ $t('Manage shipping fees')}}
                    </span>
                </span>
            </LinkPanel>
        </div>

        <div class="max-w-3xl mx-auto mt-16">
            <div class="font-medium mb-3">{{ $t('Customize Octany') }}</div>
        </div>

        <div class="max-w-3xl mx-auto">
            <LinkPanel :href="route('2.appearance.index')" class="mb-1">
                <span class="flex items-center">
                    <span class="flex items-center w-48 text-lilac-500">
                        <span class="mr-3"><AppearanceIcon /></span>
                        <span>{{ $t('Appearance')}}</span>
                    </span>
                    <span class="block max-w-md">
                        {{ $t('Create trust for your customers with your logo and colors in checkout/buttons/emails') }}
                    </span>
                </span>
            </LinkPanel>
            <LinkPanel :href="route('2.custom-fields.index')" class="mb-1">
                <span class="flex items-center">
                    <span class="flex items-center w-48 text-lilac-500">
                        <span class="mr-3">
                            <FieldIcon />
                        </span>
                        <span>{{ $t('Custom fields') }}</span>
                    </span>
                    <span class="block max-w-md">
                        {{ $t('Custom Fields allows you to collect more information about a customer when they purchase a product such as birthday, greeting or phone number.') }}
                    </span>
                </span>
            </LinkPanel>
            <LinkPanel :href="route('2.users.index')" class="mb-1">
                <span class="flex items-center">
                    <span class="flex items-center w-48 text-lilac-500">
                        <span class="mr-3"><PersonIcon /></span>
                        <span>{{ $t('Team') }}</span>
                    </span>
                    <span class="block max-w-md">
                        {{ $t('Invite your team to collaborate on the platform') }}
                    </span>
                </span>
            </LinkPanel>
            <LinkPanel :href="route('2.developers.index')" class="mb-1">
                <span class="flex items-center">
                    <span class="flex items-center w-48 text-lilac-500">
                        <span class="mr-3"><SettingsIcon /></span>
                        <span>{{ $t('Developer') }}</span>
                    </span>
                    <span class="block max-w-md">
                        {{ $t('Access to API and Webhooks') }}
                    </span>
                </span>
            </LinkPanel>
        </div>
        
        <div class="max-w-3xl mx-auto mt-16">
            <div class="font-medium mb-3">{{ $t('Add-ons') }}</div>
            
            <LinkPanel :href="route('2.settings.spar.index')" class="mb-1">
                <span class="flex items-center">
                    <span class="flex items-center w-48 text-lilac-500">
                        <span class="w-28"><SparIcon /></span>
                    </span>
                    <span class="block max-w-md">
                        {{ $t('Statens personadressregister') }}
                    </span>
                </span>
            </LinkPanel>
            <LinkPanel :href="route('2.import.index')" class="mb-1">
                <span class="flex items-center">
                    <span class="flex items-center w-48 text-lilac-500">
                        <span class="w-28">
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                              <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875Zm5.845 17.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V12a.75.75 0 0 0-1.5 0v4.19l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z" clip-rule="evenodd" />
                              <path d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                            </svg>
                        </span>
                    </span>
                    <span class="block max-w-md">
                        {{ $t('Import subscriptions from other platforms') }}
                    </span>
                </span>
            </LinkPanel>
            <LinkPanel :href="route('3.integrations.sftp.index')" class="mb-1">
                <span class="flex items-center">
                    <span class="flex items-center w-48 text-lilac-500">
                        <span class="w-28">
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
                              <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 0 1 3.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 0 1 3.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 0 1-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875Zm6.905 9.97a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72V18a.75.75 0 0 0 1.5 0v-4.19l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z" clip-rule="evenodd" />
                              <path d="M14.25 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 16.5 7.5h-1.875a.375.375 0 0 1-.375-.375V5.25Z" />
                            </svg>
                        </span>
                    </span>
                    <span class="block max-w-md">
                        {{ $t('Setup auto data export to SFTP') }}
                    </span>
                </span>
            </LinkPanel>
        </div>
        
    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout"
import AppearanceIcon from "../../Shared/Icons/AppearanceIcon";
import PaymentIcon from "../../Shared/Icons/PaymentIcon";
import ArrowRightIcon from "../../Shared/Icons/ArrowRightIcon";
import LinkPanel from "../../Shared/LinkPanel";
import PersonIcon from "../../Shared/Icons/PersonIcon";
import SettingsIcon from "../../Shared/Icons/SettingsIcon";
import ProductIcon from "../../Shared/Icons/ProductIcon";
import CheckoutIcon from "../../Shared/Icons/CheckoutIcon";
import DonationIcon from "../../Shared/Icons/DonationIcon";
import ShippingIcon from "../../Shared/Icons/ShippingIcon.vue";
import SparIcon from "../../Shared/Icons/SparIcon.vue";
import FieldIcon from "../../Shared/Activities/FieldIcon.vue";

export default {
    components: {
        FieldIcon,
        SparIcon,
        ShippingIcon,
        DonationIcon,
        CheckoutIcon,
        ProductIcon, SettingsIcon, PersonIcon, LinkPanel, ArrowRightIcon, PaymentIcon, AppearanceIcon, Layout}
}
</script>
