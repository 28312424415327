<template>
    <Layout>
        <template v-slot:header>
<!--            <Breadcrumbs>-->
<!--                <Breadcrumb :active="true">{{ $t('Activities') }}</Breadcrumb>-->
<!--            </Breadcrumbs>-->
        </template>

        <template v-slot:default>
            <template v-if="activities.data.length > 0">
                <Activities :activities="activities.data" class="mt-12"></Activities>
                <Pagination :links="activities.links"></Pagination>
            </template>
            <template v-else>
                <AppTitle>{{ $t('Översikt') }}</AppTitle>
                <BlankSlate name="home" />
            </template>
        </template>
    </Layout>
</template>

<script>
import Layout from '../../Shared/Layout';
import Breadcrumbs from "../../Shared/Breadcrumbs";
import Breadcrumb from "../../Shared/Breadcrumb";
import Pagination from "../../Shared/Pagination";
import Activities from "../../Shared/Activities";
import BlankSlate from "../../Shared/BlankSlate.vue";
import AppTitle from "../../Shared/AppTitle.vue";

export default {
    components: {
        AppTitle,
        BlankSlate,
        Activities,
        Pagination,
        Breadcrumb,
        Breadcrumbs,
        Layout
    },
    props: ['activities'],
}
</script>
