<template>
    <template v-if="subscription.product">
        {{ subscription.product.name }}<template v-if="withProduct">, <SubscriptionProduct :subscription="subscription"></SubscriptionProduct></template>
    </template>
    <template v-else>
        {{ $t('Subscription {nr}', { nr: subscription.id }) }}
    </template>
    
    <template v-if="subscription.reference_id || subscription.reference_name">
        (<Reference :subscription="subscription"></Reference>)
    </template>
</template>

<script>
import Reference from "./Reference";
import SubscriptionProduct from "./SubscriptionProduct.vue";
export default {
    components: {SubscriptionProduct, Reference},
    props: {
        subscription: {
            type: Object,
            required: true
        },
        withProduct: {
            type: Boolean,
            default: false
        }
    }
}
</script>