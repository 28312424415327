export default {
    "en": {
        "accept_terms": "I have read and agree to the Octany <a href='{termsUrl}' target='_blank' class='underline'>Terms & Privacy Policy</a>",
        "accept_terms_billecta": "I have read and agree to the <a href='{termsUrl}' target='_blank' class='underline'>Billecta Terms</a>",
        "accept_email": "Keep me updated on latest Octany happenings via email",
    },
    "sv": {
        "accept_terms": "Jag har läst och godkänner Octanys <a href='{termsUrl}' target='_blank' class='underline'>villkor & integritetspolicy</a>",
        "accept_terms_billecta": "Jag har läst och godkänner <a href='{termsUrl}' target='_blank' class='underline'>Billectas villkor</a>",
        "accept_email": "Håll mig uppdaterad kring det senaste om Octany via email",
        "Confirm your merchant settings": "Bekräfta dina försäljningsuppgifter",
        "Legal company name": "Fullständigt företagsnamn",
        "EU VAT ID": "Organisationsnummer",
        "Address": "Adress",
        "Name": "Namn",
        "Save": "Spara",
        "Visible company name": "Synligt företagsnamn",
        "Terms of Service": "Villkor",
        "Continue": "Fortsätt",
        "Customers": "Kunder",
        "First seen": "Sågs först",
        "Monthly revenue": "Månadsintäkt",
        "Total": "Totalt",
        "Total revenue": "Intäkt",
        "The Feed": "Aktivitet",
        "Subscriptions": "Prenumerationer",
        "Orders": "Ordrar",
        "Products": "Produkter",
        "Reports": "Rapporter",
        "Settings": "Inställningar",
        "Quick search": "Sök",
        "Quick search for anything": "Sök på vad som helst",
        "Recently visited": "Nyligen sedda",
        "Subscription": "Prenumeration",
        "Revenue": "Intäkt",
        "mo.": "mån",
        "Price": "Pris",
        "Renews at": "Förnyas",
        "Payment method": "Betalsätt",
        "Direct Debit": "Autogiro",
        "Invoice": "Faktura",
        "activity.external_invoice": "<span class='font-bold text-blue-400'>Skapade faktura</span> {nr} på {provider} för {customer}",
        "activity.external_invoice.as_email": "<span class='font-bold text-blue-400'>Skapade faktura</span> {nr} på {provider} för {customer} som mailas till {delivery_email}",
        "activity.external_invoice.direct_debit": "Skapade faktura {nr} för betalning via <span class='font-bold text-blue-400'>Autogiro</span> för {customer}",
        "activity.external_invoice.direct_debit.due": "Skapade faktura {nr} för betalning via <span class='font-bold text-blue-400'>Autogiro</span> av {customer}. Beloppet kommer att dras {due}.",
        "activity.new_order_card": "<span class='font-bold text-green-400'>{amount}</span> har betalats för order {order}",
        "activity.renewed": "Octany förnyade prenumerationen <span class='font-bold text-blue-400'>{product}</span> för {customer} med perioden {start} till {end}",
        "activity.note": "{person} <span class='font-bold'>skrev en notering</span> för {subject}:",
        "activity.changed_billing_method": "{person} <span class='font-bold text-blue-400'>ändrade betalsätt</span> från {old} till {new} för prenumerationen på {product} för {customer}",
        "activity.card_payment_update": "{name} <span class='font-bold text-blue-400'>uppdaterade kortuppgifter</span> för prenumerationen på {product}",
        "activity.sent_card_payment_update": "<span class='font-bold text-blue-400'>Skickade en notifiering</span> till {email} om att uppdatera kortuppgifter på prenumerationen {product} för {customer}",
        "activity.sent_renewal_request_sms": "<span class='font-bold text-blue-400'>Skickade ett SMS</span> till {phone} med en betallänk för att förnya prenumerationen {product} för {customer}",
        "activity.charged": "<span class='font-bold text-green-400'>{amount}</span> har <span class='font-bold text-blue-400'>betalats</span> av {customer} för order {order} via {provider}",
        "activity.charged.refund": "En <span class='font-bold text-blue-400'>återbetalning</span> på {amount} har genomförts via {provider} för {customer}",
        "activity.changed_product": "{name} <span class='font-bold text-blue-400'>ändrade produkt</span> för prenumerationen på {product} för {customer} från {old} till {new}",
        "activity.changed_product.api": "Prenumerationen på {product} för {customer} <span class='font-bold text-blue-400'>ändrades</span> från {old} till {new} via API",
        "activity.updated_subscription_renewal_date": "{name} <span class='font-bold text-blue-400'>ändrade förnyelsedatum</span> för prenumerationen på {product} för {customer} från {old} till {new}",
        "activity.updated_subscription_amount": "{name} <span class='font-bold text-blue-400'>ändrade priset</span> för prenumerationen på {product} för {customer} från {old} till {new}",
        "activity.update_customer": "{name} <span class='font-bold text-blue-400'>uppdaterade</span> {customer}",
        "activity.reactivated": "{name} <span class='font-bold text-blue-400'>återaktiverade</span> prenumerationen på {product} för {customer}",
        "activity.canceled": "{name} <span class='font-bold text-red-400'>avslutade</span> prenumeration på {product} för {customer}",
        "activity.canceled.api": "Prenumerationen på {product} för {customer} <span class='font-bold text-red-400'>avslutades</span> via API",
        "activity.subscribed": "{name} <span class='font-bold text-blue-400'>började prenumerera</span> på {product}",
        "activity.external_refund": "{name} <span class='font-bold text-blue-400'>återbetalade</span> {amount} på order {order} för {customer}<br><br>{reason}",
        "activity.external_refund_without_user": "En <span class='font-bold text-blue-400'>återbetalning</span> på {amount} genomfördes för {order} för {customer}<br><br>{reason}",
        "activity.new_order": "<span class='font-bold text-blue-400'>Ny order</span> {order} på {amount} via {provider} för {customer}",
        "activity.marked_as_failed": "<span class='font-normal text-red-400'>Betalning misslyckades</span> för order {order} via {provider} för {customer}",
        "activity.marked_as_failed_fortnox": "<span class='font-normal text-red-400'>Kunde inte skapa faktura</span> för order {order} via {provider} för {customer}",
        "activity.signed": "{name} <span class='font-bold text-blue-400'>signerade</span> avtalet för Autogiro",
        "activity.sent_email": "Mailade <span class='font-bold text-blue-400'>{subject}</span> till {to}",
        "activity.fulfillment.shipped": "{name} <span class='font-bold text-blue-400'>skickade</span> order {order} till {customer}",
        "activity.fulfillment.cancelled": "{name} markerade order {order} till {customer} som ej skickad",
        "activity.deleted": "Prenumerationen på {product} för <span class='font-bold text-blue-400'>{customer}</span> saknar giltiga betaluppgifter och har tagits bort",
        "Subscription {id}": "Prenumeration {id}",
        "Renews at {date}": "Förnyas {date}",
        "Renews at {date} with {method}": "Förnyas {date} med {method}",
        "Signed up {date}": "Registrerades {date}",
        "Activities": "Aktiviteter",
        "card": "kort",
        "Change amount": "Ändra belopp",
        "Change product": "Byt produkt",
        "Payment history": "Betalningshistorik",
        "Change card": "Uppdatera kortuppgifter",
        "Change payment method to invoice": "Byt betalsätt till faktura",
        "Change renewal date": "Ändra förnyelsedatum",
        "Cancel subscription": "Avsluta prenumerationen",
        "Subtotal": "Deltotal",
        "VAT {rate}": "Moms {rate}",
        "Paid": "Betald",
        "Pending": "Avvaktar",
        "Failed": "Misslyckad",
        "Created": "Skapad",
        "Cancelled": "Avslutad",
        "Subscription {nr}": "Prenumeration {nr}",
        "Ends at {date}": "Avslutas {date}",
        "Ended at {date}": "Avslutad {date}",
        "Renews": "Förnyas",
        "Awaits user payment": "Inväntar betalning",
        "Product type": "Typ av produkt",
        "New product": "Ny produkt",
        "VAT": "Moms",
        "Yes": "Ja",
        "No": "Nej",
        "Does the price include VAT?": "Är moms medräknat i priset?",
        "Currency": "Valuta",
        "Payment interval": "Intervall",
        "Physical delivery": "Fysisk leverans",
        "Ask for postal address": "Fråga efter postadress",
        "Number of billing cycles": "Antal debiteringar",
        "Forever": "Oändligt",
        "1 time": "1 gång",
        "2 times": "2 gånger",
        "3 times": "3 gånger",
        "4 times": "4 gånger",
        "5 times": "5 gånger",
        "6 times": "6 gånger",
        "7 times": "7 gånger",
        "8 times": "8 gånger",
        "9 times": "9 gånger",
        "10 times": "10 gånger",
        "11 times": "11 gånger",
        "12 times": "12 gånger",
        "24 times": "24 gånger",
        "48 times": "48 gånger",
        "Subscribers": "Prenumeranter",
        "Sales": "Försäljning",
        "Export": "Exportera",
        "Created {date}": "Skapad {date}",
        "First seen {date}": "Sågs först {date}",
        "{count} subscription": "{count} prenumeration",
        "{count} subscriptions": "{count} prenumerationer",
        "{count} order": "{count} order",
        "{count} orders": "{count} ordrar",
        "Revenue: {total}": "Total intäkt: {total}",
        "Home": "Hem",
        "{date} with {method}": "{date} med {method}",
        "Renewal order by {product}": "Förnyelse via {product}",
        "Show receipt": "Visa kvitto",
        "Email receipt to customer": "Maila kvitto till kund",
        "External refund": "Extern återbetalning",
        "Billing address": "Faktureringsadress",
        "Log out": "Logga ut",
        "Switch to 1.0": "Växla till 1.0",
        "Switch account": "Byt konto",
        "Signed in as": "Inloggad som",
        "Receive payments": "Ta emot betalningar",
        "Payment": "Betalning",
        "Manage payment gateways (Stripe/Trustly/Billecta/Fortnox/Swish)": "Hantera betalväxlar (Stripe/Trustly/Billecta/Fortnox/Swish)",
        "Manage products, prices and delivery options": "Hantera produkter, priser och leveransmöjligheter",
        "Sell products on your website": "Sälj produkter på din hemsida",
        "Donations": "Donationer",
        "Checkout": "Checkout",
        "Receive donations on your website": "Ta emot donationer på din hemsida",
        "Customize Octany": "Anpassa Octany",
        "Appearance": "Utseende",
        "Create trust for your customers with your logo and colors in checkout/buttons/emails": "Skapa trygghet för dina kunder med din logga och färger i kassa/knappar/mail",
        "Users": "Användare",
        "Invite your team to collaborate on the platform": "Bjud in ditt team för att samarbeta på plattformen",
        "Developer": "Utvecklare",
        "Access to API and Webhooks": "Tillgång till API och webhooks",
        "Payments": "Betalningar",
        "Payment methods": "Betalsätt",
        "Activate multiple payment methods to allow customers to pay in their preferred way": "Aktivera flera betalsätt så att dina kunder kan betala på det smidigaste sättet för dem",
        "Payments with Swish": "Betalningar med Swish",
        "Card payments with Stripe": "Kortbetalningar med Stripe",
        "Direct debit payments with Trustly": "Autogiro med Trustly",
        "One time purchase": "Engångsköp",
        "Recurring": "Återkommande",
        "Inactive": "Ej aktiverad",
        "Active": "Aktiv",
        "Swish number": "Swishnummer",
        "You will need to have the Swish service from your bank to activate Swish.": "Du behöver ha Swish för handel hos din bank för att aktivera Swish.",
        "Get Swish för Handel": "Skaffa Swish för handel",
        "Use Swish for one time payments": "Använd Swish för engångsbetalningar",
        "Use Swish for recurring payments": "Använd Swish för återkommande betalningar",
        "A text message with a payment link will be sent on renewal": "Ett SMS med en betallänk kommer skickas vid varje förnyelse",
        "Disable Swish payments": "Inaktivera betalningar med Swish",
        "Stripe enables you to charge cards. A Stripe account will be created automatically, and you'll be able to make payouts to your bank account every week.": "Stripe gör det möjligt att debitera kort. Ett Stripe-konto kommer att skapas automatiskt, och utbetalningar kan göras varje dag/vecka till ditt bankkonto.",
        "Connect Stripe": "Anslut Stripe",
        "You are connected to Stripe": "Du är ansluten till Stripe",
        "Disable card payments with Stripe": "Stäng av kortbetalningar via Stripe",
        "Disable card payments": "Stäng av kortbetalningar",
        "Are you sure you want to disable card payments via Stripe for {name}? No one will be able to pay with their card.": "Är du säker på att du vill stänga av kortbetalningar med Stripe för {name}?",
        "Back": "Tillbaka",
        "Cancel": "Avbryt",
        "Card payments with Stripe has not yet been fully enabled on your account. Please continue to Stripe to see the current status of your account.": "Kortbetalningar via Stripe har inte aktiverats ännu. Fortsätt gärna till Stripe för att se om du behöver komplettera någon information.",
        "Continue to Stripe": "Fortsätt till Stripe",
        "Invoice payments with Fortnox": "Fakturera med Fortnox",
        "Invoices payments with Fortnox enables Octany to send invoices automatically on your Fortox account": "Låt Octany skapa fakturor och skicka dem via ditt Fortnox-konto.",
        "Connect Fortnox": "Anslut Fortnox",
        "You are connected to Fortnox. Please reach out to the support team if you need assistance with your Fortnox connection": "Du är ansluten till Fortnox. Kontakta supporten om du behöver göra förndringar i din koppling.",
        "Reconnect Fortnox": "Återanslut till Fortnox",
        "Disable Fortnox": "Inaktivera Fortnox",
        "Activate Fortnox": "Aktivera Fortnox",
        "Sign in to Octany": "Logga in på Octany",
        "Email address": "E-postadress",
        "Password": "Lösenord",
        "Sign in": "Logga in",
        "Reset password": "Återställ lösenord",
        "Reset your password": "Återställ ditt lösenord",
        "Choose a new password": "Välj ett nytt lösenord",
        "Choose account": "Välj konto",
        "It looks like you already got an Octany account. Do you want to create a new one, or use an existing account?": "Det ser ut som du redan har ett Octany-konto. Vill du använda det, eller skapa ett nytt?",
        "Create new account": "Skapa ett nytt konto",
        "Activate Octany": "Aktivera Octany",
        "Company/Organization": "Företag/organisation",
        "VAT Number": "Organisationsnummer",
        "Keep me updated on latest Octany happenings via email": "Håll mig uppdaterad kring det senaste om Octany via email",
        "I want to receive news and the latest happenings about Octany via email": "Jag vill få de senaste nyheterna och händelserna om Octany via email",
        "Three visible example donations": "Tre synliga donationssummor",
        "Amount": "Belopp",
        "Minimum donation amount": "Minsta donationsumma",
        "Activate Octany Pay": "Aktivera Octany Pay",
        "Create product": "Skapa produkt",
        "Activate payments": "Aktivera betalsätt",
        "Connect button to product": "Koppla knapp till produkt",
        "Nice one!": "Yihaa!",
        "Your first product has been created": "Din första produkt har skapats",
        "Pay with card": "Betala med kort",
        "We recommend to start accepting cards first to get started instantly": "Vi rekommenderar att starta med kort om du vill börja ta betalt direkt.",
        "Direct Debit, Card, Invoice or Swish": "Autogiro, faktura, kort eller Swish",
        "You might accept multiple payment methods to make allow your customer to however they want. You can add more methods later on.": "Din produkt kan ha flera betalsätt kopplade vilket gör det smidigt för köpare att betala på det sätt de är vana vid. Du kan lägga till fler betalsätt senare",
        "Activate card payments": "Aktivera kortbetalning",
        "I'll do this later": "Jag gör det senare",
        "Add a block with button on a page": "Lägg in ett block med knapp på en sida",
        "Link the button to a product": "Länka knappen till din produkt",
        "Choose page to link button": "Välj sida där du vill länka knapp",
        "Add Octany to your website": "Lägg in på hemsida",
        "You are ready to add Octany to your website to accept donations. Grab the code and add it to your website. That's it!": "Du är redo att lägga in Octany på din hemsida för att ta emot donationer. Hämta koden och klistra in den på sin hemsida.",
        "You are ready to add Octany to your website to accept payments. Grab the code and add it to your website. That's it!": "Du är redo att lägga in Octany på din hemsida för att ta emot betalningar. Hämta koden och klistra in den på sin hemsida.",
        "Show code": "Hämta koden",
        "Success!": "Du är redo!",
        "Start selling anything on your website. Click a button and link it to a product": "Nu kan du börja sälja var som helst på din hemsida. Klicka på en knapp och välj länka till produkt.",
        "Today": "Idag",
        "By activating Direct Debit for {company} ({number}) you are creating an account at our partner Billecta.": "Genom att aktivera Autogiro för {company} ({number}) skapar du ett konto hos vår partner Billecta.",
        "Primary contact": "Kontaktperson",
        "First name": "Förnamn",
        "Last name": "Efternamn",
        "Email": "E-post",
        "Line 2": "Adressrad 2",
        "Postal code": "Postnummer",
        "City": "Ort",
        "Bankgiro number": "Bankgironummer",
        "Organization type": "Organisation",
        "We already have a Billecta account": "Vi har redan ett Billecta-konto",
        "This will connect your Billecta account to Octany. Before going forward, please reach out to Billecta and ask them to attach your account to Octany.": "Detta kommer att koppla ditt Billecta-konto till Octany. Börja med att ta kontakt med Billecta och be dem att koppla ditt konto till Octany.",
        "Then go ahead and fill in your Billecta Creditor ID below. This is your Billecta account number.": "När det är klart kan du skriva in ditt Billecta Creditor ID (det är ditt Billecta-kontonummer)",
        "Company address": "Företagsadress",
        "To activate Autogiro we need your Autogiro customer number from your bank. Please send the following PDF to your bank to get your number:": "För att aktivera Autogiro behöver vi ditt Autogiro-kundnummer från din bank. Skicka följande PDF till din bank för att få ditt nummer:",
        "bgc-instructions.pdf": "bgc-instruktioner.pdf",
        "Autogiro customer number": "Autogiro-kundnummer",
        "Autogiro with Billecta is active": "Autogiro via Billecta är aktivt",
        "Remove Billecta": "Stäng av Billecta",
        "Billecta disabled": "Billecta har stängts av",
        "Direct Debit payments with Billecta": "Autogiro med Billecta",
        "Direct Debit payments with Trustly": "Autogiro med Trustly",
        "Remove": "Ta bort",
        "Color theme": "Färgtema",
        "Primary color": "Primärfärg",
        "Primary text color": "Primär textfärg",
        "Upcoming": "Kommande",
        "1 sale": "1 order",
        "{count} sales": "{count} ordrar",
        "1 active subscriber": "1 aktiv prenumerant",
        "{count} active subscribers": "{count} aktiva prenumeranter",
        "Edit product": "Redigera produkt",
        "Edit emails": "Redigera mail",
        "Delete": "Ta bort",
        "{product} emails": "Mail för {product}",
        "Last changed {date}": "Senast ändrad {date}",
        "Use default email": "Använd standardmail",
        "Change image": "Ändra bild",
        "Choose image": "Välj bild",
        "Sorry, that file is too big. The maximum file size is {size}": "Tyvärr är filen du valt för stor. Maximal filstorlek är {size}",
        "Sorry, we only accept JPEG and PNG images.": "Bilden du laddar upp måste vara i formatet JPEG eller PNG.",
        "Download": "Ladda ner",
        "Digital delivery": "Digital leverans",
        "These files will be available to customers after purchase": "Ladda upp filer som kunder får tillgång till efter köp",
        "Add file": "Lägg till fil",
        "Link": "Länk",
        "Sorry, something went wrong. Please try again": "Något gick fel. Försök igen",
        "Link name": "Namn på länken",
        "Product": "Produkt",
        "Delivery": "Leverans",
        "File name": "Filnamn",
        "Are you sure you want to delete this file?": "Är du säker på att du vill ta bort filen?",
        "Open": "Öppna",
        "Your information": "Din information",
        "Your name": "Ditt namn",
        "Embed product": "Lägg in produkt",
        "Choose product": "Välj produkt",
        "Embed on website": "Lägg in på hemsida",
        "Your Billecta account could not be created. Please get in touch with the support team": "Ditt Billecta-konto kunde inte skapas. Kontakta supporten",
        "Retry create Billecta account": "Försök skapa Billecta-konto igen",
        "Show": "Visa",
        "Send preview": "Skicka förhandsgranskning",
        "Re-send welcome email": "Skicka välkomstmail igen",
        "Disable": "Inaktivera",
        "Activate": "Aktivera",
        "Import data": "Importera data",
        "Import subscriptions from other platforms": "Importera prenumerationer från andra plattformar",
        "Import Direct Debit subscriptions to your account": "Importera Autogiro-prenumerationer till ditt konto",
        "Import Direct Debit": "Importera Autogiro",
        "Please make sure you have an .XLSX file with first name, last name, personal identitity number and payer number before starting your import.": "Se till att du har en .XLSX med förnamn, efternamn, personnummer och betalarnummer innan du startar importen.",
        "Upload .XLSX": "Ladda upp .XLSX",
        "Prepare your Direct Debit import": "Förbered din Autogiro-import",
        "Now we need to know what the file contains": "Nu behöver vi veta vad filen innehåller",
        "In your file": "I din fil",
        "Import to Octany field": "Importera till Octany-fält",
        "Skip this column": "Hoppa över kolumnen",
        "Start import": "Importera",
        "Edit Appearance": "Redigera utseende",
        "Primary Color": "Primärfärg",
        "Primary Text Color": "Text på primärfärg",
        "Change": "Ändra",
        "Email color settings": "Färginställningar för mail",
        "Change email colors": "Ändra färg på mail",
        "Button & Icon": "Knapp & ikon",
        "Button text color": "Knappens textfärg",
        "Heading": "Rubrik",
        "Meta": "Minitext",
        "Background": "Bakgrund",
        "Content area": "Innehållsyta",
        "Reset colors": "Återställ färger",
        "Colors": "Färger",
        "January": "Januari",
        "February": "Februari",
        "March": "Mars",
        "April": "April",
        "May": "Maj",
        "June": "Juni",
        "July": "Juli",
        "August": "Augusti",
        "September": "September",
        "October": "Oktober",
        "November": "November",
        "December": "December",
        "1st of the month": "1:a i månaden",
        "Same day as they sign up": "Samma dag som de registrerar sig",
        "Same month as they sign up": "Samma månad som de registrerar sig",
        "Set advanced billing options": "Ställ in avancerade debiteringsalternativ",
        "Billing day": "Debiteringsdag",
        "Billing month": "Debiteringsmånad",
        "Edit subscription billing details": "Redigera uppgifter för prenumeration",
        "Phone": "Telefon",
        "Personal Identity Number": "Personnummer",
        "Receives payments receipts on auto renewals": "Får kvitto vid automatisk förnyelse",
        "Company": "Företag",
        "VAT number": "Organisationsnummer",
        "Add additional charge": "Tilläggsdebitering",
        "The new price will take effect on {date}": "Det nya priset kommer att gälla från {date}",
        "Edit subscription price": "Redigera pris",
        "The subscription will be changed to the new product immediately and the price difference for the new product price will be charged today. Switching to a product with less cost will add a discount at the next renewal on {date}": "Prenumerationen kommer att ändras till den nya produkten direkt och mellanskillnaden kommer att debiteras idag. Ett byte till en produkt med lägre kostnad lägger på en rabatt på kommande debitering {date}",
        "The subscription will be changed to the new product immediately and the price difference for the new product price will be charged today.": "Prenumerationen kommer att ändras till den nya produkten direkt och mellanskillnaden kommer att debiteras idag.",
        "Choose new product": "Välj ny produkt",
        "Edit": "Redigera",
        "Shipping": "Frakt",
        "Manage shipping fees": "Hantera fraktavgifter",
        "New delivery zone": "Ny leveranszon",
        "New shipping rate": "Ny fraktavgift",
        "Delivery zones": "Leveranszoner",
        "Deliver from": "Leverera från",
        "New shipping zone": "Ny fraktzon",
        "Regions": "Regioner",
        "Countries": "Länder",
        "New shipping rate for {zone}": "Ny fraktavgift för {zone}",
        "Edit {rate} for {zone}": "Redigera {rate} för {zone}",
        "Add condition": "Lägg till villkor",
        "Remove condition": "Ta bort villkor",
        "Based on product weight": "Baserat på produktvikt",
        "Based on order value excl. VAT": "Baserat på ordervärde exkl. moms",
        "City/Town": "Ort",
        "Edit location": "Redigera plats",
        "Send invoices to": "Skicka fakturor till",
        "Send receipts to": "Skicka kvitton till",
        "Edit order billing details": "Redigera uppgifter för order",
        "Contact details": "Kontaktuppgifter",
        "Shipping address": "Leveransadress",
        "Swedish": "Svenska",
        "English": "Engelska",
        "Language": "Språk",
        "When selling physical items we recommend adding a shipping rate. The name can be used to describe the carrier and delivery time.": "När du säljer fysiska produkter rekommenderar vi att lägga till en fraktavgift. Namnet kan användas för att beskriva transportören och leveranstiden.",
        "Use another payment method": "Använd ett annat betalsätt",
        "Trial ends {date}": "Provperiod avslutas {date}",
        "Charge customer": "Debitera kund",
        "Offer a paid trial": "Erbjud en betald provperiod",
        "Trial days": "Antal dagar",
        "Trial fee": "Avgift för provperiod",
        "{days} days trial for {amount}": "{days} dagars provperiod för {amount}",
        "Choose group": "Välj grupp",
        "Create new group": "Skapa ny grupp",
        "Add product to group": "Lägg till produkt i grupp",
        "Select group": "Välj grupp",
        "New group": "Ny grupp",
        "There are no groups": "Det finns inga grupper",
        "Product name": "Produktnamn",
        "Nest group under": "Lägg gruppen under",
        "Nest group under another group": "Lägg gruppen under en annan grupp",
        "Group name": "Gruppnamn",
        "Add to group": "Lägg i grupp",
        "Create group": "Skapa grupp",
        "Don't add any shipping rate right now": "Lägg inte till någon fraktavgift just nu",
        "Price excl. VAT": "Pris exkl. moms",
        "Product description": "Produktbeskrivning",
        "Donations are coming soon. If you want to accept donations right now, get in touch with the support team and we will help you.": "Donationer kommer snart. Om du vill ta emot donationer redan nu, kontakta supporten så hjälper vi dig.",
        "Coming soon" : "Kommer snart",
        "This add-on enables individuals to provide their Swedish social security number during a purchase or donation. Through SPAR, their name and address details are retrieved. Enter your agreement with SPAR here to get started.": "Detta tillägg gör det möjligt för privatpersoner att ange sitt personnummer vid köp eller donation. Genom SPAR hämtas deras namn- och adressuppgifter. Ange ditt avtal med SPAR här för att komma igång.",
        "Description": "Beskrivning",
        "Track quantity": "Lagerhantering",
        "Stock quantity": "Antal",
        "New order for {customer}": "Ny order för {customer}",
        "Add item": "Lägg till rad",
        "Charge card": "Debitera kort",
        "Create order": "Skapa order",
        "Quantity": "Antal",
        "Weight": "Vikt",
        "Close": "Stäng",
        "Add to website": "Lägg in på hemsida",
        "Replace certificate .PEM": "Ersätt certifikat .PEM",
        "Imported subscribers": "Importerade prenumeranter",
        "We can't charge for this subscription since it has been cancelled by Stripe. If you reactive the subscription we will attempt to charge the card on file today.": "Vi kan inte debitera för den här prenumerationen eftersom den är avslutad hos av Stripe. Om du återaktiverar prenumerationen kommer vi att försöka debitera kortet idag.",
        "Reactivate subscription": "Återaktivera prenumeration",
        "Revenue reports": "Intäktsrapporter",
        "Payouts": "Utbetalningar",
        "Payouts from {providers}": "Utbetalningar från {providers}",
        "Reports for {year}": "Rapporter för {year}",
        "Get a report of all subscription revenue during a specific interval": "Rapporten redovisar alla intäkter för prenumerationer under valt intervall",
        "Get a report of all order revenue during a specific interval": "Rapporten redovisar alla intäkter för ordrar under valt intervall",
        "Create report": "Skapa rapport",
        "Select a date": "Välj ett datum",
        "Select a period": "Välj en period",
        "Yesterday": "Igår",
        "Last {period} days": "Senaste {period} dagarna",
        "This month": "Denna månad",
        "Last month": "Förra månaden",
        "Sorry, reports are not available right now.": "Tyvärr är inte rapporter tillgängliga just nu.",
        "Use the Octany API to create integrations with other systems.": "Använd Octanys API för att skapa integrationer med andra system.",
        "Generate new API token": "Skapa ny API-nyckel",
        "Create": "Skapa",
        "API Tokens": "API-nycklar",
        "Last used {date}": "Senast använd {date}",
        "Never used": "Aldrig använd",
        "Signing secret": "Hemlighet",
        "Your team has access all Octany. They can edit customer data and cancel subscriptions": "Ditt team har tillgång till hela Octany. De kan redigera kundinformation och avsluta prenumerationer.",
        "Invite a new team member": "Bjud in en person till ditt team",
        "Invite": "Bjud in",
        "Welcome to Octany": "Välkommen till Octany",
        "Timezone": "Tidszon",
        "My profile": "Min profil",
        "Notifications": "Notifieringar",
        "New subscriptions": "Nya prenumerationer",
        "Activity": "Aktivitet",
        "Search": "Sök",
        "Search is currently not available": "Det går tyvärr inte att söka just nu",
        "Create invoice": "Skapa faktura",
        "Show invoice": "Visa faktura",
        "Recurring donation": "Återkommande donation",
        "Payment link": "Betalningslänk",
        "Custom fields": "Anpassade fält",
        "Custom Fields allows you to collect more information about a customer when they purchase a product such as birthday, greeting or phone number.": "Samla in mer information vid en betalning, som födelsedag, hälsning eller telefonnummer.",
        "New field": "Nytt fält",
        "{products} and {product}": "{products} och {product}",
        "New custom field": "Nytt anpassat fält",
        "Label": "Namn",
        "Attach it to these products": "Använd på följande produkter",
        "Create field": "Skapa fält",
        "Field is mandatory": "Fältet är obligatoriskt",
        "Edit custom field": "Redigera anpassat fält",
        "Type": "Typ",
        "Attached to:": "Används på:",
        "Update": "Uppdatera",
        "Customer reports": "Kundrapporter",
        "Export all customers to Excel": "Exportera alla kunder till Excel",
        "View all": "Visa alla",
        "There are no orders matching the current filter": "Det finns inga ordrar som matchar ditt filter",
        "Edit renewal date": "Redigera förnyelsedatum",
        "This will change {customer}'s subscription on {product}. The current renewal date is {date} and will be changed to a date you choose:": "Detta kommer att ändra {customer}s prenumeration på {product}. Det nuvarande förnyelsedatumet är {date} och kommer att ändras till ett datum du väljer:",
        "Renewal date": "Förnyelsedatum",
        "Edit custom fields": "Redigera anpassade fält",
        "Bookkeep invoices": "Bokför fakturor automatiskt",
        "Send invoices via email automatically": "Skicka fakturor via mail direkt",
        "Revenue account for swedish customers": "Intäktskonto för svenska kunder",
        "Revenue account for EU customers": "Intäktskonto för EU-kunder",
        "Revenue account for non-EU customers": "Intäktskonto för kunder utanför EU",
        "Invoice fee for postal delivery": "Faktureringsavgift för postleverans",
        "Invoice fee for email delivery": "Faktureringsavgift för mailleverans",
        "Days until due": "Dagar till förfallodag",
        "Purchase confirmations": "Bekräftelse vid köp",
        "Screen": "Skärm",
        "After purchase": "Efter köp",
        "Displayed on the screen after someone buys this product.": "Visas på skärmen efter att någon köpt produkten.",
        "Confirmations": "Bekräftelser",
        "Edit confirmation": "Redigera bekräftelse",
        "This affects all confirmations for {name}": "Detta påverkar alla bekräftelser för {name}",
        "Title": "Titel",
        "Text": "Text",
        "Button text": "Text på knapp",
        "Restore default": "Använd standard",
        "Monthly donor": "Månadsgivare",
        "Retry payment": "Försök debitera igen",
        "Change colors": "Ändra färger",
        "Your product's VAT will be applied to the shipping price.": "Dina produkters moms kommer att appliceras på priset.",
        "We will subtract {rate}% from the price you have entered": "Vi kommer att räkna bort {rate}% från priset du har valt",
        "Condition": "Villkor",
        "With {rate}% VAT": "Med {rate}% moms",
        "All orders": "Alla ordrar",
        "One time purchases": "Engångsbetalningar",
        "Delete order": "Ta bort order",
        "Manual payment methods": "Manuella betalsätt",
        "Payments made outside of your store.": "Betalningar gjorda utanför din butik.",
        "New manual payment method": "Nytt manuellt betalsätt",
        "A payment method could be a bank transfer, a check or a cash payment.": "Ett betalsätt kan vara en faktura, banköverföring, kontant betalning.",
        "Custom payment method name": "Namn på manuellt betalsätt",
        "Payment instructions": "Betalningsinstruktioner",
        "Payment instructions are shown to the customer after a checkout.": "Betalningsinstruktioner visas för kunden.",
        "Please enter a name for the method": "Ange ett namn för betalsättet",
        "A method with this name already exists.": "Ett betalsätt med det namnet finns redan.",
        "Add payment method": "Lägg till betalsätt",
        "Checkout flow": "Betalningsflöde",
        "Fixed fields": "Fasta fält",
        "Manual payment method": "Manuellt betalsätt",
        "Awaiting payment": "Inväntar betalning",
        "Fulfill order": "Skicka order",
        "Unfulfill": "Markera som ej skickad",
        "Order shipped {date}": "Order skickad {date}",
        "This order will now be marked as fulfill and shipped": "Den här ordern kommer markeras som skickad",
        "Not fulfilled": "Ej skickad",
        "Mark as paid": "Markera som betald",
        "Order fulfillment": "Hantering av order",
        "This order will now be marked as paid. Please make sure you have received the payment since we will not capture manual payments.": "Den här ordern kommer nu markeras som betald. Se till att du har mottagit betalningen eftersom vi inte hanterar manuella betalningar.",
        "Choices": "Val",
        "Add choice": "Lägg till ett val",
        "Next debit day": "Nästa debitering",
        "I understand a new mandate will be created since no payer number is selected": "Jag förstår att ett nytt medgivande kommer att skapas eftersom inget betalarnummer är valt",
        "Awaiting creation on Billecta": "Väntar på att skapas på Billecta",
        "New orders": "Nya beställningar",
        "Yearly report for {provider}": "Skapa årsrapport för {provider}",
        "Payment cancelled": "Betalning avbruten",
        "Card": "Kort",
        "Account": "Konto",
        "State": "Status",
        "Provider": "Betalsätt",
        "Confirmed": "Bekräftad",
        "Resend confirmation email": "Skicka bekräftelsemail igen",
        "New SFTP channel": "Ny SFTP-kanal",
        "Hostname": "Värdnamn",
        "Username": "Användarnamn",
        "Port": "Port",
        "Root directory": "Rotkatalog",
        "Export customers": "Exportera kunder",
        "Export orders": "Exportera ordrar",
        "Export subscriptions": "Exportera prenumerationer",
        "Remove special characters from header row": "Ta bort specialtecken från rubrikraden",
        "Connections": "Anslutningar",
        "New SFTP connection": "Ny SFTP-anslutning",
        "SFTP channels": "SFTP-kanaler",
        "Run": "Kör",
        "Setup auto data export to SFTP": "Ställ in automatisk data-export till SFTP",
        "Active, runs daily": "Aktiv, körs dagligen",
        "Edit SFTP channel": "Redigera SFTP-kanal",
        "Delivery method": "Leveransmetod",
        "Send email invoice": "Skicka faktura via mail",
        "Send physical invoice": "Skicka pappersfaktura",
        "Invoices": "Fakturor",
        "Send invoice to": "Maila faktura till",
        "Invoice reference": "Referens",
        "": ""
    }
}
