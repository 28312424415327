<template>
    <Layout>
        <Sheet>
            <SheetIcon><SubscriptionIcon /></SheetIcon>
            <SheetTitle>{{ $t('Edit subscription price') }}</SheetTitle>
            <SubscriptionInfo :subscription="subscription" :icon="false" />

            <form @submit.prevent="form.post(this.route('2.subscriptions.price.update', { subscription: subscription }))">
                <div class="flex space-x-1">
                    <div class="w-2/3"><AppInput :label="$t('Price')" v-model="form.price"></AppInput></div>
                    <div class="w-1/3 relative">
                        <AppInput :label="$t('VAT')" v-model="form.vat" disabled></AppInput>
                        <div class="absolute right-0 top-0 mt-7 mr-4">
                            %
                        </div>
                    </div>
                </div>

                <p class="text-center mt-12">{{ $t('The new price will take effect on {date}', { date: date(subscription.renews_at) }) }}</p>

                <FormButton :loading="form.processing">{{ $t('Save') }}</FormButton>
            </form>

        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout.vue";
import Sheet from "../../../Shared/Sheet.vue";
import SheetIcon from "../../../Shared/SheetIcon.vue";
import SubscriptionIcon from "../../../Shared/Activities/SubscriptionIcon.vue";
import SheetTitle from "../../../Shared/SheetTitle.vue";
import SubscriptionInfo from "../../../Shared/SubscriptionInfo.vue";
import AppInput from "../../../Shared/AppInput.vue";
import FormButton from "../../../Shared/FormButton.vue";

export default {
    props: ['subscription'],

    components: {
        FormButton,
        AppInput,
        SubscriptionInfo,
        SubscriptionIcon,
        SheetTitle,
        SheetIcon,
        Sheet,
        Layout
    },

    data() {
        return {
            form: this.$inertia.form({
                price: this.subscription.price/100,
                vat: this.subscription.vat/100,
            })
        }
    },
}
</script>