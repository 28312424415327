<template>
    <LayoutWithoutNavigation>
        <Sheet>
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>{{ $t('Sign in to Octany') }}</SheetTitle>
            <form @submit.prevent="submit">
                <AppInput :label="$t('Email address')" v-model="form.email" type="email" :error="$attrs.errors.email" class="mb-1" />
                <AppInput :label="$t('Password')" v-model="form.password" type="password" :error="$attrs.errors.password" ref="password" />
                <FormError />
                <FormButton>{{ $t('Sign in') }}</FormButton>
            </form>
        </Sheet>
        <div class="text-center text-sm text-blue-200 mt-6">
            <InertiaLink :href="route('2.auth.amnesia' +
             '')" class="text-blue-300 hover:underline">
                {{ $t('Reset password') }}
            </InertiaLink>
        </div>
    </LayoutWithoutNavigation>
</template>
<script>
import LayoutWithoutNavigation from "../../Shared/LayoutWithoutNavigation";
import Sheet from "../../Shared/Sheet";
import SheetIcon from "../../Shared/SheetIcon";
import SheetTitle from "../../Shared/SheetTitle";
import CartIcon from "../../Shared/Icons/CartIcon";
import AppInput from "../../Shared/AppInput";
import FormError from "../../Shared/FormError";
import FormButton from "../../Shared/FormButton";

export default {
    props: ['email'],
    components: {FormButton, FormError, AppInput, CartIcon, SheetTitle, SheetIcon, Sheet, LayoutWithoutNavigation},
    data() {
        return {
            form: this.$inertia.form({
                email: this.email,
                password: null,
            })
        }
    },
    mounted() {
        if (this.form.email) {
            this.$refs.password.focus()
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('2.auth.login'))
        }
    }
}
</script>