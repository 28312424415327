<template>
    <Layout>
        <Sheet>
            <SheetIcon>
                <FieldIcon />
            </SheetIcon>
            <SheetTitle>{{ $t('New custom field') }}</SheetTitle>
            <CustomFieldForm method="create" :products="products" :types="types" :checkoutFlows="checkoutFlows" />
        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import Sheet from "../../Shared/Sheet.vue";
import SheetTitle from "../../Shared/SheetTitle.vue";
import AppInput from "../../Shared/AppInput.vue";
import AppSelect from "../../Shared/AppSelect.vue";
import {useForm} from "@inertiajs/vue3";
import AppCheckboxGroup from "../../Shared/AppCheckboxGroup.vue";
import AppCheckbox from "../../Shared/AppCheckbox.vue";
import FormError from "../../Shared/FormError.vue";
import SheetButton from "../../Shared/SheetButton.vue";
import SheetIcon from "../../Shared/SheetIcon.vue";
import CheckoutIcon from "../../Shared/Icons/CheckoutIcon.vue";
import FieldIcon from "../../Shared/Activities/FieldIcon.vue";
import CustomFieldForm from "./CustomFieldForm.vue";

export default {
    components: {
        CustomFieldForm,
        FieldIcon,
        CheckoutIcon,
        SheetIcon, SheetButton, FormError, AppCheckbox, AppCheckboxGroup, AppSelect, AppInput, SheetTitle, Sheet, Layout
    },
    props: ['types', 'products', 'checkoutFlows'],
}
</script>