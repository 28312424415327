<template>
    <Layout>

        <div class="text-center mb-8">
            <div class="text-xl font-semibold text-blue-400 mb-1">{{ $t('SFTP channels') }}</div>
        </div>

        <div class="max-w-3xl mx-auto mb-12">
            <div class="font-medium mb-3 flex justify-between">
                <div>{{ $t('Connections') }}</div>
                <div><a :href="route('3.integrations.sftp.create')" class="border border-blue-200 py-1 px-3 rounded text-blue-300 text-sm hover:border-blue-300 font-medium">{{ $t('New SFTP connection') }}</a></div>
            </div>

            <template v-for="channel in channels">
                <div class="block bg-white shadow rounded mb-1">
                    <div class="flex justify-between items-center p-7">
                        <div>
                            <div class="block text-blue-400 text-lg font-semibold">{{ channel.name }}</div>
                            <div class="text-blue-300">
                                <template v-if="channel.status === 'active'">{{ $t('Active, runs daily') }}</template>
                                <template v-else>{{ $t('Inactive') }}</template>
                            </div>
                            <div class="text-blue-200">
                                {{ channel.credentials.credentials.username }}:*********@{{ channel.credentials.credentials.host }}:{{ channel.credentials.credentials.port }}
                            </div>
                        </div>
                        <div>
                            <AppMenu :fixed-height="true">
                                <AppMenuItem :href="route('3.integrations.sftp.edit', { channel: channel })">{{ $t('Edit') }}</AppMenuItem>
                                <AppMenuItem :href="route('3.integrations.sftp.test', { channel: channel })">
                                    <template v-if="channel.status === 'active'">{{ $t('Test') }}</template>
                                    <template v-else>{{ $t('Activate') }}</template>
                                </AppMenuItem>
                                <AppMenuItem v-if="channel.status === 'active'" :href="route('3.integrations.sftp.run', { channel: channel })">{{ $t('Run') }}</AppMenuItem>
                                <AppMenuItem :href="route('3.integrations.sftp.delete', { channel: channel })">{{ $t('Delete') }}</AppMenuItem>
                            </AppMenu>
                        </div>
                    </div>
                </div>
            </template>
        </div>

    </Layout>

</template>

<script>
    import Layout from "../../../Shared/Layout.vue";
    import AppMenu from '../../../Shared/AppMenu.vue'
    import AppMenuItem from '../../../Shared/AppMenuItem.vue'

    export default {
        props: ['channels'],
        components: {
            AppMenuItem,
            AppMenu,
            Layout,
        }
    }
</script>
