<template>
    <div class="
        bg-white max-w-3xl mx-auto py-4 px-4 rounded
        sm:shadow sm:py-16 sm:px-16
    ">
        <h1 class="text-lg tracking-wide font-bold text-lilac-500 text-center mb-12" v-html="title"></h1>
        <div class="flex items-center">
            <div class="w-1/2 text-center">
                <img :src="src" class="w-[250px] inline-block" alt="">
            </div>
            <div class="w-1/2">
                <p v-if="description" class="text-base">{{ description }}</p>
                <template v-if="steps.length > 0">
                    <ul class="space-y-4 text-left text-gray-500 dark:text-gray-400">
                        <li v-for="step in steps" class="flex items-center space-x-3">
                            <span class="inline-block bg-lilac-300 text-white rounded-full w-6 h-6 text-center flex items-center justify-center">
                                <span v-if="step.complete">
                                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500 dark:text-green-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5.917 5.724 10.5 15 1.5"/>
                                    </svg>
                                </span>
                                <span v-else class="text-sm">{{ step.number }}</span>
                            </span>
                            <span :class="{ 'line-through text-blue-300/50': step.complete }">
                                <span class="font-semibold">{{ step.title }}</span>
                                <a v-if="!step.complete" :href="step.link.url" class="block underline text-sm">{{ step.link.label }}</a>
                            </span>
                        </li>
                    </ul>
                </template>

            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"

export default {
    components: {},
    props: {
        name: {
            type: String,
            require: true,
        }
    },
    computed: {
        src() {
            return `/v2/images/blankslate-${this.name}.svg`;
        }
    },
    data() {
        return {
            title: '',
            description: '',
            steps: [],
            has_billing_methods: false,
            has_products: false,
        }
    },
    async created() {
        await this.load()
        this.showText()
    },
    methods: {
        async load() {
            let response = await axios.get(this.route('2.blank-slates.index'))

            this.has_products = response.data.has_products
            this.has_billing_methods = response.data.has_billing_methods
        },
        text(en, sv) {
            return this.$page.props.locale === 'sv' ? sv : en
        },
        stepInstructions() {
            return [
                {
                    number: 1,
                    title: this.text('Activate Payment Methods', 'Aktivera betalsätt'),
                    link: {
                        label: this.text('See how', 'Se hur'),
                        url: this.route('2.payments.index')
                    },
                    complete: this.has_billing_methods,
                },
                {
                    number: 2,
                    title: this.text('Add a product', 'Lägg till en produkt'),
                    link: {
                        label: this.text('See how', 'Se hur'),
                        url: this.route('2.products.create')
                    },
                    complete: this.has_products,
                },
                {
                    number: 3,
                    title: this.text('Embed on website', 'Lägg in på hemsida'),
                    link: {
                        label: this.text('See how', 'Se hur'),
                        url: this.route('2.settings.index')
                    },
                    complete: false,
                }
            ]
        },
        showText() {
            switch (this.name) {
                case 'home':
                    this.title = this.text('Soon, there will be graphs appearing here', 'Snart kommer det att finnas grafer här')
                    this.description = this.text('The overview is your central point for all information. Keep pace with the activity happening across the system, and explore your revenue and recurring payments via comprehensive graphs.', 'Här på översikten samlas all information. Följ med i aktivitetsflödet som sker i hela systemet samt se grafer över intäkter och återkommande betalningar.')
                    break

                case 'subscriptions':
                    this.title = this.text('Your subscribers will soon be displayed here<br>Follow the steps to get started', 'Snart syns dina prenumeranter här<br>Följ stegen för att komma igång')
                    this.steps = this.stepInstructions()
                    break

                case 'orders':
                    this.title = this.text('Your sales will soon appear here<br>Follow the steps to kickstart the process.', 'Snart syns din försäljning här<br>Följ stegen för att komma igång')
                    this.steps = this.stepInstructions()
                    break
                
                case 'reports':
                    this.title = this.text('Your revenue reports & payouts will be displayed here', 'Dina intäktsrapporter och utbetalningar kommer syns här')
                    this.description = this.text('When you receive revenue, you will be able to export reports to Excel. You can also retrieve documentation for payouts here.', 'När du får intäkter kommer du kunna exportera rapporter till Excel. Du kan även hämta underlag till utbetalningar här.')
                    break
                
            }

        }
    }
}
</script>