<template>
    <HqLayout>

        <div class="bg-white shadow mb-1 rounded flex space-x-4 p-4">
            <!-- Account -->
            <select v-model="filters.account" @change="applyFilters" class="px-4 py-2 max-w-[200px]">
                <option :value="null">{{ $t('Account') }}</option>
                <option v-for="account in accounts" :key="account.id" :value="account.id">{{ account.name }}</option>
            </select>

            <!-- Tid -->
            <AppDatePicker @change="setDateFilter" v-model="dateFilter" />

            <!-- State -->
            <select v-model="filters.state" @change="applyFilters" class="px-4 py-2">
                <option :value="null">{{ $t('State') }}</option>
                <option v-for="(value, key) in states" :value="key">{{ value }}</option>
            </select>

            <!-- State -->
            <select v-model="filters.provider" @change="applyFilters" class="px-4 py-2">
                <option :value="null">{{ $t('Provider') }}</option>
                <option v-for="(value, key) in providers" :value="key">{{ value }}</option>
            </select>
        </div>

        <div v-if="orders.length > 0" class="bg-white shadow mb-1 rounded">
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left text-blue-300">
                    <thead class="text-xs uppercase bg-blue-50">
                        <tr>
                            <th scope="col" class="px-6 py-3">{{ $t('Account') }}</th>
                            <th scope="col" class="px-6 py-3">Order ID</th>
                            <th scope="col" class="px-6 py-3">{{ $t('Total') }}</th>
                            <th scope="col" class="px-6 py-3">{{ $t('Created') }}</th>
                            <th scope="col" class="px-6 py-3">{{ $t('State') }}</th>
                            <th scope="col" class="px-6 py-3">{{ $t('Method') }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders" class="bg-white border-b border-blue-100">
                            <td class="px-6 py-4">
                                <div class="max-w-[180px]">
                                    <a :href="route('2.activities.index', { account: order.account })" target="_blank">{{ order.account.name }}</a>
                                </div>
                            </td>
                            <td class="px-6 py-4">
                                <a :href="route('2.orders.show', { account: order.account, order: order })" target="_blank">{{ order.id }}</a>
                            </td>
                            <td class="px-6 py-4">{{ centsToMoney(order.total_with_vat, order.currency) }}</td>
                            <td class="px-6 py-4">{{ dateTime(order.created_at) }}</td>
                            <td class="px-6 py-4">
                                <OrderStatus :order="order" :margin="false" />
                                <div v-if="order.paid_at">{{ dateTime(order.paid_at) }}</div>
                            </td>
                            <td class="px-6 py-4"><OrderBillingMethod :order="order" /></td>
                            <td>
                                <AppMenu>
                                    <AppMenuItem v-if="order.billing_method.type === 'billecta'" :external="true" :href="route('2.hq.orders.billecta.open', { order: order })">{{ $t('Open on Billecta') }}</AppMenuItem>
                                    <AppMenuItem v-if="order.billing_method.type === 'stripe'" :external="true" :href="route('2.hq.orders.stripe.open', { order: order })">{{ $t('Open on Stripe') }}</AppMenuItem>
                                    <AppMenuItem v-if="order.billing_method.type === 'fortnox'" :external="true" :href="route('2.hq.orders.fortnox.open', { order: order })">{{ $t('Open on Fortnox') }}</AppMenuItem>
                                </AppMenu>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mt-4">
            <Pagination :links="pagination.links" />
            <div class="text-center text-xs">
                {{ $t('{number} orders', { number: number(pagination.total) }) }}
                &middot;
                <a :href="route('2.hq.orders.index', { ...this.filters, export: 'excel' })" class="hover:underline">{{ $t('Export to Excel') }}</a>
            </div>
        </div>
    </HqLayout>
</template>
<script>
import HqLayout from "../HqLayout.vue";
import OrderStatus from '../../../Shared/OrderStatus.vue'
import OrderBillingMethod from '../../../Shared/OrderBillingMethod.vue'
import Pagination from '../../../Shared/Pagination.vue'
import AppDatePicker from '../../../Shared/AppDatePicker.vue'
import dayjs from 'dayjs'
import AppMenu from '../../../Shared/AppMenu.vue'
import AppMenuItem from '../../../Shared/AppMenuItem.vue'

export default {
    props: ['orders', 'pagination', 'accounts', 'providers', 'states', 'filters'],
    components: {AppMenuItem, AppMenu, AppDatePicker, Pagination, OrderBillingMethod, OrderStatus, HqLayout},
    created() {
        if (! this.filters.from || ! this.filters.to) {
            this.dateFilter = []

            return
        }

        let from = dayjs(this.filters.from)
            .locale('sv')
            .format('D MMM YYYY')

        let to = dayjs(this.filters.to)
            .locale('sv')
            .format('D MMM YYYY')


        console.log(from, to)

        this.dateFilter = [from, to]
    },
    data() {
        return {
            dateFilter: []
        }
    },
    methods: {
        setDateFilter(value) {
            this.filters.from = value[0]
            this.filters.to = value[1]

            this.applyFilters()
        },
        applyFilters() {
            this.$inertia.get(
                route('2.hq.orders.index'),
                this.filters,
                {
                    preserveState: true,
                    preserveScroll: true,
                    replace: true
                }
            )
        }
    },
}
</script>
