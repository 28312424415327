<template>
    <Layout>
        <template v-slot:header>
        
            <Breadcrumbs v-if="filters?.subscription">
                <Breadcrumb :href="route('2.customers.index')">{{ $t('Customers') }}</Breadcrumb>
                <Breadcrumb :href="route('2.customers.show', { id: filters.subscription.view.customer_id })">{{ customer.name }}</Breadcrumb>
                <Breadcrumb :active="true">{{ $t('Subscription {id}', { id: filters.subscription.id }) }}</Breadcrumb>
            </Breadcrumbs>
            <Breadcrumbs v-else>
                <Breadcrumb :href="route('2.customers.index')">{{ $t('Customers') }}</Breadcrumb>
                <Breadcrumb :active="true">{{ customer.name }}</Breadcrumb>
            </Breadcrumbs>
    
        </template>
        
        <template v-slot:default>
            
            <div class="max-w-3xl mx-auto">
                <div class="shadow rounded">
                    
                    <!-- White content -->
                    <div class="bg-white py-8 px-8 rounded-t">
                        <div>
                            <div class="text-lg text-blue-400 font-semibold">{{ customer.name }}</div>
                            <div class="text-sm text-blue-300">{{ customer.vat_number }}</div>
                        </div>
                        
                        <div v-if="customer.address" class="text-sm text-blue-300">
                            <div v-if="customer.address.line1">{{ customer.address.line1 }}</div>
                            <div v-if="customer.address.line2">{{ customer.address.line2 }}</div>
                            <div v-if="customer.address.zip">{{ customer.address.zip }} {{ customer.address.city }}</div>
                            <div v-if="customer.address.country">{{ customer.address.country_pretty }}</div>
                        </div>
        
                        <div class="flex items-center text-sm max-w-md mt-6">
                            <div class="mr-3">
                                <svg class="inline-block fill-current" width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 18A9 9 0 109 0a9 9 0 000 18zm0-1A8 8 0 109 1a8 8 0 000 16z" /><path d="M7 7a1 1 0 11-2 0 1 1 0 012 0zM13 7a1 1 0 11-2 0 1 1 0 012 0zM13 12c0 1.105-1.79 2-4 2s-4-.895-4-2h8z" /></svg>
                            </div>
                            <div>
                                <div class="text-blue-400">{{ customer.person.first_name }} {{ customer.person.last_name }}</div>
                                <div class="-mt-0.5"><a :href="`mailto:${customer.person.email}`" class="hover:underline">{{ customer.person.email }}</a></div>
                            </div>
                        </div>
                    </div>
        
                    <!-- Purple footer -->
                    <div class="bg-lilac-100 flex justify-between py-3 px-12 rounded-b">
                    <div v-if="customer.monthly_revenue" class="text-center">
                        <div class="text-lilac-300 text-sm mb-0.5 tracking-wider">{{ $t('Monthly revenue') }}</div>
                        <div>
                            <span class="block text-lilac-300 text-lg font-semibold" v-for="(value, currency) in customer.monthly_revenue">{{ centsToMoney(value, currency) }}/{{ $t('mo.') }}</span>
                        </div>
                    </div>
                    <div v-if="customer.total_revenue" class="text-center">
                        <div class="text-lilac-300 text-sm mb-0.5 tracking-wider">{{ $t('Total revenue') }}</div>
                        <div>
                            <span class="block text-lilac-300 text-lg font-semibold" v-for="(value, currency) in customer.total_revenue">{{ centsToMoney(value, currency) }}</span>
                        </div>
                    </div>
                    <div class="text-center">
                        <div class="text-lilac-300 text-sm mb-0.5 tracking-wider">{{ $t('First seen') }}</div>
                        <div>
                            <span class="block text-lilac-300 text-lg font-semibold">{{ date(customer.created_at) }}</span>
                        </div>
                    </div>
                </div>
                
                </div>
            </div>
    
            <!-- Subscriptions -->
            <SubscriptionList v-if="subscriptions.length > 0" :subscriptions="subscriptions"></SubscriptionList>
    
            <!-- Orders -->
            <OrderList v-if="orders.length > 0" :orders="orders"></OrderList>
            
            
            <Activities :activities="activities" class="mt-12"></Activities>
            
        </template>
    </Layout>
</template>

<script>
import Breadcrumbs from "../../Shared/Breadcrumbs";
import Breadcrumb from "../../Shared/Breadcrumb";
import Layout from "../../Shared/Layout";
import TableHeader from "../../Shared/TableHeader";
import TableHeaderColumn from "../../Shared/TableHeaderColumn";
import TableRow from "../../Shared/TableRow";
import TableColumn from "../../Shared/TableColumn";
import OrderList from "../../Shared/OrderList";
import Activities from "../../Shared/Activities";
import AppMenu from "../../Shared/AppMenu";
import AppMenuItem from "../../Shared/AppMenuItem";
import Reference from "../../Shared/Reference";
import SubscriptionList from "../../Shared/SubscriptionList";
import Order from "../../Shared/Order";
import AppInput from "../../Shared/AppInput";

export default {
    components: {
        AppInput,
        Order,
        SubscriptionList,
        Reference,
        AppMenuItem,
        AppMenu,
        Activities, OrderList, TableColumn, TableRow, TableHeaderColumn, TableHeader, Layout, Breadcrumbs, Breadcrumb},
    props: ['customer', 'subscriptions', 'orders', 'activities', 'filters'],
}
</script>
