<template>
    <div class="relative">
        <ProductImage :product="currentProduct" :loading="uploadingImage" @click="onProductImageClick" />
        
        <div class="absolute top-0 left-0 ml-64 mt-4 text-left">
            <AppInfo class="inline-block" :content="$t('Image thumbnail is displayed in emails and lists. Use a square image JPG/PNG (300x300)')"></AppInfo>
            
            <div class="relative group cursor-pointer w-full">
                <AppUpload
                    ref="upload"
                    :url="route('2.products.images.store', { product: this.product })"
                    :allowed-types="['image/jpeg', 'image/png', 'image/jpg']"
                    :max-file-size-mb="5"
                    visibility="public-read"
                    @upload-start="uploadingImage = true"
                    @upload-complete="onProductImageUploadComplete"
                >
                    <span class="block mt-1 underline text-xs  tracking-wider font-medium group-hover:text-black cursor-pointer">
                        <template v-if="hasImage">{{ $t('Change image') }}</template>
                        <template v-else>{{ $t('Choose image') }}</template>
                    </span>
                </AppUpload>
            </div>
            
            <a v-if="hasImage" class="block mt-2 underline text-xs  tracking-wider font-medium hover:text-black cursor-default" @click.prevent="deleteImage">
                {{ $t('Remove') }}
            </a>
        </div>
    </div>
</template>

<script>
import ProductImage from "../../../Shared/ProductImage.vue"
import axios from "axios"
import AppInfo from "../../../Shared/AppInfo.vue";
import AppSpinner from "../../../Shared/AppSpinner.vue";
import AppUpload from "../../../Shared/AppUpload.vue";

export default {
    props: ['product'],
    components: {
        AppUpload,
        AppSpinner,
        AppInfo,
        ProductImage
    },
    data() {
        return {
            uploadProgress: 0,
            uploadingImage: false,
            currentProduct: this.product,
        }
    },
    computed: {
        hasImage() {
            return this.currentProduct.image.src
        }
    },
    methods: {
        deleteImage() {
            axios.post(this.route('2.products.images.delete', { product: this.product })).then(response => {
                this.currentProduct = response.data.product
            })
        },
        onProductImageUploadComplete(response) {
            this.uploadingImage = false
            this.currentProduct = response.data.product
        },
        onProductImageClick() {
            this.$refs.upload.selectFiles()
        }
    },
}
</script>