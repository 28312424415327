<template>
<!--    <div class="shadow rounded p-4 lg:py-16 lg:px-16">-->
    <div class="shadow rounded p-4 lg:p-7 relative">

        <div class="absolute top-0 right-0 lg:p-7">
            <AppMenu>
                <AppMenuLabel>{{ $t('Edit') }}</AppMenuLabel>
                <AppMenuItem :href="`${route('2.billing-methods.edit', { 'billingMethod': billingMethod })}`">{{ $t('Contact details') }}</AppMenuItem>
                <AppMenuItem v-if="shippingAddress && shippingAddress.line1" :href="`${route('2.address.edit', { 'address': shippingAddress, 'return-to': returnTo })}`">{{ $t('Shipping address') }}</AppMenuItem>
                <AppMenuItem v-if="customFields.length > 0 && attachment" :href="`${route('2.custom-fields.attachments.edit', { 'attachment': attachment.name, 'attachmentId': attachment.id })}`">{{ $t('Custom fields') }}</AppMenuItem>
            </AppMenu>
        </div>

        <div class="lg:px-9 pt-9">
            <div class="text-center mb-3">
                <LetterAvatar :username="customerName"></LetterAvatar>
            </div>

            <div class="text-center mb-8">
                <div class="text-lg text-blue-400 font-semibold">{{ customerName }}</div>
                <div class="text-blue-300">
                    <div v-if="loading" class="block w-48 bg-blue-200 bg-opacity-20 animate-pulse rounded mx-auto">&nbsp;</div>
                    <div v-else>{{ $t('First seen {date}', { date: date(customerData.created_at) }) }}</div>
                </div>
            </div>

            <div class="flex flex-wrap justify-center space-x-4 space-y-3 md:justify-start md:space-x-0">
                <div class="md:w-full">
                    <div v-for="(revenue, currency) in customerData.total_revenue" class="flex items-center">
                        <MoneyIcon class="text-lilac-300 mr-2" />
                        <div>{{ $t('Revenue: {total}', { total: centsToMoney(revenue, currency) }) }}</div>
                    </div>

                    <div v-show="loading" class="flex items-center">
                        <MoneyIcon class="text-lilac-300 mr-2" />
                        <div class="block w-48 bg-blue-200 bg-opacity-20 animate-pulse rounded">&nbsp;</div>
                    </div>
                </div>

                <InertiaLink :href="route('2.subscriptions.index', { 'customer': billingMethod.customer_id })" class="block md:w-full flex items-center hover:underline">
                    <SubscriptionIcon class="text-lilac-300 mr-2" />
                    <span v-if="!loading" class="block">
                    <template v-if="customerData.count.subscriptions === 1">
                        {{ $t('{count} subscription', { count: customerData.count.subscriptions }) }}
                    </template>
                    <template v-else>
                        {{ $t('{count} subscriptions', { count: customerData.count.subscriptions }) }}
                    </template>
                </span>
                    <span v-else>
                    <span class="block w-48 bg-blue-200 bg-opacity-20 animate-pulse rounded">&nbsp;</span>
                </span>
                </InertiaLink>

                <InertiaLink :href="route('2.orders.index', { 'filters[customer]': billingMethod.customer_id })" class="block md:w-full flex items-center hover:underline">
                    <OrderIcon class="text-lilac-300 mr-2" />
                    <span v-if="!loading" class="block">
                    <template v-if="customerData.count.orders === 1">
                        {{ $t('{count} order', { count: customerData.count.orders }) }}
                    </template>
                    <template v-else>
                        {{ $t('{count} orders', { count: customerData.count.orders }) }}
                    </template>
                </span>
                    <span v-else>
                    <span class="block w-48 bg-blue-200 bg-opacity-20 animate-pulse rounded">&nbsp;</span>
                </span>
                </InertiaLink>
            </div>
        </div>
        
        <div class="lg:ml-1 p-9">
            <div v-if="(shippingAddress && shippingAddress.line1) || (billingAddress && billingAddress.line1) || billingMethod.company?.vat_number" class="w-8 border border-b-2 border-lilac-100 mb-8"></div>
    
            <div v-if="shippingAddress && shippingAddress.line1 && !$slots.shipping" class="mb-6">
                <CustomerCardAddress :address="shippingAddress" :title="$t('Shipping address')" />
            </div>

            <div v-if="billingAddress && billingAddress.line1">
                <CustomerCardAddress :address="billingAddress" :title="$t('Billing address')" />
            </div>

            <div v-if="billingMethod.company?.vat_number" class="mt-4">
                <div class="text-blue-400 font-medium">{{ $t('EU VAT ID') }}</div>
                <div class="break-all">{{ billingMethod.company.vat_number }}</div>
            </div>

            <div class="w-8 border border-b-2 border-lilac-100 mb-8 mt-8"></div>

            <div class="text-blue-400 font-medium">{{ $t('Contact details') }}</div>
            <div class="leading-5">
                <div class="text-blue-300">{{ billingMethod.person.first_name }} {{ billingMethod.person.last_name }}</div>
                <div class="text-blue-300 break-all">{{ billingMethod.person.personal_identity_number }}</div>
                <div class="text-blue-300 break-all">
                    <a :href="`mailto:${billingMethod.person.email}`" class="hover:underline">{{ billingMethod.person.email }}</a>
                </div>
                <div class="text-blue-300 break-all">{{ billingMethod.person.phone }}</div>
            </div>

            <template v-if="billingMethod.invoice_delivery === 'email'">
                <div class="flex items-center mt-8">
                    <div class="leading-5">
                        <div class="text-blue-400 font-medium">
                            <template v-if="billingMethod.type === 'fortnox'">{{ $t('Send invoices to') }}</template>
                            <template v-else>{{ $t('Send receipts to') }}</template>
                        </div>
                        <div class="text-blue-300 break-all">
                            <a :href="`mailto:${billingMethod.email}`" class="hover:underline">{{ billingMethod.email }}</a>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="customFields && customFields.length > 0">
                <div class="w-8 border border-b-2 border-lilac-100 mt-8 mb-8"></div>
                <div class="flex items-center mt-8">
                    <div class="leading-5 space-y-4">
                        <div v-for="field in customFields">
                            <div class="text-blue-400 font-medium">{{ field.label }}</div>
                            <div v-if="field.render" class="text-blue-300 break-all" v-html="field.render.replace(/\n/g, '<br>')"></div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>

    <template v-if="$slots.shipping">
        <div class="shadow rounded p-4 lg:p-7 relative mt-2">
            <div class="lg:ml-1 p-9"><slot name="shipping" /></div>
        </div>
    </template>

</template>

<script>
import axios from "axios"
import SubscriptionIcon from "./Activities/SubscriptionIcon"
import Order from "./Order"
import OrderIcon from "./Activities/OrderIcon"
import PersonIcon from "./Icons/PersonIcon"
import MoneyIcon from "./Icons/MoneyIcon";
import LetterAvatar from "./LetterAvatar.vue";
import AppMenu from "./AppMenu.vue";
import AppMenuItem from "./AppMenuItem.vue";
import FortnoxIcon from "./Icons/FortnoxIcon.vue";
import ArrowLeftIcon from "./Icons/ArrowLeftIcon.vue";
import ArrowRightIcon from "./Icons/ArrowRightIcon.vue";
import AppMenuLabel from "./AppMenuLabel.vue";
import CustomerCardAddress from './CustomerCardAddress.vue'

export default {
    components: {
        CustomerCardAddress,
        AppMenuLabel,
        ArrowRightIcon,
        ArrowLeftIcon,
        FortnoxIcon,
        AppMenuItem, AppMenu, LetterAvatar, MoneyIcon, PersonIcon, OrderIcon, Order, SubscriptionIcon},
    props: ['billingMethod', 'customFields', 'billingAddress', 'shippingAddress', 'attachment'],
    data: () => {
        return {
            customerData: {},
            loading: true,
        }
    },
    computed: {
        customerName() {
            if (this.billingMethod.company) {
                return this.billingMethod.company.name
            }

            return this.billingMethod.person.name
        },
        returnTo() {
            return window.location.href
        }
    },
    created() {
        this.loadAdditionalCustomerData()
    },
    methods: {
        async loadAdditionalCustomerData() {
            let response = await axios.get(this.route(`2.customers.show`, { 'id': this.billingMethod.customer_id }))
            this.customerData = response.data.data
            
            
            this.loading = false
        }
    }
}
</script>
