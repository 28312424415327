<template>
    <Layout>

        <div class="max-w-lg mx-auto shadow py-8 px-16">

            <div class="text-center">
                <div class="inline-block">
                    <CartIcon />
                </div>

                <div class="text-lg font-medium text-blue-400 py-6">
                    {{ $t('Manual payment methods') }}
                </div>

                <p class="text-sm text-blue-300 -mt-5 text-left">
                    {{ $t('Payments made outside of your store.') }}
                    {{ $t('A payment method could be a bank transfer, a check or a cash payment.') }}
                    {{ $t('Payment instructions are shown to the customer after a checkout.') }}
                </p>

            </div>
            <div>
                <!-- Add new method -->
                <template v-if="currentState === 'add'">
                    <div class="mt-12">
                        <AppInput class="mb-1" v-model="newMethod.name" :label="$t('Custom payment method name')"></AppInput>
                        <AppTextArea v-model="newMethod.instructions" :label="$t('Payment instructions')"></AppTextArea>

                        <FormButton @click="addMethod">{{ $t('Add payment method') }}</FormButton>
                    </div>
                </template>

                <!-- List of methods -->
                <template v-else>
                    <div class="mt-12">
                        <div v-for="method in form.methods">
                            <div class="flex items-center">
                                <div class="flex-1">
                                    <div class="text-lg font-medium text-blue-400">{{ method.name }}</div>
                                    <div class="text-sm text-blue-300">{{ method.instructions }}</div>
                                </div>
                                <div>
                                    <AppMenu>
                                        <AppMenuItem @click="editMethod(method)">{{ $t('Edit') }}</AppMenuItem>
                                        <AppMenuItem @click="deleteMethod(method)">{{ $t('Delete') }}</AppMenuItem>
                                    </AppMenu>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="font-medium mb-3 text-center mt-12">
                        <div>
                            <a href="" @click.prevent="add" class="border border-blue-200 py-1 px-3 rounded text-blue-300 text-sm hover:border-blue-300 font-medium">
                                {{ $t('New manual payment method') }}
                            </a>
                        </div>
                    </div>
                </template>

                <form v-if="currentState === 'index'" @submit.prevent="form.post(route('2.payments.manual.store'))">
                    <FormButton :loading="form.processing">
                        {{ $t('Save') }}
                    </FormButton>
                </form>

            </div>

        </div>

    </Layout>
</template>

<script>

import AppMenuItem from "../../../Shared/AppMenuItem.vue";
import AppMenu from "../../../Shared/AppMenu.vue";
import AppButton from "../../../Shared/AppButton.vue";
import AppButtonOutline from "../../../Shared/AppButtonOutline.vue";
import CartIcon from "../../../Shared/Icons/CartIcon.vue";
import FormButton from "../../../Shared/FormButton.vue";
import AppInput from "../../../Shared/AppInput.vue";
import AppTextArea from "../../../Shared/AppTextArea.vue";
import Layout from "../../../Shared/Layout.vue";
import {useForm} from "@inertiajs/vue3";

export default {
    props: ['methods'],
    components: {
        AppMenuItem,
        AppMenu,
        AppButton,
        AppButtonOutline,
        CartIcon,
        FormButton,
        AppInput,
        AppTextArea,
        Layout
    },
    data() {
        return {
            currentState: 'index',
            oldMethod: null,
            newMethod: {
                name: '',
                instructions: '',
            },
            form: useForm({
                methods: this.methods
            }),
        }
    },
    created() {
        this.currentState = this.form.methods.length > 0 ? 'index' : 'add'
    },
    methods: {
        add() {
            this.oldMethod = null
            this.newMethod = {
                name: '',
                instructions: '',
            }
            this.currentState = 'add'
        },
        addMethod() {
            if (this.isNameDuplicate(this.newMethod.name)) {
                alert('A method with this name already exists.')

                return
            }

            if (this.newMethod.name === '') {
                alert('Please enter a name for the method')

                return
            }

            if (this.oldMethod) {
                const index = this.form.methods.findIndex(m => m === this.oldMethod)

                if (index !== -1) {
                    this.form.methods.splice(index, 1, {...this.newMethod})
                }
            } else {
                this.form.methods.push({...this.newMethod})
            }

            this.newMethod = {
                name: '',
                instructions: '',
            }
            this.oldMethod = null
            this.currentState = 'index'
        },
        editMethod(method) {
            this.oldMethod = method
            this.newMethod = {...method}
            this.currentState = 'add'
        },
        deleteMethod(method) {
            this.form.methods = this.form.methods.filter(m => m !== method)
        },
        isNameDuplicate(name) {
            return this.form.methods.some(method =>
                method.name.toLowerCase() === name.toLowerCase() && method !== this.oldMethod
            )
        }
    }
}
</script>