<template>
    <TransitionRoot as="template" :show="isOpen">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="cancel">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-lilac-100 bg-opacity-75 transition-opacity" />
                </TransitionChild>
                
                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div
                        class="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full"
                        :class="{
                            'sm:max-w-lg': size === 'sm',
                            'sm:max-w-3xl': size === 'md',
                        }"
                    >
                        <div
                            class="py-12 mx-auto"
                            :class="{
                                'max-w-sm': size === 'sm',
                                'max-w-2xl': size === 'md',
                            }"
                        >
                            <slot></slot>
                        </div>
                        <div class="bg-blue-50 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                class="
                                    text-lg font-semibold w-full inline-flex justify-center rounded-r border border-transparent shadow-sm px-8 text-base h-[64px] flex items-center
                                    focus:outline-none focus-visible:shadow-inner
                                    sm:ml-3 sm:w-auto
                                "
                                :class="{
                                    'bg-red-400 text-white hover:bg-red-400 focus:ring-red-400': type === 'danger',
                                    'bg-green-400 text-white hover:bg-green-400 focus:ring-green-400': type === 'success',
                                    'bg-lilac-300 text-white hover:bg-opacity-90 focus:ring-lilac-400': type === 'info',
                                }"
                                @click="confirm"
                            >
                                <slot name="confirm"></slot>
                                <template v-if="!this.$slots.confirm">OK</template>
                            </button>
                            <button
                                type="button"
                                class="
                                    mt-3 w-full inline-flex justify-center rounded-md px-8 py-2 text-base font-medium text-blue-300 h-[64px] flex items-center
                                    focus:outline-none focus-visible:shadow-inner
                                    sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
                                "
                                @click="cancel"
                                ref="cancelButtonRef"
                            >
                                <slot name="cancel"></slot>
                                <template v-if="!this.$slots.cancel">{{ $t('Cancel') }}</template>
                            </button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon } from '@heroicons/vue/outline'

export default {
    props: {
        open: { default: false },
        closeOnConfirm: { default: false },
        size: { default: 'sm' },
    },
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ExclamationIcon,
    },
    data() {
        return {
            type: 'info',
            isOpen: this.open
        }
    },
    watch: {
        open() {
            this.isOpen = this.open
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm')
            
            if (this.closeOnConfirm) {
                this.isOpen = false
            }
        },
        cancel() {
            this.isOpen = false
            this.$emit('cancel')
        },
    }
}
</script>
