<template>
    <span class="flex items-center block text-sm text-blue-300">
        <span class="font-medium text-sm tracking-wider" :class="textColor">{{ prettyName() }}</span>
    </span>
</template>

<script>
export default {
    props: ['subscription'],
    data() {
        return {
            textColor: {
                'text-green-400': this.subscription.status === 'active' || this.subscription.status === 'trialing',
                'text-red-400': this.subscription.status === 'cancelled',
                'text-yellow-600': this.subscription.status === 'unpaid',
                'text-blue-300': this.subscription.status === 'expired' || this.subscription.status === 'future_start',
            }
        }
    },
    methods: {
        prettyName() {
            switch (this.subscription.status) {
                case 'active':
                    return this.$t('Active')
                case 'cancelled':
                    return this.$t('Cancelled')
                case 'unpaid':
                    return this.$t('Unpaid')
                case 'expired':
                    return this.$t('Expired')
                case 'future_start':
                    return this.$t('Upcoming')
                case 'trialing':
                    return this.$t('Trial ends {date}', { date: this.date(this.subscription.trial_ends_at) })
                default:
                    return this.capitalizeFirstLetter(this.subscription.status)
            }
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
}
</script>