<template>
    <Layout>
        <Sheet>
            <SheetTitle>{{ $t('Edit custom fields') }}</SheetTitle>
            
            <form @submit.prevent="submit">
                <div v-for="field in customFields">
                    <EditCustomField :label="field.label" v-model="form.values[field.id]" :type="field.type" :choices="field.choices" :countries="countries" />
                </div>
                
                <FormError />
                <SheetButton @click="submit">{{ $t('Save') }}</SheetButton>
            </form>
        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout.vue";
import SheetTitle from "../../../Shared/SheetTitle.vue";
import Sheet from "../../../Shared/Sheet.vue";
import AppInput from "../../../Shared/AppInput.vue";
import FormError from "../../../Shared/FormError.vue";
import SheetButton from "../../../Shared/SheetButton.vue";
import {useForm} from "@inertiajs/vue3";
import EditCustomField from "./EditCustomField.vue";

export default {
    components: {EditCustomField, SheetButton, FormError, AppInput, Sheet, SheetTitle, Layout},
    props: ['attachment', 'model', 'customFields', 'values', 'countries'],
    data() {
        return {
            form: useForm({
                values: { ...this.values },
            }),
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('2.custom-fields.attachments.update', { attachment: this.attachment, attachmentId: this.model.id }))
        }
    }
}
</script>