<template>
    <AnythingSearch ref="anythingSearch"></AnythingSearch>
    <FlashMessage />
    <WarningMessages />
    <SuperUser v-if="$page.props.user.role === 'SUPERUSER'" />
    <main class="bg-blue-50 text-blue-300 min-h-[1400px]">
        
        <LayoutHeaderSnowfireIntegration
            v-if="header && $page.props.integration && usingSnowfireIntegration()"
            @search="$refs.anythingSearch.toggle()"
        ></LayoutHeaderSnowfireIntegration>

        <!-- Header (Large Display) -->
        <LayoutHeaderLarge
            v-if="header && (! $page.props.integration || !usingSnowfireIntegration())"
            @search="$refs.anythingSearch.toggle()"
            class="hidden md:flex"
        ></LayoutHeaderLarge>

        <LayoutHeaderSmall
            v-if="header && (! $page.props.integration || !usingSnowfireIntegration())"
            class="md:hidden"
        ></LayoutHeaderSmall>
    
        <Navigation v-if="header && !$page.props.onboarding" class="hidden md:flex mt-3">
            <NavigationItem :href="route('2.activities.index')" :active="route().current('2.activities.*')">
                <template #icon><svg class="inline-block fill-current" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5h3v3H5zM11 5h3v3h-3zM17 5h3v3h-3zM5 11h3v3H5zM11 11h3v3h-3zM17 11h3v3h-3zM5 17h3v3H5zM11 17h3v3h-3zM17 17h3v3h-3z"/></svg></template>
                {{ $t('Activity') }}
            </NavigationItem>
            <NavigationItem v-if="$page.props.account.has.subscriptions" :href="route('2.subscriptions.index')" :active="route().current('2.subscriptions.*')">
                <template #icon><svg class="inline-block fill-current" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.25 8.12L17 5.06 13.25 2v2.253c-.409-.05-.826-.076-1.25-.076-4.97 0-9 3.56-9 7.95 0 1.407.414 2.729 1.14 3.875l1.424-1.557A5.161 5.161 0 015 12.127c0-3.06 2.894-5.95 7-5.95.366 0 .722.023 1.068.067l.182-.2V8.12zm-2.68 7.76v2.075c.457.08.935.122 1.43.122 4.106 0 7-2.89 7-5.95 0-.971-.291-1.925-.82-2.775l1.393-1.523C20.476 9.069 21 10.544 21 12.127c0 4.39-4.03 7.95-9 7.95-.487 0-.964-.034-1.43-.1V22l-3.75-3.06 3.75-3.06z" /></svg></template>
                {{ $t('Subscribers') }}
            </NavigationItem>
            <NavigationItem v-if="$page.props.account.has.oneTimeOrders" :href="route('2.orders.index', { 'filters[kind]': 'one-time' })" :active="route().current('2.orders.*')">
                <template #icon><svg class="inline-block fill-current" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m8 6.009 4-.509 4 .509V6c0-.34-.234-.817-.979-1.264C14.296 4.301 13.231 4 12 4c-1.23 0-2.296.301-3.021.736C8.234 5.183 8 5.661 8 6v.009Zm-.995.126L4 6.517l1.23 12.414L12 21l6.154-2.069L20 6.517l-3.005-.382C16.998 6.09 17 6.045 17 6c0-1.657-2.239-3-5-3S7 4.343 7 6c0 .045.002.09.005.135ZM11.55 17.07a3.61 3.61 0 0 1-1.38-.25 2.284 2.284 0 0 1-.98-.74c-.24-.327-.366-.72-.38-1.18h1.82c.027.26.117.46.27.6.154.133.354.2.6.2.254 0 .454-.057.6-.17a.6.6 0 0 0 .22-.49.566.566 0 0 0-.18-.43 1.335 1.335 0 0 0-.43-.28 6.503 6.503 0 0 0-.72-.25 7.897 7.897 0 0 1-1.11-.42 2.051 2.051 0 0 1-.74-.62c-.206-.273-.31-.63-.31-1.07 0-.653.237-1.163.71-1.53.474-.373 1.09-.56 1.85-.56.774 0 1.397.187 1.87.56.474.367.727.88.76 1.54h-1.85a.707.707 0 0 0-.25-.53.867.867 0 0 0-.59-.2.722.722 0 0 0-.5.17c-.126.107-.19.263-.19.47 0 .227.107.403.32.53.214.127.547.263 1 .41.454.153.82.3 1.1.44.287.14.534.343.74.61.207.267.31.61.31 1.03 0 .4-.103.763-.31 1.09a2.1 2.1 0 0 1-.88.78c-.386.193-.843.29-1.37.29Z"/></svg></template>
                {{ $t('Sales') }}
            </NavigationItem>
            <NavigationItem :href="route('2.products.index')">
                <template #icon><ProductIcon /></template>
                {{ $t('Products') }}
            </NavigationItem>
            <NavigationItem :href="route('2.reports.index')">
                <template #icon><svg class="inline-block fill-current" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 4h16v3H4zM4 10h16v3H4zM4 16h10v3H4z"/></svg></template>
                {{ $t('Reports') }}
            </NavigationItem>
            <button @click="$refs.anythingSearch.toggle()" class="font-medium group mt-[-14px] pt-[14px] transition-all duration-150 border-t-2 text-lilac-300 hover:text-lilac-500 border-transparent">
                <span class="block flex items-center">
                    <span class="inline-block group-hover:text-lilac-500 mr-2 transition-all duration-150 text-lilac-200">
                        <SearchIcon />
                    </span>
                    <span class="inline-block">
                        {{ $t('Search') }}
                    </span>
                </span>
            </button>
        </Navigation>
        
        <!-- Content header (Breadcrumbs/Filters) -->
        <div class="pt-16">
            <slot name="header"></slot>
        </div>
        
        <!-- Main -->
        <div class="flex max-w-[1204px] mx-auto pb-24">
            <div class="flex-1 mx-4">
                <slot />
            </div>
        </div>
    </main>

    <!-- To make Tailwind JIT work -->
    <div class="hidden text-yellow-300 text-yellow-600"></div>
</template>

<script>
import AnythingSearch from './AnythingSearch';
import Navigation from "./Navigation";
import NavigationItem from "./NavigationItem";
import LayoutHeaderLarge from "./LayoutHeaderLarge";
import LayoutHeaderSmall from "./LayoutHeaderSmall";
import FlashMessage from "./FlashMessage";
import SettingsIcon from "./Icons/SettingsIcon";
import LayoutHeaderSnowfireIntegration from "./LayoutHeaderSnowfireIntegration";
import WarningMessages from "./WarningMessages.vue";
import SuperUser from "./SuperUser.vue";
import SearchIcon from "./Icons/SearchIcon.vue";
import ProductIcon from "./Icons/ProductIcon.vue";

export default {
    props: {
        header: {
            type: Boolean,
            default: true
        }
    },
    components: {
        ProductIcon,
        SearchIcon,
        SuperUser,
        WarningMessages,
        LayoutHeaderSnowfireIntegration,
        SettingsIcon, LayoutHeaderSmall, LayoutHeaderLarge, NavigationItem, Navigation, AnythingSearch, FlashMessage
    },
    methods: {
        usingSnowfireIntegration() {
            if (this.$page.props.integration.identifier === 'snowfire') {
                return true;
            }

            if (this.$page.props.integration.identifier === 'snowfire-staging') {
                return true;
            }

            return false;
        }
    }
}
</script>
