<template>
    <Layout>
        
        <div class="text-center mb-8">
            <div class="text-xl font-semibold text-blue-400 mb-1">{{ $t('Reports') }}</div>
        </div>
        
        <p v-if="! active" class="text-center">{{ $t('Sorry, reports are not available right now.') }} </p>
        
        <BlankSlate v-if="! hasReports && active" name="reports" />
        
        <template v-if="hasReports && active">
            <div class="max-w-3xl mx-auto" v-if="has.customers">
                <div class="font-medium mb-3">{{ $t('Customer reports') }}</div>
            </div>
            
            <div class="max-w-3xl mx-auto mb-16" v-if="has.customers">
                <LinkPanel :href="route('2.customers.export')">
                    {{ $t('Export all customers to Excel') }}
                </LinkPanel>
            </div>
            
            <div class="max-w-3xl mx-auto" v-if="has.orders || has.subscriptions">
                <div class="font-medium mb-3">{{ $t('Revenue reports') }}</div>
            </div>
            
            <div class="max-w-3xl mx-auto">
                <div v-if="has.subscriptions" class="py-9 px-9 block bg-white shadow rounded px-7 flex items-center justify-between mb-1">
                    <div class="w-6/12">
                        <p class="block font-semibold text-lg">{{ $t('Subscriptions') }}</p>
                        <p class="">{{ $t('Get a report of all subscription revenue during a specific interval') }}</p>
                    </div>
                    <div class="w-5/12">
                        <form @submit.prevent="exportRevenueReport('subscriptions')" class="flex">
                            <AppDatePicker @change="setDates('subscriptions', $event)" />
                            
                            <div class="ml-2">
                                <AppButtonOutline type="submit" class="rounded px-3 py-2.5" :disabled="revenueReportForm.subscriptions.startDate === ''">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" class="fill-current"><path fill-rule="evenodd" d="M11.876 17.98 4 10.5 5.5 9l5.397 5.417V3.96h2v10.623l5.69-5.524 1.393 1.435-8.083 7.466-.021.02Z" clip-rule="evenodd"/><path d="M4 20h16v2H4z"/></svg>
                                </AppButtonOutline>
                            </div>
                        </form>
                    </div>
                </div>
                <div v-if="has.orders" class="py-9 px-9 block bg-white shadow rounded px-7 flex items-center justify-between">
                    <div class="w-6/12">
                        <p class="block font-semibold text-lg">{{ $t('Orders') }}</p>
                        <p class="">{{ $t('Get a report of all order revenue during a specific interval') }}</p>
                    </div>
                    <div class="w-5/12">
                        <form @submit.prevent="exportRevenueReport('orders')" class="flex">
                            <AppDatePicker @change="setDates('orders', $event)" />
                            
                            <div class="ml-2">
                                <AppButtonOutline type="submit" class="rounded px-3 py-2.5" :disabled="revenueReportForm.orders.startDate === ''">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" class="fill-current"><path fill-rule="evenodd" d="M11.876 17.98 4 10.5 5.5 9l5.397 5.417V3.96h2v10.623l5.69-5.524 1.393 1.435-8.083 7.466-.021.02Z" clip-rule="evenodd"/><path d="M4 20h16v2H4z"/></svg>
                                </AppButtonOutline>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
            <div v-if="has.payouts"  class="max-w-3xl mx-auto mt-16">
                <div class="font-medium mb-3">{{ $t('Payouts') }}</div>
                <LinkPanel :href="route('2.reports.year', { year: year })" v-for="year in payouts.years" class="mb-1">
                    <span class="flex items-center">
                        <span class="block text-lg font-semibold mr-6">{{ year }}</span>
                        <span class="block">{{ $t('Payouts from {providers}', { providers: payouts.providers }) }}</span>
                    </span>
                </LinkPanel>
            </div>
        </template>
        
    </Layout>
</template>
<script>
import Layout from "../../Shared/Layout.vue";
import AppButtonOutline from "../../Shared/AppButtonOutline.vue";
import LinkPanel from "../../Shared/LinkPanel.vue";
import AppModal from "../../Shared/AppModal.vue";
import AppDatePicker from "../../Shared/AppDatePicker.vue";
import { useForm } from '@inertiajs/vue3'
import BlankSlate from "../../Shared/BlankSlate.vue";
import AppTitle from "../../Shared/AppTitle.vue";

export default {
    props: ['payouts', 'has', 'active'],
    components: {
        AppTitle, BlankSlate,
        AppDatePicker,
        AppModal,
        Layout,
        AppButtonOutline,
        LinkPanel
    },
    data() {
        return {
            revenueReportForm: useForm({
                subscriptions: {
                    startDate: '',
                    endDate: '',
                },
                orders: {
                    startDate: '',
                    endDate: ''
                }
            })
        }
    },
    computed: {
        hasReports() {
            return this.has.subscriptions || this.has.orders || this.has.payouts
        }
    },
    methods: {
        async exportRevenueReport(type) {
            await this.revenueReportForm.post(this.route(`2.reports.revenue.${type}`))
            this.revenueReportForm.reset()
        },
        setDates(type, value) {
            this.revenueReportForm[type].startDate = value[0]
            this.revenueReportForm[type].endDate = value[1]
        }
    }
}
</script>