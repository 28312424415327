<template>

    <div>
        <div class="font-medium">{{ $t('Digital delivery') }}</div>
        <div class="text-sm mb-4">{{ $t('These files will be available to customers after purchase') }}</div>
        
        <div v-if="state === 'list' || state === 'edit-delivery'" class="flex items-center space-x-1">
            
            <AppUpload
                class="block w-1/2"
                :url="route('2.products.media.create', { product: currentProduct, type: 'file' })"
                :multiple="true"
                visibility="private"
                :max-file-size-mb="500"
                
                @upload-complete="onUploadComplete"
            >
                <span class="shadow p-5 rounded shadow flex items-center border border-transparent transition hover:bg-white hover:border-lilac-300">
                    <span class="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><g clip-path="url(#a)"><path fill="#808CFA" fill-rule="evenodd" d="M14.76 6.82c1.006.516 1.601 1.22 1.686 1.985l.07.639.602-.226c1.046-.392 1.92-.123 2.35.323.212.221.322.486.318.763-.004.276-.122.617-.46.98l-.335.362.356.34c.994.951 1.404 1.792 1.469 2.486.064.682-.195 1.31-.73 1.87-1.1 1.148-3.29 1.908-5.586 1.908v1c2.454 0 4.952-.802 6.307-2.217.692-.721 1.101-1.624 1.005-2.654-.086-.912-.562-1.85-1.451-2.791.276-.407.419-.839.425-1.27a2.068 2.068 0 0 0-.599-1.471c-.65-.674-1.72-.985-2.874-.728-.317-.954-1.128-1.692-2.097-2.189-1.168-.599-2.656-.902-4.12-.836-1.464.066-2.952.504-4.084 1.443-1.035.859-1.737 2.108-1.866 3.777-.926-.02-1.708.428-2.239 1.117-.628.817-.909 1.968-.74 3.126.172 1.168.802 2.355 2.009 3.242 1.203.885 2.949 1.451 5.324 1.451v-1c-2.22 0-3.742-.528-4.732-1.256-.987-.726-1.478-1.672-1.61-2.582-.136-.92.097-1.793.541-2.37.434-.563 1.06-.85 1.816-.68l.61.139v-.626c0-1.673.62-2.817 1.526-3.568.92-.764 2.179-1.155 3.49-1.214 1.31-.059 2.623.216 3.618.727ZM12 11.75l3 3h-2.25v4.5h-1.5v-4.5H9l3-3Z" clip-rule="evenodd"/></g><defs><clipPath id="a"><path fill="#fff" d="M2 5h20v14.25H2z"/></clipPath></defs></svg></span>
                    <span>{{ $t('Add file') }}</span>
                </span>
            </AppUpload>
            
            <button @click.prevent="addLink" class="shadow p-5 rounded shadow w-1/2 flex items-center border border-transparent transition hover:bg-white hover:border-lilac-300">
                <span class="mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#808CFA" fill-rule="evenodd" d="M11.309 11.918c-1.24-1.11-3.114-1.2-4.414-.136l-2.678 2.192c-1.44 1.178-1.63 3.326-.423 4.797 1.205 1.472 3.35 1.709 4.79.53l2.677-2.192c1.265-1.036 1.565-2.82.8-4.236l.731-.599c1.239 1.028 3.048 1.086 4.313.05l2.678-2.192c1.44-1.179 1.63-3.327.423-4.798-1.205-1.471-3.35-1.708-4.79-.53L12.74 6.996c-1.3 1.065-1.58 2.92-.735 4.353l-.695.57Zm-3.51.968-2.677 2.192c-.843.69-.954 1.948-.248 2.809.706.86 1.961 1 2.804.31l2.678-2.192c.664-.544.873-1.44.587-2.217l-1.896 1.553a.601.601 0 0 1-.845-.094.6.6 0 0 1 .075-.846l1.927-1.578c-.718-.511-1.704-.51-2.404.063Zm6.112-1.527 1.296-1.061a.6.6 0 0 0 .074-.847.601.601 0 0 0-.845-.093l-1.327 1.087c-.36-.805-.165-1.77.535-2.344l2.678-2.192c.843-.69 2.098-.551 2.804.31s.595 2.118-.248 2.808L16.2 11.22a1.978 1.978 0 0 1-2.29.139Z" clip-rule="evenodd"/></svg></span>
                <span>{{ $t('Link') }}</span>
            </button>
        </div>
        
        <div v-if="state === 'add-link'">
            <AddLink
                :product="product"
                @cancel="onAddLinkCancel"
            ></AddLink>
        </div>
        
        <div class="-mx-16 mt-4">
            <draggable
                v-if="state === 'list' || state === 'edit-delivery'"
                v-model="currentProduct.media"
                @start="drag = true"
                @end="onSortEnd"
                :animation="200"
                ghost-class="opacity-0"
                handle=".handle"
            >
                <template #item="{element}">
                    <MediaItem
                        :delivery="element"
                        :product="currentProduct"
                        :edit="currentlyEditing === element.id"
                        @edit="onEditDelivery"
                        @remove="onRemoveDelivery"
                        @show="onShowDelivery"
                        @cancel="onStopEditDelivery"
                        @save="onSaveDelivery"
                        class="mt-1 -ml-[32px]"
                    ></MediaItem>
                </template>
            </draggable>
        </div>
    </div>

</template>

<script>
import AppUpload from '../../../Shared/AppUpload.vue'
import draggable from "vuedraggable"
import axios from "axios"
import MediaItem from "./MediaItem.vue";
import AppInput from "../../../Shared/AppInput.vue";
import AddLink from "./AddLink.vue";

export default {
    props: ['product'],
    data() {
        return {
            currentProduct: this.product,
            currentlyEditing: null,
            drag: null,
            editDelivery: null,
            state: 'list',
        }
    },
    components: {
        AddLink,
        AppInput,
        MediaItem,
        AppUpload,
        draggable
    },
    methods: {
        addLink() {
            this.state = 'add-link'
            
            this.$emit('is-editing', true)
        },
        onAddLinkCancel() {
            this.state = 'list'
            
            this.$emit('is-editing', false)
        },
        onUploadComplete(responses) {
            this.currentProduct = responses.pop().data.product
            this.currentlyEditing = null
            
            this.state = 'list'
            this.$emit('is-editing', false)
            
            this.$emit('update', this.currentProduct)
        },
        onSortEnd(event) {
            this.drag = false
            let files = this.currentProduct.media
    
            axios.post(this.route('2.products.media.sort', { product: this.currentProduct }), {
                files: files.map(file => file.id)
            })
        },
        onEditDelivery(delivery) {
            this.currentlyEditing = delivery.id
            // this.currentProduct = product
            
            this.state = 'edit-delivery'
            this.$emit('is-editing', true)
        },
        onStopEditDelivery(data) {
            this.currentlyEditing = null
            this.state = 'list'
            this.$emit('is-editing', false)
        },
        onSaveDelivery(product) {
            this.currentProduct = product
            
            this.state = 'list'
            this.$emit('is-editing', false)
        },
        onRemoveDelivery(delivery) {
            if (!confirm(this.$t('Are you sure you want to delete {file}?', { file: delivery.display_name }))) {
                return
            }
            
            axios
                .post(this.route('2.products.media.remove', { product: this.currentProduct, delivery: delivery }))
                .then(() => {
                    this.currentProduct.media = this.currentProduct.media.filter(item => item.id !== delivery.id)
                    this.$emit('is-editing', false)
                    this.state = 'list'
                })
        },
        onShowDelivery(delivery) {
            let url = delivery.link
            
            if (delivery.type === 'file') {
                url = this.route('2.products.media.download', { product: this.product, delivery: delivery })
            }
            
            window.open(url, '_blank')
        },
    }
}
</script>

<style>
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}
</style>