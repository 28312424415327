<template>
    <div
        :class="{
            'text-blue-300': !active,
            'text-blue-400': active
        }"
    >
        <template v-if="href">
            <InertiaLink :href="href" class="hover:underline"><slot></slot></InertiaLink>
        </template>
        <template v-else>
            <span><slot></slot></span>
        </template>
    </div>
    <template v-if="!active">
        <div class="text-blue-300">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
        </div>
    </template>
</template>

<script>
export default {
    props: {
        href: null,
        active: false,
    },
    mounted() {
    }
}
</script>