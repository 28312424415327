<template>
    <form @submit.prevent="save">
        <AppInput :label="$t('Link name')" v-model="form.display_name" class="mb-1" />
        <AppInput :label="$t('Link')" v-model="form.link" class="mb-1" />
        
        <div class="">
            <AppButton
                class="w-full"
                @click.prevent="save" :loading="form.processing"
            >{{ $t('Save') }}</AppButton>
            <p class="text-center mt-4 text-sm hover:underline">
                <a href="" @click.prevent="cancel">{{ $t('Cancel') }}</a>
            </p>
        </div>
    </form>
</template>

<script>
import AppInput from "../../../Shared/AppInput.vue";
import FormButton from "../../../Shared/FormButton.vue";
import AppButton from "../../../Shared/AppButton.vue";

export default {
    props: ['product'],
    components: {AppButton, FormButton, AppInput},
    data() {
        return {
            form: this.$inertia.form({
                display_name: '',
                link: '',
            })
        }
    },
    methods: {
        async save() {
            this.form.post(this.route('2.products.media.create', { product: this.product, type: 'link' }))
        },
        cancel() {
            this.form.clearErrors()
            this.$emit('cancel')
        }
    }
}
</script>