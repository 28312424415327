<template>
    <div class="editor-wrapper">
        <bubble-menu
            :editor="editor"
            :tippy-options="{ duration: 150 }"
            v-if="editor && !singleLine"
            class="bubble-menu"
        >
            <button v-if="allowed('headings')" @click.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'bubble-menu-item--active': editor.isActive('heading') }" class="bubble-menu-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-type-h1" viewBox="0 0 16 16">
                    <path d="M8.637 13V3.669H7.379V7.62H2.758V3.67H1.5V13h1.258V8.728h4.62V13h1.259zm5.329 0V3.669h-1.244L10.5 5.316v1.265l2.16-1.565h.062V13h1.244z"/>
                </svg>
            </button>
            <button @click.prevent="editor.chain().focus().toggleBold().run()" :class="{ 'bubble-menu-item--active': editor.isActive('bold') }" class="bubble-menu-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-type-bold" viewBox="0 0 16 16">
                    <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 0 0 1.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z"/>
                </svg>
            </button>
            <button @click.prevent="editor.chain().focus().toggleItalic().run()" :class="{ 'bubble-menu-item--active': editor.isActive('italic') }" class="bubble-menu-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-type-italic" viewBox="0 0 16 16">
                    <path d="M7.991 11.674 9.53 4.455c.123-.595.246-.71 1.347-.807l.11-.52H7.211l-.11.52c1.06.096 1.128.212 1.005.807L6.57 11.674c-.123.595-.246.71-1.346.806l-.11.52h3.774l.11-.52c-1.06-.095-1.129-.211-1.006-.806z"/>
                </svg>
            </button>
            <button @click.prevent="toggleTextAlignCenter" :class="{ 'bubble-menu-item--active': editor.isActive({ textAlign: 'center' }) }" class="bubble-menu-item text-black">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.625C0 3.45924 0.0658479 3.30027 0.183058 3.18306C0.300268 3.06585 0.45924 3 0.625 3H14.375C14.5408 3 14.6997 3.06585 14.8169 3.18306C14.9342 3.30027 15 3.45924 15 3.625C15 3.79076 14.9342 3.94973 14.8169 4.06694C14.6997 4.18415 14.5408 4.25 14.375 4.25H0.625C0.45924 4.25 0.300268 4.18415 0.183058 4.06694C0.0658479 3.94973 0 3.79076 0 3.625ZM0 8C0 7.83424 0.0658479 7.67527 0.183058 7.55806C0.300268 7.44085 0.45924 7.375 0.625 7.375H14.375C14.5408 7.375 14.6997 7.44085 14.8169 7.55806C14.9342 7.67527 15 7.83424 15 8C15 8.16576 14.9342 8.32473 14.8169 8.44194C14.6997 8.55915 14.5408 8.625 14.375 8.625H0.625C0.45924 8.625 0.300268 8.55915 0.183058 8.44194C0.0658479 8.32473 0 8.16576 0 8ZM0 12.375C0 12.2092 0.0658479 12.0503 0.183058 11.9331C0.300268 11.8158 0.45924 11.75 0.625 11.75H7.5C7.66576 11.75 7.82473 11.8158 7.94194 11.9331C8.05915 12.0503 8.125 12.2092 8.125 12.375C8.125 12.5408 8.05915 12.6997 7.94194 12.8169C7.82473 12.9342 7.66576 13 7.5 13H0.625C0.45924 13 0.300268 12.9342 0.183058 12.8169C0.0658479 12.6997 0 12.5408 0 12.375Z" />
                </svg>
            </button>
            <button @click.prevent="editor.chain().focus().toggleStrike().run()" :class="{ 'bubble-menu-item--active': editor.isActive('strike') }" class="bubble-menu-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-type-strikethrough" viewBox="0 0 16 16">
                    <path d="M6.333 5.686c0 .31.083.581.27.814H5.166a2.776 2.776 0 0 1-.099-.76c0-1.627 1.436-2.768 3.48-2.768 1.969 0 3.39 1.175 3.445 2.85h-1.23c-.11-1.08-.964-1.743-2.25-1.743-1.23 0-2.18.602-2.18 1.607zm2.194 7.478c-2.153 0-3.589-1.107-3.705-2.81h1.23c.144 1.06 1.129 1.703 2.544 1.703 1.34 0 2.31-.705 2.31-1.675 0-.827-.547-1.374-1.914-1.675L8.046 8.5H1v-1h14v1h-3.504c.468.437.675.994.675 1.697 0 1.826-1.436 2.967-3.644 2.967z"/>
                </svg>
            </button>
            <button @click.prevent="setLink" :class="{ 'bubble-menu-item--active': editor.isActive('link') }" class="bubble-menu-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"><path fill-rule="evenodd" d="M7.54 7.946c-.827-.741-2.077-.801-2.944-.092L2.811 9.316c-.96.785-1.086 2.218-.282 3.198.804.981 2.233 1.14 3.193.354l1.785-1.462c.844-.69 1.043-1.88.534-2.824l.487-.4c.826.686 2.032.725 2.876.034l1.785-1.461c.96-.786 1.086-2.218.282-3.199-.804-.98-2.233-1.139-3.193-.353L8.493 4.664c-.867.71-1.054 1.947-.49 2.902l-.464.38ZM5.2 8.59l-1.785 1.461c-.562.46-.636 1.298-.166 1.873.471.574 1.308.666 1.87.206l1.785-1.461c.442-.363.582-.96.392-1.478L6.03 10.227a.4.4 0 0 1-.563-.062.4.4 0 0 1 .05-.565l1.285-1.052a1.321 1.321 0 0 0-1.603.043Zm4.074-1.018.864-.708a.4.4 0 0 0 .05-.564.401.401 0 0 0-.564-.062l-.885.724a1.32 1.32 0 0 1 .357-1.562l1.785-1.462c.562-.46 1.399-.367 1.87.207.47.574.396 1.412-.166 1.872L10.8 7.48a1.318 1.318 0 0 1-1.526.093Z" clip-rule="evenodd"/></svg>
            </button>
        </bubble-menu>
    
        <editor-content :editor="editor" />
    
        <div class="toolbar" v-if="!singleLine && undo">
            <button @click.prevent="editor.chain().focus().undo().run()" class="toolbar-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                </svg>
            </button>
            <button @click.prevent="editor.chain().focus().redo().run()" class="toolbar-item">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import Typography from '@tiptap/extension-typography'
import Placeholder from '@tiptap/extension-placeholder'
import StarterKit from '@tiptap/starter-kit'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import Mention from '@tiptap/extension-mention'
import Link from '@tiptap/extension-link'
import { Editor, BubbleMenu, EditorContent, VueRenderer } from '@tiptap/vue-3'
import EditorPersonalizationList from './EditorPersonalizationList.vue'
import tippy from 'tippy.js'

export default {
    components: {
        EditorContent,
        BubbleMenu,
    },
    
    props: {
        value: {
            type: String,
            default: '',
        },
        singleLine: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: 'Write something …',
        },
        undo: {
            type: Boolean,
            default: true,
        },
        exclude: {
            type: Array,
            default: () => [],
        },
    },
    
    data() {
        return {
            editor: null,
        }
    },

    methods: {
        focus() {
            this.editor.commands.focus()
        },
        
        allowed(key) {
            if (this.exclude.includes(key)) {
                return false
            }
            
            return true
        },
        toggleTextAlignCenter() {
            if (this.editor.isActive({ textAlign: 'center' })) {
                this.editor.chain().focus().setTextAlign('left').run()
            } else {
                this.editor.chain().focus().setTextAlign('center').run()
            }

            // this.editor.chain().focus().run()
        },
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ href: url })
                .run()
        },
    },
    
    mounted() {
        let extensions = [
            StarterKit.configure({
                heading: {
                    levels: [1, 2],
                },
                history: {
                    depth: 100,
                    newGroupDelay: 500,
                },
            }),
            Placeholder.configure({
                placeholder: this.placeholder,
            }),
            Typography,
            Link.configure({
                openOnClick: false,
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Mention.configure({
                HTMLAttributes: {
                    class: 'editor-mention',
                },
                renderLabel({ options, node }) {
                    return `${node.attrs.label ?? node.attrs.id}`
                },
                suggestion: {
                    char: '!',
                    items: ({ query }) => {
                        return [
                            'FirstName', 'LastName', 'Email', 'OrderNumber',
                        ].filter(item => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
                    },
                    render: () => {
                        let component
                        let popup

                        return {
                            onStart: props => {
                                component = new VueRenderer(EditorPersonalizationList, {
                                    props,
                                    editor: props.editor,
                                })

                                if (!props.clientRect) {
                                    return
                                }

                                popup = tippy('body', {
                                    getReferenceClientRect: props.clientRect,
                                    appendTo: () => document.body,
                                    content: component.element,
                                    showOnCreate: true,
                                    interactive: true,
                                    trigger: 'manual',
                                    placement: 'bottom-start',
                                })
                            },

                            onUpdate(props) {
                                component.updateProps(props)

                                if (!props.clientRect) {
                                    return
                                }

                                popup[0].setProps({
                                    getReferenceClientRect: props.clientRect,
                                })
                            },

                            onKeyDown(props) {
                                if (props.event.key === 'Escape') {
                                    popup[0].hide()

                                    return true
                                }

                                return component.ref?.onKeyDown(props)
                            },

                            onExit() {
                                popup[0].destroy()
                                component.destroy()
                            },
                        }
                    },
                }
            })
        ]

        if (this.singleLine) {
            extensions = [
                Document,
                Paragraph,
                Text,
            ]
        }
    
        this.editor = new Editor({
            extensions: extensions,
            editorProps: {
                attributes: {
                    class: 'editor-content',
                },
            },
            content: this.value,
            onUpdate: () => {
                this.$emit('update', this.editor.getHTML())
                // this.$emit('update:modelValue', this.editor.getJSON())
            },
        })
    },
    
    beforeUnmount() {
        this.editor.destroy()
    },
}
</script>

<style>
/* Basic editor styles */
.ProseMirror > * + * {
  margin-top: 0.75em;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
    height: 0;
}

/* Placeholder (on every new line) */
/*.ProseMirror p.is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}*/

.editor-wrapper {
    position: relative;
}

.editor-wrapper h1 {
    font-size: 18px;
    font-weight: 500;
}

.editor-content:focus {
    outline: none;
}

.bubble-menu {
    background-color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}
.bubble-menu-item{
    border: 0;
    background: none;
    padding: 4px;
    padding: 10px 10px;
}
.bubble-menu-item:hover {
    background-color: #EBF8FF;
    border-radius: 50%;
}
.bubble-menu-item--active {
    color: blue;
}
.toolbar {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -64px;
}
.toolbar-item {
    margin: 0.25rem 0;
    background: none;
    border: none;
    color: #BCBED3;
}
.toolbar-item:hover {
    color: #5961A7;
}

.editor-mention {
    border: 1px solid #000;
    padding: 2px 7px;
    border-radius: 8px;
}

.tippy-box {
    background-color: transparent !important;
    color: #333;
    font-size: 12px;
}

.tippy-arrow {
    display: none;
}
</style>