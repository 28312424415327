<template>
    <div class="relative block mt-1 flex items-center">
        <div class="block handle cursor-move mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill="#A6AFFC" d="M8 6h3v3H8zM13 6h3v3h-3zM8 11h3v3H8zM13 11h3v3h-3zM8 16h3v3H8zM13 16h3v3h-3z"/></svg>
        </div>
        <div
            class="shadow relative block bg-white group-hover:bg-white flex-1 flex items-center flex-wrap border"
            :class="{
                'border-lilac-300 rounded-b rounded-t': isEditing,
                'border-transparent': !isEditing
            }"
        >
            <div class="block flex items-center p-4 w-full">
                <a @click.prevent="edit" href="" class="block w-10 mr-3">
                    <template v-if="delivery.preview_image">
                        <img :src="delivery.preview_image" class="rounded-tr-xl rounded-bl-xl">
                    </template>
                    <DefaultMediaImage v-else :kind="delivery.kind" :extension="delivery.file_name ? delivery.file_name.split('.').pop() : null" size="sm" />
                </a>
                <a @click.prevent="edit" href="" class="block flex-1">
                    <span class="block text-blue-400 leading-4 text-sm break-all">{{ delivery.display_name }}</span>
                    
                    <span v-if="delivery.type === 'file'" class="block text-sm text-blue-300 leading-4 uppercase">
                        {{ delivery.file_name.split('.').pop() }} · {{ humanFileSize() }}
                    </span>
                    <span v-else class="block text-sm text-blue-300 leading-4">
                        {{ delivery.link }}
                    </span>
                </a>
                <div>
                    <div class="-mb-2 -mt-2">
                        <AppMenu ref="menu" :fixed-height="true">
                            <AppMenuItem @click="edit">
                                <template v-if="isEditing">{{ $t('Close') }}</template>
                                <template v-else>{{ $t('Edit') }}</template>
                            </AppMenuItem>
                            <AppMenuItem @click="$emit('show', delivery)">
                                <template v-if="delivery.type === 'file'">{{ $t('Download') }}</template>
                                <template v-else-if="delivery.type === 'link'">{{ $t('Open link') }}</template>
                            </AppMenuItem>
                            <AppMenuItem @click="$emit('remove', delivery)">{{ $t('Remove') }}</AppMenuItem>
                        </AppMenu>
                    </div>
                </div>
            </div>
            <div v-if="isEditing" class="w-full p-4">
                <MediaItemEdit
                    :delivery="delivery"
                    :product="product"
                    @save="onSave"
                ></MediaItemEdit>
            </div>
        </div>
    </div>
</template>

<script>
import AppMenu from "../../../Shared/AppMenu.vue";
import AppMenuItem from "../../../Shared/AppMenuItem.vue";
import AppInput from "../../../Shared/AppInput.vue";
import AppButton from "../../../Shared/AppButton.vue";
import Editor from "../../../Shared/Editor.vue";
import MediaItemEdit from "./MediaItemEdit.vue";
import DefaultMediaImage from "./DefaultMediaImage.vue";

export default {
    components: {DefaultMediaImage, MediaItemEdit, Editor, AppButton, AppInput, AppMenuItem, AppMenu},
    props: {
        delivery: {
            type: Object,
            required: true,
        },
        product: {
            type: Object,
            required: true,
        },
        edit: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            isEditing: this.edit,
            
            form: {
                displayName: this.delivery.display_name,
                link: this.delivery.link,
                description: this.delivery.description,
            }
        }
    },
    methods: {
        edit() {
            // console.log(this.$refs.menu)
            // this.$refs.menu.close()
            
            this.isEditing = ! this.isEditing
            
            if (this.isEditing) {
                this.$emit('edit', this.delivery)
            } else {
                this.$emit('cancel', this.delivery)
            }
        },
        onSave(product) {
            this.isEditing = false
            this.$emit('save', product)
        },
        humanFileSize() {
            let fileSizeInMB = (this.delivery.file_size / (1024 * 1024)).toFixed(2)
            let fileSizeInKB = (this.delivery.file_size / 1024).toFixed(2)
            
            if (this.delivery.file_size < 1024 * 1024) {
                return fileSizeInKB + "KB"
            }
            
            return fileSizeInMB + "MB"
        },
    },
    watch: {
        edit(newVal) {
            if (newVal) {
                this.isEditing = true
            } else {
                this.isEditing = false
            }
        }
    }
}
</script>