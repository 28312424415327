<template>
    <template v-if="order.billing_method && order.billing_method.type === 'stripe'">
        <span>
            {{ $t('Card') }}, ···· ···· ···· {{ order.billing_method_remote_data?.last4 }} ({{ order.billing_method_remote_data?.brand }})
        </span>
    </template>
    <template v-if="order.billing_method && order.billing_method.type === 'swish'">
        <span v-if="order.billing_method_remote_data">{{ order.billing_method_remote_data.payerAlias }} (Swish)</span>
        <span v-else>Swish</span>
    </template>
    <template v-if="order.billing_method && order.billing_method.type === 'trustly'">
        <span>
            {{ $t('Direct Debit') }}, {{ order.billing_method_remote_data?.bank }}
            ···· {{ order.billing_method_remote_data?.lastdigits }}
        </span>
    </template>
    <template v-if="order.billing_method && order.billing_method.type === 'billecta'">
        <span v-if="order.billing_method_remote_data?.billecta_invoice_number">
            Billecta ID: {{ order.billing_method_remote_data?.billecta_invoice_number }}
        </span>
        <span v-else>{{ $t('Awaiting creation on Billecta') }}</span>
    </template>
    <template v-if="order.billing_method && order.billing_method.type === 'fortnox'">
        <span>
            {{ $t('Invoice') }} {{ order.billing_method_remote_id }}
        </span>
    </template>
    <template v-if="order.billing_method && order.billing_method.type === 'manual'">
        <span>
            {{ $t('Manual payment method') }}: {{ manualName(order.billing_method_remote_data.cart_method) }}
        </span>
    </template>
</template>

<script>
export default {
    props: ['order'],
    methods: {
        manualName(method) {
            if (! method) {
                return null
            }

            return method.split(':')[1]
        }
    }
}
</script>
