<template>
    <div class="text-lg font-medium text-blue-400 text-center pt-6 pb-6">{{ $t('Choices') }}</div>

    <button
        @click.prevent="addChoice"
        class="
            py-5 pl-5 pr-7 rounded shadow mx-auto mb-6 flex items-center border border-transparent transition
            hover:bg-white hover:border-lilac-300
        ">
        <span class="mr-2 text-blue-200"><svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path fill-rule="evenodd" d="M13 5h-2v6H5v2h6v6h2v-6h6v-2h-6V5Z" clip-rule="evenodd"/></svg></span><span>{{ $t('Add choice') }}</span>
    </button>

    <SlickList axis="y" v-model:list="choiceList" @sort-end="onSortEnd" @sort-start="onSortStart" useDragHandle>
        <SlickItem v-for="(choice, i) in choiceList" :key="choice.id" :index="i">
            <div class="-ml-16 -mr-16">
                <div
                    class="bg-white shadow mb-1 p-6 flex-1 border"
                    :class="{
                        'border-lilac-400 rounded': choice.editing,
                        'border-transparent': !choice.editing,
                    }"
                >
                    <DragHandle class="absolute -ml-20 cursor-move">
                        <svg class="fill-current" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="20" y="17" width="3" height="3" /><rect x="25" y="17" width="3" height="3" /><rect x="20" y="22" width="3" height="3" /><rect x="25" y="22" width="3" height="3" /><rect x="20" y="27" width="3" height="3" /><rect x="25" y="27" width="3" height="3" /></svg>
                    </DragHandle>

                    <CustomFieldChoice
                        :field="field"
                        :choice="choice"
                        :edit="choice.editing"
                        @edit="onEditCustomFieldChoice"
                    />
                </div>
            </div>
        </SlickItem>
    </SlickList>
</template>

<script>
import CustomFieldListItem from './CustomFieldListItem.vue'
import { SlickList, SlickItem, DragHandle } from 'vue-slicksort'
import CustomFieldChoice from './CustomFieldChoice.vue'

export default {
    components: {CustomFieldChoice, CustomFieldListItem, SlickList, SlickItem, DragHandle},
    props: ['field'],
    data() {
        return {
            choiceList: this.field.choices,
            isDragging: false,
        }
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        onSortStart() {
            this.isDragging = true
        },
        onSortEnd() {
            this.isDragging = false
            this.updateOrder()
        },
        updateOrder() {
            this.$nextTick(() => {
                this.$inertia.post(this.route('2.custom-fields.choices.sort', { field: this.field }), {
                    order: this.choiceList.map(field => field.id),
                }, {
                    preserveScroll: true,
                    preserveState: true,
                })
            })
        },
        onEditCustomFieldChoice(editingChoice) {
            this.choiceList = this.choiceList.map(choice => {
                choice.editing = editingChoice.id === choice.id

                return choice
            })
        },
        async addChoice() {
            let name = prompt(this.$t('Enter choice name'))

            if (name !== null) {
                await this.$inertia.post(this.route('2.custom-fields.choices.store', { field: this.field }), {
                    label: name,
                    description: '',
                }, {
                    preserveState: false,
                    preserveScroll: true,
                })
            }
        },
    }
}
</script>