<template>
    <Layout>
        
            <Breadcrumbs v-if="subscription">
                <Breadcrumb :href="route('2.subscriptions.index')">{{ $t('Subscriptions') }}</Breadcrumb>
                <Breadcrumb :active="true">{{ subscription.product.name }}</Breadcrumb>
            </Breadcrumbs>
        
            <div class="flex flex-wrap md:flex-row flex-col-reverse">
                
                <!-- Subscription -->
                <div class="md:w-2/3">
                    
                    <template v-if="stripe.isCancelled">
                        <div class="bg-red-400/10 shadow rounded p-4 sm:py-7 sm:px-7 md:mr-1.5 mb-1 border border-red-400">
                            {{ $t("We can't charge for this subscription since it has been cancelled by Stripe. If you reactive the subscription we will attempt to charge the card on file today.") }}
                            
                            <div class="mt-8">
                                <form @submit.prevent="reactivateStripeSubscription">
                                    <AppSpinner v-if="stripe.isReactivating" color="#f1546b"></AppSpinner>
                                    <div v-else class="text-center"><button type="submit" class="bg-red-400 text-white px-4 py-2 rounded">{{ $t('Reactivate subscription') }}</button></div>
                                </form>
                            </div>
                        </div>
                    </template>
                    
                    <div class="bg-white shadow rounded p-4 sm:py-7 sm:px-7 md:mr-1.5">
                        <div class="flex justify-between">
                            <div>
                                <SubscriptionStatus :subscription="subscription" class="mb-1"></SubscriptionStatus>
                
                                <div class="mb-12">
                                    <div class="text-xl text-blue-400 font-semibold tracking-wider">{{ subscription.product.name }}</div>
                                    <div class="text-blue-300 font-medium tracking-wider"><Reference :subscription="subscription"></Reference></div>
                                    <div class="text-blue-300">{{ $t('Created {date}', { date: date(subscription.created_at) }) }}</div>
                                </div>
                
                                <div>
                                    <div class="flex items-center mb-2">
                                        <MoneyIcon class="text-lilac-300 mr-2" />
                                        <SubscriptionProduct :subscription="subscription" />
                                    </div>
                                    <div class="flex items-center">
                                        <SubscriptionIcon class="text-lilac-300 mr-2" />
                                        <SubscriptionRenews :subscription="subscription"></SubscriptionRenews>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <AppMenu>
                                    <AppMenuItem :href="route('2.orders.create', { subscription: subscription.id })">{{ $t('Add additional charge') }}</AppMenuItem>
                                    <AppMenuItem :href="route('2.subscriptions.price.edit', { subscription })">{{ $t('Change amount') }}</AppMenuItem>
                                    <AppMenuItem :href="route('2.subscriptions.product.edit', { subscription })">{{ $t('Change product') }}</AppMenuItem>
                                    <AppMenuItem :href="`${route('2.orders.index')}?filters[subscription]=${subscription.id}`">{{ $t('Payment history') }}</AppMenuItem>
                                    <AppMenuItem href="#">{{ $t('Change card') }}</AppMenuItem>
                                    <AppMenuItem :href="route('2.subscriptions.date.edit', { subscription })">{{ $t('Change renewal date') }}</AppMenuItem>
                                    <AppMenuItem :href="route('2.subscriptions.cancel', { subscription })">{{ $t('Cancel subscription') }}</AppMenuItem>
                                    <AppMenuItem :href="route('2.subscriptions.welcome', { subscription })">{{ $t('Re-send welcome email') }}</AppMenuItem>
                                </AppMenu>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Orders for Subscription -->
                    <div v-if="orders.length > 0" class="mt-12 md:mr-1.5">
                        <div class="flex items-center justify-between  mb-3">
                            <div class="font-medium">{{ $t('Payments') }}</div>
                            <div v-if="hasMoreOrders">
                                <InertiaLink :href="route('2.orders.index', { 'filters[subscription]': subscription.id })" class="text-blue-300 font-medium text-sm">
                                    <span class="flex items-center">
                                        <span class="mr-1.5">{{ $t('View all') }}</span>
                                        <span><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="none"><g clip-path="url(#a)"><path fill="#808CFA" d="m2.614.109-.309.354a.237.237 0 0 0 0 .303L5.13 4 2.305 7.234a.237.237 0 0 0 0 .304l.31.353a.172.172 0 0 0 .265 0l3.272-3.74a.237.237 0 0 0 0-.303L2.88.11a.172.172 0 0 0-.266 0Z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 8V0h8v8z"/></clipPath></defs></svg></span>
                                    </span>
                                </InertiaLink>
                            </div>
                        </div>
                        <TableRow
                            :href="`${route('2.orders.show', { order: order.id })}`"
                            v-for="order in orders"
                        >
                            <TableColumn class="sm:w-2/3">
                                <span class="block text-blue-300 font-medium">{{ $t('{date} with {method}', { date: date(order.created_at), method: order.billing_method.pretty_name }) }}</span>
                                <span class="block text-blue-300 text-sm">{{ $t('Order {number}', { number: order.uniq_id })}}</span>
                            </TableColumn>
                            <TableColumn class="sm:w-1/3 sm:text-right pt-0 sm:pt-4">
                                <span class="font-semibold text-blue-300">{{ centsToMoney(order.total_with_vat, order.currency) }}</span>
                                <OrderStatus :order="order" :margin="false" class="justify-end"></OrderStatus>
                            </TableColumn>
                        </TableRow>
                    </div>
                    
                    <Activities v-if="! stripe.isReactivating" :activities="activities.data" class="mt-12 md:mr-1.5"></Activities>
                    <Pagination v-if="! stripe.isReactivating" :links="activities.links"></Pagination>
                    
                </div>
                
                <!-- Customer -->
                <div class="md:w-1/3">
                    <CustomerCard
                        class="md:ml-1.5 mb-3"
                        :billing-method="subscription.billing_method"
                        :custom-fields="subscription.custom_fields"
                        :billing-address="subscription.billing_method.address"
                        :attachment="{ name: 'subscription', id: subscription.id }"
                    />
                </div>
            </div>
        
    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout";
import Breadcrumb from "../../Shared/Breadcrumb";
import Breadcrumbs from "../../Shared/Breadcrumbs";
import SubscriptionName from "../../Shared/SubscriptionName";
import SubscriptionIcon from "../../Shared/Icons/SubscriptionIcon";
import SubscriptionStatus from "../../Shared/SubscriptionStatus";
import AppMenuItem from "../../Shared/AppMenuItem";
import AppMenu from "../../Shared/AppMenu";
import Reference from "../../Shared/Reference";
import Activities from "../../Shared/Activities";
import SubscriptionRenews from "../../Shared/SubscriptionRenews";
import MoneyIcon from "../../Shared/Icons/MoneyIcon";
import OrderIcon from "../../Shared/Activities/OrderIcon";
import PersonIcon from "../../Shared/Icons/PersonIcon";
import CustomerCard from "../../Shared/CustomerCard";
import Pagination from "../../Shared/Pagination";
import SubscriptionProduct from "../../Shared/SubscriptionProduct.vue";
import axios from "axios";
import AppSpinner from "../../Shared/AppSpinner.vue";
import { router } from '@inertiajs/vue3'
import TableColumn from "../../Shared/TableColumn.vue";
import TableRow from "../../Shared/TableRow.vue";
import OrderStatus from "../../Shared/OrderStatus.vue";

export default {
    components: {
        OrderStatus, TableRow, TableColumn,
        AppSpinner,
        SubscriptionProduct,
        Pagination,
        CustomerCard,
        PersonIcon,
        OrderIcon,
        MoneyIcon,
        SubscriptionRenews,
        Activities,
        Reference,
        AppMenu, AppMenuItem, SubscriptionStatus, SubscriptionIcon, SubscriptionName, Breadcrumbs, Breadcrumb, Layout},
    props: ['subscription', 'revenue', 'activities', 'customerRevenue', 'ordersCount', 'subscriptionsCount', 'orders', 'hasMoreOrders'],
    data() {
        return {
            usingStripe: this.subscription.billing_method.type === 'stripe',
            stripe: {
                isReactivating: false,
                isCancelled: false,
                endsAt: null,
                cards: {},
            }
        }
    },
    created() {
        if (this.usingStripe) {
            this.loadStripeStatus()
        }
    },
    methods: {
        async loadStripeStatus() {
            let response = await axios.get(this.route('2.subscriptions.stripe.show', { subscription: this.subscription.id }))
            
            this.stripe.isCancelled = response.data.isCancelled
            this.stripe.endsAt = response.data.endsAt
            this.stripe.cards = response.data.cards
        },
        async reactivateStripeSubscription() {
            this.stripe.isReactivating = true
            
            router.post(this.route('2.subscriptions.stripe.reactivate', { subscription: this.subscription.id }), {}, {
                replace: true,
                onSuccess: () => {
                    this.stripe.isReactivating = false
                    this.loadStripeStatus()
                }
            })
        }
    }
}
</script>