<template>
    <InertiaLink :href="href"
       class="
            font-medium
            group
            mt-[-14px]
            pt-[14px]
            transition-all
            duration-150
            border-t-2
        "
        :class="{
            'text-lilac-300 hover:text-lilac-500': !active,
            'border-transparent': !active,
            
            'border-lilac-300': active,
            'text-lilac-500': active
        }"
        >
        <span class="block flex items-center">
            <span
                class="inline-block group-hover:text-lilac-500 mr-2 transition-all duration-150"
                :class="{
                    'text-lilac-200': !active,
                    'text-lilac-500': active,
                }"
            >
                <slot name="icon"></slot>
            </span>
            <span class="inline-block">
                <slot></slot>
            </span>
        </span>
    </InertiaLink>
</template>

<script>
export default {
    props: {
        href: null,
        active: false,
    }
}
</script>