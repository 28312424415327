<template>
    <Layout>

        <Sheet :back="route('2.shipping.index')">
            <SheetIcon><ShippingIconLarge class="text-white" /></SheetIcon>
            <SheetTitle v-if="rate">{{ $t('Edit {rate} for {zone}', { rate: rate.name, zone: zone.name }) }}</SheetTitle>
            <SheetTitle v-else>{{ $t('New shipping rate for {zone}', { zone: zone.name }) }}</SheetTitle>

            <form @submit.prevent="submit">
                <AppInput :label="$t('Name')" v-model="form.name" class="mb-1" :error="$attrs.errors.name" />

                <div class="flex justify-between mb-0.5">
                    <div class="w-2/3 mr-0.5">
                        <AppInput :label="$t('Price')" v-model="form.price" class="mb-1" :error="$attrs.errors.price"></AppInput>
                    </div>

                    <div class="w-1/3 ml-0.5">
                        <AppSelect v-model="form.currency" :error="$attrs.errors.currency" :label="$t('Currency')">
                            <option v-for="(value, type) in currencies" :value="type">{{ value }}</option>
                        </AppSelect>
                    </div>
                </div>

                <template v-if="vatRate > 0">
                    <div>
                        <AppSelect :label="$t('Does the price include VAT?')" v-model="form.show_price_including_vat">
                            <option :value="1">{{ $t('Yes') }}</option>
                            <option :value="0">{{ $t('No') }}</option>
                        </AppSelect>

                        <p v-if="form.show_price_including_vat" class="text-center mt-2 text-sm">{{ $t("We will subtract {rate}% from the price you have entered", { rate: vatRate/100 }) }}</p>
                        <p v-else class="text-center mt-2 text-sm">{{ $t('Your product\'s VAT will be applied to the shipping price.') }}</p>
                    </div>
                </template>

                <div v-if="! showConditions" class="text-center mt-12">
                    <a href="" @click.prevent="showConditions = true" class="inline-block text-sm text-blue-300 border border-lilac-200 rounded group transform duration-300 hover:border-lilac-400">
                        <span class="flex items-center h-full">
                            <span class="block px-3">{{ $t('Add condition') }}</span>
                            <span class="px-3 bg-blue-100 h-[32px] flex items-center justify-center text-center rounded-r border-l border-lilac-200 group-hover:border-lilac-400">
                                <svg class="" xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="none"><path fill="#251161" d="m2.614.109-.309.354a.237.237 0 0 0 0 .303L5.13 4 2.305 7.234a.237.237 0 0 0 0 .304l.31.353a.172.172 0 0 0 .265 0l3.272-3.74a.237.237 0 0 0 0-.303L2.88.11a.172.172 0 0 0-.266 0Z"/></svg>
                            </span>
                        </span>
                    </a>
                </div>

                <template v-if="showConditions">

                    <AppRadioGroup
                        :options="options"
                        v-model="form.condition"
                        class="mt-12"
                    />

                    <div class="flex space-x-1" v-if="form.condition">
                        <div class="w-1/2 relative">
                            <AppInput :label="$t('Min')" v-model="form.min" class="mb-1" :error="$attrs.errors.min"></AppInput>
                            <div class="absolute right-0 top-0 mt-6 mr-4 uppercase">
                                <span v-if="form.condition === 'price'">{{ form.currency }}</span>
                                <span v-else-if="form.condition === 'weight'" class="normal-case">kg</span>
                            </div>
                        </div>
                        <div class="w-1/2 relative">
                            <AppInput :label="$t('Max')" v-model="form.max" class="mb-1" :error="$attrs.errors.max"></AppInput>
                            <div class="absolute right-0 top-0 mt-6 mr-4 uppercase">
                                <span v-if="form.condition === 'price'">{{ form.currency }}</span>
                                <span v-else-if="form.condition === 'weight'" class="normal-case">kg</span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-4 text-center">
                        <a href="" @click.prevent="showConditions = false" class="text-sm underline text-blue-300">{{ $t('Remove condition') }}</a>
                    </div>

                </template>

                <FormError />
                <FormButton :loading="form.processing" type="submit">{{ $t('Save') }}</FormButton>
            </form>
        </Sheet>

    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout.vue"
import Sheet from "../../../Shared/Sheet.vue"
import SheetIcon from "../../../Shared/SheetIcon.vue"
import SheetTitle from "../../../Shared/SheetTitle.vue"
import ShippingIconLarge from "../../../Shared/Icons/ShippingIconLarge.vue";
import AppInput from "../../../Shared/AppInput.vue";
import AppSelect from "../../../Shared/AppSelect.vue";
import FormButton from "../../../Shared/FormButton.vue";
import FormError from "../../../Shared/FormError.vue";
import AppRadioGroup from "../../../Shared/AppRadioGroup.vue";

export default {
    props: ['zone', 'currencies', 'rate', 'vatRate'],
    components: {
        AppRadioGroup,
        FormError, FormButton, AppSelect, AppInput, ShippingIconLarge, SheetTitle, SheetIcon, Sheet, Layout
    },
    data() {
        return {
            showConditions: this.rate ? this.rate.condition : false,
            form: this.$inertia.form({
                'name': this.rate ? this.rate.name : '',
                'price': this.rate ? this.rate.price / 100 : '',
                'currency': this.rate ? this.rate.currency : 'sek',
                'min': this.rate ? this.rate.min : null,
                'max': this.rate ? this.rate.max : null,
                'condition': this.rate ? this.rate.condition : '',
                'show_price_including_vat': this.rate ? this.rate.show_price_including_vat : 0,
            }),
            options: [
                { label: this.$t('Based on product weight'), value: 'weight' },
                { label: this.$t('Based on order value excl. VAT'), value: 'price' },
            ],
        }
    },
    methods: {
        submit() {
            if (this.rate) {
                this.form.post(this.route('2.shipping.rate.update', { zone: this.zone.id, id: this.rate.id }))
            } else {
                this.form.post(this.route('2.shipping.rate.store', { zone: this.zone.id }))
            }
        },
    },
    watch: {
        'showConditions': function (val) {
            if (! val) {
                this.form.condition = null
                this.form.min = null
                this.form.max = null
            }
        }
    }
}
</script>