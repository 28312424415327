<template>
    <div class="app-spinner">
        <div class="app-bounce1" :style="style" :class="className"></div>
        <div class="app-bounce2" :style="style" :class="className"></div>
        <div class="app-bounce3" :style="style" :class="className"></div>
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#808CFA'
        },
        className: {
            type: String,
            default: null,
        }
    },
    computed: {
        style() {
            if (this.className) {
                return {}
            }
            
            return {
                'background-color': this.color
            }
        }
    }
}
</script>

<style>
.app-spinner {
    width: 50px;
    text-align: center;
    margin: 0 auto;
}

.app-spinner > div {
    width: 8px;
    height: 8px;
    margin-right: 4px;
    
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.app-spinner .app-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.app-spinner .app-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}
</style>