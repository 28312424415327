<template>
    <Layout>
    
        <Sheet>
            <SheetIcon>
                <AppearanceIcon class="text-white" />
            </SheetIcon>
            <SheetTitle>{{ $t('Edit Appearance') }}</SheetTitle>

            <AppUpload
                :url="route('2.appearance.logo-upload')"
                visibility="public-read"
                :allowed-types="['image/png', 'image/jpeg']"
                class="mb-12"

                @upload-start="logoUploading = true"
                @upload-complete="onUploadComplete"
                @drag-enter="logoDragging = true"
                @drag-leave="logoDragging = false"
            >
                <span
                    class="shadow p-5 rounded flex items-center justify-center border border-dashed border-blue-200 flex-wrap transition hover:bg-white hover:border-lilac-300"
                    :class="{ 'bg-white border-lilac-300': logoDragging }"
                >
                    <span v-if="logoUploading">
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-lilac-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </span>
                    <span v-else>
                        <span v-if="currentLogoUrl" class="block text-center">
                            <img class="inline-block" :src="currentLogoUrl" width="140">
                        </span>
                        <span v-else class="block text-center mb-2 text-lilac-300">
                            <svg class="inline-block fill-current" xmlns="http://www.w3.org/2000/svg" width="54" height="54" fill="none"><path fill-rule="evenodd" d="M33.209 15.345c2.265 1.161 3.603 2.747 3.793 4.466l.16 1.438 1.354-.508c2.353-.883 4.318-.277 5.285.726.48.498.726 1.094.717 1.717-.008.622-.274 1.388-1.034 2.207l-.755.812.802.766c2.236 2.14 3.158 4.031 3.305 5.594.144 1.533-.44 2.947-1.645 4.206-2.474 2.583-7.4 4.294-12.566 4.294v2.25c5.521 0 11.142-1.805 14.19-4.988 1.556-1.624 2.479-3.655 2.26-5.972-.192-2.051-1.263-4.163-3.263-6.28.622-.915.943-1.888.956-2.858.018-1.275-.499-2.43-1.348-3.31-1.462-1.516-3.87-2.215-6.465-1.637-.715-2.146-2.538-3.807-4.72-4.925-2.627-1.347-5.976-2.03-9.27-1.88-3.293.148-6.64 1.132-9.188 3.245-2.328 1.932-3.91 4.744-4.198 8.498-2.083-.044-3.844.963-5.039 2.515-1.413 1.836-2.044 4.427-1.663 7.032.385 2.63 1.802 5.299 4.518 7.295 2.708 1.992 6.635 3.264 11.98 3.264v-2.25c-4.996 0-8.42-1.188-10.648-2.826-2.22-1.633-3.324-3.762-3.624-5.809-.303-2.071.22-4.033 1.22-5.334.975-1.266 2.386-1.91 4.086-1.527l1.372.31V24.47c0-3.764 1.396-6.34 3.432-8.029 2.072-1.719 4.904-2.597 7.853-2.73 2.948-.133 5.902.486 8.143 1.635ZM27 26.437l6.75 6.75h-5.062v10.126h-3.375V33.187H20.25l6.75-6.75Z" clip-rule="evenodd"/></svg>
                        </span>

                        <span class="absolute left-0 right-0 w-full mt-2 flex justify-center items-center z-10 flex space-x-2">
                            <span class="bg-lilac-300 shadow px-3 py-1 text-white rounded text-sm font-medium">
                                <span v-if="currentLogoUrl">{{ $t('Change') }}</span>
                                <span v-else>{{ $t('Upload logo') }}</span>
                            </span>
                            <span v-if="currentLogoUrl" class="bg-lilac-300 shadow px-3 min-h-[26px] text-white rounded text-sm font-medium flex items-center" @click.prevent="removeLogo">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-3.5 h-3.5">
                                    <path fill-rule="evenodd" d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z" clip-rule="evenodd" />
                                </svg>
                            </span>
                        </span>

                        <a v-if="currentLogoUrl" @click.prevent="removeLogo" class="text-lilac-300 bg-white rounded-full absolute right-0 mt-2 -mr-3 p-1 z-20 shadow">

                        </a>
                    </span>
                </span>
            </AppUpload>

            <form @submit.prevent="submit">
                <div class="mb-1">
                    <AppInput :label="$t('Visible company name')" v-model="form.app_name" :error="form.errors.app_name"></AppInput>
                </div>
                <div class="mb-1">
                    <AppInput :label="$t('Legal company name')" v-model="form.merchant_company_name" :error="form.errors.merchant_company_name"></AppInput>
                </div>
                <div class="mb-1">
                    <AppInput :label="$t('EU VAT ID')" v-model="form.merchant_vat_number" :error="form.errors.merchant_vat_number"></AppInput>
                </div>
                <div class="mb-1">
                    <AppInput :label="$t('Address')" v-model="form.location.line1"></AppInput>
                </div>
                <div class="mb-1">
                    <div class="flex space-x-1">
                        <div class="w-1/3"><AppInput :label="$t('Postal code')" v-model="form.location.zip"></AppInput></div>
                        <div class="w-2/3"><AppInput :label="$t('City/Town')" v-model="form.location.city"></AppInput></div>
                    </div>
                </div>
                <div class="mb-12">
                    <AppSelect v-model="form.merchant_country" :error="form.errors.merchant_country">
                        <option v-for="(country, iso) in countries" :value="iso">{{ country.name }}</option>
                    </AppSelect>
                </div>

                <div class="text-xs uppercase mb-1 tracking-wide">
                    {{ $t('Terms') }}
                </div>
                <div class="bg-white shadow p-4 mb-4 rounded">
                    <Editor placeholder="" :value="form.terms" @update="onUpdateTerms" :undo="false"></Editor>
                </div>

                <div class="text-center">
                    <button @click.prevent="changeMoreColors" class="text-blue-250 underline">
                        {{ $t('Change colors') }}
                    </button>
                </div>

                <FormError />

                <FormButton :loading="form.processing" type="submit">{{ $t('Save') }}</FormButton>
            </form>
        </Sheet>
        
    </Layout>
</template>

<script>
import Layout from '../../Shared/Layout';
import FormError from "../../Shared/FormError.vue";
import FormButton from "../../Shared/FormButton.vue";
import Sheet from "../../Shared/Sheet.vue";
import SheetTitle from "../../Shared/SheetTitle.vue";
import SheetIcon from "../../Shared/SheetIcon.vue";
import AppUpload from "../../Shared/AppUpload.vue";
import AppearanceIcon from "../../Shared/Icons/AppearanceIcon.vue";
import AppInput from "../../Shared/AppInput.vue";
import AppSelect from "../../Shared/AppSelect.vue";
import AppTextArea from "../../Shared/AppTextArea.vue";
import Editor from "../../Shared/Editor.vue";
import AppColor from "../../Shared/AppColor.vue";

export default {
    props: ['logoUrl', 'settings', 'countries'],
    components: {
        AppColor,
        Editor,
        AppTextArea, AppSelect,
        AppInput,
        AppearanceIcon,
        AppUpload,
        SheetIcon,
        SheetTitle,
        Sheet,
        FormButton,
        FormError,
        Layout
    },
    data() {
        return {
            logoDragging: false,
            logoUploading: false,
            currentLogoUrl: this.logoUrl,
            form: this.$inertia.form(this.settings)
        }
    },
    created() {
    },
    methods: {
        submit() {
            this.$inertia.post(this.route('2.appearance.store'), this.form)
        },
        onUploadComplete(response) {
            this.logoUploading = false
            this.currentLogoUrl = response.data.url
            this.form.appearance_logo = response.data.name
        },
        removeLogo() {
            this.currentLogoUrl = null
            this.form.appearance_logo = null
        },
        onUpdateTerms(value) {
            this.form.terms = value
        },
        changeMoreColors() {
            this.form.next = '2.appearance.colors'
            this.submit()
        }
    },
}
</script>
