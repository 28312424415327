<template>
    <span :class="computedClass">
        <slot></slot>
    </span>
</template>

<script>
export default {
    props: {
        class: {
            type: String,
            default: ''
        }
    },
    computed: {
        computedClass() {
            const classes = [this.class, 'p-4', 'sm:px-7', 'sm:py-4'] // Start with the "class" prop

            if (!this.class || !this.class.includes('w-')) {
                classes.push('w-full') // Add "w-full" if the "class" prop doesn't start with "w-"
            }

            return classes.join(' ')
        }
    },
}
</script>