<template>
    <Layout>
        <template v-slot:header>
        
            <Breadcrumbs>
                <Breadcrumb :active="true">{{ $t('Customers') }}</Breadcrumb>
            </Breadcrumbs>
        
        </template>
        
        <template v-slot:default>
            <TableHeader class="hidden lg:flex">
                <TableHeaderColumn class="flex-1">{{ $t('Name') }}</TableHeaderColumn>
                <TableHeaderColumn class="w-44">{{ $t('First seen') }}</TableHeaderColumn>
                <TableHeaderColumn class="w-48">{{ $t('Monthly revenue') }}</TableHeaderColumn>
                <TableHeaderColumn class="w-40 text-right">{{ $t('Total') }}</TableHeaderColumn>
            </TableHeader>
            <TableRow
                :href="route('2.customers.show', { id: customer.id })"
                v-for="customer in customers"
            >
                <TableColumn class="sm:flex-1">
                    <span class="block text-blue-400 font-semibold">{{ customer.name }}</span>
                    <span class="block text-sm text-blue-300">{{ customer.person?.email }}</span>
                </TableColumn>
                <TableColumn class="hidden lg:inline sm:w-auto lg:w-44 text-sm">{{ date(customer.created_at) }}</TableColumn>
                <TableColumn class="sm:w-auto lg:w-48 text-sm pb-0">
                    <span class="sm:hidden mr-1">{{ $t('Monthly revenue') }}</span>
                    <span class="sm:block" v-for="(value, currency) in customer.monthly_revenue">{{ centsToMoney(value, currency) }}/{{ $t('mo.') }}</span>
                </TableColumn>
                <TableColumn class="sm:w-auto lg:w-40 sm:text-right text-sm font-bold text-blue-400 pt-0">
                    <span class="sm:hidden mr-1">{{ $t('Total revenue') }}</span>
                    <span class="sm:block" v-for="(value, currency) in customer.total_revenue">{{ centsToMoney(value, currency) }}</span>
                </TableColumn>
            </TableRow>
        </template>
    </Layout>
</template>

<script>
import Layout from '../../Shared/Layout';
import Breadcrumbs from "../../Shared/Breadcrumbs";
import Breadcrumb from "../../Shared/Breadcrumb";
import TableHeader from "../../Shared/TableHeader";
import TableHeaderColumn from "../../Shared/TableHeaderColumn";
import TableRow from "../../Shared/TableRow";
import TableColumn from "../../Shared/TableColumn";

export default {
    components: {TableColumn, TableRow, TableHeaderColumn, TableHeader, Breadcrumb, Breadcrumbs, Layout},
    props: {
        customers: Object
    },
}
</script>
