<template>
    <AppButton
        v-bind="$attrs"
        class="
            w-full inline-flex justify-center rounded-full
            border border-transparent shadow-sm
            px-6 py-2
            font-medium text-white
            focus:outline-none focus:ring-1 focus:ring-offset-2
            sm:ml-3 sm:w-auto sm:text-sm
            tracking-wide
"
        :class="{
            'pl-8': $slots.icon,
        }"
        :loading="loading"
    >
        <span class="flex items-center">
            <span v-if="$slots.icon" class="-ml-4 inline-block mr-3 -mb-2 -mt-2">
                <slot name="icon" />
            </span>
            <span class="inline-block"><slot /></span>
        </span>
    </AppButton>
</template>

<script>
import AppButton from './AppButton.vue'

export default {
    components: {AppButton},
    inheritAttrs: false,
    props: {
        loading: Boolean,
    },
    data() {
        return {
        }
    },
}
</script>
