<template>
    <template v-if="!editing">
        <div class="flex items-center">
            <div class="flex-1">
                <div>{{ choice.label }}</div>
                <div class="text-sm text-blue-200">{{ choice.description }}</div>
            </div>
            <div>
                <AppMenu>
                    <AppMenuItem @click="edit">{{ $t('Edit') }}</AppMenuItem>
                    <AppMenuItem @click="destroy">{{ $t('Delete') }}</AppMenuItem>
                </AppMenu>
            </div>
        </div>
    </template>
    <template v-else>
        <form @submit.prevent="save">
            <AppInput :label="$t('Label')" v-model="form.label" class="mb-1" />
            <AppInput :label="$t('Description')" v-model="form.description" class="mb-4" />

            <AppButton type="submit" :loading="form.processing">{{ $t('Save') }}</AppButton>
        </form>
    </template>
</template>

<script>
import AppMenu from '../../Shared/AppMenu.vue'
import AppMenuItem from '../../Shared/AppMenuItem.vue'
import AppInput from '../../Shared/AppInput.vue'
import SheetButton from '../../Shared/SheetButton.vue'
import AppButton from '../../Shared/AppButton.vue'
import {useForm} from '@inertiajs/vue3'

export default {
    components: {AppButton, SheetButton, AppInput, AppMenuItem, AppMenu},
    props: ['field', 'choice', 'edit'],
    data: function () {
        return {
            editing: this.edit,
            saving: false,
            form: useForm({
                label: this.choice.label,
                description: this.choice.description,
            }),
        }
    },
    methods: {
        edit() {
            this.editing = true

            this.$emit('edit', this.choice)
        },
        async save() {
            await this.form.post(this.route('2.custom-fields.choices.update', {
                field: this.field.id,
                choice: this.choice.id
            }),{
                preserveScroll: true,
                preserveState: false,
            })
        },
        async destroy() {
            let url = this.route('2.custom-fields.choices.destroy', {
                field: this.field,
                choice: this.choice,
            })

            await this.$inertia.get(url)
        }
    },
    watch: {
        edit() {
            this.editing = this.edit
        }
    }
}
</script>