<template>
    <template v-if="subscription.renews_at">
        {{ $t('Renews at {date} with {method}', { date: date(subscription.renews_at), method: subscription.billing_method.pretty_name }) }}
    </template>
    <template v-else-if="subscription.ends_at && !hasEnded(subscription)">
        {{ $t('Ends at {date}', { date: date(subscription.ends_at) }) }}
    </template>
    <template v-else-if="subscription.ends_at && hasEnded(subscription)">
        {{ $t('Ended at {date}', { date: date(subscription.ends_at) }) }}
    </template>
</template>

<script>
import dayjs from 'dayjs'

export default {
    props: ['subscription'],
    methods: {
        hasEnded(subscription) {
            if (! subscription.ends_at) {
                return false
            }
    
            return dayjs().isAfter(subscription.ends_at)
        }
    }
}
</script>