<template>
    <Layout>

        <Sheet :back="route('2.shipping.index')">
            <SheetIcon><ShippingIcon class="text-white" /></SheetIcon>
            <SheetTitle>{{ $t('New shipping zone') }}</SheetTitle>

            <form @submit.prevent="submit">
                <AppInput :label="$t('Name')" v-model="form.name" class="mb-6"></AppInput>

                <div>
                    <div class="text-sm mb-1 font-medium">{{ $t('Regions') }}</div>
                    <AppCheckboxGroup v-for="(region, index) in regions" :key="index" class="mb-12">
                        <AppCheckbox
                            v-model="form.regions"
                            :value="region.id"
                            :isArray="true"
                        >
                            {{ region.name }}
                        </AppCheckbox>
                    </AppCheckboxGroup>

                    <div class="text-sm mb-1 font-medium">{{ $t('Countries') }}</div>
                    <AppCheckboxGroup v-for="(country, index) in countries" :key="index" class="mb-1">
                        <AppCheckbox
                            v-model="form.countries"
                            :value="country.id"
                            :isArray="true"
                        >
                            {{ country.name }}
                        </AppCheckbox>
                    </AppCheckboxGroup>
                </div>

                <FormError />

                <FormButton :loading="form.processing" type="submit">{{ $t('Save') }}</FormButton>
            </form>
        </Sheet>

    </Layout>
</template>

<script>
import ShippingIcon from "../../../Shared/Icons/ShippingIcon.vue";
import SheetIcon from "../../../Shared/SheetIcon.vue";
import Sheet from "../../../Shared/Sheet.vue";
import Layout from "../../../Shared/Layout.vue";
import SheetTitle from "../../../Shared/SheetTitle.vue";
import FormError from "../../../Shared/FormError.vue";
import FormButton from "../../../Shared/FormButton.vue";
import AppSelect from "../../../Shared/AppSelect.vue";
import AppCheckboxGroup from "../../../Shared/AppCheckboxGroup.vue";
import AppCheckbox from "../../../Shared/AppCheckbox.vue";
import AppInput from "../../../Shared/AppInput.vue";

export default {
    props: ['countries', 'regions'],
    components: {
        AppInput,
        AppCheckbox,
        AppCheckboxGroup,
        AppSelect,
        FormButton,
        FormError,
        SheetTitle,
        Layout,
        Sheet,
        SheetIcon,
        ShippingIcon
    },
    data() {
        return {
            form: this.$inertia.form({
                name: '',
                regions: [],
                countries: [],
            }),
        }
    },
    mounted() {
    },
    methods: {
        submit() {
            this.$inertia.post(this.route('2.shipping.zone.store'), this.form)
        },
    },
}
</script>
