<template>
    <div class="pt-24 mx-4">
        <!-- Intro -->
        <h1 class="text-2xl text-blue-300 font-bold text-center mb-6">
            {{ this.text('Sell anything anywhere on your website', 'Sälj vad som helt var som helst på hemsidan') }}
        </h1>
        <p class="max-w-xl mx-auto text-blue-300">
            {{ this.text(
            'Snowfire Commerce is integrated with Octany, handling payments - from Swish to card, invoice to Autogiro - and managing reporting for all your products. Choose your product below.',
            'Snowfire Commerce är integrerad med Octany som hanterar betalningar (Swish, kort, faktura, Autogiro) och rapportering av alla dina produkter. Välj produkt nedan'
        ) }}
        </p>
        <!-- Product grid -->
        <div class="lg:flex lg:space-x-2 mt-16 max-w-5xl mx-auto">
            <div class="lg:w-1/2 max-w-lg mx-auto mb-8 lg:mb-0">
                <div class="font-medium mb-2">{{ text('One time payments', 'Köp') }}</div>
                <div class="space-y-2">
                    <ProductKindPanel href="" @click.prevent="handleClick('App\\Products\\DigitalDeliveryProduct')">
                        <template v-slot:title>{{ text('Product with digital delivery', 'Produkt med digital leverans') }}</template>
                        <template v-slot:text>{{ text(
                            'Sell e-books, PDFs, sound files etc. The delivery will be sent to your customers as an email.',
                            'Försäljning av e-böcker, PFD:er, ljudfiler etc. där länk skickas via mail eller laddas ned från din hemsida.',
                        ) }}</template>
                        <template v-slot:icon><svg width="47" height="46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.175 22.843c-2.375-2.129-5.969-2.302-8.46-.262l-5.132 4.202c-2.76 2.259-3.123 6.376-.812 9.196 2.31 2.82 6.421 3.274 9.18 1.015l5.132-4.202c2.425-1.985 3-5.405 1.535-8.119l1.4-1.147c2.374 1.97 5.843 2.08 8.267.095l5.132-4.201c2.76-2.26 3.123-6.377.812-9.197-2.31-2.82-6.421-3.274-9.18-1.015l-5.132 4.202c-2.492 2.04-3.03 5.595-1.409 8.342l-1.333 1.091Zm-6.726 1.855-5.131 4.201c-1.616 1.323-1.829 3.733-.476 5.384 1.353 1.65 3.76 1.917 5.374.594l5.132-4.201c1.273-1.042 1.675-2.76 1.127-4.248l-3.635 2.975a1.153 1.153 0 0 1-1.62-.18 1.152 1.152 0 0 1 .144-1.622l3.694-3.024c-1.376-.98-3.266-.979-4.609.121Zm11.713-2.927 2.484-2.033a1.152 1.152 0 0 0 .143-1.623 1.153 1.153 0 0 0-1.62-.18l-2.544 2.084c-.69-1.542-.316-3.393 1.027-4.492l5.132-4.202c1.615-1.322 4.02-1.056 5.374.594 1.352 1.651 1.14 4.061-.476 5.384l-5.131 4.201c-1.273 1.042-3.037 1.098-4.39.267Z" fill="#808CFA"/></svg></template>
                    </ProductKindPanel>
                    <ProductKindPanel href="" @click.prevent="comingSoon($t('Donations are coming soon. If you want to accept donations right now, get in touch with the support team and we will help you.'))" class="opacity-30">
                        <template v-slot:title>{{ text('Donations / Campaigns', 'Donation / kampanjer') }}</template>
                        <template v-slot:text>{{ text(
                            'Allow your donors the chance to give a one-time monetary gift. They can choose from three set amounts or provide a sum of their own choosing.',
                            'Ger dina givare möjlighet att ge en summa pengar en gång(engångsgåva). De kan välja mellan tre fasta belopp eller ge en egen summa.'
                        ) }}</template>
                        <template v-slot:icon><svg width="47" height="46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 14.333C22.309 11.254 18.972 9 15.5 9c-4.52 0-8 3.435-8 8 0 6.274 6.743 11.125 16 20.444 9.257-9.319 16-14.17 16-20.444 0-4.565-3.48-8-8-8-3.476 0-6.809 2.254-8 5.333Z" fill="#808CFA"/></svg></template>
                    </ProductKindPanel>
                    <!--                        <ProductKindPanel href="" @click.prevent="">-->
                    <!--                            <template v-slot:title>{{ text('Ticket', 'Biljett') }}</template>-->
                    <!--                            <template v-slot:text>{{ text(-->
                    <!--                                'Provide customers the opportunity to purchase tickets to a variety of events. Whether it\'s a physical conference or a digital seminar, your customers have the flexibility to choose from multiple ticket types and avail of discounts.',-->
                    <!--                                'Sälj biljetter till event (både fysiska och digitala) där man kan välja flera biljettyper, rabatter m.m.',-->
                    <!--                            ) }}</template>-->
                    <!--                            <template v-slot:icon><svg width="47" height="46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 13.417a1 1 0 0 0-1 1v4.59c0 .47.487.799.958.799 1.588 0 2.875 1.43 2.875 3.194s-1.287 3.195-2.875 3.195c-.471 0-.958.328-.958.799v4.59a1 1 0 0 0 1 1h32.5a1 1 0 0 0 1-1v-4.59c0-.471-.487-.8-.958-.8-1.588 0-2.875-1.43-2.875-3.194s1.287-3.194 2.875-3.194c.471 0 .958-.329.958-.8v-4.59a1 1 0 0 0-1-1H7.25Zm22.958 0a.958.958 0 1 1 0 1.916.958.958 0 0 1 0-1.916Zm0 5.75a.958.958 0 1 0 0-1.917.958.958 0 0 0 0 1.917Zm0 3.833a.958.958 0 1 0 0-1.917.958.958 0 0 0 0 1.917Zm.959 2.875a.958.958 0 1 1-1.917 0 .958.958 0 0 1 1.917 0Zm-.959 4.792a.958.958 0 1 0 0-1.917.958.958 0 0 0 0 1.917Z" fill="#808CFA"/></svg></template>-->
                    <!--                        </ProductKindPanel>-->
                    <ProductKindPanel href="" @click.prevent="handleClick('App\\Products\\DigitalDeliveryProduct')">
                        <template v-slot:title>{{ text('Course', 'Kurs') }}</template>
                        <template v-slot:text>{{ text(
                            'Offer courses complete with individual course sections for a personalized and focused learning experience.',
                            'Försäljning av kurs med kursavsnitt med t.ex. video/ljud/länkar'
                        ) }}</template>
                        <template v-slot:icon><svg width="47" height="46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 7.667 4.333 16.332l1.518.762v8.327h1v-7.825L23.5 25.96l19.167-9.628L23.5 7.667ZM12 24.022l11.5 6.335L35 24.022v8.284c-4.128 4.549-14.508 10.916-23 0v-8.284Z" fill="#808CFA"/><path d="m4.333 16.331-.206-.455-.955.432.937.47.224-.447ZM23.5 7.667l.206-.456-.206-.093-.206.093.206.456ZM5.85 17.094h.5v-.309l-.275-.138-.224.447Zm0 8.327h-.5v.5h.5v-.5Zm1 0v.5h.5v-.5h-.5Zm0-7.825.225-.447-.724-.364v.811h.5ZM23.5 25.96l-.224.447.224.113.224-.113-.224-.447Zm19.167-9.628.224.447.937-.47-.955-.432-.206.455ZM23.5 30.357l-.241.438.241.133.241-.133-.241-.438ZM12 24.022l.241-.438-.741-.408v.846h.5Zm23 0h.5v-.846l-.741.408.241.438Zm0 8.284.37.336.13-.143v-.193H35Zm-23 0h-.5v.172l.105.135.395-.307ZM4.54 16.787l19.166-8.665-.412-.91-19.167 8.664.412.911Zm1.535-.14-1.517-.762-.45.893 1.518.763.45-.894Zm.276 8.774v-8.327h-1v8.327h1Zm.5-.5h-1v1h1v-1Zm-.5-7.325v7.825h1v-7.825h-1Zm17.373 7.916L7.075 17.15l-.449.894 16.65 8.363.448-.894Zm18.718-9.627-19.166 9.627.448.894 19.167-9.628-.449-.893ZM23.294 8.122l19.167 8.665.412-.911L23.706 7.21l-.412.911Zm.447 21.797-11.5-6.335-.482.876 11.5 6.335.482-.876Zm11.018-6.335-11.5 6.335.482.876 11.5-6.335-.482-.876Zm.741 8.722v-8.284h-1v8.284h1Zm-23.895.307c2.17 2.788 4.48 4.499 6.81 5.404 2.33.905 4.65.992 6.815.58 4.313-.823 8.03-3.63 10.14-5.955l-.74-.672c-2.018 2.224-5.555 4.875-9.587 5.644-2.007.383-4.133.3-6.266-.53-2.134-.828-4.305-2.414-6.382-5.085l-.79.614Zm-.105-8.59v8.283h1v-8.284h-1Z" fill="#808CFA"/></svg></template>
                    </ProductKindPanel>
                </div>
            </div>
            
            <div class="lg:w-1/2 max-w-lg mx-auto">
                <div class="font-medium mb-2">{{ text('Recurring payments', 'Återkommande betalningar') }}</div>
                <div class="space-y-2">
                    <ProductKindPanel href="" @click.prevent="handleClick('App\\Products\\SubscriptionProduct')">
                        <template v-slot:title>{{ text('Subscription', 'Prenumeration') }}</template>
                        <template v-slot:text>{{ text(
                            'Secure steady, predictable revenue by selling a service or product that your customers love so much, they subscribe for regular delivery. This is not just a sale - it\'s a long-term relationship with your customer.',
                            'Vid försäljning av en tjänst som dina kunder prenumererar på eller en produkt som skall skickas återkommande.'
                        ) }}</template>
                        <template v-slot:icon><svg width="47" height="46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m25.896 15.563 7.187-5.865-7.187-5.865v4.319a19.665 19.665 0 0 0-2.396-.146c-9.527 0-17.25 6.822-17.25 15.237 0 2.697.793 5.23 2.185 7.428l2.85-3.116c-.672-1.36-1.035-2.827-1.035-4.312 0-5.754 5.451-11.237 13.25-11.237.653 0 1.29.038 1.909.112l.487-.532v3.977ZM20.759 30.437v3.809c.875.153 1.79.235 2.741.235 7.799 0 13.25-5.483 13.25-11.238 0-1.811-.54-3.595-1.523-5.19l2.787-3.048c1.732 2.376 2.736 5.203 2.736 8.238 0 8.416-7.723 15.238-17.25 15.238-.933 0-1.849-.066-2.741-.192v3.878l-7.188-5.865 7.188-5.865Z" fill="#808CFA"/></svg></template>
                    </ProductKindPanel>
                    <ProductKindPanel href="" @click.prevent="handleClick('App\\Products\\SubscriptionProduct')">
                        <template v-slot:title>{{ text('Membership', 'Medlemskap') }}</template>
                        <template v-slot:text>{{ text(
                            'Ideal for running an association or community with memberships paid on an annual, semi-annual, or monthly basis. You have the freedom to choose the start date that suits you best.',
                            'Driver du en förening eller community och vill sälja medlemskap som betalas årsvis, halvårsvis eller per månad. Fritt val av startdatum.',
                        ) }}</template>
                        <template v-slot:icon><svg width="47" height="46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M25.99 7.667h-4.73v7.218h4.73V7.667ZM20.512 11.5H6.25v26.833h34.5V11.5H26.736v3.385h3.235v2.987H17.277v-2.987h3.236V11.5Zm3.111 8.115 1.873 5.762h6.058l-4.901 3.56 1.872 5.763-4.901-3.561-4.902 3.56 1.872-5.761-4.901-3.561h6.058l1.873-5.762Z" fill="#808CFA"/></svg></template>
                    </ProductKindPanel>
                    <ProductKindPanel href="" @click.prevent="comingSoon($t('Donations are coming soon. If you want to accept donations right now, get in touch with the support team and we will help you.'))" class="opacity-30">
                        <template v-slot:title>{{ text('Monthly donor', 'Månadsgivare') }}</template>
                        <template v-slot:text>{{ text(
                            'Perfect if you\'re running a fundraising organization or association, enabling donors to make recurring contributions, thus ensuring a stable cash flow.',
                            'För dig som driver en insamlingsorganisation eller förening med återkommande bidrag.'
                        ) }}</template>
                        <template v-slot:icon><svg width="47" height="46" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 14.333C22.309 11.254 18.972 9 15.5 9c-4.52 0-8 3.435-8 8 0 6.274 6.743 11.125 16 20.444 9.257-9.319 16-14.17 16-20.444 0-4.565-3.48-8-8-8-3.476 0-6.809 2.254-8 5.333Z" fill="#808CFA"/></svg></template>
                    </ProductKindPanel>
                </div>
            </div>
        </div>
        
        <div class="lg:flex lg:space-x-2 mt-10 max-w-5xl mx-auto">
            <div class="lg:w-1/2 max-w-lg mx-auto lg:mx-0">
                <div class="font-medium mb-2">{{ text('Physical products', 'Fysiska produkter') }}</div>
                <div class="space-y-2">
                    <ProductKindPanel href="" @click.prevent="handleClick('App\\Products\\PhysicalDeliveryProduct')">
                        <template v-slot:title>{{ text('Product with physical delivery', 'Produkt med fysisk leverans') }}</template>
                        <template v-slot:text>{{ text(
                            'Sell books, items, and other products requiring a delivery address, ensuring a seamless customer experience from order to delivery.',
                            'Försäljning av böcker, saker och andra produkter där leveransadress behövs.'
                        ) }}</template>
                        <template v-slot:icon><svg width="47" height="46" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M23.287 5.75 38.3 12.139 23.606 21.13 8.805 12.139 23.287 5.75ZM8.167 13.44l14.694 9.228V41.36L8.167 31.896V13.439ZM24.458 22.668l14.375-9.229v18.457L24.458 41.36V22.668Z" fill="#808CFA"/></svg></template>
                    </ProductKindPanel>
                </div>
            </div>
        </div>
    
    </div>
</template>
<script>
import ProductKindPanel from "./ProductKindPanel.vue";

export default {
    props: ['locale'],
    components: {ProductKindPanel},
    methods: {
        handleClick(type) {
            this.$emit('select-product', type)
        },
        comingSoon(message = null) {
            if (message) {
                return alert(message)
            }
            
            alert(this.$t('Coming soon'))
        },
        text(en, sv) {
            return this.locale === 'sv' ? sv : en
        },
    }
}
</script>