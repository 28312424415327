<template>
    <div class="text-blue-400 font-medium">{{ title }}</div>
    <div>{{ address.line1 }}</div>
    <div>{{ address.line2 }}</div>
    <div>{{ address.zip }} {{ address.city }}</div>
    <div>{{ address.countryName }}</div>
</template>
<script>
export default {
    props: ['address', 'title'],
}
</script>