<template>
    <Layout>

        <Sheet size="">
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>
                {{ $t('New order for {customer}', { customer: subscription.customer.name }) }}
                <template #description>{{ subscription.product.pretty_name }}, {{ billingMethod.pretty_name }}</template>
            </SheetTitle>

            <form @submit.prevent="submit">
                <template v-for="row in form.rows">
                    <div class="flex space-x-1 items-center relative mb-1">
                        <div class="w-1/2 flex space-x-1">
                            <div class="w-2/5">
                                <AppSelect v-model="row.product" :label="$t('Product')" @change="changeProduct(row)">
                                    <option v-for="product in products" :value="product.id">{{ product.pretty_name }}</option>
                                </AppSelect>
                            </div>

                            <div class="w-3/5">
                                <AppInput :label="$t('Description')" v-model="row.description" @keydown="row.dirty = true"></AppInput>
                            </div>
                        </div>

                        <div class="w-1/2 flex space-x-1">
                            <div class="relative w-1/3">
                                <AppInput :label="$t('Quantity')" v-model="row.quantity" @keydown="row.dirty = true"></AppInput>
                            </div>
                            <div class="relative w-1/3">
                                <AppInput :label="$t('Amount')" v-model="row.amount" @keydown="row.dirty = true"></AppInput>
                                <div class="absolute right-0 top-0 mt-7 mr-4">{{ currency(row) }}</div>
                            </div>
                            <div class="relative w-1/3">
                                <AppInput :label="$t('VAT')" v-model="row.vat" @keydown="row.dirty = true"></AppInput>
                                <div class="absolute right-0 top-0 mt-7 mr-4">%</div>
                            </div>
                        </div>

                        <div class="absolute right-0" style="margin-right: -35px">
                            <a href="" @click.prevent="removeRow(row)" class="text-lilac-300">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                     class="w-4 h-4">
                                    <path fill-rule="evenodd"
                                          d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
                                          clip-rule="evenodd"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </template>

                <div class="text-center mt-4">
                    <button @click.prevent="addRow" class="underline">{{ $t('Add item') }}</button>
                </div>

                <FormError />

                <FormButton :loading="form.processing" type="submit" size="xl">
                    <template v-if="billingMethod.type === 'stripe'">{{ $t('Charge card') }}</template>
                    <template v-else>{{ $t('Create order') }}</template>
                </FormButton>
            </form>
        </Sheet>

    </Layout>
</template>

<script>

import Sheet from "../../Shared/Sheet.vue";
import Layout from "../../Shared/Layout.vue";
import SheetIcon from "../../Shared/SheetIcon.vue";
import SheetTitle from "../../Shared/SheetTitle.vue";
import AppSelect from "../../Shared/AppSelect.vue";
import FormError from "../../Shared/FormError.vue";
import FormButton from "../../Shared/FormButton.vue";
import CartIcon from "../../Shared/Icons/CartIcon.vue";
import AppInput from "../../Shared/AppInput.vue";
import {useForm} from '@inertiajs/vue3'

export default {
    props: ['products', 'billingMethod', 'subscription', 'rows', 'statements'],
    components: {
        AppInput,
        CartIcon,
        FormButton,
        FormError,
        AppSelect,
        SheetTitle,
        SheetIcon,
        Layout,
        Sheet
    },
    data() {
        return {
            form: useForm({
                subscription: this.subscription.id,
                billing_method: this.billingMethod.id,
                rows: this.rows,
            })
        }
    },
    created() {
        if (this.form.rows.length === 0) {
            this.addRow()
        }
    },
    methods: {
        addRow() {
            this.form.rows.push({
                product: this.products[0].id,
                amount: this.products[0].price / 100,
                quantity: 1,
                vat: this.products[0].vat_rate,
                description: this.products[0].name,
                dirty: false,
            })
        },
        removeRow(row) {
            this.form.rows.splice(this.form.rows.indexOf(row), 1)
        },
        submit() {
            let params = {}
            
            if (this.statements) {
                params.statements = this.statements
            }

            this.form.post(this.route('2.orders.store', params))
        },
        currency(row) {
            const product = this.products?.find(product => product.id === row.product)

            return product?.currency?.toUpperCase() ?? ''
        },
        changeProduct(row) {
            let product = this.products.find(product => product.id === row.product)
            
            if (row.dirty) {
                return
            }
            
            row.amount = product.price / 100
            row.vat = product.vat_rate
            row.description = product.name
            row.quantity = 1
        }
    },
}
</script>
