<template>
    <Layout>
        <Sheet>
            <SheetIcon><SubscriptionIcon /></SheetIcon>
            <SheetTitle>{{ $t('Change product') }}</SheetTitle>
            <SubscriptionInfo :subscription="subscription" :icon="false" />

            <form @submit.prevent="form.post(this.route('2.subscriptions.product.update', { subscription: subscription }))">

                <p v-if="subscription.billing_method.type === 'stripe'">{{ $t("The subscription will be changed to the new product immediately and the price difference for the new product price will be charged today. Switching to a product with less cost will add a discount at the next renewal on {date}", { date: date(subscription.renews_at) }) }}</p>
                <p v-else>{{ $t("The subscription will be changed to the new product immediately and the price difference for the new product price will be charged today.", { date: date(subscription.renews_at) }) }}</p>

                <div class="mt-4">
                    <AppSelect :label="$t('Choose new product')" v-model="form.product">
                        <option v-for="product in products" :value="product.id">
                            {{ product.pretty_name }}
                        </option>
                    </AppSelect>
                </div>

                <FormButton :loading="form.processing">{{ $t('Save') }}</FormButton>
            </form>

        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout.vue";
import Sheet from "../../../Shared/Sheet.vue";
import SheetIcon from "../../../Shared/SheetIcon.vue";
import SubscriptionIcon from "../../../Shared/Activities/SubscriptionIcon.vue";
import SheetTitle from "../../../Shared/SheetTitle.vue";
import SubscriptionInfo from "../../../Shared/SubscriptionInfo.vue";
import AppInput from "../../../Shared/AppInput.vue";
import FormButton from "../../../Shared/FormButton.vue";
import AppSelect from "../../../Shared/AppSelect.vue";

export default {
    props: ['subscription', 'products'],

    components: {
        AppSelect,
        FormButton,
        AppInput,
        SubscriptionInfo,
        SubscriptionIcon,
        SheetTitle,
        SheetIcon,
        Sheet,
        Layout
    },

    data() {
        return {
            form: this.$inertia.form({
                product: this.subscription.product.id
            })
        }
    },
}
</script>