<template>
    <div class="max-w-xl mx-auto shadow-lg -mx-16 -mb-8 mt-16">
        <div class="flex text-lilac-200">
            <InertiaLink
                :href="secondaryHref"
                :class="secondaryClasses"
            ><slot name="secondary" /></InertiaLink>
            <InertiaLink
                :href="primaryHref"
                :class="primaryClasses"
            ><slot name="primary" /></InertiaLink>
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        primaryHref: { default: '' },
        secondaryHref: { default: '' },
        external: { default: false }
    },
    data() {
        return {
            'classes': {},
            'primaryClasses': {},
            'secondaryClasses': {},
        }
    },
    created() {
        this.classes['block w-full px-5 py-5 text-center w-1/2'] = true;
        this.classes['px-5 py-5 text-lg font-semibold'] = true;
        this.classes['transition duration-300'] = true;
        this.classes['hover:bg-opacity-90'] = true;
        
        this.primaryClasses = {
            ...this.classes,
            ...{
                'bg-lilac-300': true,
                'text-white': true,
                'rounded-br': true,
            }
        }
    
        this.secondaryClasses = {
            ...this.classes,
            ...{
                'bg-lilac-100': true,
                'text-lilac-300': true,
                'rounded-bl': true,
            }
        }
    }
}
</script>