<template>
    <Layout>
        
        <Breadcrumbs v-if="subscription" class="mb-1">
            <Breadcrumb :href="route('2.subscriptions.index')">{{ $t('Subscriptions') }}</Breadcrumb>
            <Breadcrumb :href="route('2.subscriptions.show', { subscription })">{{ subscription.product.name }}</Breadcrumb>
            <Breadcrumb :active="true">{{ $t('Order') }}</Breadcrumb>
        </Breadcrumbs>
        
        <div class="flex flex-wrap md:flex-row flex-col-reverse">
            
            <!-- Order -->
            <div class="md:w-2/3">

                <div class="bg-white shadow rounded p-4 sm:py-7 sm:px-7 md:mr-1.5">
                    <div class="flex justify-between">
                        <div class="flex-1">
                            <OrderStatus :order="order" :margin="false"></OrderStatus>
                            
                            <div class="mb-12">
                                <div class="text-xl text-blue-400 font-semibold tracking-wider">{{ $t('Order {number}', { number: order.uniq_id }) }}</div>
                                <div class="text-blue-300 font-medium tracking-wider"><Reference :order="order"></Reference></div>
                                <div class="text-blue-300">{{ date(order.created_at) }}</div>
                            </div>
                            
                            <div>
                                <div class="flex items-center justify-between">
                                    <div class="flex items-center">
                                        <MoneyIcon class="text-lilac-300 mr-2" />
                                        <div><OrderBillingMethod :order="order"></OrderBillingMethod></div>
                                    </div>
                                    <div v-if="order.billing_method.type === 'manual' && order.state === 'payment_pending_user'" class="-mr-12">
                                        <AppButtonSmall @click="$refs.markAsPaidModal.isOpen = true">
                                            <template #icon><CheckIcon  /></template>
                                            {{ $t('Mark as paid') }}
                                        </AppButtonSmall>

                                        <AppModalAlert ref="markAsPaidModal" @confirm="confirmMarkAsPaid" type="confirm">
                                            <template v-slot:title>{{ $t('Payment') }}</template>
                                            {{ $t('This order will now be marked as paid. Please make sure you have received the payment since we will not capture manual payments.') }}
                                            <template v-slot:confirm>{{ $t('Mark as paid') }}</template>
                                        </AppModalAlert>
                                    </div>
                                </div>
                                <div v-if="subscription" class="flex items-center mt-2">
                                    <SubscriptionIcon class="text-lilac-300 mr-2" />
                                    <InertiaLink class="hover:underline" :href="route(`2.subscriptions.show`, { subscription: subscription })">{{ $t('Renewal order by {product}', { product: subscription.product.name }) }}</InertiaLink>
                                </div>
                            </div>
                        </div>
                        <div>
                            <AppMenu>
                                <AppMenuItem v-if="(order.state === 'payment_failed' || processable === 'created') && order.billing_method.type === 'fortnox'" :href="route('2.orders.process', { order: order, retry: true })">{{ $t('Create invoice') }}</AppMenuItem>
                                <AppMenuItem v-if="order.state === 'payment_failed' && order.billing_method.type === 'billecta'" :href="route('2.orders.process', { order: order, retry: true })">{{ $t('Retry payment') }}</AppMenuItem>
                                <AppMenuItem v-if="order.state === 'paid' || (order.billing_method.type === 'fortnox' && order.state === 'payment_pending')" :href="route('2.orders.confirmation.show', { order })" target="_blank" :external="true">
                                    <template v-if="order.billing_method.type === 'fortnox'">{{ $t('Show invoice') }}</template>
                                    <template v-else>{{ $t('Show receipt') }}</template>
                                </AppMenuItem>
                                <AppMenuItem v-if="order.state !== 'payment_failed'" :href="route('2.orders.confirmation.email', { order })">{{ $t('Resend confirmation email') }}</AppMenuItem>
                                <AppMenuItem v-if="order.state === 'paid'" href="#">{{ $t('External refund') }}</AppMenuItem>
                                <AppMenuItem v-if="order.state === 'created'" :href="route('2.orders.delete', { order: order })">{{ $t('Delete order') }}</AppMenuItem>
                            </AppMenu>
                        </div>
                    </div>
                </div>
    
                <div class="mt-1 md:mr-1.5 bg-white shadow rounded p-4 sm:py-7 sm:px-7">
                    <div class="flex mb-5" v-for="item in order.items">
                        <div class="w-12 font-bold text-blue-300 text-center">{{ item.quantity }}</div>
                        <div class="flex-1 text-blue-300">
                            {{ item.description }}
                            <div class="text-xs" v-if="item.period_starts_at">
                                {{ date(item.period_starts_at, false) }} &ndash; {{ date(item.period_ends_at, false) }}
                            </div>
                        </div>
                        <div class="sm:w-48 text-blue-300 font-medium text-right">{{ centsToMoney(item.price, order.currency, 2) }}</div>
                    </div>
                    
                    <div class="flex justify-end mt-12 text-sm font-medium">
                        <div>
                            <div class="flex mt-4">
                                <div class="w-32 text-right">{{ $t('Subtotal') }}</div>
                                <div class="w-24 text-right">{{ centsToMoney(order.total, order.currency, 2) }}</div>
                            </div>
                            <div v-for="(amount, percent) in vat" class="flex mt-1">
                                <div class="w-32 text-right">{{ $t('VAT {percent}%', { percent: percent/100 }) }}</div>
                                <div class="w-24 text-right">{{ centsToMoney(amount, order.currency, 2) }}</div>
                            </div>
    
                            <div class="w-16 border border-b-2 border-lilac-100 mt-4 ml-auto"></div>
    
                            <div class="flex mt-4 text-blue-400">
                                <div class="w-32 text-right text-base">{{ $t('Total') }}</div>
                                <div class="w-24 text-right text-base font-semibold">{{ centsToMoney(order.total_with_vat, order.currency, 2) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Activities :activities="activities.data" class="mt-12"></Activities>
                <Pagination :links="activities.links"></Pagination>
            
            </div>
            
            <!-- Customer -->
            <div class="md:w-1/3">
                <CustomerCard
                    class="md:ml-1.5 mb-3"
                    :billing-method="order.billing_method"
                    :custom-fields="order.custom_fields"
                    :billing-address="billingAddress"
                    :shipping-address="shippingAddress"
                    :attachment="{ name: 'order', id: order.id }"
                >
                    <template v-if="order.require_shipping" v-slot:shipping>
                        <div v-if="order.shipped_at" class="absolute top-0 right-0 lg:p-7">
                            <AppMenu>
                                <AppMenuItem method="delete" :href="route('2.orders.fulfill.delete', { order: order })">{{ $t('Unfulfill') }}</AppMenuItem>
                            </AppMenu>
                        </div>

                        <CustomerCardAddress :address="shippingAddress ? shippingAddress : billingAddress" :title="$t('Shipping address')"></CustomerCardAddress>

                        <template v-if="order.shipped_at">
                            <div class="w-8 border border-b-2 border-lilac-100 mt-8 mb-8"></div>
                            <div>
                                {{ $t('Order shipped {date}', {date: lowercase(date(order.shipped_at))}) }}
                            </div>
                        </template>
                        <template v-else>
                            <AppButtonSmall @click="$refs.fulfillmentModal.isOpen = true" class="mt-6">
                                <template #icon><SendIcon /></template>
                                {{ $t('Fulfill order') }}
                            </AppButtonSmall>

                            <AppModalAlert ref="fulfillmentModal" @confirm="confirmFulfillment" type="confirm">
                                <template v-slot:title>{{ $t('Order fulfillment') }}</template>
                                {{ $t('This order will now be marked as fulfill and shipped') }}
                                <template v-slot:confirm>{{ $t('Fulfill order') }}</template>
                            </AppModalAlert>
                        </template>

                    </template>
                </CustomerCard>
            </div>
        </div>
    
    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout";
import AppMenuItem from "../../Shared/AppMenuItem";
import AppMenu from "../../Shared/AppMenu";
import Reference from "../../Shared/Reference";
import Activities from "../../Shared/Activities";
import CustomerCard from "../../Shared/CustomerCard";
import OrderStatus from "../../Shared/OrderStatus";
import OrderBillingMethod from "../../Shared/OrderBillingMethod";
import SubscriptionIcon from "../../Shared/Activities/SubscriptionIcon";
import MoneyIcon from "../../Shared/Icons/MoneyIcon";
import TableRow from "../../Shared/TableRow";
import TableColumn from "../../Shared/TableColumn";
import Pagination from "../../Shared/Pagination";
import Breadcrumbs from "../../Shared/Breadcrumbs.vue";
import Breadcrumb from "../../Shared/Breadcrumb.vue";
import CustomerCardAddress from '../../Shared/CustomerCardAddress.vue'
import AppButton from '../../Shared/AppButton.vue'
import AppModalAlert from '../../Shared/AppModalAlert.vue'
import AppMenuLabel from '../../Shared/AppMenuLabel.vue'
import AppButtonSmall from '../../Shared/AppButtonSmall.vue'
import CheckIcon from '../../Shared/Icons/CheckIcon.vue'
import SendIcon from '../../Shared/Icons/SendIcon.vue'

export default {
    components: {
        SendIcon,
        CheckIcon,
        AppButtonSmall,
        AppMenuLabel,
        AppModalAlert,
        AppButton,
        CustomerCardAddress,
        Breadcrumb,
        Breadcrumbs,
        Pagination,
        TableColumn,
        TableRow,
        MoneyIcon,
        SubscriptionIcon,
        OrderBillingMethod,
        OrderStatus,
        CustomerCard,
        Activities,
        Reference,
        AppMenu, AppMenuItem, Layout},
    props: ['order', 'activities', 'vat', 'subscription', 'billingAddress', 'shippingAddress', 'staff', 'processable'],
    methods: {
        confirmFulfillment() {
            this.$inertia.post(this.route('2.orders.fulfill.store', { order: this.order.id }))

            this.$refs.fulfillmentModal.isOpen = false
        },
        confirmMarkAsPaid() {
            this.$inertia.post(this.route('2.orders.pay.store', { order: this.order.id }))

            this.$refs.markAsPaidModal.isOpen = false
        }
    }
}
</script>
