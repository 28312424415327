<template>
    <div>
        <template v-if="$page.props.toaster && $page.props.toaster">
            <div class="pt-24">
                <div class="
                    bg-blue-100 rounded border border-lilac-200
                    px-16 pt-5 pb-4
                    max-w-lg mx-auto
                    whitespace-pre-wrap
                    leading-6
                ">
                    {{ $page.props.toaster }}
                </div>
            </div>
        </template>
    </div>
</template>
