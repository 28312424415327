<template>
    <span>
        <span v-if="subscription?.reference_id">{{ subscription?.reference_id }}</span>
        <span v-if="subscription?.reference_id && subscription?.reference_name">&nbsp;&mdash;&nbsp;</span>
        <span v-if="subscription?.reference_name">{{ subscription?.reference_name }}</span>
        
        <span v-if="order?.reference_id">{{ order?.reference_id }}</span>
        <span v-if="order?.reference_id && order?.reference_name">&nbsp;&mdash;&nbsp;</span>
        <span v-if="order?.reference_name">{{ order?.reference_name }}</span>
    </span>
</template>

<script>
export default {
    props: {
        subscription: {},
        order: {},
    }
}
</script>