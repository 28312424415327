<template>
    <Layout>
        <Sheet>
            <SheetIcon><BillectaIcon /></SheetIcon>
            <SheetTitle>Billecta</SheetTitle>
    
            {{ $t('To activate Autogiro we need your Autogiro customer number from your bank. Please send the following PDF to your bank to get your number:') }}
            <br><br>
            <strong>PDF</strong><br>
            <a href="/docs/billecta-bgc-instructions.pdf" target="_blank" class="underline">{{ $t('bgc-instructions.pdf') }}</a>
            
            <form @submit.prevent="form.post(this.route('2.payments.billecta.activate.autogiro'))" class="mt-8">
                <AppInput :label="$t('Autogiro customer number')" v-model="form.autogiro_number" :error="$attrs.errors.autogiro_number"></AppInput>
    
                <FormError />
    
                <FormButton :loading="form.processing" type="submit">{{ $t('Continue') }}</FormButton>
            </form>
        </Sheet>
        
    </Layout>
</template>

<script>
import Layout from "../../../../Shared/Layout.vue";
import Sheet from "../../../../Shared/Sheet.vue";
import SheetIcon from "../../../../Shared/SheetIcon.vue";
import CartIcon from "../../../../Shared/Icons/CartIcon.vue";
import SheetTitle from "../../../../Shared/SheetTitle.vue";
import SheetButton from "../../../../Shared/SheetButton.vue";
import {useForm} from "@inertiajs/vue3";
import AppInput from "../../../../Shared/AppInput.vue";
import FormButton from "../../../../Shared/FormButton";
import FormError from "../../../../Shared/FormError";
import BillectaIcon from "../../../../Shared/Icons/BillectaIcon";

export default {
    props: ['autogiro_number'],
    components: {
        BillectaIcon,
        FormError,
        FormButton,
        AppInput, SheetButton, SheetTitle, CartIcon, SheetIcon, Sheet, Layout},
    data() {
        return {
            form: useForm({
                autogiro_number: this.autogiro_number,
            })
        }
    },
}
</script>