<template>
    <div
        @dragenter.prevent="toggleActive()"
        @dragleave.prevent="toggleActive()"
        @dragover.prevent
        @drop.prevent="drop"
        :class="{ 'bg-blue-100 border-blue-300': dragActive }"
        class="flex items-center justify-center w-full px-12 py-8 border-2 border-lilac-300 border-dashed rounded bg-white"
    >
        <div class="flex flex-col items-center justify-center gap-2">
            <span>
                <svg :class="{ 'animate-bounce': dragActive || loading }" class="inline-block" width="42" height="42" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#a)"><path fill-rule="evenodd" clip-rule="evenodd" d="M25.829 11.935c1.762.903 2.802 2.136 2.95 3.474l.124 1.118 1.053-.395c1.83-.687 3.36-.215 4.111.564.373.388.565.851.558 1.336-.007.484-.213 1.08-.804 1.716l-.587.632.623.596c1.74 1.664 2.456 3.136 2.57 4.351.112 1.192-.34 2.292-1.279 3.27-1.924 2.01-5.755 3.34-9.773 3.34v1.75c4.294 0 8.666-1.403 11.037-3.879 1.21-1.262 1.927-2.843 1.758-4.645-.15-1.595-.983-3.237-2.539-4.884.484-.712.733-1.468.744-2.223.014-.992-.388-1.89-1.048-2.574-1.138-1.18-3.01-1.723-5.03-1.274-.555-1.669-1.973-2.96-3.67-3.83-2.043-1.048-4.648-1.578-7.21-1.463-2.562.115-5.165.881-7.146 2.525-1.811 1.502-3.04 3.69-3.265 6.61-1.62-.035-2.99.748-3.92 1.955-1.099 1.428-1.59 3.444-1.293 5.47.3 2.044 1.402 4.12 3.514 5.674 2.106 1.549 5.16 2.538 9.318 2.538v-1.75c-3.886 0-6.549-.924-8.282-2.198-1.727-1.27-2.586-2.926-2.819-4.518-.235-1.611.171-3.137.95-4.149.757-.985 1.855-1.486 3.177-1.187l1.068.24v-1.094c0-2.927 1.085-4.93 2.67-6.244 1.61-1.337 3.813-2.02 6.106-2.124 2.293-.103 4.59.378 6.334 1.272Zm-4.83 8.627 5.25 5.25h-3.937v7.875h-2.625v-7.874H15.75l5.25-5.25Z" fill="#808CFA"/></g><defs><clipPath id="a"><path fill="#fff" transform="translate(3.5 8.75)" d="M0 0h35v24.938H0z"/></clipPath></defs></svg>
            </span>
            <label v-if="!loading" for="file" class="p-2 text-sm md:text-sm cursor-pointer bg-lilac-100 shadow rounded px-4 py-2">
                <span class="">{{ $t('Select file ') }}</span>
                <input type="file" @change="selectedFile" name="file" id="file" class="hidden" />
            </label>
            <AppSpinner v-if="loading" class="pt-4"></AppSpinner>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import AppSpinner from "./AppSpinner.vue";

export default {
    components: {AppSpinner},
    data() {
        return {
            dragActive: false,
            droppedFile: null,
            loading: false,
        }
    },
    methods: {
        toggleActive() {
            this.dragActive = !this.dragActive
        },
        drop(e) {
            e.preventDefault()
            this.droppedFile = e.dataTransfer.files[0]
            this.toggleActive()
    
            this.upload()
        },
        selectedFile(e) {
            this.droppedFile = e.target.files[0]
            this.toggleActive()
            
            this.upload()
        },
        async upload() {
            const formData = new FormData()
            formData.append('file', this.droppedFile)
    
            let url = this.route('2.settings.checkout.store-logo')
            this.loading = true
    
            let result = await axios.post(url, formData, {
                'Content-Type': 'multipart/form-data',
            })
    
            this.loading = false
    
            this.$emit('upload', {
                url: result.data.data.url,
                path: result.data.data.path,
            })
        }
    }
}
</script>