<template>
    <HqLayout size="lg">

        <AppModal :open="showModal" @confirm="showModal = false" @cancel="showModal = false" size="md">
            <pre class="mb-4">ID: {{ selectedCheckout.id }}</pre>
            <pre>{{ JSON.stringify(selectedCheckout.data, null, 2) }}</pre>
            <template #cancel></template>
        </AppModal>

        <div class="bg-white shadow mb-1 rounded flex space-x-4 p-4">
            <!-- Account -->
            <select v-model="filters.account" @change="applyFilters" class="px-4 py-2 max-w-[200px]">
                <option :value="null">{{ $t('Account') }}</option>
                <option v-for="account in accounts" :key="account.id" :value="account.id">{{ account.name }}</option>
            </select>

            <!-- Tid -->
            <AppDatePicker @change="setDateFilter" v-model="dateFilter" />

            <!-- Status -->
            <select v-model="filters.status" @change="applyFilters" class="px-4 py-2">
                <option :value="null">{{ $t('Status') }}</option>
                <option v-for="(value, key) in statuses" :value="key">{{ value }}</option>
            </select>

            <!-- Provider -->
            <select v-model="filters.provider" @change="applyFilters" class="px-4 py-2">
                <option :value="null">{{ $t('Provider') }}</option>
                <option v-for="(value, key) in providers" :value="key">{{ value }}</option>
            </select>
        </div>

        <div v-if="checkouts.length > 0" class="bg-white shadow mb-1 rounded">
            <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left text-blue-300">
                    <thead class="text-xs uppercase bg-blue-50">
                    <tr>
                        <th scope="col" class="px-6 py-3">{{ $t('Account') }}</th>
                        <th scope="col" class="px-6 py-3">{{ $t('Person') }}</th>
                        <th scope="col" class="px-6 py-3">{{ $t('Amount') }}</th>
                        <th scope="col" class="px-6 py-3">{{ $t('Method') }}</th>
                        <th scope="col" class="px-6 py-3">{{ $t('Last interaction') }}</th>
                        <th scope="col" class="px-6 py-3">{{ $t('Step') }}</th>
                        <th scope="col" class="px-6 py-3">{{ $t('Device') }}</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="checkout in checkouts">
                    <tr @click="selectCheckout(checkout)" :class="{
                        'bg-blue-50': checkout.show_steps,
                    }">
                        <td class="px-6">
                            <div class="max-w-[180px]">
                                <a class="text-blue-400" :href="route('2.activities.index', { account: checkout.account })" target="_blank">{{ checkout.account.name }}</a>
                                <div class="text-xs uppercase">
                                    Mail v{{ checkout.account.mail_version }}
                                </div>
                            </div>
                        </td>
                        <td class="px-6">
                            <div>{{ checkout.data.person?.first_name }} {{ checkout.data.person?.last_name }}</div>
                            <div class="text-xs">{{ checkout.data.person?.email }}</div>
                        </td>
                        <td class="px-6">
                            {{ checkout.product_name }}, {{ centsToMoney(checkout.data.custom_amount * 100, 'sek') }}
                        </td>
                        <td class="px-6">
                            {{ checkout.data?.method }}
                            <template v-if="checkout.method === 'billecta'">
                                <div class="text-xs">
                                    {{ checkout.data.billecta_init_data?.bank }} &middot;
                                    <template v-if="checkout.data.continue?.from">{{ $t('Phone') }}</template>
                                    <template v-else-if="checkout.data.billecta_init_data">{{ $t('Desktop') }}</template>
                                </div>
                            </template>
                        </td>
                        <td class="px-6">{{ dateTime(checkout.updated_at) }}</td>
                        <td class="px-6">
                            {{ checkout.step }}
                        </td>
                        <td class="px-6">
                            <template v-if="checkout.device">
                                {{ checkout.device.browser }}/{{ checkout.device.platform }}
                            </template>
                        </td>
                        <td class="px-6">
                            <AppMenu>
                                <AppMenuItem @click="show(checkout)">{{ $t('Show all data') }}</AppMenuItem>
                            </AppMenu>
                        </td>
                    </tr>
                    <tr class="border-b border-blue-100">
                        <td colspan="8" class="bg-blue-50" :class="{ 'hidden': !checkout.show_steps }">
                            <div class="px-6 py-2 text-center">
                                <span v-for="step in checkout.completed_steps" class="text-sm text-blue-400">
                                    {{ step }} &rarr;
                                </span>
                                <span class="text-sm text-blue-400">{{ checkout.step }}</span>
                            </div>
                        </td>
                    </tr>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mt-4">
            <Pagination :links="pagination.links" />
            <div class="text-center text-xs">
                {{ $t('{number} checkouts', {number: number(pagination.total)}) }}
                &middot;
                <a :href="route('2.hq.checkouts.index', { ...this.filters, export: 'excel' })" class="hover:underline">{{ $t('Export to Excel') }}</a>
            </div>
        </div>
    </HqLayout>
</template>
<script>
import HqLayout from '../HqLayout.vue'
import Pagination from '../../../Shared/Pagination.vue'
import AppDatePicker from '../../../Shared/AppDatePicker.vue'
import dayjs from 'dayjs'
import AppMenu from '../../../Shared/AppMenu.vue'
import AppMenuItem from '../../../Shared/AppMenuItem.vue'
import AppModal from '../../../Shared/AppModal.vue'
import AppInput from '../../../Shared/AppInput.vue'

export default {
    props: ['checkouts', 'pagination', 'accounts', 'providers', 'statuses', 'filters', 'people'],
    components: {AppInput, AppModal, AppMenuItem, AppMenu, AppDatePicker, Pagination, HqLayout},
    created() {
        if (!this.filters.from || !this.filters.to) {
            this.dateFilter = []

            return
        }

        let from = dayjs(this.filters.from)
            .locale('sv')
            .format('D MMM YYYY')

        let to = dayjs(this.filters.to)
            .locale('sv')
            .format('D MMM YYYY')


        this.dateFilter = [from, to]
    },
    data() {
        return {
            dateFilter: [],

            showModal: false,
            selectedCheckout: {},
        }
    },
    methods: {
        selectCheckout(checkout) {
            checkout.show_steps = !checkout.show_steps

            // Ensure only one checkout is expanded
            this.checkouts.forEach(c => {
                if (c.id !== checkout.id) {
                    c.show_steps = false
                }
            })
        },
        show(checkout) {
            this.showModal = true
            this.selectedCheckout = checkout
        },
        setDateFilter(value) {
            this.filters.from = value[0]
            this.filters.to = value[1]

            this.applyFilters()
        },
        applyFilters() {
            this.$inertia.get(
                route('2.hq.checkouts.index'),
                this.filters,
                {
                    preserveState: true,
                    preserveScroll: true,
                    replace: true,
                },
            )
        },
    },
}
</script>
