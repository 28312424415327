<template>
    <Layout>

        <template v-if="subscription">
            <Breadcrumbs>
                <Breadcrumb :href="route('2.subscriptions.index')">{{ $t('Subscriptions') }}</Breadcrumb>
                <Breadcrumb :href="route('2.subscriptions.show', { subscription: subscription.id })">{{ subscription.product.name }}</Breadcrumb>
                <Breadcrumb :active="true">{{ $t('Orders') }}</Breadcrumb>
            </Breadcrumbs>
        </template>
        
        <div v-if="!subscription && (orders.length > 0 || filters)" class="flex items-center justify-between mb-2">
            <div>
                <AppMenu direction="left">
                    <template v-slot:icon><ArrowDownIcon /></template>
                    <template v-slot:label>{{ this.current }}</template>

                    <AppMenuItem :href="route('2.orders.index')">{{ $t('All orders') }}</AppMenuItem>
                    <AppMenuItem :href="route('2.orders.index', { 'filters[kind]': 'one-time' })">{{ $t('One time purchases') }}</AppMenuItem>
                    <AppMenuItem v-for="(label, state) in this.states" :href="route('2.orders.index', { 'filters[state]': state })">{{ label }}</AppMenuItem>
                </AppMenu>
            </div>
            <div class="flex items-center">
                <InertiaLink :href="exportUrl()" class="flex items-center py-3 px-5 rounded-full transition hover:bg-blue-100">
                    <ExportIcon />
                    <span class="block ml-3">
                        {{ $t('Export') }}
                    </span>
                </InertiaLink>
            </div>
        </div>
    
        <TableRow
            :href="`${route('2.orders.show', { order: order.id })}`"
            v-for="order in orders"
        >
            <TableColumn class="sm:w-1/3">
                <OrderStatus :order="order" :margin="false" class="mb-0.5"></OrderStatus>
                <span class="block text-blue-400 font-semibold text-lg">
                    <template v-if="order.billing_method.company">{{ order.billing_method.company.name }}</template>
                    <template v-else>{{ order.billing_method.person.name }}</template>
                </span>
                <span class="block text-blue-300 text-base">{{ $t('Order {number}', { number: order.uniq_id })}}</span>
            </TableColumn>
            <TableColumn class="sm:w-1/3 pt-0 sm:pt-4 pb-0 sm:pb-4">
                <span class="block text-blue-300">{{ $t('{date} with {method}', { date: date(order.created_at), method: order.billing_method.pretty_name }) }}</span>
                <span class="block text-blue-300 text-base">{{ order.description }}</span>
            </TableColumn>
            <TableColumn class="sm:w-1/3 sm:text-right pt-0 sm:pt-4">
                <div class="flex items-center justify-between mt-4 sm:mt-0">
                    <div v-if="order.require_shipping && ! order.shipped_at" class="flex items-center text-sm font-medium text-lilac-300 mr-4 sm:mr-0">
                        <ShippingIcon class="text-lilac-300 mr-2" />
                        {{ $t('Not fulfilled') }}
                    </div>
                    <div v-else><!-- Keep empty for alignment --></div>
                    <span class="sm:text-lg font-semibold text-blue-300">{{ centsToMoney(order.total_with_vat, order.currency) }}</span>
                </div>
            </TableColumn>
        </TableRow>
        
        <template v-if="orders.length === 0 && ! filters">
            <BlankSlate name="orders" />
        </template>
        
        <template v-if="orders.length === 0 && filters">
            <div class="bg-white rounded shadow p-8">
                <p class="text-center">{{ $t('There are no orders matching the current filter') }}</p>
            </div>
        </template>
        
        <div class="mt-6">
            <Pagination :links="links"></Pagination>
        </div>

    </Layout>
</template>

<script>
import Layout from '../../Shared/Layout';
import TableHeader from "../../Shared/TableHeader";
import TableHeaderColumn from "../../Shared/TableHeaderColumn";
import TableRow from "../../Shared/TableRow";
import TableColumn from "../../Shared/TableColumn";
import AppMenu from "../../Shared/AppMenu";
import AppMenuItem from "../../Shared/AppMenuItem";
import Pagination from "../../Shared/Pagination";
import OrderStatus from "../../Shared/OrderStatus";
import SubscriptionName from "../../Shared/SubscriptionName";
import ArrowDownIcon from "../../Shared/Icons/ArrowDownIcon";
import ExportIcon from "../../Shared/Icons/ExportIcon";
import BlankSlate from "../../Shared/BlankSlate.vue";
import Breadcrumbs from "../../Shared/Breadcrumbs.vue";
import Breadcrumb from "../../Shared/Breadcrumb.vue";
import ShippingIcon from '../../Shared/Icons/ShippingIcon.vue'

export default {
    components: {
        ShippingIcon,
        Breadcrumb,
        Breadcrumbs,
        BlankSlate,
        ExportIcon,
        ArrowDownIcon,
        SubscriptionName,
        OrderStatus,
        Pagination,
        AppMenuItem,
        AppMenu,
        TableColumn, TableRow, TableHeaderColumn, TableHeader, Layout},
    props: ['orders', 'links', 'filters', 'states', 'current', 'queryString', 'subscription'],
    methods: {
        exportUrl() {
            if (! this.queryString) {
                return this.route('2.orders.export')
            }
        
            return this.route('2.orders.export') + '?' + this.queryString
        }
    }
}
</script>
