<template>
    <div class="relative -mb-1.5">
        <textarea
            :id="id"
            ref="input"
            v-bind="$attrs"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            class="
                min-h-[100px] rounded
                pt-7 pb-3 px-4 bg-white shadow
                peer w-full placeholder-transparent
                text-blue-400
                border border-white
                focus:outline-none focus:border-blue-200
                focus:pt-7 focus:pb-3
                transition duration-300
                
            "
            :class="{
                'border-red-400': error,
                'focus:border-red-400': error,
                'bg-red-400': error,
                'bg-opacity-10': error,
             }"
            placeholder="john@doe.com"
        />
        <label
            :for="id"
            class="
                absolute left-4 top-2 text-blue-300 text-sm transition-all
                peer-placeholder-shown:text-base peer-placeholder-shown:text-blue-300 peer-placeholder-shown:top-5
                peer-focus:top-2 peer-focus:text-blue-300 peer-focus:text-sm
            "
           :class="{
                'peer-placeholder-shown:text-red-400': error,
            }"
        >{{ label }}</label>
    </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid"

export default {
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            default() {
                return `textarea-input-${uuidv4()}`
            },
        },
        modelValue: String,
        label: String,
        error: String,
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        },
        select() {
            this.$refs.input.select()
        },
        setSelectionRange(start, end) {
            this.$refs.input.setSelectionRange(start, end)
        },
    },
}
</script>
