<template>
    <LayoutWithoutNavigation>
        <Sheet>
            <SheetTitle class="pt-0">{{ $t('Welcome to Octany') }}</SheetTitle>
            
            <form @submit.prevent="submit">
                <div class="flex items-center mb-1 space-x-1 mt-6">
                    <div class="w-1/2"><AppInput :label="$t('First name')" v-model="form.first_name" /></div>
                    <div class="w-1/2"><AppInput :label="$t('Last name')" v-model="form.last_name" /></div>
                </div>
                
                <AppInput :label="$t('Email')" v-model="form.email" type="email" class="mb-1" />
                
                <AppInput :label="$t('Password')" v-model="form.password" type="password" class="mb-12" />
                
                <div class="flex items-center space-x-1 mb-1">
                    <div class="w-1/2">
                        <AppSelect :label="$t('Timezone')" v-model="form.time_zone">
                            <option v-for="timeZone in timeZones" :value="timeZone">{{ timeZone }}</option>
                        </AppSelect>
                    </div>
                    <div class="w-1/2">
                        <AppSelect :label="$t('Language')" v-model="form.locale">
                            <option value="sv">{{ $t('Swedish') }}</option>
                            <option value="en">{{ $t('English') }}</option>
                        </AppSelect>
                    </div>
                </div>
                
                <AppCheckboxGroup class="mb-1">
                    <AppCheckbox v-model="form.agree">
                        <p v-html="$t('accept_terms', { termsUrl })"></p>
                    </AppCheckbox>
                </AppCheckboxGroup>
                
                <AppCheckboxGroup>
                    <AppCheckbox v-model="form.email_communication">{{ $t('accept_email') }}</AppCheckbox>
                </AppCheckboxGroup>
            </form>
            
            <FormError />
            
            <SheetButton @click="submit" :loading="form.processing">{{ $t('Continue') }}</SheetButton>
            
        </Sheet>
    </LayoutWithoutNavigation>
</template>

<script>
import LayoutWithoutNavigation from "../../../Shared/LayoutWithoutNavigation.vue";
import Sheet from "../../../Shared/Sheet.vue";
import SheetTitle from "../../../Shared/SheetTitle.vue";
import AppInput from "../../../Shared/AppInput.vue";
import {useForm} from "@inertiajs/vue3";
import SheetButton from "../../../Shared/SheetButton.vue";
import AppSelect from "../../../Shared/AppSelect.vue";
import AppCheckbox from "../../../Shared/AppCheckbox.vue";
import AppCheckboxGroup from "../../../Shared/AppCheckboxGroup.vue";
import FormError from "../../../Shared/FormError.vue";

export default {
    props: ['user', 'timeZones', 'termsUrl', 'token'],
    components: {
        FormError,
        AppCheckboxGroup,
        AppCheckbox,
        AppSelect,
        SheetButton,
        AppInput,
        SheetTitle,
        Sheet,
        LayoutWithoutNavigation
    },
    data() {
        return {
            form: useForm({
                first_name: this.user.first_name,
                last_name: this.user.last_name,
                email: this.user.email,
                time_zone: this.user.time_zone,
                locale: this.user.locale,
                password: '',
                agree: false,
                email_communication: false,
            })
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('2.users.rsvp.store', { user: this.user, token: this.token }));
        }
    }
};
</script>