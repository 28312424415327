<template>
    <Layout>
        <DevelopersHeader active="api" />
        
        <div class="max-w-5xl mx-auto mb-16">
            <form @submit.prevent="create">
                <div class="font-medium mb-3">{{ $t('Generate new API token') }}</div>
                
                <div class="flex items-center">
                    <div class="flex-1 mr-2"><AppInput :label="$t('Description')" v-model="form.description"></AppInput></div>
                    <div><AppButtonOutline @click="create">{{ $t('Create') }}</AppButtonOutline></div>
                </div>
                
                <FormError :inline="true" />
                
            </form>
        </div>
        
        <div v-if="tokens.length > 0" class="max-w-5xl mx-auto">
            <div class="font-medium mb-3">{{ $t('API Tokens') }}</div>
        </div>
        
        <div class="max-w-5xl mx-auto">
            <div v-for="token in tokens" class="bg-white p-8 shadow rounded flex items-center mb-1">
                <div class="w-3/4">
                    <div class="pr-8">
                        <div class="text-lg font-semibold">{{ token.description }}</div>
                        <div class="text-wrap break-words">{{ token.token }}</div>
                    </div>
                </div>
                <div class="w-1/4 flex items-center justify-between">
                    <div class="text-sm">
                        <template v-if="token.used_at">
                            {{ $t('Last used {date}', { date: date(token.used_at) })}}
                        </template>
                        <template v-else>{{ $t('Never used') }}</template>
                    </div>
                    <div>
                        <AppMenu>
                            <AppMenuItem :href="route('2.developers.tokens.remove', { token })">{{ $t('Remove') }}</AppMenuItem>
                        </AppMenu>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Layout from "../../../Shared/Layout.vue";
import DevelopersHeader from "../DevelopersHeader.vue";
import AppMenu from "../../../Shared/AppMenu.vue";
import AppMenuItem from "../../../Shared/AppMenuItem.vue";
import AppInput from "../../../Shared/AppInput.vue";
import {useForm} from "@inertiajs/vue3";
import AppButtonOutline from "../../../Shared/AppButtonOutline.vue";
import FormError from "../../../Shared/FormError.vue";

export default {
    props: ['tokens'],
    components: {
        FormError,
        AppButtonOutline,
        AppInput,
        AppMenuItem,
        AppMenu,
        DevelopersHeader,
        Layout
    },
    data() {
        return {
            form: useForm({
                description: ''
            })
        }
    },
    methods: {
        create() {
            this.form.post(this.route('2.developers.tokens.store'), {
                preserveState: true,
                onSuccess: () => {
                    this.form.reset()
                }
            })
        }
    }
}
</script>