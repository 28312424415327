<template>
    <div class="
        fixed
        px-6 py-4
        m-4
        rounded
        bottom-0 left-0
        bg-black
        bg-opacity-80
        transition-all
        transform
        z-50
    "
        :class="{ 'translate-y-20': !show }"
    >
        <span class="text-white opacity-80">
<!--            <template v-if="$page.props.flash && $page.props.flash.success && show">-->
<!--                {{ $page.props.flash.success }}-->
<!--            </template>-->
            
            <template v-if="$page.props.flash && $page.props.flash.message && show">
                {{ $page.props.flash.message }}
            </template>
            
<!--            <template v-if="(($page.props.flash && $page.props.flash.error) || Object.keys($page.props.errors).length > 0) && show">-->
<!--                <template v-if="$page.props.flash.error">{{ $page.props.flash.error }}</template>-->
<!--                <template v-else>{{ $t('Please make sure all fields are correct') }}</template>-->
<!--            </template>-->
        </span>
    </div>
</template>

<script>
module.exports = {
    data() {
        return {
            previousMessage: null,
            show: false,
        }
    },
    created() {
      if (this.$page.props.flash.message) {
          this.flash(500)
      }
    },
    watch: {
        '$page.props.flash': {
            handler() {
                if (this.$page.props.flash.message && this.$page.props.flash.message !== this.previousMessage) {
                    this.flash()
                    this.previousMessage = this.$page.props.flash.message
                }
            },
            deep: true,
        },
    },
    methods: {
        flash(delay = 0) {
            if (delay > 0) {
                setTimeout(() => {
                    this.show = true
                }, delay);
            } else {
                this.show = true
            }
            
            this.hide()
        },
        hide() {
            setTimeout(() => {
                this.show = false
            }, 6000)
        }
    }
}
</script>
