<template>
    <Layout>

        <Sheet :back="route('2.settings.index')">
            <SheetIcon><SendFileIcon /></SheetIcon>
            <SheetTitle>{{ $t('New SFTP channel') }}</SheetTitle>

            <form @submit.prevent="submit">
                <AppInput :label="$t('Name')" v-model="form.name" class="mb-1"></AppInput>
                <AppInput :label="$t('Hostname')" v-model="form.host" class="mb-1"></AppInput>
                <AppInput :label="$t('Username')" v-model="form.username" class="mb-1"></AppInput>
                <AppInput type="password" :label="$t('Password')" v-model="form.password" class="mb-1"></AppInput>
                <AppInput :label="$t('Port')" v-model="form.port" class="mb-1"></AppInput>
                <AppInput :label="$t('Root directory')" v-model="form.root_directory" class="mb-12"></AppInput>

                <AppCheckboxGroup class="mb-1">
                    <AppCheckbox v-model="form.export['customers:csv']">{{ $t('Export customers') }}</AppCheckbox>
                    <AppCheckbox v-model="form.export['subscriptions:csv']">{{ $t('Export subscriptions') }}</AppCheckbox>
                    <AppCheckbox v-model="form.export['orders:csv']">{{ $t('Export orders') }}</AppCheckbox>
                </AppCheckboxGroup>

                <AppCheckboxGroup>
                    <AppCheckbox v-model="form.formatting['headers_with_a_to_z']">{{ $t('Remove special characters from header row') }}</AppCheckbox>
                </AppCheckboxGroup>

                <FormError />
                <SheetButton @click.prevent="submit">{{ $t('Create') }}</SheetButton>
            </form>
        </Sheet>

    </Layout>

</template>

<script>
    import Layout from "../../../Shared/Layout.vue";
    import Sheet from '../../../Shared/Sheet.vue'
    import SheetIcon from '../../../Shared/SheetIcon.vue'
    import SheetTitle from '../../../Shared/SheetTitle.vue'
    import AppInput from '../../../Shared/AppInput.vue'
    import {useForm} from '@inertiajs/vue3'
    import SendFileIcon from '../../../Shared/Icons/SendFileIcon.vue'
    import AppButton from '../../../Shared/AppButton.vue'
    import SheetButton from '../../../Shared/SheetButton.vue'
    import FormError from '../../../Shared/FormError.vue'
    import AppCheckboxGroup from '../../../Shared/AppCheckboxGroup.vue'
    import AppCheckbox from '../../../Shared/AppCheckbox.vue'

    export default {
        components: {
            AppCheckbox, AppCheckboxGroup,
            FormError,
            SheetButton,
            AppButton,
            SendFileIcon,
            AppInput,
            SheetTitle,
            SheetIcon,
            Sheet,
            Layout,
        },
        data() {
            return {
                form: useForm({
                    name: '',
                    host: '',
                    username: '',
                    password: '',
                    port: '22',
                    root_directory: '/',
                    export: {
                        'customers:csv': false,
                        'subscriptions:csv': false,
                        'orders:csv': false,
                    },
                    formatting: {
                        headers_with_a_to_z: false,
                    },
                })
            }
        },
        methods: {
            submit() {
                this.form.post(this.route('3.integrations.sftp.store'))
            }
        }
    }
</script>
