<template>
    <div class="flex items-center">
        <input
            :id="id"
            ref="input"
            v-bind="$attrs"
            type="checkbox"
            @input="change($event)"
            :checked="isChecked"
            class="
                form-checkbox bg-white rounded border-blue-300 text-blue-400 w-[18px] h-[18px] mr-3
                checked:bg-white checked:border-blue-400
                focus:ring-1
                focus:ring-offset-2
                disabled:bg-blue-200
                disabled:border-blue-200
            "
            :class="{
                'border-red-400': error,
                'focus:border-red-400': error,
                'bg-red-400': error,
                'bg-opacity-10': error,
             }"
            placeholder="john@doe.com"/>
        <label
            :for="id"
            class="text-sm text-blue-300 text-left"
            :class="{
                'peer-placeholder-shown:text-red-400': error,
                'text-blue-200' : this.$attrs.disabled
            }"
        >
            <template v-if="label">{{ label }}</template>
            <template v-else><slot /></template>
        </label>
    </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid"

export default {
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            default() {
                return `checkbox-input-${uuidv4()}`
            },
        },
        modelValue: [String, Number, Boolean, Object, Array],
        value: [String, Number, Boolean, Object],
        isArray: Boolean,
        label: String,
        error: String,
    },
    computed: {
        isChecked() {
            if (this.isArray) {
                return this.modelValue?.includes(this.value);
            }
            return this.modelValue;
        }
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        },
        select() {
            this.$refs.input.select()
        },
        change($event) {
            if (this.isArray) {
                if ($event.target.checked) {
                    this.$emit('update:modelValue', [...(this.modelValue || []), this.value]);
                } else {
                    this.$emit('update:modelValue', (this.modelValue || []).filter(v => v !== this.value));
                }
            } else {
                this.$emit('update:modelValue', $event.target.checked);
            }
        },
    },
}
</script>