<template>
    <button
        v-bind="$attrs"
        :class="buttonClasses"
        class="
            border border-lilac-300 text-lilac-500 text-sm font-medium min-h-[44px]
            transition duration-100
            disabled:border-lilac-100 disabled:text-lilac-200
        "
    >
        <AppSpinner v-if="loading" color="#ffffff"></AppSpinner>
        <span v-else class="flex items-center">
            <span v-if="$slots.icon" class="mr-2"><slot name="icon"></slot></span>
            <slot></slot>
        </span>
    </button>
</template>

<script>
import AppSpinner from './AppSpinner';

export default {
    components: { AppSpinner },
    inheritAttrs: false,
    props: {
        loading: Boolean,
    },
    data() {
        return {
            staticClasses: this.initializeStaticClasses(),
        };
    },
    computed: {
        buttonClasses() {
            let dynamicClasses = {};
            
            if (this.$attrs.disabled === undefined || this.$attrs.disabled === false) {
                dynamicClasses['hover:bg-lilac-300 hover:text-white'] = true
            } else {
                dynamicClasses['hover:bg-lilac-300 hover:text-white'] = false
            }
            
            return { ...this.staticClasses, ...dynamicClasses }
        },
    },
    methods: {
        initializeStaticClasses() {
            let classes = {};
            let attrsClass = this.$attrs.class || '';
            
            if (!attrsClass.includes('rounded')) {
                classes['rounded-full'] = true;
            }
            
            if (!attrsClass.includes('block')) {
                classes['inline-block'] = true;
            }
            
            if (!attrsClass.includes('px-')) {
                if (this.$slots.icon) {
                    classes['pl-6 pr-8'] = true;
                } else {
                    classes['px-8'] = true;
                }
            }
            
            if (!attrsClass.includes('py-')) {
                classes['py-1'] = true;
            }
            
            return classes;
        },
    },
}
</script>