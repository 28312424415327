<template>
    <Layout>
        <Sheet>
            <SheetIcon :transparent="true"></SheetIcon>

            <SheetTitle>{{ $t('Edit address') }}</SheetTitle>

            <form @submit.prevent="submit">
                <AppInput :label="$t('Address')" v-model="form.address.line1" class="mb-1" />
                <AppInput :label="$t('Line 2')" v-model="form.address.line2" class="mb-1" />
                <AppInput :label="$t('Postal code')" v-model="form.address.zip" class="mb-1" />
                <AppInput :label="$t('City')" v-model="form.address.city" class="mb-1" />
                <AppSelect :label="$t('Country')" v-model="form.address.country">
                    <option v-for="country in countries" :value="country.iso">{{ country.name }}</option>
                </AppSelect>

                <FormButton :loading="form.processing">{{ $t('Save') }}</FormButton>
            </form>
        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import Sheet from "../../Shared/Sheet.vue";
import SheetIcon from "../../Shared/SheetIcon.vue";
import SheetTitle from "../../Shared/SheetTitle.vue";
import AppInput from "../../Shared/AppInput.vue";
import AppSelect from "../../Shared/AppSelect.vue";
import FormButton from "../../Shared/FormButton.vue";

export default {
    props: ['address', 'countries', 'returnTo'],
    components: {FormButton, AppSelect, AppInput, SheetTitle, SheetIcon, Sheet, Layout},
    data: () => {
        return {
            form: {},
        }
    },
    computed: {
        url() {
            return this.route('2.address.update', { address: this.address, 'return-to': this.returnTo })
        },
    },
    created() {
        this.form.address = this.$inertia.form(this.address)
    },
    methods: {
        submit() {
            this.$inertia.post(this.url, this.form.address)
        }
    }
}
</script>