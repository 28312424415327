<template>
    <Layout>
        <AppTitle>
            {{ $t('Team') }}
            
            <template #description>
                {{ $t('Your team has access all Octany. They can edit customer data and cancel subscriptions') }}
            </template>
        </AppTitle>
        
        <div class="max-w-4xl mx-auto">
            <div class="font-medium mb-3">{{ $t('Team') }}</div>
        </div>
        
        <div class="max-w-4xl mx-auto mb-8">
            <TableRow v-for="user in users">
                <TableColumn class="sm:w-1/2">
                    <div class="text-lg font-semibold text-blue-400 py-5">
                        {{ user.first_name }} {{ user.last_name }}
                        <div v-if="user.status === 'INVITED'" class="text-xs tracking-wider text-blue-300 font-normal">{{ $t('Invited') }}</div>
                    </div>
                </TableColumn>
                <TableColumn class="sm:w-1/2">
                    <div class="flex items-center justify-between">
                        <div>
                            {{ user.email }}
                        </div>
                        <div>
                            <AppMenu>
                                <AppMenuItem :href="route('2.users.remove', { user: user })">
                                    {{ $t('Remove') }}
                                </AppMenuItem>
                            </AppMenu>
                        </div>
                    </div>
                </TableColumn>
            </TableRow>
        </div>
        
        <div class="max-w-4xl mx-auto">
            <div class="font-medium mb-3">{{ $t('Invite a new team member') }}</div>
        </div>
        
        <div class="max-w-4xl mx-auto">
            
                <form @submit.prevent="sendInvitation">
                    <div><FormError :inline="true" class="mb-4" /></div>
                
                    <div class="flex items-center justify-between">
                        <div class="flex-1 pr-2"><AppInput :label="$t('Name')" v-model="form.name"></AppInput></div>
                        <div class="flex-1 pr-4"><AppInput :label="$t('Email Address')" v-model="form.email"></AppInput></div>
                        <AppButtonOutline :loading="form.processing" @click="sendInvitation">
                            <template #icon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" class="fill-current"><path fill-rule="evenodd" d="M17.879 18.043 20.964 4 3 13.126l6.716 2.22 4.242-3.475-2.731 3.974 6.652 2.198Zm-6.74-1.008.772 3.377 2.54-2.357-3.311-1.02Z" clip-rule="evenodd"/></svg>
                            </template>
                            
                            {{ $t('Invite') }}
                        </AppButtonOutline>
                    </div>
                </form>
        </div>
        
    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import AppTitle from "../../Shared/AppTitle.vue";
import TableRow from "../../Shared/TableRow.vue";
import TableColumn from "../../Shared/TableColumn.vue";
import AppMenu from "../../Shared/AppMenu.vue";
import AppMenuItem from "../../Shared/AppMenuItem.vue";
import AppInput from "../../Shared/AppInput.vue";
import {useForm} from "@inertiajs/vue3";
import AppButtonOutline from "../../Shared/AppButtonOutline.vue";
import FormError from "../../Shared/FormError.vue";

export default {
    props: ['users'],
    components: {
        FormError,
        AppButtonOutline,
        AppInput,
        AppMenuItem,
        AppMenu,
        TableColumn,
        TableRow,
        AppTitle,
        Layout
    },
    data() {
        return {
            form: useForm({
                name: '',
                email: ''
            })
        }
    },
    methods: {
        sendInvitation() {
            this.form.post(this.route('2.users.invite.store'), {
                preserveScroll: true,
                onSuccess: () => {
                    this.form.reset()
                }
            });
        }
    }
};
</script>