    <template>
    
    <!-- If row is link -->
    <InertiaLink
        v-if="href"
        :href="href"
        class="
            block bg-white rounded shadow hover:shadow-lilac-2 mb-1
            transition-colors
            duration-100
            border-l-2
            border-white
        "
        :class="classes"
    >
        <span class="flex items-center flex-wrap w-full h-full">
            <slot></slot>
        </span>
    </InertiaLink>
    
    <!-- Row is not a link -->
    <div
        v-else
        class="
            block bg-white rounded shadow mb-1
            transition-colors
            duration-100
            border-l-2
            border-white
        "
        :class="classes"
    >
        <span class="flex items-center flex-wrap w-full h-full">
            <slot></slot>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        href: {
            default: ''
        },
    },
    data() {
        return {
            classes: {
                // 'hover:bg-lilac-100' : this.href,
                'hover:rounded-r' : this.href,
                'hover:rounded-none' : this.href,
                'hover:border-lilac-300' : this.href,
            }
        }
    }
}
</script>