<template>
    <Steps>
        <Step :number="1" :complete="step > 1" :active="step === 1">{{ $t('Activate Octany Pay') }}</Step>
        <Step :number="2" :complete="step > 2" :active="step === 2">{{ $t('Create product') }}</Step>
        <Step :number="3" :complete="step > 3" :active="step === 3">{{ $t('Activate payments') }}</Step>
    
        <template v-if="$page.props.integration && $page.props.integration.identifier === 'snowfire'">
            <Step :number="4" :complete="step > 4" :active="step === 4" :last="true">{{ $t('Connect button to product') }}</Step>
        </template>
        <template v-else>
            <Step :number="4" :complete="step > 4" :active="step === 4" :last="true">{{ $t('Add Octany to your website') }}</Step>
        </template>
        
    </Steps>
</template>

<script>
import Steps from "./Steps";
import Step from "./Step";

export default {
    props: ['step'],
    components: {Step, Steps},
}
</script>