<template>
    <Layout>
        <DevelopersHeader active="webhooks" />
        
        <div class="max-w-5xl mx-auto mb-16">
            <form @submit.prevent="create">
                <SmallTitle>{{ $t('Add new endpoint') }}</SmallTitle>
                
                <div class="flex items-center">
                    <div class="flex-1 mr-2"><AppInput :label="$t('Url')" v-model="form.url"></AppInput></div>
                    <div><AppButtonOutline @click="create">{{ $t('Create') }}</AppButtonOutline></div>
                </div>
                
                <FormError :inline="true" />
            
            </form>
        </div>
        
        <div class="max-w-5xl mx-auto mb-16">
            
            <LinkPanel :href="route('2.developers.webhooks.index', { endpoint })" v-for="endpoint in endpoints" class="mb-1">
                <span class="block font-semibold">{{ endpoint.url }}</span>
            </LinkPanel>
        </div>
        
    </Layout>
</template>
<script>
import Layout from "../../../../Shared/Layout.vue";
import DevelopersHeader from "../../DevelopersHeader.vue";
import LinkPanel from "../../../../Shared/LinkPanel.vue";
import SmallTitle from "../../../../Shared/SmallTitle.vue";
import FormError from "../../../../Shared/FormError.vue";
import AppButtonOutline from "../../../../Shared/AppButtonOutline.vue";
import AppInput from "../../../../Shared/AppInput.vue";
import {useForm} from "@inertiajs/vue3";

export default {
    props: ['endpoints'],
    components: {
        AppInput, AppButtonOutline, FormError,
        SmallTitle,
        Layout,
        DevelopersHeader,
        LinkPanel
    },
    data() {
        return {
            form: useForm({
                url: '',
            }),
        }
    },
    methods: {
        create() {
            this.form.post(this.route('2.developers.webhooks.endpoints.store'), {
                preserveScroll: true,
                onSuccess: () => {
                    this.form.reset()
                }
            });
        }
    }
};
</script>