<template>
    <Layout>
        <Sheet>
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>{{ $t('Checkout') }}</SheetTitle>
    
            <form @submit.prevent="store">
                <!-- Logo -->
                <div v-if="showUploadLogo">
                    <DropZone @upload="logoUploaded" />
                    <p class="text-center mt-2">{{ $t('Logo (PNG, rec. 480x280)') }}</p>
                </div>
                <div v-else>
                    <div class="relative group">
                        <div class="
                            absolute h-full w-full top-0 flex items-center justify-center
                            transition-opacity duration-100
                            opacity-0 group-hover:opacity-100
                        ">
                            <div>
                                <div>
                                    <button
                                        @click="removeLogo"
                                        class="p-2 text-sm text-sm cursor-pointer bg-lilac-100 shadow rounded px-4 py-2">
                                        {{ $t('Remove') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <img :src="logoUrl" alt="" class="max-w-[240px] max-h-[140px] mx-auto" />
                    </div>
                </div>
                
                <!-- Color theme -->
                <div class="mt-12 mb-1">
                    <AppSelect v-model="form.colorTheme" :label="$t('Color theme')">
                        <option v-for="(value, type) in colorThemes" :value="type">{{ value }}</option>
                    </AppSelect>
                </div>
                
                <!-- Colors -->
                <AppColor v-model="form.primaryColor" class="mb-1">{{ $t('Primary color') }}</AppColor>
                <AppColor v-model="form.primaryTextColor" class="mb-1">{{ $t('Primary text color') }}</AppColor>
                
                <FormButton :loading="form.processing">{{ $t('Save') }}</FormButton>
            </form>
            
        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout";
import Sheet from "../../../Shared/Sheet";
import SheetIcon from "../../../Shared/SheetIcon";
import CartIcon from "../../../Shared/Icons/CartIcon";
import SheetTitle from "../../../Shared/SheetTitle";
import DropZone from "../../../Shared/DropZone";
import {useForm} from "@inertiajs/vue3";
import FormButton from "../../../Shared/FormButton";
import AppColor from "../../../Shared/AppColor";
import AppSelect from "../../../Shared/AppSelect";

export default {
    props: ['settings', 'colorThemes'],
    components: {AppSelect, AppColor, FormButton, SheetTitle, CartIcon, SheetIcon, Sheet, Layout, DropZone},
    data() {
        return {
            showUploadLogo: !this.settings.logo.path,
            logoUrl: this.settings.logo.url,
            form: useForm({
                logo: {
                    path: this.settings.logo.path,
                    changed: false,
                },
                colorTheme: this.settings.colorTheme,
                primaryColor: this.settings.primaryColor,
                primaryTextColor: this.settings.primaryTextColor,
            })
        }
    },
    methods: {
        logoUploaded(data) {
            this.showUploadLogo = false
            this.logoUrl = data.url
            this.form.logo.changed = true
            this.form.logo.path = data.path
        },
        removeLogo() {
            this.showUploadLogo = true
            this.logoUrl = null
            this.form.logo.changed = true
            this.form.logo.path = null
        },
        store() {
            this.$inertia.post(this.route('2.settings.checkout.store'), this.form)
        }
    }
}
</script>