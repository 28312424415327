<template>
    <HqLayout size="md">
        <template #header><SubscriptionsNavigation /></template>

        <div class="bg-white shadow mb-1 rounded">
            <div class="flex space-x-4 p-4">
                <select v-model="filters.account" @change="applyFilters" class="px-4 py-2 max-w-[200px]">
                    <option :value="null">{{ $t('Account') }}</option>
                    <option v-for="account in accounts" :key="account.id" :value="account.id">{{ account.name }}</option>
                </select>
            </div>
        </div>


        <ul role="list" class="divide-y divide-blue-100">
            <template v-for="group in duplicateGroups.data" :key="group.id">
                <li class="py-5 space-y-4">
                    <div
                        class="w-[500px] shadow bg-white p-4 rounded-lg flex justify-between"
                        v-for="subscription in group.subscriptions"
                    >
                        <div>
                            <p class="text-sm font-semibold text-blue-400">
                                <a :href="route('2.subscriptions.show', { account: subscription.account_id, subscription: subscription })" target="_blank">
                                    {{ subscription.billing_method.person.first_name }} {{ subscription.billing_method.person.last_name }}, sub_{{ subscription.id }}
                                </a>
                            </p>
                            <p class="mt-1 truncate text-xs text-blue-300">{{ subscription.billing_method.person.email }}</p>
                            <p class="mt-1 truncate text-xs text-blue-200">Group {{ group.id }}</p>
                            <p class="mt-1 truncate text-blue-400">
                                <template v-if="subscription.orders_count === 0">❗</template>
                                {{ subscription.orders_count }} orders
                            </p>
                        </div>
                        <div>
                            <p class="text-sm text-blue-300">{{ dateTime(subscription.created_at) }}</p>
                            <p class="mt-1 truncate text-xs text-blue-200">{{ subscription.provider }}</p>
                            <p class="mt-1 truncate  text-xs font-semibold text-blue-400">{{ subscription.status }}</p>
                            <p class="mt-1 truncate  text-blue-400">{{ centsToMoney(subscription.price, subscription.currency) }}</p>
                        </div>
                        <div>
                            <AppMenu>
                                <AppMenuItem @click="confirmCancelSubscription(subscription)">
                                    <template v-if="subscription.orders_count === 0">
                                        {{ $t('Delete subscription') }}
                                    </template>
                                    <template v-else>
                                        {{ $t('Cancel subscription') }}
                                    </template>
                                </AppMenuItem>
                            </AppMenu>

                            <AppModalAlert :ref="`cancelSubscriptionModal-${subscription.id}`" @confirm="cancelSubscription(subscription, group)" :open="false">
                                <template v-slot:title>{{ $t('Cancel/delete subscription') }}</template>
                                {{ $t('This subscription will be cancelled if there are paid orders, otherwise it will be deleted from the system') }}
                                <template v-slot:confirm>{{ $t('Cancel/delete subscription') }}</template>
                            </AppModalAlert>
                        </div>
                    </div>
                </li>
            </template>
        </ul>

<!--        <div v-for="group in duplicateGroups.data" :key="group.id">-->
<!--            <div class="bg-blue-50 shadow mb-12 rounded ">-->
<!--                <div class="font-medium p-4 text-center">{{ group.email }} – {{ group.account.name }}</div>-->

<!--                <SubscriptionList :subscriptions="group.subscriptions" :pagination="false" :filters="filters" />-->
<!--            </div>-->
<!--        </div>-->

        <div class="mt-4">
            <Pagination :links="pagination.links" />
            <div class="text-center text-xs">{{ $t('{number} subscriptions', { number: number(pagination.total) }) }}</div>
        </div>
    </HqLayout>
</template>

<script>
import HqLayout from '../../HqLayout.vue'
import OrderStatus from '../../../../Shared/OrderStatus.vue'
import OrderBillingMethod from '../../../../Shared/OrderBillingMethod.vue'
import Pagination from '../../../../Shared/Pagination.vue'
import AppDatePicker from '../../../../Shared/AppDatePicker.vue'
import AppMenu from '../../../../Shared/AppMenu.vue'
import AppMenuItem from '../../../../Shared/AppMenuItem.vue'
import SubNavigation from '../../SubNavigation.vue'
import SubscriptionFilters from '.././SubscriptionFilters.vue'
import SubscriptionList from '.././SubscriptionList.vue'
import SubscriptionsNavigation from '../SubscriptionsNavigation.vue'
import AppModalAlert from '../../../../Shared/AppModalAlert.vue'
import axios from 'axios'

export default {
    props: ['duplicateGroups', 'pagination', 'accounts', 'filters'],
    components: {
        AppModalAlert,
        SubscriptionsNavigation,
        SubscriptionList,
        SubscriptionFilters,
        SubNavigation,
        AppMenuItem, AppMenu, AppDatePicker, Pagination, OrderBillingMethod, OrderStatus, HqLayout,
    },
    methods: {
        applyFilters() {
            this.$inertia.get(
                route('2.hq.subscriptions.duplicates.index'),
                this.filters,
                {
                    preserveState: true,
                    preserveScroll: true,
                    replace: true
                }
            )
        },
        confirmCancelSubscription(subscription) {
            this.$refs[`cancelSubscriptionModal-${subscription.id}`][0].isOpen = true
        },
        async cancelSubscription(subscription, group) {
            await axios.post(
                this.route('2.hq.subscriptions.duplicates.cancel'),
                { subscription: subscription.id, group: group.id },
            ).then(() => {
                this.$inertia.reload()
                this.$refs[`cancelSubscriptionModal-${subscription.id}`][0].isOpen = false;
            }).catch((error) => {
                console.error(error)
            })
        }
    },
}
</script>
