<template>
    <div class="text-center" v-if="links.length > 1">
        <div class="inline-block mx-auto">
            <div class="flex flex-wrap justify-center w-full border-t-2 border-blue-100">
                <template v-for="(link, key) in links">
                    <inertia-link
                        v-if="link.url"
                        class="
                            inline-block p-4
                            text-blue-300 border-t-2
                        "
                        :class="{
                            'border-transparent': !link.active,
                            'border-lilac-300': link.active,
                            'text-lilac-300': link.active,
                        }"
                        :href="link.url"
                        v-html="link.label"
                    />
                    <div
                        v-if="!link.url"
                        class="inline-block p-4 text-blue-300 border-t-2 border-transparent"
                        v-html="link.label"
                    ></div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {router} from '@inertiajs/vue3'

export default {
    props: {
        links: Array,
    },
    data() {
        return {
            state: {
                links: Array
            }
        }
    },
    mounted() {
        router.on('success', (event) => {
            // This makes showing an order with activities work when updating the order (i.e. fulfilling)
            if (! event.detail.page.props.activities || ! event.detail.page.props.activities.links) {
                return
            }

            this.state.links = event.detail.page.props.activities.links
            this.removeNextAndPreviousLinks()
        })
    },
    created() {
        this.state.links = this.links
        this.removeNextAndPreviousLinks()
    },
    methods: {
        removeNextAndPreviousLinks() {
            this.state.links.shift()
            this.state.links.pop()
        }
    }
}
</script>