<template>
    <HqLayout>
        
        <div class="text-center">
            <div class="mx-auto bg-blue-50 inline-block mb-8 rounded">
                <div class="flex justify-center items-center">
                    <a :href="route('2.hq.transactions.fees')" class="block px-5 py-2 m-1 rounded font-medium text-sm text-lilac-500 bg-lilac-50">{{ $t('Fees') }}</a>
                    <a :href="route('2.hq.transactions.statements.index')" class="bg-blue-50 block px-5 py-2 m-1 rounded font-medium text-sm  text-lilac-500">{{ $t('Statements') }}</a>
                </div>
            </div>
        </div>
        
        <div class="">
        
            <TableRow
                v-for="account in accounts"
            >
                <TableColumn class="sm:w-1/3">{{ account.name }}</TableColumn>
                <TableColumn class="sm:w-1/3">{{ ucfirst(account.plan?.name) }}</TableColumn>
                <TableColumn class="sm:w-1/3 text-right">
                    <div class="" v-for="fee in account.fees">
                        {{ ucfirst(fee.billing_method) }}: {{ centsToMoney(fee.amount_fee, 'sek', fee.amount_fee > 0 ? 2 : 0) }} + {{ fee.percent_fee/100 }}%
                    </div>
                </TableColumn>
            </TableRow>
        
        </div>
        
    </HqLayout>
</template>

<script>
import HqLayout from "../HqLayout.vue"
import TableColumn from "../../../Shared/TableColumn.vue";
import TableRow from "../../../Shared/TableRow.vue";

export default {
    props: ['accounts'],
    components: {
        TableRow, TableColumn,
        HqLayout
    }
}
</script>
