<template>
    <div ref="myElement">
        <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none"><path d="M7 8.22c.001.436.003 1.36 0 1.78h2V8c.066-.103.08-.111.155-.154.039-.023.095-.055.184-.116a4.01 4.01 0 0 0 1.183-1.022c.319-.406.477-.848.477-1.326 0-.734-.27-1.313-.814-1.739C9.646 3.214 8.902 3 7.952 3c-.882 0-1.599.192-2.15.576-.548.384-.815.937-.802 1.66l.013.033H6.82c.004-.32.114-.563.33-.728.22-.166.488-.248.802-.248.382 0 .674.097.878.293.208.196.312.467.312.813 0 .312-.091.594-.274.847a3.08 3.08 0 0 1-.719.705c-.106.073-.211.139-.312.202-.388.243-.718.45-.837.847v.22ZM8 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"/><path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-1 0A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" clip-rule="evenodd"/></svg>
    </div>
</template>

<script>
import tippy from 'tippy.js';

export default {
    props: ['content'],
    data() {
        return {
        };
    },
    mounted() {
        const myElement = this.$refs.myElement;

        this.tooltip = tippy(myElement, {
            content: this.content,
            // trigger: 'manual',
            maxWidth: 250,
        });

        // Show the tooltip manually
        // this.tooltip.show();
    },
    beforeUnmount() {
        this.tooltip.destroy();
    }
};
</script>

<style>
.tippy-box {
    background-color: #808CFA;
    font-size: 12px;
}
.tippy-arrow {
    color: #808CFA;
}
</style>





