<template>
  <Layout>
    <Sheet>
        <SheetTitle>{{ $t('Cancel subscription') }}</SheetTitle>

        <template v-if="subscription.status === 'cancelled'">
            <form @submit.prevent="form.post(this.route('2.subscriptions.cancel', {subscription: subscription}))">
                <p class="mb-4 text-center">{{ $t('This subscription is cancelled ends {endsAt}.', { endsAt: date(subscription.ends_at) }) }}</p>
                
                <AppInput :label="$t('Change end date')" v-model="form.ends_at" class="mb-4" />
                
                <AppButton :loading="form.processing" type="submit" class="block rounded-b w-full px-5 py-5">{{ $t('Change') }}</AppButton>
            </form>
        </template>
        <template v-else>
            <p class="mb-4">{{ $t('This subscription will be marked as cancelled today, and it will expire {renewsAt}.', { renewsAt: date(subscription.renews_at) }) }}</p>
            <p>{{ $t('If you\'d like to ask the customer to cancel themselves, send them this link: ') }}</p>
            
            <div class="relative my-4 h-24 group">
                <textarea id="cancelUrl" class="p-3 shadow rounded w-full h-full">{{ subscription.cancel_url }}</textarea>
            </div>
            
            <SheetButton :href="`${route('2.subscriptions.cancel', {subscription: subscription, confirmed:true})}`">{{ $t('Cancel subscription') }}</SheetButton>
        </template>

    </Sheet>

  </Layout>
</template>

<script>
import Layout from "../../Shared/Layout";
import Sheet from "../../Shared/Sheet.vue";
import SheetIcon from "../../Shared/SheetIcon.vue";
import SheetTitle from "../../Shared/SheetTitle.vue";
import SheetButton from "../../Shared/SheetButton.vue";
import AppInput from "../../Shared/AppInput.vue";
import AppButton from "../../Shared/AppButton.vue";
import {useForm} from "@inertiajs/vue3";

export default {
    components: {AppButton, AppInput, SheetButton, SheetTitle, SheetIcon, Sheet, Layout},
    props: ['subscription'],
    data() {
        return {
            form: useForm({
                ends_at: this.date(this.subscription.ends_at, false, 'YYYY-MM-DD HH:mm'),
            }),
        }
    },
    methods: {
    }
}
</script>