<template>
    
    <LayoutWithoutNavigation>
        <Sheet>
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>{{ $t('Activate Octany') }}</SheetTitle>
            <form @submit.prevent="submit">
                <AppInput :label="$t('Email address')" v-model="form.email" type="email" :error="$attrs.errors.email" />
                <FormError />
                <FormButton :loading="form.processing">{{ $t('Continue') }}</FormButton>
            </form>
        </Sheet>
    </LayoutWithoutNavigation>
    
</template>
<script>
import LayoutWithoutNavigation from "../../Shared/LayoutWithoutNavigation";
import SheetTitle from "../../Shared/SheetTitle";
import Sheet from "../../Shared/Sheet";
import SheetIcon from "../../Shared/SheetIcon";
import CartIcon from "../../Shared/Icons/CartIcon";
import AppInput from "../../Shared/AppInput";
import FormButton from "../../Shared/FormButton";
import FormError from "../../Shared/FormError";

export default {
    components: {FormError, FormButton, AppInput, CartIcon, SheetIcon, Sheet, SheetTitle, LayoutWithoutNavigation},
    data() {
        return {
            form: this.$inertia.form({
                email: null
            })
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('2.users.enterEmail'), this.form)
        }
    }
}
</script>