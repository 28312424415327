<template>
    <Layout>
    
        <Sheet :back="this.route('2.payments.index')">
            <template v-slot:menu>
                <AppMenu>
                    <AppMenuItem v-if="state === 'account_creation_failed'" :href="route('2.payments.billecta.activate.create') + '?retry=1'">{{ $t('Retry create Billecta account') }}</AppMenuItem>
                    <AppMenuItem v-if="state === 'active'" method="delete" :href="route('2.payments.billecta.delete')">{{ $t('Remove Billecta') }}</AppMenuItem>
<!--                    <AppMenuItem :href="route('2.payments.fortnox.delete')">{{ $t('Disable Fortnox') }}</AppMenuItem>-->
                </AppMenu>
            </template>
            
            <SheetIcon :transparent="true"><BillectaIcon /></SheetIcon>
            <SheetTitle>{{ $t('Billecta') }}</SheetTitle>
            
            <p v-if="state === 'account_creation_failed'">
                {{ $t('Your Billecta account could not be created. Please get in touch with the support team') }}
            </p>
            <div v-else-if="state === 'active'">
                <p class="mb-6 text-center">{{ $t('Autogiro with Billecta is active') }}</p>
                <p class="text-xs text-center">Billecta Creditor ID <span class="block">{{ creditorId }}</span></p>
            </div>
            
        </Sheet>
        
    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout"
import AppInput from "../../../Shared/AppInput"
import AppCheckbox from "../../../Shared/AppCheckbox"
import FormButton from "../../../Shared/FormButton"
import { useForm } from '@inertiajs/vue3'
import FortnoxIcon from "../../../Shared/Icons/FortnoxIcon";
import AppButtonOutline from "../../../Shared/AppButtonOutline";
import Sheet from "../../../Shared/Sheet";
import SheetTitle from "../../../Shared/SheetTitle";
import SheetIcon from "../../../Shared/SheetIcon";
import AppMenu from "../../../Shared/AppMenu";
import AppMenuItem from "../../../Shared/AppMenuItem";
import FormError from "../../../Shared/FormError";
import SheetButton from "../../../Shared/SheetButton";
import BillectaIcon from "../../../Shared/Icons/BillectaIcon";

export default {
    props: ['state', 'creditorId'],
    components: {
        BillectaIcon,
        SheetButton,
        FormError,
        AppMenuItem,
        AppMenu,
        SheetIcon,
        SheetTitle,
        Sheet,
        AppButtonOutline,
        FortnoxIcon,
        FormButton,
        AppCheckbox,
        AppInput,
        Layout
    },
    data() {
        return {
            form: useForm({
            }),
        }
    },
    methods: {
    }
}
</script>