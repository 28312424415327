<template>
    <Layout>
        <OnboardingSteps :step="2"></OnboardingSteps>

        <Sheet>
            <SheetIcon><ShippingIconLarge class="text-white" /></SheetIcon>
            <SheetTitle>{{ $t('Shipping') }}</SheetTitle>

            <p class="text-blue-300 mb-6">{{ $t('When selling physical items we recommend adding a shipping rate. The name can be used to describe the carrier and delivery time.') }}</p>

            <form @submit.prevent="submit">
                <AppInput :label="$t('Name')" v-model="form.name" class="mb-1" :error="$attrs.errors.name"></AppInput>

                <div class="flex justify-between mb-6">
                    <div class="w-2/3 mr-0.5">
                        <AppInput :label="$t('Price excl. VAT')" v-model="form.price" class="mb-1" :error="$attrs.errors.price"></AppInput>
                    </div>

                    <div class="w-1/3 ml-0.5">
                        <AppSelect v-model="form.currency" :error="$attrs.errors.currency" :label="$t('Currency')">
                            <option v-for="currency in currencies" :value="currency">{{ currency.toUpperCase() }}</option>
                        </AppSelect>
                    </div>
                </div>

                <FormError />

                <FormButton :loading="form.processing">
                    {{ $t('Continue') }}
                </FormButton>
            </form>

        </Sheet>
        
<!--        <div class="text-center mt-6 text-sm">-->
<!--            <a href="" class="underline">{{ $t(`Don't add any shipping rate right now`) }}</a>-->
<!--        </div>-->

    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout"
import LinkPanel from "../../Shared/LinkPanel.vue";
import AppMenu from "../../Shared/AppMenu.vue";
import AppMenuItem from "../../Shared/AppMenuItem.vue";
import ShippingIcon from "../../Shared/Icons/ShippingIcon.vue";
import OnboardingSteps from "../../Shared/OnboardingSteps.vue";
import Sheet from "../../Shared/Sheet.vue";
import SheetTitle from "../../Shared/SheetTitle.vue";
import SheetIcon from "../../Shared/SheetIcon.vue";
import ShippingIconLarge from "../../Shared/Icons/ShippingIconLarge.vue";
import AppInput from "../../Shared/AppInput.vue";
import AppSelect from "../../Shared/AppSelect.vue";
import FormError from "../../Shared/FormError.vue";
import FormButton from "../../Shared/FormButton.vue";

export default {
    props: ['currencies'],
    data() {
        return {
            form: this.$inertia.form({
                name: '',
                price: '',
                currency: 'sek',
            })
        }
    },
    components: {
        FormButton,
        FormError,
        AppSelect,
        AppInput,
        ShippingIconLarge,
        SheetIcon, SheetTitle, Sheet, OnboardingSteps, ShippingIcon, AppMenuItem, AppMenu, LinkPanel, Layout
    },
    methods: {
        submit() {
            this.$inertia.post(this.route('2.shipping.onboarding'), this.form)
        }
    }
}
</script>