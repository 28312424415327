<template>
    <div>
        <div v-for="option in options" :key="option.value" class="bg-white px-5 py-3.5 rounded shadow mb-1 min-h-[64px] flex items-center ">
            <AppRadio
                :id="option.value"
                :value="option.value"
                :label="option.label"
                v-model="selectedValue"
                @update:modelValue="handleChange"
            />
        </div>
    </div>
</template>

<script>
import AppRadio from "./AppRadio.vue";

export default {
    components: {
        AppRadio
    },
    props: {
        options: {
            type: Array,
            required: true
        },
        modelValue: [String, Number, Boolean, Object]
    },
    data() {
        return {
            selectedValue: this.modelValue, // Initial value from prop
        }
    },
    watch: {
        modelValue(newVal) {
            // Update selectedValue when the prop changes
            this.selectedValue = newVal;
        },
    },
    methods: {
        handleChange(newValue) {
            this.$emit('update:modelValue', newValue);
            // Update selectedValue when the user makes a selection
            this.selectedValue = newValue;
        }
    },
}
</script>
