<template>

    <Layout>
        <OnboardingSteps :step="3"></OnboardingSteps>
        
        <Sheet>
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>{{ $t('Activate payments') }}</SheetTitle>
            
<!--            <div class="bg-white shadow px-3 py-5 flex justify-between items-center mb-2">-->
<!--                <div class="flex items-center">-->
<!--                    <div><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 6a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H4Zm16 2H4v2h16V8Z" fill="#808CFA"/></svg></div>-->
<!--                    <div class="ml-3">{{ $t('Pay with card') }}</div>-->
<!--                </div>-->
<!--                <div class="text-right">-->
<!--                    <svg width="93" height="27" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M71.302 23.02a12.783 12.783 0 0 1-8.381 3.122C55.785 26.142 50 20.29 50 13.07 50 5.853 55.785 0 62.921 0c3.199 0 6.125 1.175 8.381 3.122A12.783 12.783 0 0 1 79.683 0c7.136 0 12.92 5.852 12.92 13.07 0 7.22-5.784 13.072-12.92 13.072a12.783 12.783 0 0 1-8.38-3.123h-.001Z" fill="#ED0006"/><path fill-rule="evenodd" clip-rule="evenodd" d="M71.302 23.02a13.104 13.104 0 0 0 4.54-9.95c0-3.983-1.762-7.55-4.54-9.948A12.786 12.786 0 0 1 79.683 0c7.136 0 12.92 5.852 12.92 13.07 0 7.22-5.784 13.072-12.92 13.072a12.783 12.783 0 0 1-8.38-3.123h-.001Z" fill="#F9A000"/><path fill-rule="evenodd" clip-rule="evenodd" d="M71.302 23.02a13.104 13.104 0 0 0 4.54-9.95c0-3.983-1.762-7.55-4.54-9.947a13.104 13.104 0 0 0-4.54 9.948c0 3.983 1.762 7.55 4.54 9.948Z" fill="#FF5E00"/><path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 18.906H6.212L3.746 9.597c-.118-.428-.366-.807-.732-.985A10.783 10.783 0 0 0 0 7.626v-.358h5.298a1.39 1.39 0 0 1 .904.33c.252.213.417.51.466.834l1.28 6.715 3.287-7.879h3.198L9.5 18.906Zm6.76 0h-3.105l2.557-11.638h3.106L16.26 18.906Zm6.576-8.414c.091-.627.64-.985 1.28-.985 1.005-.09 2.1.09 3.014.537l.549-2.506A7.937 7.937 0 0 0 24.846 7c-3.014 0-5.208 1.612-5.208 3.85 0 1.702 1.554 2.595 2.65 3.133 1.187.537 1.644.896 1.553 1.432 0 .806-.914 1.164-1.826 1.164-1.097 0-2.194-.268-3.198-.717l-.548 2.507c1.096.447 2.283.628 3.38.628 3.38.088 5.48-1.523 5.48-3.94 0-3.044-4.293-3.222-4.293-4.565ZM38 18.906 35.534 7.268h-2.649c-.548 0-1.096.359-1.28.896L27.04 18.906h3.197l.638-1.7h3.929l.365 1.7H38Zm-4.658-8.504.912 4.386h-2.557l1.645-4.386Z" fill="#172B85"/></svg>-->
<!--                </div>-->
<!--            </div>-->

            <h2 class="text-lg font-medium text-blue-400">{{ $t('Pay with card') }}</h2>
            <p class="mb-6">{{ $t('We recommend to start accepting cards first to get started instantly') }}</p>
            
            <h2 class="text-lg font-medium text-blue-400">{{ $t('Direct Debit, Card, Invoice or Swish') }}</h2>
            <p>{{ $t('You might accept multiple payment methods to make allow your customer to however they want. You can add more methods later on.') }}</p>

            <SheetButton :href="route('2.payments.stripe.init')">{{ $t('Activate card payments') }}</SheetButton>
        </Sheet>

        <div class="text-center text-blue-200 underline mt-8">
            <a :href="route('2.accounts.connect')">{{ $t('Use another payment method') }}</a>
        </div>

    </Layout>

</template>
<script>
import Layout from "../../Shared/Layout";
import Sheet from "../../Shared/Sheet";
import SheetIcon from "../../Shared/SheetIcon";
import CartIcon from "../../Shared/Icons/CartIcon";
import SheetTitle from "../../Shared/SheetTitle";
import SheetButtonSplit from "../../Shared/SheetButtonSplit";
import OnboardingSteps from "../../Shared/OnboardingSteps";
import FormButton from "../../Shared/FormButton.vue";
import SheetButton from "../../Shared/SheetButton.vue";

export default {
    components: {
        SheetButton,
        FormButton, OnboardingSteps, SheetButtonSplit, SheetTitle, CartIcon, SheetIcon, Sheet, Layout}
}
</script>