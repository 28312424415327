<template>
    <LayoutWithoutNavigation class-list="pt-0">
        <template #top>
            <LayoutHeaderSnowfireIntegration
                :hide-search="true"
                :hide-hub="true"
                v-if="$page.props.integration && usingSnowfireIntegration()"
            >
                <template #breadcrumbs>
                    <a :href="route('2.close', { reason: 'abort' })">{{ $t('Back') }}</a>
                </template>
            </LayoutHeaderSnowfireIntegration>
        </template>
        
        <ProductKinds @select-product="selectProduct" :locale="locale"></ProductKinds>
        
        <AppModal :open="showProductNameModal" @confirm="createProduct" @cancel="cancelModal">
            <AppInput v-model="form.name" :label="$t('Product name')" @keydown.enter="createProduct"></AppInput>
            <template #confirm>{{ $t('Create product') }}</template>
        </AppModal>
    </LayoutWithoutNavigation>
</template>
<script>
import LayoutWithoutNavigation from "../../Shared/LayoutWithoutNavigation";
import LayoutHeaderSnowfireIntegration from "../../Shared/LayoutHeaderSnowfireIntegration.vue";
import ProductKinds from "../../Shared/ProductKinds.vue";
import AppModal from "../../Shared/AppModal.vue";
import AppInput from "../../Shared/AppInput.vue";

export default {
    props: ['continueUrl', 'success', 'onTrial', 'onboardingId', 'type'],
    components: {AppInput, AppModal, ProductKinds, LayoutHeaderSnowfireIntegration, LayoutWithoutNavigation},
    data() {
        return {
            showProductNameModal: !!this.type,
            locale: this.$attrs.locale,
            form: {
                name: '',
                type: this.type,
            }
        }
    },
    methods: {
        selectProduct(type) {
            this.form.type = type
            
            this.showProductNameModal = true
        },
        createProduct() {
            if (! this.form.name || ! this.form.type) {
                return
            }
            
            this.$inertia.post(this.route('2.products.store'), {
                name: this.form.name,
                type: this.form.type,
            })
        },
        cancelModal() {
            this.showProductNameModal = false
            
            console.log('cancel')
        },
        text(en, sv) {
            return this.locale === 'sv' ? sv : en
        },
        usingSnowfireIntegration() {
            if (this.$page.props.integration.identifier === 'snowfire') {
                return true;
            }
            
            if (this.$page.props.integration.identifier === 'snowfire-staging') {
                return true;
            }
            
            return false;
        }
    }
}
</script>