<template>
    <div
        v-if="visible"
        class="bg-blue-300 text-white p-3 text-center font-medium"
        :class="{
            'bg-blue-300': severity === 'warning',
            'bg-red-400': severity === 'error',
        }"
        v-html="$page.props.warning.message"
    />
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            message: '',
            severity: '',
        }
    },
    created() {
        this.loadWarning()
    },
    watch: {
        '$page.props.warning': {
            handler() {
                this.loadWarning()
            },
            deep: true,
        },
    },
    methods: {
        loadWarning() {
            // Never show warnings during onboarding
            if (this.$page.props.onboarding) {
                this.visible = false
                return
            }

            if (this.$page.props.warning && this.$page.props.warning.message) {
                this.message = this.$page.props.warning.message
                this.severity = this.$page.props.warning.severity
                this.visible = true
            } else {
                this.visible = false
            }
        }
    }
}
</script>