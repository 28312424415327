<template>
    <LayoutWithoutNavigation>
        <Sheet>
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>{{ $t('Choose account') }}</SheetTitle>
    
            <div class="space-y-1">
                <LinkPanel :href="route('2.auth.account', { account: account.id })" v-for="account in accounts" size="md">
                    <span class="block w-1/2">
                        <span class="block font-semibold">{{ account.name }}</span>
                        <span class="block text-sm">{{ account.vat_number }}</span>
                    </span>
                </LinkPanel>
            </div>
        </Sheet>
    </LayoutWithoutNavigation>
</template>
<script>
import LayoutWithoutNavigation from "../../Shared/LayoutWithoutNavigation";
import Sheet from "../../Shared/Sheet";
import SheetIcon from "../../Shared/SheetIcon";
import SheetTitle from "../../Shared/SheetTitle";
import CartIcon from "../../Shared/Icons/CartIcon";
import LinkPanel from "../../Shared/LinkPanel";

export default {
    props: ['accounts'],
    components: {LinkPanel, CartIcon, SheetTitle, SheetIcon, Sheet, LayoutWithoutNavigation},
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>