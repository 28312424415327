<template>
    <ConnectSnowfire v-if="$page.props.integration && $page.props.integration.identifier === 'snowfire'"></ConnectSnowfire>
    <template v-else>
    
        <Layout>
            <OnboardingSteps :step="4"></OnboardingSteps>
            <Sheet>
                <SheetIcon><CartIcon /></SheetIcon>
                <SheetTitle>{{ $t('Add Octany to your website') }}</SheetTitle>
                
                <template v-if="isDonation">
                    <p>{{ $t("You are ready to add Octany to your website to accept donations. Grab the code and add it to your website. That's it!") }}</p>
                </template>
                <template>
                    <p>{{ $t("You are ready to add Octany to your website to accept payments. Grab the code and add it to your website. That's it!") }}</p>
                </template>
    
                <SheetButton :href="route(`2.accounts.connect`, { continue: 'true' })">{{ $t('Show code') }}</SheetButton>
            </Sheet>
        </Layout>
        
    </template>
</template>

<script>
import ConnectSnowfire from "./ConnectSnowfire";
import OnboardingSteps from "../../Shared/OnboardingSteps";
import SheetTitle from "../../Shared/SheetTitle";
import Sheet from "../../Shared/Sheet";
import SheetIcon from "../../Shared/SheetIcon";
import CartIcon from "../../Shared/Icons/CartIcon";
import Layout from "../../Shared/Layout";
import SheetButton from "../../Shared/SheetButton";

export default {
    props: ['isDonation'],
    components: {SheetButton, Layout, CartIcon, SheetIcon, Sheet, SheetTitle, OnboardingSteps, ConnectSnowfire}
}
</script>