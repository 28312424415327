<template>
    <LayoutWithoutNavigation>
        
        <OnboardingSteps :step="1"></OnboardingSteps>
        
        <Sheet>
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>{{ $t('Activate Octany') }}</SheetTitle>
            <form @submit.prevent="submit">
                <AppInput :label="$t('Company/Organization')" v-model="form.company" :error="$attrs.errors.company" class="mb-1" />
                <AppInput :label="$t('VAT Number')" v-model="form.vat_number" :error="$attrs.errors.vat_number" class="mb-1" />

                <div class="mb-1">
                    <AppInput :label="$t('Address')" v-model="form.location.line1"></AppInput>
                </div>
                <div class="mb-1">
                    <div class="flex space-x-1">
                        <div class="w-1/3"><AppInput :label="$t('Postal code')" v-model="form.location.zip"></AppInput></div>
                        <div class="w-2/3"><AppInput :label="$t('City/Town')" v-model="form.location.city"></AppInput></div>
                    </div>
                </div>
                <div>
                    <AppSelect v-model="form.country" :error="form.errors.country">
                        <option v-for="(country, iso) in countries" :value="iso">{{ country.name }}</option>
                    </AppSelect>
                </div>
    
                <AppCheckboxGroup class="mb-1 mt-16">
                    <AppCheckbox v-model="form.terms" :error="$attrs.errors.terms">
                        <span v-if="this.$page.props.locale === 'sv'" class="block pr-12">
                            Jag har läst och godkänner Octanys <a :href="termsUrl" target="_blank" class='underline'>villkor & integritetspolicy</a>
                        </span>
                        <span v-else class="block pr-12">
                            I have read and agree to the Octany <a :href="termsUrl" target='_blank' class='underline'>Terms & Privacy Policy</a>
                        </span>
                    </AppCheckbox>
                </AppCheckboxGroup>
    
                <AppCheckboxGroup>
                    <AppCheckbox v-model="form.email_communication">{{ $t('accept_email') }}</AppCheckbox>
                </AppCheckboxGroup>
                
                <FormError />
                <FormButton :loading="form.processing">{{ $t('Continue') }}</FormButton>
            </form>
        </Sheet>
    </LayoutWithoutNavigation>
</template>

<script>
import LayoutWithoutNavigation from "../../Shared/LayoutWithoutNavigation";
import Sheet from "../../Shared/Sheet";
import SheetTitle from "../../Shared/SheetTitle";
import SheetIcon from "../../Shared/SheetIcon";
import CartIcon from "../../Shared/Icons/CartIcon";
import AppInput from "../../Shared/AppInput";
import FormButton from "../../Shared/FormButton";
import FormError from "../../Shared/FormError";
import AppCheckbox from "../../Shared/AppCheckbox";
import AppCheckboxGroup from "../../Shared/AppCheckboxGroup";
import AppSelect from "../../Shared/AppSelect";
import AppTextArea from "../../Shared/AppTextArea";
import OnboardingSteps from "../../Shared/OnboardingSteps";

export default {
    props: ['termsUrl', 'countries', 'formUrl'],
    components: {
        OnboardingSteps,
        AppTextArea,
        AppSelect,
        AppCheckboxGroup,
        AppCheckbox, FormError, FormButton, AppInput, CartIcon, SheetIcon, SheetTitle, Sheet, LayoutWithoutNavigation},
    data() {
        return {
            form: this.$inertia.form({
                name: null,
                password: null,
                company: null,
                vat_number: null,
                country: 'SE',
                location: {
                    line1: '',
                    zip: '',
                    city: '',
                },
                terms: false,
                email_communication: false,
            })
        }
    },
    methods: {
        submit() {
            this.form.post(this.formUrl)
        }
    }
}
</script>