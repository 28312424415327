<template>
    <div :class="{ 'cursor-wait' : isUploading }" @drop.prevent="handleDrop" ref="dropzone">
        <label :class="{ 'cursor-pointer' : !isUploading, 'pointer-events-none': isUploading }" tabindex="0" @keydown.enter="$refs.fileInput.click()" @keydown.space="$refs.fileInput.click()">
            <span class="block relative">
                <span :class="{ 'opacity-50' : isUploading }">
                    <slot></slot>
                </span>
                
                <span v-if="totalProgress > 0 && totalProgress < 100" class="absolute bottom-0 left-0 w-full rounded-bl rounded-br h-1">
                    <span class="block bg-lilac-300 h-1 rounded-bl transition-all" :style="{ width: `${totalProgress}%` }"></span>
                </span>
            </span>
            
            <input
                ref="fileInput"
                type="file"
                @change="startUpload"
                class="hidden"
                :accept="accept instanceof Array ? accept.join(',') : accept"
                :multiple="multiple ? 'multiple' : ''"
            />
        </label>
    </div>
</template>

<script>
    import axios from "axios";
    import EXIF from 'exif-js';
    
    export default {
        props: {
            multiple: {
                type: Boolean,
                default: false
            },
            url: {
                type: String,
                required: true
            },
            maxFileSizeMb: {
                type: Number,
                default: 5,
            },
            allowedTypes: {
                type: Array,
                default: () => [],
            },
            accept: {
                type: null,
                default: '*',
            },
            visibility: {
                type: String,
                default: 'private',
            },
        },
        data() {
            return {
                files: [],
                progress: [],
                totalProgress: 0,
                isUploading: false,
                isDragging: false,
            }
        },
        mounted() {
            this.$refs.dropzone.addEventListener("dragenter", event => {
                event.preventDefault()
                this.isDragging = true

                this.$emit('drag-enter')
            })

            this.$refs.dropzone.addEventListener("dragleave", event => {
                event.preventDefault()
                this.isDragging = false

                this.$emit('drag-leave')
            })

            this.$refs.dropzone.addEventListener("dragover", event => {
                event.preventDefault()
                this.isDragging = true
            })
        },
        methods: {
            isValid(files) {
                if (files.length === 0) {
                    return false;
                }
                
                for (const file of files) {
                    if (file.size > this.maxFileSizeMb * 1024 * 1024) {
                        alert(this.$t('Sorry, that file is too big. The maximum file size is {size}', { size: `${this.maxFileSizeMb}MB` }))
                        
                        return false;
                    }
                    
                    if (this.allowedTypes.length && !this.allowedTypes.includes(file.type)) {
                        alert(this.$t('Sorry, we only accept {types} images.', { types: this.allowedTypes.join(', ') }))
                        
                        return false
                    }
                }
                
                return true
            },
            selectFiles() {
                this.$refs.fileInput.click()
            },
            startUpload() {
                this.files = Array.from(this.$refs.fileInput.files)

                this.upload(this.files)
            },
            async upload(files) {
                if (! this.isValid(files)) {
                    return
                }

                this.$emit('upload-start', () => {
                    return this.multiple ? files : files[0]
                })
                
                this.isUploading = true
                
                files.forEach((file, index) => {
                    this.progress[index] = 0
                })
                
                const orientations = await Promise.all(files.map(file => {
                    if (file.type.startsWith('image/')) {
                        return new Promise(resolve => {
                            this.getImageOrientation(file, resolve)
                        });
                    } else {
                        return null;
                    }
                }));
                
                const responses = await Promise.all(files.map((file, index) =>
                    Vapor.store(file, {
                        visibility: this.visibility,
                        progress: progress => {
                            this.progress[index] = progress
                            this.calculateProgress()
                        }
                    }).then(response => axios.post(this.url, {
                        uuid: response.uuid,
                        key: response.key,
                        bucket: response.bucket,
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        orientation: orientations[index],
                    }))
                ));
                
                this.$emit('upload-complete', this.multiple ? responses : responses[0])
                this.isUploading = false
            },
            calculateProgress() {
                // Calculate the total progress in percent of all files
                this.totalProgress = Math.round((this.progress.reduce((total, progress) => total + progress, 0) / this.files.length) * 100)
            },
            handleDrop(event) {
                event.preventDefault()

                this.isDragging = false
                const files = event.dataTransfer.files

                this.upload(Array.from(files))
            },
            getImageOrientation(file, callback) {
                EXIF.getData(file, function() {
                    const orientation = EXIF.getTag(this, 'Orientation');
                    callback(orientation || 1)
                })
            }
        }
    }
</script>

<style>
.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
}
.dropzone.dragging {
    background-color: #f0f0f0;
}
</style>