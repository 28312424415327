<template>
    <div>
        <InertiaLink :href="href" class="flex items-center transition hover:-translate-x-0.5">
            <span class="block h-6 w-6 border border-lilac-300 flex items-center justify-center rounded-full text-lilac-300">
                <ArrowLeftIcon class="absolute" />
            </span>
            <span class="block ml-2 text-lilac-300">{{ $t('Back') }}</span>
        </InertiaLink>
    </div>
</template>

<script>
import ArrowLeftIcon from "./Icons/ArrowLeftIcon";

export default {
    components: {ArrowLeftIcon},
    props: {
        href: null,
    },
}
</script>