<template>
    <TableRow
        class="min-h-[96px] flex items-center w-full"
        :class="{ 'pointer-events-none': isDragging }"
        href="#"
    >
        <TableColumn class="w-3/6">
            <div v-if="field.label.length < 55" class="font-semibold w-full text-lg text-blue-400">{{ field.label }}</div>
            <div v-else class="font-semibold w-full text-lg text-blue-400">{{ field.label.substring(0, 40) }}...</div>
            <div class="text-sm text-blue-300">{{ field.type_pretty }}</div>
        </TableColumn>
        <TableColumn class="w-2/6">
            <div v-if="field.products.length > 0" class="text-sm text-blue-300">{{ $t('Attached to:') }}</div>
            {{ formatProductNames(field.products) }}
        </TableColumn>
        <TableColumn class="w-1/6 flex justify-end items-center">
            <AppMenu :fixed-height="true">
                <AppMenuItem :href="route('2.custom-fields.edit', { field })">{{ $t('Edit') }}</AppMenuItem>
                <AppMenuItem :href="route('2.custom-fields.delete', { field })">{{ $t('Remove') }}</AppMenuItem>
            </AppMenu>
        </TableColumn>
    </TableRow>
</template>

<script>
import TableRow from "../../Shared/TableRow.vue";
import TableColumn from "../../Shared/TableColumn.vue";
import AppMenu from "../../Shared/AppMenu.vue";
import AppMenuItem from "../../Shared/AppMenuItem.vue";

export default {
    components: {AppMenuItem, AppMenu, TableColumn, TableRow},
    props: {
        field: {
            type: Object,
            required: true,
        },
        isDragging: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        formatProductNames(products) {
            if (products.length === 0) {
                return '';
            } else if (products.length === 1) {
                return products[0].name
            } else {
                const lastProductName = products[products.length - 1].name
                const otherProductNames = products.slice(0, -1).map(product => product.name).join(', ')

                return this.$t('{products} and {product}', {
                    products: otherProductNames,
                    product: lastProductName,
                })
            }
        },
    }
}
</script>