<template>
    <Layout>
        <template v-slot:default>

            <div v-if="subscriptions.length > 0" class="flex items-center justify-between mb-2">
                <div>
                    <AppMenu direction="left">
                        <template v-slot:icon><ArrowDownIcon /></template>
                        <template v-slot:label>{{ this.current }}</template>
                        
                        <AppMenuItem v-for="(label, status) in this.statuses" :href="route('2.subscriptions.index', { status: status })">{{ label }}</AppMenuItem>
                        <AppMenuItem v-if="hasImports"  :href="route('2.subscriptions.index', { imported: 'true' })">{{ $t('Imported subscribers') }}</AppMenuItem>
                    </AppMenu>
                </div>
                <div class="flex items-center">
                    <InertiaLink :href="exportUrl()" class="flex items-center py-3 px-5 rounded-full transition hover:bg-blue-100">
                        <ExportIcon />
                        <span class="block ml-3">
                            {{ $t('Export') }}
                        </span>
                    </InertiaLink>
                </div>
            </div>
            
            <TableRow
                :href="`${route('2.subscriptions.show', { subscription: subscription.id  })}`"
                v-for="subscription in subscriptions"
            >
                <TableColumn class="sm:w-1/3">
                    <SubscriptionStatus :subscription="subscription" class="mb-0.5"></SubscriptionStatus>
                    <span class="block text-blue-400 font-semibold text-lg ">
                        <template v-if="subscription.billing_method.company">{{ subscription.billing_method.company.name }}</template>
                        <template v-else>{{ subscription.billing_method.person.name }}</template>
                    </span>
                    <span class="block text-blue-300 text-base">{{ subscription.product.name }}</span>
                </TableColumn>
                <TableColumn class="sm:w-1/3 pb-0.5">
                    <Reference :subscription="subscription" class="block text-blue-400 font-medium mb-0.5"></Reference>
                    <span class="block text-blue-300"><SubscriptionRenews :subscription="subscription"></SubscriptionRenews></span>
                </TableColumn>
                <TableColumn class="sm:w-1/3 pt-0 sm:text-right">
                    <span class="sm:text-lg font-semibold text-blue-300">{{ centsToMoney(subscription.price, subscription.currency) }}</span>
                    <span class="font-medium text-blue-300">/{{ subscription.product.interval_i18n_short }}</span>
                </TableColumn>
            </TableRow>
            
            <div class="mt-6">
                <Pagination :links="links"></Pagination>
            </div>

            <template v-if="subscriptions.length === 0">
                <BlankSlate name="subscriptions" />
            </template>
        </template>
    </Layout>
</template>

<script>
import Layout from '../../Shared/Layout';
import Breadcrumbs from "../../Shared/Breadcrumbs";
import Breadcrumb from "../../Shared/Breadcrumb";
import TableHeader from "../../Shared/TableHeader";
import TableHeaderColumn from "../../Shared/TableHeaderColumn";
import TableRow from "../../Shared/TableRow";
import TableColumn from "../../Shared/TableColumn";
import OrderBillingMethod from "../../Shared/OrderBillingMethod";
import AppMenu from "../../Shared/AppMenu";
import AppMenuItem from "../../Shared/AppMenuItem";
import Pagination from "../../Shared/Pagination";
import Reference from "../../Shared/Reference";
import SubscriptionStatus from "../../Shared/SubscriptionStatus";
import SubscriptionRenews from "../../Shared/SubscriptionRenews";
import ArrowDownIcon from "../../Shared/Icons/ArrowDownIcon";
import ExportIcon from "../../Shared/Icons/ExportIcon";
import BlankSlate from "../../Shared/BlankSlate.vue";

export default {
    components: {
        BlankSlate,
        ExportIcon,
        ArrowDownIcon,
        SubscriptionRenews,
        SubscriptionStatus,
        Reference,
        Pagination,
        AppMenuItem,
        AppMenu,
        OrderBillingMethod, TableColumn, TableRow, TableHeaderColumn, TableHeader, Breadcrumb, Breadcrumbs, Layout},
    props: ['subscriptions', 'links', 'filters', 'statuses', 'current', 'queryString', 'hasImports'],
    methods: {
        exportUrl() {
            if (! this.queryString) {
                return this.route('2.subscriptions.export')
            }
            
            return this.route('2.subscriptions.export') + '?' + this.queryString
        }
    }
}
</script>
