<template>
    <span
        class="flex items-center text-blue-300 font-medium text-sm tracking-wider"
        :class="{
            'mb-2': margin
        }"
    >
        <span :class="textColor">{{ prettyName() }}</span>
    </span>
</template>

<script>
export default {
    props: {
        order: Object,
        margin: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            textColor: {
                'text-green-400': this.order.state === 'paid',
                'text-yellow-600': this.order.state === 'payment_pending' || this.order.state === 'payment_pending_user',
                'text-red-400': this.order.state === 'payment_failed' || this.order.state === 'payment_cancelled',
                'text-blue-300': this.order.state === 'created',
            }
        }
    },
    methods: {
        prettyName() {
            switch (this.order.state) {
                case 'paid':
                    return this.$t('Paid')
                case 'payment_pending':
                    return this.$t('Awaiting payment')
                case 'payment_pending_user':
                    return this.$t('Awaits user payment')
                case 'payment_failed':
                    return this.$t('Failed')
                case 'payment_cancelled':
                    return this.$t('Payment cancelled')
                case 'created':
                    return this.$t('Created')
                case 'confirmed':
                    return this.$t('Confirmed')
                default:
                    return this.capitalizeFirstLetter(this.order.state)
            }
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    }
}
</script>
