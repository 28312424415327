<template>
    <div class="max-w-[290px] mx-auto">
        <button class="shadow rounded-full bg-white px-10 min-h-[58px] flex items-center w-full" @click.prevent="showColorPicker = !showColorPicker">
            <span class="flex justify-between items-center w-full">
                <span class="font-medium"><slot /></span>
                <span class="relative mt-1">
                    <span>
                        <span class="block p-0.5 rounded-full shadow relative">
                            <span class="block rounded-full w-5 h-5 border-2" style="border-color: #F5F5FF" :style="`background-color: ${this.color}`"></span>
                        </span>
                    </span>
                    <span @click.prevent.stop v-if="showColorPicker" class="absolute top-0 left-0 mb-4 flex justify-end z-50" v-click-outside="onClickOutsideColorPicker">
                        <Chrome v-model="this.pickedColor" />
                    </span>
                </span>
            </span>
        </button>
    </div>
</template>

<script>
import Panel from "./Panel";
import { Chrome } from '@ckpack/vue-color'
import vClickOutside from 'click-outside-vue3'

export default {
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        modelValue: '',
    },
    components: {
        Panel,
        Chrome,
    },
    data() {
        return {
            color: this.modelValue,
            pickedColor: this.modelValue,
            showColorPicker: false,
            timeout: null,
        }
    },
    created() {
        if (! this.color) {
            this.color = '#000000'
        }
    },
    watch: {
        pickedColor: {
            handler() {
                this.color = this.pickedColor.hex8
                this.$emit('update:modelValue', this.color)

                // Debounce workaround
                // @see https://github.com/xiaokaike/vue-color/issues/111
                clearTimeout(this.timeout)
                this.timeout = setTimeout(() => {
                    this.$emit('change', this.color)
                }, 500)
            },
            deep: true,
        },
    },
    methods: {
        onClickOutsideColorPicker() {
            this.showColorPicker = false
        }
    }
}
</script>

<style>
.vc-chrome {
    box-shadow: 0 0 10px 1px rgba(128, 140, 250, 0.15);
}
</style>