<template></template>

<script>
export default {
    props: {
        reason: {
            type: String,
            default: null
        }
    },
    created() {
        window.close()

        if (this.$page.props.returnUrl) {
            let url = new URL(this.$page.props.returnUrl);
            let params = url.searchParams;

            if (this.reason) {
                params.set('reason', this.reason);
            }

            window.location = url.toString()
        }
    }
}
</script>
