<template>
    <div class="max-w-3xl mx-auto">
        <div class="mt-6">
            
            <div v-for="order in orders" class="mb-1">
                <inertia-link
                    :href="route('2.orders.show', { order: order.view.id })"
                    class="
                        block p-8 bg-white rounded shadow
                        border-l-2 border-white
                        hover:bg-lilac-100 hover:border-lilac-300 hover:rounded-none hover:rounded-r
                    ">
                    <span class="flex justify-between items-center">
                        <span class="block">
                            <OrderStatus :order="order"></OrderStatus>
                            <span class="block text-sm text-blue-400 font-bold tracking-wider">
                                {{ $t('Order {nr}', { nr: order.id }) }}
                                <span class="font-normal">({{ order.view.description }})</span>
                            </span>
                            <span class="block text-sm text-blue-300"><Reference :order="order"></Reference></span>
                            <span class="block text-sm text-blue-300">
                                {{ date(order.created_at) }}
                                <OrderBillingMethod :order="order"></OrderBillingMethod>
                            </span>
                        </span>
                        <span class="block text-sm text-blue-400 font-bold tracking-wider mr-4">{{ centsToMoney(order.total, order.currency) }}</span>
                    </span>
                </inertia-link>
            </div>
        
        </div>
    </div>
</template>

<script>
import Reference from "./Reference";
import AppMenu from "./AppMenu";
import AppMenuItem from "./AppMenuItem";
import SubscriptionIcon from "./Icons/SubscriptionIcon";
import SubscriptionStatus from "./SubscriptionStatus";
import OrderStatus from "./OrderStatus";
import OrderBillingMethod from "./OrderBillingMethod";

export default {
    components: {
        OrderBillingMethod,
        OrderStatus,
        SubscriptionStatus,
        SubscriptionIcon, AppMenuItem, AppMenu, Reference
    },
    props: ['orders']
}
</script>