<template>
    
    <!-- During image upload -->
    <div v-if="loading" :class="classes">
        <div class="flex items-center justify-center w-full h-full">
            <AppSpinner color="#fff"></AppSpinner>
        </div>
    </div>
    
    <!-- When we don't have a product image -->
<!--    <div v-else-if="!product.image.src" :class="classes">-->
<!--        <div class="flex items-center justify-center w-full h-full text-white">-->
<!--                <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none"><path fill-rule="evenodd" d="M16.675 17.843c-2.375-2.129-5.969-2.302-8.46-.262l-5.132 4.202c-2.76 2.259-3.123 6.376-.812 9.196 2.31 2.82 6.421 3.274 9.18 1.015l5.132-4.202c2.425-1.985 3-5.405 1.535-8.119l1.4-1.147c2.374 1.97 5.843 2.08 8.267.095l5.132-4.201c2.76-2.26 3.123-6.377.812-9.197-2.31-2.82-6.421-3.274-9.18-1.015L19.417 8.41c-2.492 2.04-3.03 5.595-1.409 8.342l-1.333 1.091ZM9.95 19.698l-5.131 4.201c-1.616 1.323-1.829 3.733-.476 5.384 1.353 1.65 3.76 1.917 5.374.594l5.132-4.201c1.273-1.042 1.675-2.76 1.127-4.248l-3.634 2.975a1.153 1.153 0 0 1-1.62-.18 1.152 1.152 0 0 1 .143-1.622l3.694-3.024c-1.376-.98-3.266-.979-4.609.121Zm11.713-2.927 2.484-2.033a1.152 1.152 0 0 0 .143-1.623 1.153 1.153 0 0 0-1.62-.18l-2.544 2.084c-.69-1.542-.316-3.393 1.027-4.492l5.132-4.202c1.615-1.322 4.02-1.056 5.374.594 1.352 1.651 1.14 4.061-.476 5.384l-5.131 4.201c-1.273 1.042-3.037 1.098-4.39.267Z" clip-rule="evenodd"/></svg>-->
<!--        </div>-->
<!--    </div>-->
    
    <!-- When we have a product image -->
    <img v-else
         :class="classes"
         :src="product.image.src" :width="product.image.width" :height="product.image.height" alt=""
         @click="$emit('click')"
    >
    
</template>

<script>
import AppSpinner from "./AppSpinner.vue";

export default {
    components: {AppSpinner},
    props: ['product', 'loading'],
    data() {
        return {
            classes: 'inline-block border-[6px] border-white shadow bg-lilac-300 h-24 w-24 rounded-bl-3xl rounded-tr-3xl relative',
        }
    },
}
</script>