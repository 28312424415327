<template>
    <MenuItem v-slot="{ active }">
        <div>
            <a
                v-if="external"
                v-bind="$attrs"
                :href="href"
                @click.prevent="openExternalLink"
                class="block p-4 text-sm text-blue-400 font-medium tracking-wider hover:bg-lilac-300 hover:text-yellow-300 rounded"
            ><slot></slot></a>
            <InertiaLink
                v-else-if="!external && href"
                v-bind="$attrs"
                :href="href"
                :method="method"
                class="block p-4 text-sm text-blue-400 font-medium tracking-wider hover:bg-lilac-300 hover:text-yellow-300 rounded">
                <slot></slot>
            </InertiaLink>
            <a
                v-else
                @click="$emit('click')"
                class="block p-4 text-sm text-blue-400 font-medium tracking-wider hover:bg-lilac-300 hover:text-yellow-300 rounded cursor-pointer"
            >
                <slot></slot>
            </a>
        </div>
    </MenuItem>
</template>

<script>
import { MenuItem } from '@headlessui/vue'

export default {
    inheritAttrs: false,
    components: {
        MenuItem,
    },
    props: {
        href: null,
        external: false,
        method: 'get',
    },
    data() {
        return {
        
        }
    },
    created() {
    },
    methods: {
        openExternalLink() {
            window.open(this.href, '_blank');
        }
    }
}
</script>