<template>
    <div class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50 bg-lilac-300/20 backdrop-blur-md">
        <div class="bg-white p-12 rounded-lg shadow-lg min-w-[600px] max-w-[600px] relative">
            <a href="" @click.prevent="close" class="absolute top-0 right-0 p-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none"><path fill="#5452DA" fill-rule="evenodd" d="m11.437 1.342-.862-.844L5.88 5.095 1.283.596l-.88.862 4.596 4.498-4.403 4.31.862.843 4.403-4.31 4.308 4.218.881-.862-4.309-4.217 4.696-4.596Z" clip-rule="evenodd"/></svg>
            </a>

            <h2 class="text-xl font-bold mb-4 text-center text-lilac-500 mb-6" v-html="modal.heading"></h2>

            <div class="text-center">
                <img src="/v2/images/connect-snowfire-1.png" width="250" class="inline-block mb-12">

                <div v-if="modal.paragraph_before_button" class="max-w-lg mx-auto mb-6">
                    <p class="text-sm text-center mb-3"  v-html="modal.paragraph_before_button"></p>
                </div>

                <div class="mb-3">
                    <a :href="modal.button_link" class="bg-lilac-300 text-white px-8 py-3 rounded-full ring-3 ring-lilac-200 inline-block mb-6" v-html="modal.button_text"></a>
                </div>

                <div class="max-w-sm mx-auto">
                    <p class="text-sm text-left mb-3" v-html="modal.paragraph_after_button"></p>
                </div>
            </div>


        </div>
    </div>
</template>
<script>
export default {
    props: ['modal', 'locale', 'onTrial'],
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
        close() {
            this.$emit('close')
        }
    }
}
</script>