<template>
    <Layout>
        <DevelopersHeader active="webhooks" />
        
        <SmallTitle>{{ $t('Endpoint') }}</SmallTitle>
        
        <div class="bg-white shadow p-8 rounded flex items-center mb-16">
            <div class="w-1/2 block font-semibold break-all pr-12">{{ endpoint.url }}</div>
            <div class="w-1/2 block text-sm text-blue-300 flex items-center justify-between">
                <div>
                    <span class="block font-semibold">{{ $t('Signing secret') }}</span>
                    {{ endpoint.secret }}
                </div>
                <AppMenu>
                    <AppMenuItem :href="route('2.developers.webhooks.endpoints.send-test', { endpoint })">{{ $t('Send test event') }}</AppMenuItem>
                    <AppMenuItem :href="route('2.developers.webhooks.endpoints.remove', { endpoint })">{{ $t('Remove') }}</AppMenuItem>
                </AppMenu>
            </div>
        </div>
        
        <div v-if="state.webhooks.length > 0" class="flex">
            <div :class="{
                'w-full': !webhook,
                'w-1/2': webhook,
            }">
                <SmallTitle>{{ $t('Sent webhooks') }}</SmallTitle>
                
                <TableRow :href="route('2.developers.webhooks.show', { endpoint, webhook })" v-for="webhook in state.webhooks">
                    <TableColumn class="w-1/4 h-[96px] flex items-center">
                        <span>
                            <span class="font-medium text-xs tracking-wider uppercase tracking-widest" :class="{
                                'text-green-400': webhook.status === 'success',
                                'text-yellow-600': webhook.status === 'pending',
                                'text-red-400': webhook.status === 'error',
                            }">
                                {{ webhook.status }}
                            </span>
                            <span class="block">{{ webhook.name }}</span>
                        </span>
                    </TableColumn>
                    <TableColumn class="w-2/4 text-sm text-blue-300">
                        <div class="pl-12">{{ webhook.id }}</div>
                    </TableColumn>
                    <TableColumn class="w-1/4 text-right text-sm">
                        <div>{{ date(webhook.created_at) }}</div>
                    </TableColumn>
                </TableRow>
                
                <div class="mt-6">
                    <Pagination :links="links"></Pagination>
                </div>
            </div>
            <div class="w-1/2 overflow-x-auto" v-if="state.webhook">
                
                <SmallTitle class="ml-1">{{ $t('Inspect webhook') }}</SmallTitle>
                
                <div class="bg-white shadow p-8 rounded ml-1 relative">
                    
                    <div class="absolute top-0 right-0 mr-2 mt-2">
                        <AppMenu>
                            <AppMenuItem :href="route('2.developers.webhooks.resend', { endpoint, webhook })">{{ $t('Resend') }}</AppMenuItem>
                        </AppMenu>
                    </div>
                    
                    <div>
                        <span class="uppercase text-sm text-blue-200">Url</span>
                        <span class="text-sm pl-1">{{ state.webhook.url }}</span>
                    </div>
                    <div v-if="state.webhook.response_code">
                        <span class="uppercase text-sm text-blue-200">Http code</span>
                        <span class="text-sm pl-1">{{ state.webhook.response_code }}</span>
                    </div>
                    <div v-if="state.webhook.response_body">
                        <span class="uppercase text-sm text-blue-200">Http response body</span>
                        <div class="text-sm mt-2 bg-blue-100 p-4 overflow-auto max-h-[500px] max-w-[512px]">
                            <pre class="whitespace-pre-wrap break-words"><code>{{ state.webhook.response_body }}</code></pre>
                        </div>
                    </div>
                    <div class="mt-12">
                        <span class="uppercase text-sm text-blue-200">Http request body</span>
                        <div class="text-sm mt-2 bg-blue-100 p-4 overflow-auto max-h-[500px] max-w-[512px]">
                            <pre class="whitespace-pre-wrap break-words"><code>{{ state.webhook.data }}</code></pre>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </Layout>
</template>
<script>
import Layout from "../../../Shared/Layout.vue";
import DevelopersHeader from "../DevelopersHeader.vue";
import LinkPanel from "../../../Shared/LinkPanel.vue";
import SmallTitle from "../../../Shared/SmallTitle.vue";
import TableRow from "../../../Shared/TableRow.vue";
import TableColumn from "../../../Shared/TableColumn.vue";
import Pagination from "../../../Shared/Pagination.vue";
import AppMenu from "../../../Shared/AppMenu.vue";
import AppMenuItem from "../../../Shared/AppMenuItem.vue";

export default {
    props: ['webhooks', 'webhook', 'endpoint', 'links'],
    components: {
        AppMenuItem,
        AppMenu,
        Pagination,
        TableColumn,
        TableRow,
        SmallTitle,
        Layout,
        DevelopersHeader,
        LinkPanel
    },
    data() {
        return {
            interval: null,
            
            state: {
                webhook: this.webhook,
                webhooks: this.webhooks,
            }
        }
    },
    methods: {
        refreshWebhooks() {
            this.$inertia.reload({
                only: ['webhooks', 'webhook'],
                onSuccess: () => {
                    this.state.webhooks = this.webhooks
                    this.state.webhook = this.webhook
                }
            });
        }
    },
    mounted() {
        this.interval = setInterval(this.refreshWebhooks, 5000)
    },
    beforeUnmount() {
        clearInterval(this.interval)
    },
};
</script>