<template>
    <div class="text-center">
        <div class="mx-auto bg-blue-50 inline-block mb-8 rounded">
            <div class="flex justify-center items-center">
                <InertiaLink
                    v-for="item in items"
                    class="block px-5 py-2 m-1 rounded-full font-medium text-sm text-lilac-500"
                    :class="{ 'bg-lilac-50': isActive(item.route) }"
                    :key="item.route"
                    :href="item.route"
                >
                    {{ item.label }}
                </InertiaLink>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['items'],
    methods: {
        isActive(fullUrl) {
            try {
                // Create URL objects to extract paths and search params
                const itemUrl = new URL(fullUrl)
                const currentPath = this.$page.url

                // Extract just the pathname and search parts from the full URL
                const itemPathAndSearch = itemUrl.pathname + itemUrl.search

                return currentPath === itemPathAndSearch
            } catch (e) {
                console.error('Error parsing URL:', e)

                return false
            }
        },
    },
}
</script>
