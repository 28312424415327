<template>
    <LayoutWithoutNavigation>
        <Sheet>
            <SheetIcon><CartIcon /></SheetIcon>
            <SheetTitle>{{ $t('Your information') }}</SheetTitle>
            <form @submit.prevent="submit">
                <AppInput v-model="form.name" :label="$t('Your name')" :error="$attrs.errors.name" class="mb-1"></AppInput>
                <AppInput :label="$t('Password')" v-model="form.password" type="password" :error="$attrs.errors.password" />
                <FormError />
                <FormButton :loading="form.processing">{{ $t('Continue') }}</FormButton>
            </form>
        </Sheet>
    </LayoutWithoutNavigation>
</template>

<script>
import LayoutWithoutNavigation from "../../Shared/LayoutWithoutNavigation";
import Sheet from "../../Shared/Sheet";
import SheetTitle from "../../Shared/SheetTitle";
import SheetIcon from "../../Shared/SheetIcon";
import CartIcon from "../../Shared/Icons/CartIcon";
import AppInput from "../../Shared/AppInput";
import FormButton from "../../Shared/FormButton";
import FormError from "../../Shared/FormError";

export default {
    components: {FormError, FormButton, AppInput, CartIcon, SheetIcon, SheetTitle, Sheet, LayoutWithoutNavigation},
    data() {
        return {
            form: this.$inertia.form({
                name: null,
                password: null,
            })
        }
    },
    methods: {
        submit() {
            this.form.post(this.route('2.users.create.store', this.form))
        }
    }
}
</script>