<template>
    <div class="text-center">
        <div class="inline-block">
            <div
                class="rounded-full w-16 h-16 flex justify-center items-center shadow text-white"
                :class="{
                    'bg-lilac-300': !transparent,
                }"
            >
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            transparent: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>