<template>
    <Layout>
        <div class="text-center mb-8">
            <div class="text-xl font-semibold text-blue-400 mb-1">{{ $t('Import data') }}</div>
        </div>

        <div class="max-w-3xl mx-auto">
            <LinkPanel :href="route('2.import.billecta.index')" class="mb-1">
            <span class="flex items-center">
                <span class="flex items-center w-48 text-lilac-500">
                    <span class="mr-3"><BillectaIcon /></span>
                    <span>{{ $t('Direct Debit')}}</span>
                </span>
                <span class="block max-w-md">
                    {{ $t('Import Direct Debit subscriptions to your account') }}
                </span>
            </span>
            </LinkPanel>
        </div>
    </Layout>
</template>

<script>
import Layout from '../../Shared/Layout';
import LinkPanel from "../../Shared/LinkPanel.vue";
import BillectaIcon from "../../Shared/Icons/BillectaIcon.vue";

export default {
    components: {BillectaIcon, LinkPanel, Layout},
    props: {
        customers: Object
    },
}
</script>
