<template>
    <div
        :class="[
            'text-lg', 'font-medium', 'text-blue-400', 'text-center',
            {
                'pt-6': shouldApplyDefaultPadding,
                'pb-6': !$slots.description && shouldApplyDefaultPadding
            }
        ]"
    >
        <slot />
    </div>
    <div v-if="$slots.description" class="pb-6 text-sm text-center">
        <slot name="description" />
    </div>
</template>

<script>
export default {
    computed: {
        shouldApplyDefaultPadding() {
            const paddingClassRegex = /p[xytb]?-[0-9]/
            
            return !(this.$attrs.class && paddingClassRegex.test(this.$attrs.class))
        }
    }
}
</script>
