<template>
    <AppCheckboxGroup :label="label">
        <template v-for="option in options" :key="option.id">
            <div :class="{
                    'pl-8': option[parent] && option[parent] > 0
                }">
            <AppCheckbox
                :value="option[optionId]"
                :model-value="modelValue"
                @update:model-value="handleModelUpdate"
                :isArray="true"
            >
                {{ option[optionLabel] }}
            </AppCheckbox>
            </div>
        </template>
    </AppCheckboxGroup>
</template>

<script>
import AppCheckboxGroup from "./AppCheckboxGroup.vue";
import AppCheckbox from "./AppCheckbox.vue";
import { toRaw } from 'vue';

export default {
    props: {
        options: Array,
        modelValue: {
            type: Array,
            default: []
        },
        label: String,
        optionId: {
            type: String,
            default: 'id'
        },
        optionLabel: {
            type: String,
            default: 'name'
        },
        parent: Number,
    },
    components: {
        AppCheckbox,
        AppCheckboxGroup
    },
    methods: {
        isSelected(id) {
            return toRaw(this.modelValue).includes(id)
        },
        toggleSelection(id) {
            const newValue = [...this.modelValue];
            const index = newValue.indexOf(id);
            if (index === -1) {
                newValue.push(id);
            } else {
                newValue.splice(index, 1);
            }
            this.$emit('update:modelValue', newValue);
        },
        handleModelUpdate(newValue) {
            this.$emit('update:modelValue', newValue);
        },
    }
}
</script>
