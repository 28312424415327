<template>
    <div class="relative" :class="{ 'h-[48px]': fixedHeight }">
        <Menu as="div">
            <MenuButton>
                <span class="flex items-center relative z-10">
                    <slot name="icon">
                        <span class="block rounded-full transition hover:bg-blue-100 p-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 8C13.1 8 14 7.1 14 6C14 4.9 13.1 4 12 4C10.9 4 10 4.9 10 6C10 7.1 10.9 8 12 8ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10ZM12 16C10.9 16 10 16.9 10 18C10 19.1 10.9 20 12 20C13.1 20 14 19.1 14 18C14 16.9 13.1 16 12 16Z" fill="#5961A7"/>
                            </svg>
                        </span>
                    </slot>
                    <span v-if="this.$slots.label" class="block pl-2 pr-2">
                        <slot name="label"></slot>
                    </span>
                </span>
            </MenuButton>
            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <MenuItems
                    ref="menuItems"
                    v-slot="{ close }"
                    class="absolute w-56 top-0 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
                    :class="{
                        'right-0 origin-top-right': direction === 'right',
                        'left-0 origin-top-left': direction === 'left'
                    }"
                >
                    <div class="px-1 py-1">
                        <slot></slot>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'

export default {
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        Menu, MenuButton, MenuItems, MenuItem
    },
    props: {
        direction: {
            default: 'right'
        },
        tight: {
            default: false
        },
        fixedHeight: {
            default: false
        },
    },
    data() {
        return {
            isMenuOpen: false,
        }
    },
    created() {
    },
    methods: {
        closeMenu() {
            // this.$refs.menuItems.close
        },
        onClickOutside (event) {
            // this.state.expanded = false
        }
    }
}
</script>