<template>
    <Layout>

        <div class="text-center mb-8">
            <div class="text-xl font-semibold text-blue-400 mb-1">{{ $t('Shipping') }}</div>
        </div>

        <div class="max-w-3xl mx-auto mb-12">
            <div class="font-medium mb-3">{{ $t('Deliver from') }}</div>
            <div class="bg-white shadow rounded px-7 py-7 flex items-center justify-between">
                <div class="flex w-full">
                    <div class="w-1/3 flex items-center">
                        <ShippingIcon class="text-lilac-500 mr-3" />
                        <span class="text-lilac-500 font-medium">{{ location.name }}</span>
                    </div>

                    <div class="w-2/3 text-right mr-4">{{ location.string }}</div>
                </div>
                <div>
                    <AppMenu>
                        <AppMenuItem :href="route('2.locations.edit', { id: location.id })">{{ $t('Edit') }}</AppMenuItem>
                    </AppMenu>
                </div>
            </div>
        </div>

        <div class="max-w-3xl mx-auto mb-12">
            <div class="font-medium mb-3 flex justify-between">
                <div>{{ $t('Delivery zones') }}</div>
                <div><a :href="route('2.shipping.zone.create')" class="border border-blue-200 py-1 px-3 rounded text-blue-300 text-sm hover:border-blue-300 font-medium">{{ $t('New delivery zone') }}</a></div>
            </div>

            <template v-for="zone in zones">
                <div class="block bg-white shadow rounded px-7 py-7 mb-1">

                    <div class="flex items-center justify-between mb-6 ml-2 border-b border-lilac-100">
                        <div class="font-medium text-lg">
                            {{ zone.name }}

                            <div class="text-xs text-blue-300 opacity-75">
                                <span v-for="region in zone.regions">
                                    <span class="font-bold">{{ region.name }}: </span>
                                    <span>{{ formatList(region.countries) }}</span>
                                </span>
                                <div>
                                    <span v-if="zone.countries.length > 0 && zone.regions.length > 0" class="font-bold">{{ $t('Countries') }}: </span>
                                    <span>{{ formatList(zone.countries) }}</span>
                                </div>
                            </div>
                        </div>

                        <div>
                            <AppMenu>
                                <AppMenuItem :href="route('2.shipping.zone.edit', { id: zone.id })">{{ $t('Edit') }}</AppMenuItem>
                                <AppMenuItem :href="route('2.shipping.zone.delete', { id: zone.id })">{{ $t('Delete') }}</AppMenuItem>
                            </AppMenu>
                        </div>
                    </div>

                    <div v-if="zone.rates.length > 0" class="flex items-center pl-2 mb-2">
                        <div class="w-1/2 text-sm font-medium">{{ $t('Name') }}</div>
                        <div class="w-1/2 flex items-center">
                            <div class="w-1/2 text-sm font-medium">{{ $t('Condition') }}</div>
                            <div class="w-1/2 text-sm font-medium">{{ $t('Price') }}</div>
                        </div>
                    </div>

                    <template v-for="rate in zone.rates">
                        <div class="flex items-center shadow rounded-lg mb-1 pl-4 py-2 pr-2">
                            <div class="w-1/2">
                                {{ rate.name }}
                            </div>
                            <div class="w-1/2 flex items-center">
                                <div class="w-1/2">
                                    <template v-if="rate.condition">{{ rate.condition }}</template>
                                    <template v-else>&infin;</template>
                                </div>
                                <div class="w-1/2 flex justify-between items-center">
                                    <div v-if="rate.show_price_including_vat">
                                        {{ centsToMoney(rate.price * (1 + vatRate / 10000), rate.currency, 2) }}
                                        <p class="text-xs">{{ $t('With {rate}% VAT', {rate: vatRate/100}) }}</p>
                                    </div>
                                    <div v-else>{{ centsToMoney(rate.price, rate.currency, 2) }}</div>
                                    <div>
                                        <AppMenu>
                                            <AppMenuItem :href="route('2.shipping.rate.edit', { id: rate.id })">{{ $t('Edit') }}</AppMenuItem>
                                            <AppMenuItem :href="route('2.shipping.rate.delete', { id: rate.id })">{{ $t('Remove') }}</AppMenuItem>
                                        </AppMenu>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div class="mt-6 pb-12"><a :href="route('2.shipping.rate.create', { zone: zone.id })" class="border border-blue-200 py-1 px-3 rounded text-blue-300 text-sm hover:border-blue-300 font-medium">{{ $t('New shipping rate') }}</a></div>

                </div>
            </template>
        </div>

    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout"
import LinkPanel from "../../Shared/LinkPanel.vue";
import AppMenu from "../../Shared/AppMenu.vue";
import AppMenuItem from "../../Shared/AppMenuItem.vue";
import ShippingIcon from "../../Shared/Icons/ShippingIcon.vue";

export default {
    props: ['zones', 'location', 'vatRate'],
    components: {ShippingIcon, AppMenuItem, AppMenu, LinkPanel, Layout },
    computed: {
        formatList: function() {
            return function(items) {
                return items.map(item => item.name).join(', ');
            }
        },
    }
}
</script>