<template>
    <form @submit.prevent="submit">
        <div class="mb-1">
            <AppSelect :label="$t('Type')" v-model="form.type">
                <option v-for="(name, type) in types" :value="type">{{ name }}</option>
            </AppSelect>
        </div>
        <AppInput v-if="labelable" :label="$t('Label')" v-model="form.label" class="mb-1" />
        <AppCheckboxGroup class="mb-8">
            <AppCheckbox v-model="form.mandatory" :label="$t('Field is mandatory')"></AppCheckbox>
        </AppCheckboxGroup>

        <AppSelect :label="$t('Checkout flow')" v-model="form.checkout_flow">
            <option v-for="(name, type) in checkoutFlows" :value="type">{{ name }}</option>
        </AppSelect>

        <template v-if="form.checkout_flow === 'widget_v1'">
            <SheetTitle>{{ $t('Attach it to these products') }}</SheetTitle>

            <AppCheckboxGroup v-for="(product, index) in products" :key="index" class="mb-1">
                <AppCheckbox
                    v-model="form.products"
                    :value="product.id"
                    :isArray="true"
                >
                    {{ product.name }}
                </AppCheckbox>
            </AppCheckboxGroup>
        </template>

        <template v-if="method === 'update' && form.type === 'choice'">
            <CustomFieldChoices :field="field"></CustomFieldChoices>
        </template>

        <FormError />

        <SheetButton @click="submit">
            <template v-if="method === 'create'">{{ $t('Create') }}</template>
            <template v-else-if="method === 'update'">{{ $t('Update') }}</template>
        </SheetButton>
    </form>
</template>

<script>
import AppInput from "../../Shared/AppInput.vue";
import AppCheckboxGroup from "../../Shared/AppCheckboxGroup.vue";
import AppCheckbox from "../../Shared/AppCheckbox.vue";
import {useForm} from "@inertiajs/vue3";
import AppSelect from "../../Shared/AppSelect.vue";
import SheetTitle from "../../Shared/SheetTitle.vue";
import FormError from "../../Shared/FormError.vue";
import SheetButton from "../../Shared/SheetButton.vue";
import CustomFieldListItem from './CustomFieldListItem.vue'
import CustomFieldChoices from './CustomFieldChoices.vue'

export default {
    components: {
        CustomFieldChoices,
        CustomFieldListItem,
        SheetButton,
        FormError,
        SheetTitle,
        AppSelect,
        AppCheckbox,
        AppCheckboxGroup,
        AppInput
    },
    props: ['types', 'products', 'field', 'method', 'checkoutFlows'],
    created() {
        // Set default when creating a new field
        if (! this.form.checkout_flow) {
            this.form.checkout_flow = Object.keys(this.checkoutFlows)[0]
        }
    },
    data() {
        return {
            defaultCheckoutFlow: null,

            form: useForm({
                label: this.field ? this.field.label : '',
                type: this.field ? this.field.type : 'text',
                mandatory: this.field ? this.field.mandatory : false,
                products: this.field ? this.field.products.map(product => product.id) : [],
                checkout_flow: this.field ? this.field.checkout_flow : null,
            })
        }
    },
    computed: {
        labelable() {
            return !['phone', 'personal_identity_number', 'name'].includes(this.form.type)
        },
    },
    methods: {
        submit() {
            // Only widget v1 supports attached products
            // Since checkout support cart items
            if (this.form.checkout_flow !== 'widget_v1') {
                this.form.products = []
            }

            switch (this.method) {
                case 'create':
                    this.form.post(this.route('2.custom-fields.store'))
                    break

                case 'update':
                    this.form.post(this.route('2.custom-fields.update', { field: this.field }))
                    break
            }
        }
    }
}
</script>