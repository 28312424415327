<template>
    <main class="flex h-full text-blue-300">

        <!-- Sidebar -->
        <form @submit.prevent="submit">
            <div class="w-[512px] bg-blue-100 shadow flex flex-col min-h-screen">
                <div class="flex-grow flex-shrink">
                    <div class="p-4 mb-12">
                        <Back :href="route('2.appearance.index')" />
                    </div>

                    <div class="text-lg font-medium text-center mb-6 text-blue-400">
                        {{ $t('Colors') }}
                    </div>

                    <AppColor class="mb-2" v-model="form.primary" @change="previewProductImage">{{ $t('Primary Color') }}</AppColor>
                    <AppColor class="mb-16" v-model="form.primary_text">{{ $t('Primary Text Color') }}</AppColor>

                    <div class="text-lg font-medium text-center mb-6 text-blue-400">
                        {{ $t('Content colors') }}
                    </div>

                    <AppColor class="mb-2" v-model="form.universal.button">{{ $t('Button & Icon') }}</AppColor>
                    <AppColor class="mb-6" v-model="form.universal.button_text">{{ $t('Button text color') }}</AppColor>

                    <AppColor class="mb-2" v-model="form.universal.heading">{{ $t('Heading') }}</AppColor>
                    <AppColor class="mb-2" v-model="form.universal.text">{{ $t('Text') }}</AppColor>
                    <AppColor class="mb-6" v-model="form.universal.meta">{{ $t('Meta') }}</AppColor>

                    <AppColor class="mb-2" v-model="form.universal.background">{{ $t('Background') }}</AppColor>
                    <AppColor class="mb-2" v-model="form.universal.content">{{ $t('Content area') }}</AppColor>

                    <div class="text-sm text-center mt-12 text-blue-300">
                        <a @click.prevent="resetColors" href="" class="underline">{{ $t('Reset colors') }}</a>
                    </div>
                </div>

                <div class="flex-shrink-0">
                    <AppButton type="submit" class="rounded-none w-full">{{ $t('Save') }}</AppButton>
                </div>
            </div>
        </form>

        <!-- Main -->
        <div class="flex-1 bg-white">
            <div class="border-b border-blue-100">
                <div class="max-w-sm ml-auto mr-8 h-[128px] flex items-center">
                    <AppSelect v-model="previewMail" @change="updatePreview">
                        <template v-for="product in products">
                            <option v-for="mail in product.mails" :value="`${product.id}-${mail.type_without_namespace}`">{{ product.name}} > {{ mail.name }}</option>
                        </template>
                    </AppSelect>
                </div>
            </div>

            <iframe
                ref="previewFrame"
                class="border-0 resize-both overflow-auto w-full"
                :src="previewSrc"
            ></iframe>
        </div>

    </main>
</template>

<script>
import AppColor from "../../../Shared/AppColor.vue";
import AppButton from "../../../Shared/AppButton.vue";
import Back from "../../../Shared/Back.vue";
import AppSelect from "../../../Shared/AppSelect.vue";
import axios from "axios";

export default {
    props: ['colors', 'products', 'defaultColors'],
    components: {
        AppSelect,
        Back,
        AppButton,
        AppColor,
    },
    data() {
        return {
            previewMail: this.products[0] ? this.products[0].id + '-' + this.products[0].mails[0].type_without_namespace : '',
            previewSrc: null,
            form: this.$inertia.form({
                universal: this.colors.universal,
                primary: this.colors.primary,
                primary_text: this.colors.primary_text,
            }),
        }
    },
    mounted() {
        this.loadPreview()
    },
    methods: {
        updatePreview(e) {
            this.previewMail = e.target.value
            this.loadPreview()
        },
        loadPreview() {
            this.previewSrc = this.route('2.appearance.colors.preview') + '?' + 'mail=' + this.previewMail
            this.$refs.previewFrame.contentWindow.location.reload(true)

            // I can't find a work solution to trigger an event after the iframe has reloaded
            // This is a cheap workaround
            setTimeout(this.updateColors, 100)
            setTimeout(this.updateColors, 300)
            setTimeout(this.updateColors, 500)
        },
        updateColors() {
            const iframe = this.$refs.previewFrame
            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
            const iframeRoot = iframeDoc.documentElement;

            for (let key in this.form.universal) {
                iframeRoot.style.setProperty('--color-' + key, this.form.universal[key]);
            }
        },
        resetColors() {
            this.form.universal = this.defaultColors.universal
            this.form.primary = this.defaultColors.primary
            this.form.primary_text  = this.defaultColors.primary_text
        },
        async previewProductImage() {
            let response = await axios.get(this.route('2.appearance.colors.product-logo', {
                primary: this.form.primary,
                primary_text: this.form.primary_text,
                preview_mail: this.previewMail,
            }))

            const iframe = this.$refs.previewFrame
            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document
            const iframeRoot = iframeDoc.documentElement;

            const imgElement = iframeRoot.querySelector(".block-image");

            imgElement.setAttribute("src", response.data.src);
        },
        submit() {
            this.form.post(this.route('2.appearance.colors.store'))
        }
    },
    watch: {
        'form.universal': {
            handler: function (val, oldVal) {
                this.updateColors()
            },
            deep: true
        },
    },
}
</script>

<style scoped>
iframe {
    height: calc(100vh - 129px);
}
</style>