<template>
    <Layout>
        <Sheet>
            <SheetTitle>{{ $t('Edit custom field') }}</SheetTitle>
            <CustomFieldForm method="update" :field="field" :products="products" :types="types" :checkoutFlows="checkoutFlows" />
        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../Shared/Layout.vue";
import Sheet from "../../Shared/Sheet.vue";
import SheetTitle from "../../Shared/SheetTitle.vue";
import AppInput from "../../Shared/AppInput.vue";
import AppSelect from "../../Shared/AppSelect.vue";
import {useForm} from "@inertiajs/vue3";
import AppCheckboxGroup from "../../Shared/AppCheckboxGroup.vue";
import AppCheckbox from "../../Shared/AppCheckbox.vue";
import FormError from "../../Shared/FormError.vue";
import SheetButton from "../../Shared/SheetButton.vue";
import SheetIcon from "../../Shared/SheetIcon.vue";
import CheckoutIcon from "../../Shared/Icons/CheckoutIcon.vue";
import Form from "../Shipping/Rate/Form.vue";
import CustomFieldForm from "./CustomFieldForm.vue";

export default {
    components: {
        CustomFieldForm,
        Form,
        CheckoutIcon,
        SheetIcon, SheetButton, FormError, AppCheckbox, AppCheckboxGroup, AppSelect, AppInput, SheetTitle, Sheet, Layout},
        props: ['types', 'products', 'field', 'checkoutFlows'],
    data() {
        return {
            form: useForm({
                label: this.field.label,
                type: this.field.type,
                mandatory: this.field.mandatory,
                products: this.field.products.map(product => product.id),
            })
        }
    },
}
</script>