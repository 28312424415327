import { createApp, h } from 'vue'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import dayjs from 'dayjs'
import 'dayjs/locale/sv'
import isToday from 'dayjs/plugin/isToday'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { createI18n } from 'vue-i18n'
import Locales from './Locales/locales.js'

// i18n
const messages = Locales
const i18n = createI18n({
    locale: document.body.getAttribute('data-locale'),
    fallbackLocale: 'en',
    formatFallbackMessages: true,
    messages,
})

// Tippy.js for tooltips via https://github.com/KABBOUCHI/vue-tippy
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
const VueTippyOptions = {
    defaultProps: {
        placement: 'right',
    },
    animateFill: true,
    animation: 'fade',
}

// Use Vapor npm package to upload files directly to S3 without touching our servers
window.Vapor = require('laravel-vapor')

// Init
dayjs.extend(localizedFormat)
dayjs.extend(isToday)

createInertiaApp({
    resolve: name => require(`./Pages/${name}`),
    setup({ el, App, props, plugin }) {
        let mixin = {
            methods: {
                route: function (route, options = {}) {
                    if (typeof options.account === 'undefined') {
                        options.account = document.body.getAttribute('data-account')
                    }

                    if (! options.account) {
                        options.account = props.initialPage.props.accountId
                    }

                    if (route && route.startsWith('3.')) {
                        options.newAccount = options.account;
                        delete options.account;
                    }

                    return window.route(route, options);
                },
                date: function (value, relative = true, format = 'll') {
                    let locale = document.body.getAttribute('data-locale')
                    let date = dayjs(value)
    
                    if (relative && date.isToday()) {
                        return i18n.global.t('Today') + ', ' + date.locale(locale).format('LT')
                    }

                    return date.locale(locale).format(format)
                },
                dateTime: function (value, format = 'D MMM YYYY[,] HH:mm') {
                    let locale = document.body.getAttribute('data-locale')
                    let date = dayjs(value)

                    return date.locale(locale).format(format)
                },
                number: function (value) {
                    const parts = value.toString().split('.')
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

                    return parts.join('.')
                },
                capitalize: function (string) {
                    return string.charAt(0).toUpperCase() + string.slice(1)
                },
                lowercase: function (string) {
                    return string.toLowerCase()
                },
                centsToMoney: function (value, currency = null, precision = 0) {
                    let locale = 'en-US'
                    
                    switch (currency) {
                        case 'sek':
                            locale = 'sv-SE'
                            break
                    }
                    
                    // Create our number formatter.
                    let formatter = new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: currency,
    
                        minimumFractionDigits: precision,
                        maximumFractionDigits: precision, // (causes 2500.99 to be printed as $2,501)
                    });
    
                    return formatter.format(
                        precision === 0 ? Math.round(value/100) : (value/100).toFixed(2)
                    );
                },
                ucfirst(str) {
                    if (!str) return str;
                    return str.charAt(0).toUpperCase() + str.slice(1);
                },
                pretty(str) {
                    if (!str) return str

                    return this.ucfirst(str.replace(/_/g, ' ').replace(/-/g, ' '))
                }
            }
        }
        
        createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(i18n)
            .use(VueTippy, VueTippyOptions)
            .component("InertiaLink", Link)
            .mixin(mixin)
            .mount(el)
    },
})
