<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <TransitionRoot as="template" :show="isOpen">
        <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="isOpen = false">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-lilac-100 bg-opacity-75 transition-opacity" />
                </TransitionChild>
                
                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                        <div class="bg-white px-4 pt-5 pb-4 sm:p-16">
                            <div class="sm:flex sm:items-start">
                                <div class="-mt-2 mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-opacity-25 sm:mx-0 sm:h-10 sm:w-10">
                                    <span v-if="type === 'warning'">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path stroke="#F1546B" d="M11.668 3.716c.084-.156.214-.216.332-.216.118 0 .248.06.332.216L20.439 18.8a.51.51 0 0 1 0 .484c-.076.142-.199.216-.332.216H3.893c-.133 0-.256-.074-.332-.216a.51.51 0 0 1 0-.484l-.44-.237.44.237 8.107-15.084Z"/><path fill="#F1546B" d="M11.324 14.696 11.176 11h1.62l-.166 3.696h-1.306ZM11.991 17c-.293 0-.533-.08-.718-.24a.806.806 0 0 1-.273-.63.8.8 0 0 1 .273-.615c.185-.166.425-.25.718-.25.29 0 .53.084.722.25a.784.784 0 0 1 .287.616.789.789 0 0 1-.287.628c-.191.16-.432.241-.722.241Z"/></svg>
                                    </span>
                                    <span v-if="type === 'confirm'" class="text-green-400">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" class="fill-current"><path d="M18.293 5.14 9.09 15.599l-3.382-3.843a.385.385 0 0 0-.594 0l-.99 1.124a.524.524 0 0 0 0 .675l4.669 5.305c.164.187.43.187.594 0L19.877 6.94a.524.524 0 0 0 0-.676l-.99-1.124a.385.385 0 0 0-.594 0Z"/></svg>
                                    </span>
                                </div>
                                <div class="text-center sm:text-left">
                                    <DialogTitle as="h3" class="text-lg font-medium text-blue-400"><slot name="title"></slot></DialogTitle>
                                    <div :class="{ 'mt-2': $slots.title }">
                                        <p class="text-base text-blue-300 tracking-normal"><slot></slot></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-blue-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                class="w-full inline-flex justify-center rounded-full border border-transparent shadow-sm px-6 py-2 text-base font-medium text-white focus:outline-none focus:ring-1 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                :class="{
                                    'bg-red-400': type === 'warning',
                                    'focus:ring-red-400': type === 'warning',
                                    'hover:bg-red-400': type === 'warning',

                                    'bg-green-400': type === 'confirm',
                                    'focus:ring-green-400': type === 'confirm',
                                    'hover:bg-green-400': type === 'confirm',
                                }"
                                @click="confirm"
                            >
                                <slot name="confirm"></slot>
                                <template v-if="!this.$slots.confirm">OK</template>
                            </button>
                            <button
                                type="button"
                                class="mt-3 w-full inline-flex justify-center rounded-full px-4 py-2 text-base font-medium text-blue-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                                @click="cancel"
                                ref="cancelButtonRef"
                            >
                                <slot name="cancel"></slot>
                                <template v-if="!this.$slots.cancel">{{ $t('Cancel') }}</template>
                            </button>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon } from '@heroicons/vue/outline'

export default {
    props: {
        open: { default: false },
        closeOnConfirm: { default: false },
        type: { default: 'warning' },
    },
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ExclamationIcon,
    },
    data() {
        return {
            isOpen: this.open
        }
    },
    watch: {
        open() {
            this.isOpen = this.open
        }
    },
    methods: {
        confirm(){
            this.$emit('confirm')
            
            if (this.closeOnConfirm) {
                this.isOpen = false
            }
        },
        cancel(){
            this.isOpen = false
            this.$emit('cancel')
        },
    }
}
</script>