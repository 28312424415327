<template>
    <Layout>
        <Sheet :back="route('2.payments.index')">
            <SheetIcon :transparent="true"><StripeIcon /></SheetIcon>
            <SheetTitle>{{ $t('Card payments with Stripe') }}</SheetTitle>
            <p class="text-center mb-4">
                <svg class="fill-current text-green-400 inline-block mr-1" width="14" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.31.487 4.542 8.892 1.689 5.804a.336.336 0 0 0-.501 0l-.835.903a.406.406 0 0 0 0 .543l3.94 4.263c.137.15.362.15.5 0l8.852-9.58a.406.406 0 0 0 0-.542L12.81.487a.336.336 0 0 0-.5 0Z" /></svg>
                {{ $t('You are connected to Stripe') }}
            </p>
            
            <p class="text-center">
                {{ stripe.name }}<br>
                {{ stripe.email }}<br>
                {{ stripe.id }}
            </p>
            
            <div class="text-center mt-8">
                <a href="" @click.prevent="confirmDisconnect" class="font-medium underline text-red-400">
                    {{ $t('Disable card payments with Stripe') }}
                </a>
    
                <AppModalAlert ref="modal" @confirm="confirm">
                    <template v-slot:title>{{ $t('Disable card payments') }}</template>
                    {{ $t('Are you sure you want to disable card payments via Stripe for {name}? No one will be able to pay with their card.', { name: stripe.name }) }}
                    <template v-slot:confirm>{{ $t('Disable card payments') }}</template>
                </AppModalAlert>
            </div>
        </Sheet>
    </Layout>
</template>
<script>
import Layout from "../../../Shared/Layout";
import Sheet from "../../../Shared/Sheet";
import SheetIcon from "../../../Shared/SheetIcon";
import StripeIcon from "../../../Shared/Icons/StripeIcon";
import SheetTitle from "../../../Shared/SheetTitle";
import AppButtonOutline from "../../../Shared/AppButtonOutline";
import AppModalAlert from "../../../Shared/AppModalAlert";

export default {
    props: ['stripe'],
    components: {AppModalAlert, AppButtonOutline, SheetTitle, StripeIcon, SheetIcon, Sheet, Layout},
    data() {
        return {
        }
    },
    methods: {
        confirmDisconnect() {
            this.$refs.modal.isOpen = true
        },
        confirm() {
            this.$inertia.visit(this.route('2.payments.stripe.disconnect'), { method: 'post' })
        }
    }
}
</script>