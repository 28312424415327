<template>
    <Layout>
        <Sheet :back="route('2.payments.index')">
            <SheetIcon>
                <StripeIcon></StripeIcon>
            </SheetIcon>
            <SheetTitle>{{ $t('Card payments with Stripe') }}</SheetTitle>
            
            <div class="text-center">
                <template v-if="!inProgress">
                    <p class="tracking-normal mb-8">{{ $t("Stripe enables you to charge cards. A Stripe account will be created automatically, and you'll be able to make payouts to your bank account every week.") }}</p>
                    <a :href="route('2.payments.stripe.init')"><AppButtonOutline>{{ $t('Connect Stripe') }}</AppButtonOutline></a>
                </template>
                <template v-else>
                    <p class="tracking-normal mb-8">{{ $t("Card payments with Stripe has not yet been fully enabled on your account. Please continue to Stripe to see the current status of your account.") }}</p>
                    <a :href="route('2.payments.stripe.init')"><AppButtonOutline>{{ $t('Continue to Stripe') }}</AppButtonOutline></a>
                </template>
            </div>
        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout";
import Sheet from "../../../Shared/Sheet";
import SheetTitle from "../../../Shared/SheetTitle";
import SheetIcon from "../../../Shared/SheetIcon";
import StripeIcon from "../../../Shared/Icons/StripeIcon";
import AppButtonOutline from "../../../Shared/AppButtonOutline";

export default {
    props: ['inProgress'],
    components: {AppButtonOutline, StripeIcon, SheetIcon, SheetTitle, Sheet, Layout}
}
</script>