<template>
    <Layout>
        <Sheet>
            <SheetIcon :transparent="true"><SubscriptionIcon /></SheetIcon>
            <SheetTitle>{{ $t('Edit renewal date') }}</SheetTitle>
            <SubscriptionInfo :subscription="subscription" :icon="false" />

            <form @submit.prevent="form.post(this.route('2.subscriptions.date.update', { subscription: subscription }))">

                <p class="mb-2">
                    {{ $t("This will change {customer}'s subscription on {product}. The current renewal date is {date} and will be changed to a date you choose:", {
                        customer: subscription.billing_method.paid_by,
                        product: subscription.product.name,
                        date: date(subscription.renews_at)
                    }) }}
                </p>

                <AppInput :label="$t('Renewal date')" v-model="form.renews_at"></AppInput>

                <FormError />
                <FormButton :loading="form.processing">{{ $t('Save') }}</FormButton>
            </form>

        </Sheet>
    </Layout>
</template>

<script>
import Layout from "../../../Shared/Layout.vue";
import Sheet from "../../../Shared/Sheet.vue";
import SheetIcon from "../../../Shared/SheetIcon.vue";
import SubscriptionIcon from "../../../Shared/Activities/SubscriptionIcon.vue";
import SheetTitle from "../../../Shared/SheetTitle.vue";
import SubscriptionInfo from "../../../Shared/SubscriptionInfo.vue";
import AppInput from "../../../Shared/AppInput.vue";
import FormButton from "../../../Shared/FormButton.vue";
import dayjs from "dayjs";
import FormError from "../../../Shared/FormError.vue";

export default {
    props: ['subscription'],

    components: {
        FormError,
        FormButton,
        AppInput,
        SubscriptionInfo,
        SubscriptionIcon,
        SheetTitle,
        SheetIcon,
        Sheet,
        Layout
    },

    data() {
        return {
            form: this.$inertia.form({
                renews_at: dayjs(this.subscription.renews_at).format('YYYY-MM-DD'),
            })
        }
    },
}
</script>