<template>
    <button
        v-bind="$attrs"
        :class="[
            'text-white text-lg font-semibold transition duration-300 hover:bg-opacity-90',
            !$attrs.class?.includes('rounded') && 'rounded',
            !$attrs.class?.includes('block') && 'inline-block',
            !$attrs.class?.includes('px-') && 'px-12',
            !$attrs.class?.includes('py-') && 'py-4',
            disabled ? 'bg-blue-100' : 'bg-lilac-300'
        ]"
    >
        <AppSpinner v-if="loading" color="#ffffff" />
        <span v-else><slot></slot></span>
    </button>
</template>

<script>
import AppSpinner from './AppSpinner';

export default {
    components: { AppSpinner },
    inheritAttrs: false,
    props: {
        loading: Boolean,
        disabled: Boolean
    }
}
</script>
