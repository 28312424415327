<template>
    <HqLayout>
        <div class="text-center">
            <div class="mx-auto bg-blue-50 inline-block mb-8 rounded">
                <div class="flex justify-center items-center">
                    <a :href="route('2.hq.transactions.fees')" class="block px-5 py-2 m-1 rounded font-medium text-sm text-lilac-500">{{ $t('Fees') }}</a>
                    <a :href="route('2.hq.transactions.statements.index')" class="bg-lilac-50 block px-5 py-2 m-1 rounded font-medium text-sm text-lilac-500">{{ $t('Statements') }}</a>
                </div>
            </div>
        </div>

        <div class="">
            <div v-for="account in accounts" class="bg-white shadow mb-1 p-4">
                <div class="flex items-start justify-between">
                    <div>
                        <div class="text-lg font-semibold">{{ account.name }}</div>
                        <div>
                            {{ ucfirst(account.plan?.name) }} &ndash; {{ account.id }}
                        </div>
                    </div>
                    <div>
                        <div class="text-right" v-for="fee in account.fees">
                            {{ ucfirst(fee.billing_method) }}: {{ centsToMoney(fee.amount_fee, 'sek', fee.amount_fee > 0 ? 2 : 0) }} + {{ fee.percent_fee/100 }}%
                        </div>
                    </div>
                </div>

                <div class="space-y-2 mt-4">
                    <form @submit.prevent="createOrder(account)">
                        <div v-for="statement in account.transaction_statements" class="flex items-center space-y-4">
                            <div class="w-2/3 flex">
                                <div class="w-24">
                                    <input
                                        :disabled="statement.state !== 'pending' || statement.order_id !== null"
                                        type="checkbox"
                                        v-model="selectedStatements[account.id]"
                                        :value="statement.id"
                                        class="mr-2"
                                    />
                                    {{ statement.id }}
                                </div>
                                <div class="w-1/4">{{ statement.period }}</div>
                                <div class="w-1/4 text-sm">{{ centsToMoney(statement.total, 'sek') }} ({{ statement.count }} qty)</div>
                                <div class="w-1/4 text-sm">{{ statement.uniq_id }}</div>
                            </div>
                            <div class="w-1/3">
                                <div class="flex items-center justify-end">
                                    <a
                                        v-if="statement.state === 'pending' && statement.order_id === null"
                                        class="ml-6 text-blue-300/80 hover:text-blue-300 hover:bg-blue-300/5 font-medium py-0.5 px-2 text-sm rounded-full"
                                        @click.prevent="confirmDelete(account, statement)"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                        </svg>
                                    </a>
                                    <span v-if="statement.state === 'paid'" class="bg-green-400/10 text-green-400 px-2 py-1 rounded-full text-xs uppercase tracking-wider font-semibold">
                                        <a target="_blank" :href="statement.order_url">{{ $t('Paid') }}</a>
                                    </span>
                                    <span v-else-if="statement.order_id" class="bg-blue-100/50 text-blue-600 px-2 py-1 rounded-full text-xs uppercase tracking-wider font-semibold">
                                        <a target="_blank" :href="statement.order_url">{{ $t('Invoiced') }}</a>
                                    </span>
                                    <span v-else class="bg-yellow-100/50 text-yellow-600 px-2 py-1 rounded-full text-xs uppercase tracking-wider font-semibold">
                                        {{ statement.state }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end mt-4">
                            <AppButtonSmall
                                type="submit"
                                :disabled="!hasSelectedStatementsForAccount(account)"
                            >
                                <template v-if="hasSelectedStatementsForAccount(account)">{{ $t('Create order for {number} statements', {number: getSelectedCount(account)}) }}</template>
                                <template v-else>{{ $t('Create order') }}</template>
                            </AppButtonSmall>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </HqLayout>
</template>

<script>
import HqLayout from "../../HqLayout.vue"
import TableColumn from "../../../../Shared/TableColumn.vue";
import TableRow from "../../../../Shared/TableRow.vue";
import AppButtonOutline from '../../../../Shared/AppButtonOutline.vue'
import AppButtonSmall from '../../../../Shared/AppButtonSmall.vue'
import axios from 'axios'

export default {
    props: ['accounts'],
    components: {
        AppButtonSmall,
        AppButtonOutline,
        TableRow,
        TableColumn,
        HqLayout
    },
    data() {
        return {
            selectedStatements: Object.fromEntries(this.accounts.map(account => [account.id, []]))
        }
    },
    methods: {
        hasSelectedStatementsForAccount(account) {
            return this.selectedStatements[account.id]?.length > 0
        },

        getSelectedCount(account) {
            return this.selectedStatements[account.id]?.length || 0
        },

        async createOrder(account) {
            let response = await axios.post(route('2.hq.transactions.statements.create-order'), {
                account_id: account.id,
                statements: this.selectedStatements[account.id]
            })

            window.open(response.data.url, '_blank')
        },

        confirmDelete(account, statement) {
            if (confirm(`This will delete statement ${statement.id} for account ${account.name}`)) {
                this.$inertia.delete(route('2.hq.transactions.statements.destroy', {statement: statement}), {
                    preserveScroll: true
                })
            }
        }
    }
}
</script>
