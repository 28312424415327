<template>
    <span>{{ prettyType }}</span>
</template>

<script>
export default {
    props: ['type'],
    computed: {
        prettyType() {
            switch (this.type) {
                case 'App\\Products\\DigitalDeliveryProduct':
                    return this.$t('Digital delivery');
                case 'App\\Products\\DonationProduct':
                    return this.$t('Donation');
                case 'App\\Products\\MembershipProduct':
                    return this.$t('Membership');
                case 'App\\Products\\MonthlyDonorProduct':
                    return this.$t('Monthly donor');
                case 'App\\Products\\PhysicalDeliveryProduct':
                    return this.$t('Physical delivery');
                case 'App\\Products\\SubscriptionProduct':
                    return this.$t('Subscription');
                case 'App\\Products\\YearlyDonorProduct':
                    return this.$t('Yearly donor');
                default:
                    return '';
            }
        }
    }
}
</script>