<template>
    <MenuItem v-slot="{ active }">
        <div
            v-bind="$attrs"
            class="mx-4 pt-2 pb-0 -mb-1"
        >
            <span class="text-xs uppercase text-blue-200 p-0 tracking-widest font-semibold">
                <slot></slot>
                <AppDivider size="sm" />
            </span>
        </div>
    </MenuItem>
</template>

<script>
import { MenuItem } from '@headlessui/vue'
import AppDivider from "./AppDivider.vue";

export default {
    inheritAttrs: false,
    components: {
        AppDivider,
        MenuItem,
    },
    props: {
        href: null,
        external: false,
    },
    data() {
        return {

        }
    },
    created() {
    }
}
</script>