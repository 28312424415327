<template>
    <Layout>
        <OnboardingSteps :step="4"></OnboardingSteps>
        
        <Sheet size="md">
            
            <div class="text-center mt-4">
                <h1 class="font-semibold text-xl text-blue-400 mb-2">{{ $t('Success!') }}</h1>
                <p class="mb-8">{{ $t('Start selling anything on your website. Click a button and link it to a product') }}</p>
    
                <img src="/v2/images/connect-snowfire-1.png" class="w-[250px] h-[250px] object-cover mx-auto">
            </div>
            
            <SheetButton :href="route('2.accounts.connect', { continue: 'true' })" size="lg">
                {{ $t('Continue') }}
            </SheetButton>
        
        </Sheet>
    </Layout>
</template>
<script>
import OnboardingSteps from "../../Shared/OnboardingSteps";
import Sheet from "../../Shared/Sheet";
import SheetIcon from "../../Shared/SheetIcon";
import CartIcon from "../../Shared/Icons/CartIcon";
import Layout from "../../Shared/Layout";
import SheetButton from "../../Shared/SheetButton";
import ArrowRightIcon from "../../Shared/Icons/ArrowRightIcon";

export default {
    components: {ArrowRightIcon, SheetButton, Layout, CartIcon, SheetIcon, Sheet, OnboardingSteps},
    props: ['continueUrl']
}
</script>