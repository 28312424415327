<template>
    <div class="max-w-sm mx-auto">
        
        <ImageEdit
            :upload-url="route('2.products.media.preview-image.upload', { product: product, delivery: delivery })"
            :delete-url="route('2.products.media.preview-image.delete', { product: product, delivery: delivery })"
            :image-url="hasImage ? imageUrl() : null"
        >
            <template #default>
                <DefaultMediaImage :kind="delivery.kind" :extension="delivery.file_name ? delivery.file_name.split('.').pop() : null"></DefaultMediaImage>
            </template>
        </ImageEdit>
        
        <div class="flex items-center">
            <div class="flex-1">
                <AppInput :label="delivery.type === 'file' ? $t('File name') : $t('Link name')" v-model="form.displayName" class="mb-1"></AppInput>
            </div>
            <span v-if="delivery.type === 'file'" class="bg-blue-100 inline-block h-[63px] w-24 shadow flex items-center justify-center">.{{ delivery.file_name.split('.').pop() }}</span>
        </div>
        
        <AppInput v-if="delivery.type === 'link'" :label="$t('Link')" v-model="form.link" class="mb-1"></AppInput>
        
        <div class="bg-white shadow p-4 mb-4 rounded mt-1" @click="$refs.editor.focus()">
            <div class="text-sm -mt-1 mb-2">{{ $t('Description') }}</div>
            <Editor
                ref="editor"
                class="text-blue-400 min-h-[64px]"
                placeholder=""
                :value="form.description"
                @update="onUpdateProductDescription"
                :undo="false"
                :exclude="['headings']"
            ></Editor>
        </div>
    
    </div>
    
    <div class="-mx-4">
        <AppButton
            class="w-full rounded-none -mb-4"
            @click.prevent="save" :loading="loading"
        >{{ $t('Save') }}</AppButton>
    </div>
</template>

<script>
import AppButton from "../../../Shared/AppButton.vue";
import AppInput from "../../../Shared/AppInput.vue";
import Editor from "../../../Shared/Editor.vue";
import axios from "axios";
import AppUpload from "../../../Shared/AppUpload.vue";
import ImageEdit from "./ImageEdit.vue";
import AppMenu from "../../../Shared/AppMenu.vue";
import AppMenuItem from "../../../Shared/AppMenuItem.vue";
import DefaultMediaImage from "./DefaultMediaImage.vue";

export default {
    components: {DefaultMediaImage, AppMenuItem, AppMenu, ImageEdit, AppUpload, Editor, AppInput, AppButton},
    props: {
        delivery: {
            type: Object,
            required: true,
        },
        product: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            loading: false,
            
            form: {
                displayName: this.delivery.display_name,
                link: this.delivery.link,
                description: this.delivery.description,
            }
        }
    },
    methods: {
        hasImage() {
            return this.delivery.preview_image !== null
        },
        imageUrl() {
            return this.delivery.preview_image
        },
        save() {
            this.loading = true
            let url = this.route('2.products.media.update', { product: this.product, delivery: this.delivery })
            
            axios.post(url, {
                display_name: this.form.displayName,
                link: this.form.link,
                description: this.form.description
            }).then(response => {
                this.loading = false
                
                this.$emit('save', response.data.product)
            }).catch(error => {
                this.loading = false
                
                this.error = error
            })
        },
        onUpdateProductDescription(value) {
            this.form.description = value
        }
    }
}
</script>