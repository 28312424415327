<template>
    <div v-if="subscriptions.length > 0" class="bg-white shadow mb-1 rounded">
        <div class="relative overflow-x-auto">
            <table class="w-full text-sm text-left text-blue-300">
                <thead class="text-xs uppercase bg-blue-50">
                <tr>
                    <th scope="col" class="px-6 py-3">{{ $t('Account') }}</th>
                    <th scope="col" class="px-6 py-3">Subscription ID</th>
                    <th scope="col" class="px-6 py-3">{{ $t('Total') }}</th>
                    <th scope="col" class="px-6 py-3">{{ $t('Created') }}</th>
                    <th scope="col" class="px-6 py-3">{{ $t('Provider') }}</th>
                    <th scope="col" class="px-6 py-3">{{ $t('Status') }}</th>
                    <th scope="col" class="px-6 py-3">{{ $t('Last order') }}</th>
                    <th scope="col" class="px-6 py-3">{{ $t('Next') }}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="subscription in subscriptions" class="bg-white border-b border-blue-100">
                    <td class="px-6 py-4">
                        <div class="max-w-[180px]">
                            <a :href="route('2.activities.index', { account: subscription.account_id })" target="_blank">{{ subscription.account.name }}</a>
                        </div>
                    </td>
                    <td class="px-6 py-4">
                        <a :href="route('2.subscriptions.show', { account: subscription.account, subscription: subscription })" target="_blank">{{ subscription.id }}</a>
                    </td>
                    <td class="px-6 py-4">{{ centsToMoney(subscription.total_with_vat, subscription.currency) }}</td>
                    <td class="px-6 py-4">{{ dateTime(subscription.created_at) }}</td>
                    <td class="px-6 py-4"><span class="capitalize">{{ subscription.provider }}</span></td>
                    <td class="px-6 py-4">{{ subscription.heartbeat }}</td>
                    <td class="px-6 py-4">
                        <template v-if="dayjs(subscription.starts_at).isAfter(dayjs())">
                            <span>{{ $t('Starts {date}', { date: date(subscription.starts_at)} )}}</span>
                        </template>
                        <template v-else>
                            {{ subscription.last_order ? pretty(subscription.last_order.state) : '?' }}
                        </template>
                    </td>
                    <td class="px-6 py-4">
                        <span v-if="subscription.renews_at">{{ $t('Renews at {date}', { date: date(subscription.renews_at) }) }}</span>
                        <span v-else-if="subscription.ends_at">
                            <span v-if="dayjs(subscription.ends_at).isAfter(dayjs())">{{ $t('Ends at {date}', { date: date(subscription.ends_at) }) }}</span>
                            <span v-else>{{ $t('Ended at {date}', { date: date(subscription.ends_at) }) }}</span>
                        </span>
                        <span v-else-if="subscription.starts_at">{{ $t('Starts at {date}', { date: subscription.starts_at }) }}</span>
                    </td>
                    <td>
                        <AppMenu>
                            <template v-if="subscription.last_order">
                                <AppMenuItem v-if="subscription.last_order.billing_method.type === 'billecta'" :external="true" :href="route('2.hq.orders.billecta.open', { order: subscription.last_order })">{{ $t('Open on Billecta') }}</AppMenuItem>
                                <AppMenuItem v-if="subscription.last_order.billing_method.type === 'stripe'" :external="true" :href="route('2.hq.orders.stripe.open', { order: subscription.last_order })">{{ $t('Open on Stripe') }}</AppMenuItem>
                                <AppMenuItem v-if="subscription.last_order.billing_method.type === 'fortnox'" :external="true" :href="route('2.hq.orders.fortnox.open', { order: subscription.last_order })">{{ $t('Open on Fortnox') }}</AppMenuItem>
                            </template>
                        </AppMenu>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="mt-4" v-if="pagination">
        <Pagination :links="pagination.links" />
        <div class="text-center text-xs">
            {{ $t('{number} subscriptions', { number: number(pagination.total) }) }}
            &middot;
            <a :href="route('2.hq.subscriptions.index', { ...this.filters, export: 'excel' })" class="hover:underline">{{ $t('Export to Excel') }}</a>
        </div>
    </div>
</template>

<script>
import AppMenu from '../../../Shared/AppMenu.vue'
import AppMenuItem from '../../../Shared/AppMenuItem.vue'
import Pagination from '../../../Shared/Pagination.vue'
import dayjs from 'dayjs'

export default {
    methods: {dayjs},
    components: {Pagination, AppMenuItem, AppMenu},
    props: ['subscriptions', 'pagination', 'filters'],
}
</script>
