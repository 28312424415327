<template>
    <template v-if="asText">
        <AppInput :label="label" v-model="value" @input="updateValue" class="mb-1" />
    </template>
    <template v-else-if="asTextArea">
        <AppTextArea :label="label" v-model="value" @input="updateValue" class="mb-1" />
    </template>
    <template v-else-if="asName">
        <div class="flex space-x-1 mb-1">
            <div class="w-1/2"><AppInput :label="$t('First name')" v-model="firstName" @input="updateValue($event, 'firstName')" /></div>
            <div class="w-1/2"><AppInput :label="$t('Last name')" v-model="lastName" @input="updateValue($event, 'lastName')" /></div>
        </div>
    </template>
    <template v-else-if="asAddress">
        <AppInput :label="$t('Line 1')" v-model="line1" @input="updateValue($event, 'line1')" class="mb-1" />
        <AppInput :label="$t('Line 2')" v-model="line2" @input="updateValue($event, 'line2')" class="mb-1" />
        <div class="flex space-x-1 mb-1">
            <div class="w-1/3"><AppInput :label="$t('Postal code')" v-model="zip" @input="updateValue($event, 'zip')" /></div>
            <div class="w-2/3"><AppInput :label="$t('City')" v-model="city" @input="updateValue($event, 'city')" /></div>
        </div>
        <div class="mb-1">
            <AppSelect :label="$t('Country')" v-model="country" @input="updateValue($event, 'country')">
                <option v-for="(country, iso) in countries" :value="iso">{{ country }}</option>
            </AppSelect>
        </div>
    </template>
    <template v-else-if="asChoice">
        <div class="mb-1">
            <AppSelect :label="label" v-model="value" @input="updateValue">
                <template v-for="choice in choices">
                    <option :value="choice.id">{{ choice.label }}</option>
                </template>
            </AppSelect>
        </div>
    </template>
</template>

<script>
import AppInput from "../../../Shared/AppInput.vue";
import AppTextArea from "../../../Shared/AppTextArea.vue";
import AppSelect from "../../../Shared/AppSelect.vue";

export default {
    components: {AppSelect, AppTextArea, AppInput},
    props: ['label', 'modelValue', 'type', 'choices', 'countries'],
    data() {
        return {
            value: this.modelValue,
            firstName: null,
            lastName: null,
            line1: null,
            line2: null,
            zip: null,
            city: null,
            country: null,
        }
    },
    created() {
        if (this.type === 'name') {
            this.firstName = this.modelValue.firstName
            this.lastName = this.modelValue.lastName
        } else if (this.type === 'address') {
            this.line1 = this.modelValue.line1
            this.line2 = this.modelValue.line2
            this.zip = this.modelValue.zip
            this.city = this.modelValue.city
            this.country = this.modelValue.country
        }
    },
    computed: {
        asText() {
            return  this.type === 'text' ||
                    this.type === 'phone' ||
                    this.type === 'personal_identity_number' ||
                    this.type === 'date'
        },
        asTextArea() {
            return this.type === 'dedication' || this.type === 'text_area'
        },
        asName() {
            return this.type === 'name'
        },
        asAddress() {
            return this.type === 'address'
        },
        asChoice() {
            return this.type === 'choice'
        },
    },
    methods: {
        updateValue(value, inputName) {
            const inputFields = {
                firstName: 'firstName',
                lastName: 'lastName',
                line1: 'line1',
                line2: 'line2',
                zip: 'zip',
                city: 'city',
                country: 'country',
            };
            
            if (inputFields[inputName]) {
                this[inputFields[inputName]] = value.target.value
            }
            
            if (this.asName) {
                this.$emit('update:modelValue', { firstName: this.firstName, lastName: this.lastName })
            } else if (this.asAddress) {
                this.$emit('update:modelValue', { line1: this.line1, line2: this.line2, zip: this.zip, city: this.city, country: this.country })
            } else {
                this.$emit('update:modelValue', value.target.value)
            }
        },
    },
}
</script>
