<template>
    <a href=""
       ref="panel"
       class="
            block bg-white shadow rounded px-7 flex items-center justify-between transition-all group hover:translate-x-0.5
            border-l-2 border-white
            hover:border-lilac-300
            hover:rounded-none
            hover:rounded-r
        "
       :class="classList"
        >
        <slot />
        <span class="block">
            <ArrowRightIcon class="text-blue-400 transition group-hover:translate-x-1" />
        </span>
        
<!--        <span class="block w-1/3 flex items-center text-lilac-500" v-if="$slots.icon && $slots.label">-->
<!--            <slot name="icon"></slot>-->
<!--            <span class="ml-3">-->
<!--                <slot name="label"></slot>-->
<!--            </span>-->
<!--        </span>-->
<!--        <span class="block w-2/3">-->
<!--            <span class="text-blue-300 leading-6"><slot></slot></span>-->
<!--        </span>-->
<!--        <span class="block">-->
<!--            <ArrowRightIcon class="text-blue-400 transition group-hover:translate-x-1" />-->
<!--        </span>-->
    </a>
</template>

<script>
import ArrowRightIcon from "./Icons/ArrowRightIcon"

export default {
    props: {
        size: { default: 'lg' }
    },
    data() {
        return {
            classList: {}
        }
    },
    mounted() {
        switch (this.size) {
            case 'md':
                this.classList['h-16'] = true
                break
            
            case 'lg':
                this.classList['h-24'] = true
                break
        }
    },
    components: {ArrowRightIcon}
}
</script>