<template>
    <Layout>
        
        <div class="text-center mb-4">
            <div class="text-xl font-semibold text-blue-400 mb-1">{{ $t('Custom fields') }}</div>
        </div>

        <div class="flex justify-center mb-12">
            <InertiaLink :href="route('2.custom-fields.create')">
                <AppButtonOutline>
                    <template #icon><FieldIcon /></template>
                    {{ $t('New field') }}
                </AppButtonOutline>
            </InertiaLink>
        </div>

        <div class="max-w-3xl mx-auto">
            <template v-if="baseFields.length > 0">
                <SmallTitle>{{ $t('Fixed fields') }}</SmallTitle>
                <CustomFieldListItem v-for="(field, i) in baseFields" :key="field.id" :field="field" />
            </template>

            <template v-if="fieldList.length > 0">
                <SmallTitle class="mt-12">{{ $t('Custom fields') }}</SmallTitle>
                <SlickList axis="y" v-model:list="fieldList" @sort-end="onSortEnd" @sort-start="onSortStart" useDragHandle>
                    <SlickItem v-for="(field, i) in fieldList" :key="field.id" :index="i">
                        <DragHandle class="absolute -ml-12 mt-7 cursor-move">
                            <svg class="fill-current" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="20" y="17" width="3" height="3" /><rect x="25" y="17" width="3" height="3" /><rect x="20" y="22" width="3" height="3" /><rect x="25" y="22" width="3" height="3" /><rect x="20" y="27" width="3" height="3" /><rect x="25" y="27" width="3" height="3" /></svg>
                        </DragHandle>

                        <CustomFieldListItem :field="field" :is-dragging="isDragging" />
                    </SlickItem>
                </SlickList>
            </template>
            
        </div>
    </Layout>
</template>

<script>
import AppMenu from "../../Shared/AppMenu.vue";
import AppMenuItem from "../../Shared/AppMenuItem.vue";
import Layout from "../../Shared/Layout.vue";
import ProductIcon from "../../Shared/Icons/ProductIcon.vue";
import AppButtonOutline from "../../Shared/AppButtonOutline.vue";
import TableRow from "../../Shared/TableRow.vue";
import TableColumn from "../../Shared/TableColumn.vue";
import FieldIcon from "../../Shared/Activities/FieldIcon.vue";
import { SlickList, SlickItem, DragHandle } from 'vue-slicksort';
import SmallTitle from "../../Shared/SmallTitle.vue";
import CustomFieldListItem from "./CustomFieldListItem.vue";

export default {
    components: {
        CustomFieldListItem,
        SmallTitle,
        FieldIcon, TableColumn, TableRow, AppButtonOutline, ProductIcon, Layout, AppMenuItem, AppMenu, SlickItem, SlickList, DragHandle},
    props: ['fields', 'baseFields'],
    methods: {
        onSortStart() {
            this.isDragging = true
        },
        onSortEnd() {
            this.isDragging = false
            this.updateOrder()
        },
        updateOrder() {
            this.$nextTick(() => {
                this.$inertia.post(this.route('2.custom-fields.sort'), {
                    order: this.fieldList.map(field => field.id),
                }, {
                    preserveScroll: true,
                    preserveState: true,
                })
            })
        },
    },
    data() {
        return {
            isDragging: false,
            fieldList: this.fields,
        }
    },
}
</script>