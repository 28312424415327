<template>
    <div class="
        relative
        shadow border-1
        h-[63px]
        flex items-center
    ">
        <label
            v-if="label"
            :for="id"
            class="
                form-label
                absolute left-4 top-2 text-blue-300 text-sm transition-all
            "
        >{{ label }}</label>
        <select
            :id="id" ref="input" v-model="selected" v-bind="$attrs"
            class="
                form-select rounded h-[63px] text-base w-full
                text-blue-400
                focus:border-blue-200
                focus:outline-none
                focus:ring-0
            "
            :class="{
                'pl-4 pt-[22px]': label,
                
                'border-red-400': error,
                'border-white': !error,
                
                'focus:border-red-400': error,
                'bg-red-400': error,
                'bg-opacity-10': error,
             }"
        >
            <slot />
        </select>
        <div v-if="error" class="form-error">{{ error }}</div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid"

export default {
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            default() {
                return `select-input-${uuidv4()}`
            },
        },
        modelValue: [String, Number, Boolean, Object],
        label: String,
        error: String,
    },
    data() {
        return {
            selected: this.modelValue,
        }
    },
    watch: {
        modelValue(newValue) {
            this.selected = newValue
        },
        selected(selected) {
            this.$emit('update:modelValue', selected)
        },
    },
    methods: {
        focus() {
            this.$refs.input.focus()
        },
        select() {
            this.$refs.input.select()
        },
    },
}
</script>