<template>
    <div
        class="flex items-center"
        :class="{ 'ring-2 ring-blue-500': isFocused && focusViaKeyboard }"
    >
        <input
            :id="id"
            ref="input"
            v-bind="$attrs"
            type="radio"
            @input="change($event)"
            :checked="isChecked"
            class="sr-only"
            @focus="isFocused = true"
            @blur="isFocused = false"
            @mousedown.prevent
        />
        <label
            :for="id"
            class="text-blue-300 text-left flex items-center cursor-pointer"
            :class="{
                'peer-placeholder-shown:text-red-400': error,
                'text-blue-200' : this.$attrs.disabled
            }"
        >
            <span class="w-[18px] h-[18px] mr-3 relative inline-block border border-1 rounded-full border-lilac-200">
                <span v-if="isChecked" class="absolute inset-0 bg-lilac-500 rounded-full m-0.5"></span>
            </span>
            <template v-if="label">{{ label }}</template>
            <template v-else><slot /></template>
        </label>
    </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid"

export default {
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            default() {
                return `radio-input-${uuidv4()}`
            },
        },
        modelValue: [String, Number, Boolean, Object, Array],
        value: [String, Number, Boolean, Object],
        label: String,
        error: String,
    },
    computed: {
        isChecked() {
            return this.modelValue === this.value;
        }
    },
    data() {
        return {
            isFocused: false,
            focusViaKeyboard: false,
        }
    },
    methods: {
        enableKeyboardFocus() {
            this.focusViaKeyboard = true;
        },
        disableKeyboardFocus() {
            this.focusViaKeyboard = false;
        },
        focus() {
            this.$refs.input.focus()
        },
        select() {
            this.$refs.input.select()
        },
        change($event) {
            this.$emit('update:modelValue', this.value);
        },
    },
    mounted() {
        window.addEventListener('keydown', this.enableKeyboardFocus);
        window.addEventListener('mousedown', this.disableKeyboardFocus);
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.enableKeyboardFocus);
        window.removeEventListener('mousedown', this.disableKeyboardFocus);
    },
}
</script>
