<template>
    <LayoutWithoutNavigation>
    
        <form @submit.prevent="form.post(this.route('2.users.rsvp.agreement.store'))">
            <div class="mt-24 max-w-xl mx-auto shadow-lg rounded-t p-16">
    
                <div class="text-center">
                    <CartIcon class="inline-block" />
                </div>
    
                <div class="text-lg font-medium text-blue-400 text-center pt-8 mb-12">
                    {{ $t('Terms of Service') }}
                </div>
                
                <div class="max-w-xs mx-auto">
                    <AppCheckboxGroup class="mb-1">
                        <AppCheckbox v-model="form.agree">
                            <p v-html="$t('accept_terms', { termsUrl: termsUrl })"></p>
                        </AppCheckbox>
                    </AppCheckboxGroup>
                    
                    <AppCheckboxGroup>
                        <AppCheckbox v-model="form.email_communication">{{ $t('accept_email') }}</AppCheckbox>
                    </AppCheckboxGroup>
                </div>
    
            </div>
    
            <div class="max-w-xl mx-auto shadow-lg">
                <AppButton :loading="form.processing" type="submit" class="block rounded-b w-full px-5 py-5">{{ $t('Continue') }}</AppButton>
            </div>
            
        </form>
        
        
    </LayoutWithoutNavigation>
</template>

<script>
import LayoutWithoutNavigation from "../../../Shared/LayoutWithoutNavigation";
import AppCheckbox from "../../../Shared/AppCheckbox";
import AppButton from "../../../Shared/AppButton";
import { useForm } from '@inertiajs/vue3'
import CartIcon from "../../../Shared/Icons/CartIcon";
import AppCheckboxGroup from "../../../Shared/AppCheckboxGroup.vue";

export default {
    props: ['termsUrl'],
    components: {AppCheckboxGroup, CartIcon, AppButton, AppCheckbox, LayoutWithoutNavigation},
    data: (instance) => {
        return {
            form: useForm({
                agree: false,
                email_communication: false,
            }),
        }
    },
}
</script>