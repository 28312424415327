<template>
    
    <div
        v-if="$slots.menu || this.back"
        class="max-w-lg mx-auto relative flex items-center min-h-[54px]"
    >
        <div
            v-if="this.back"
            class="mb-1"
        >
            <Back :href="back" />
        </div>
    
        <div class="ml-auto">
            <slot name="menu"></slot>
        </div>
    </div>
    
    <div
        class="
            relative
            mx-auto py-4 px-4
            sm:shadow sm:py-16 sm:px-16
        "
        :class="{
            'max-w-lg': size === 'md',
            'max-w-xl': size === 'lg',
            'max-w-3xl': size === 'xl'
        }"
    >
        <slot></slot>
    </div>
    
</template>

<script>
import Back from "./Back";

export default {
    props: {
        size: { default: 'md' },
        back: { default: null }
    },
    components: {Back}
}
</script>