<template>
    <div
        class="mx-auto bg-lilac-300 text-white shadow border border-white flex items-center justify-center"
        :class="{
            'h-36 w-36': size === 'lg',
        
            'h-24 w-24': size === 'md',
            'border-8': size === 'md' || size === 'lg',
            'rounded-tr-3xl rounded-bl-3xl': size === 'md' || size === 'lg',
            
            'h-10 w-10': size === 'sm',
            'rounded-tr-xl rounded-bl-xl': size === 'sm',
        }"
    >
        <div class="inline-flex items-center justify-center rounded-tr-3xl rounded-bl-3xl">
            <span v-if="kind === 'video'">
                <svg :width="area" :height="area" viewBox="0 0 46 46" preserveAspectRatio="xMidYMid meet" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" fill-rule="evenodd" d="M8 8a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H8Zm22.466 2H15.532v8h14.934v-8ZM15.532 20h14.934v8H15.532v-8Zm14.934 10H15.532v8h14.934v-8ZM9.137 10h4.267v4H9.137v-4Zm4.267 6H9.137v4h4.267v-4Zm-4.267 6h4.267v4H9.137v-4Zm4.267 6H9.137v4h4.267v-4Zm-4.267 6h4.267v4H9.137v-4Zm27.726-24h-4.266v4h4.266v-4Zm-4.266 6h4.266v4h-4.266v-4Zm4.266 6h-4.266v4h4.266v-4Zm-4.266 6h4.266v4h-4.266v-4Zm4.266 6h-4.266v4h4.266v-4Z" clip-rule="evenodd"/>
                </svg>
            </span>
            
            <span v-else-if="kind === 'image'">
                <svg :width="area" :height="area" viewBox="0 0 46 46" preserveAspectRatio="xMidYMid meet" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" fill-rule="evenodd" d="M6.75 5.75a1 1 0 0 0-1 1v32.5a1 1 0 0 0 1 1h32.5a1 1 0 0 0 1-1V6.75a1 1 0 0 0-1-1H6.75Zm19.418 15.917a1 1 0 0 1 1.625 0l8.145 11.346H10.061l6.683-8.378a1 1 0 0 1 1.563 0l3.016 3.781 4.844-6.75Z" clip-rule="evenodd"/>
                </svg>
            </span>
            
            <span v-else-if="kind === 'document'">
                <svg :width="area" :height="area" viewBox="0 0 46 46" preserveAspectRatio="xMidYMid meet" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" fill-rule="evenodd" d="M11.583 5.75a2 2 0 0 0-2 2v30.5a2 2 0 0 0 2 2h22.834a2 2 0 0 0 2-2V12.219L33.32 8.984 30.224 5.75h-18.64Zm20.705 8.625H13.711v2.156h18.577v-2.156Zm-18.577 6.469h18.577V23H13.711v-2.156Zm13.824 7.367a2.014 2.014 0 0 0-3.354 0l-2.384 3.47-1.113-1.457a2.01 2.01 0 0 0-3.228 0l-3.745 4.906h18.577l-4.753-6.919Z" clip-rule="evenodd"/>
                </svg>
            </span>
            
            <span v-else-if="kind === 'archive'">
                <svg :width="area" :height="area" viewBox="0 0 46 46" preserveAspectRatio="xMidYMid meet" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" fill-rule="evenodd" d="M11.777 6a2 2 0 0 0-2 2v30a2 2 0 0 0 2 2h22.445a2 2 0 0 0 2-2V12.375L33.17 9.187 30.12 6H23v1.889h-1.89V6h-9.333ZM23 7.889h1.889v1.889h-1.89v-1.89Zm0 1.889h-1.89v1.889H23v-1.89Zm0 1.889h1.889v1.889h-1.89v-1.89Zm0 1.889h-1.89v1.888H23v-1.889Zm0 1.889h1.889v1.888h-1.89v-1.888Zm-7.857 18.032h5.002v.856h-6.59v-.78l4.972-5.919h-4.914v-.856h6.502v.78l-4.972 5.919Zm8.165-6.7v7.556h-1.34v-7.555h1.34Zm8.253 3.784c.588-.42.883-.943.883-1.572 0-.65-.304-1.181-.912-1.593-.599-.412-1.476-.618-2.634-.618h-3.31v7.555h1.34V31.18h1.97c1.197 0 2.084-.206 2.663-.618Zm-1.015-.553c-.353.239-.902.358-1.648.358h-1.97V27.59h1.97c1.452 0 2.178.466 2.178 1.398 0 .441-.177.78-.53 1.02Z" clip-rule="evenodd"/>
                </svg>
            </span>
            
            <span v-else-if="kind === 'link'">
                <svg :width="area" :height="area" viewBox="0 0 46 46" preserveAspectRatio="xMidYMid meet" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill="currentColor" fill-rule="evenodd" d="M21.733 22.85c-2.272-2.037-5.71-2.202-8.093-.25l-4.909 4.018c-2.639 2.161-2.987 6.1-.776 8.796 2.21 2.698 6.142 3.132 8.781.971l4.909-4.018c2.32-1.9 2.869-5.171 1.468-7.767l1.34-1.097c2.27 1.884 5.588 1.99 7.907.091l4.909-4.019c2.639-2.16 2.987-6.099.776-8.796-2.21-2.697-6.142-3.132-8.781-.971l-4.909 4.019c-2.383 1.951-2.898 5.352-1.347 7.98l-1.275 1.043Zm-6.434 1.774-4.908 4.019c-1.545 1.265-1.749 3.57-.455 5.15 1.294 1.578 3.596 1.833 5.14.568l4.91-4.02c1.216-.996 1.601-2.638 1.077-4.062l-3.476 2.846a1.103 1.103 0 0 1-1.55-.172 1.101 1.101 0 0 1 .137-1.552l3.534-2.893c-1.316-.937-3.124-.936-4.409.116Zm11.204-2.8 2.376-1.945a1.101 1.101 0 0 0 .137-1.552 1.103 1.103 0 0 0-1.55-.17l-2.433 1.992c-.66-1.475-.303-3.246.982-4.298l4.908-4.018c1.546-1.265 3.847-1.01 5.14.568 1.295 1.579 1.091 3.884-.454 5.15l-4.908 4.018c-1.218.997-2.905 1.05-4.198.256Z" clip-rule="evenodd"/>
                </svg>
            </span>
            
            <!-- Handling for other kinds -->
            <span v-else class="text-white uppercase text-xs">{{ extension }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        kind: {
            type: String,
            required: true,
        },
        extension: {
            type: String,
            required: false,
        },
        size: {
            type: String,
            default: 'md'
        },
    },
    data: function () {
        return {
            area: null,
        }
    },
    created() {
        switch (this.size) {
            case 'lg':
                this.area = '36';
                break;
            case 'md':
                this.area = '32';
                break;
            case 'sm':
                this.area = '24';
                break;
        }
    }
}
</script>
