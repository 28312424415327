<template>
    <div class="mx-auto flex justify-center mb-6">
        <div class="text-center">
            <div v-if="icon"><SubscriptionIcon class="inline-block text-lilac-300 mr-2 mb-2" /></div>
            <div>
                <template v-if="subscription.billing_method.company">{{ subscription.billing_method.company.name }}</template>
                <template v-else>{{ subscription.billing_method.person.name }}</template>
            </div>
            <div class="font-medium mb-0.5"><SubscriptionName :subscription="subscription" :with-product="true"></SubscriptionName></div>
            <div class="text-sm"><SubscriptionRenews :subscription="subscription"></SubscriptionRenews></div>
        </div>
    </div>
</template>

<script>
import SubscriptionIcon from "./Activities/SubscriptionIcon.vue";
import SubscriptionRenews from "./SubscriptionRenews.vue";
import SubscriptionName from "./SubscriptionName.vue";

export default {
    // set props with default values
    props: {
        subscription: {
            type: Object,
            required: true,
        },
        icon: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        SubscriptionIcon,
        SubscriptionRenews,
        SubscriptionName,
    },
}
</script>