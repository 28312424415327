<template>
    <Layout>

        <Sheet :back="route('2.shipping.index')">
            <SheetIcon><ShippingIcon class="text-white" /></SheetIcon>
            <SheetTitle>{{ $t('Edit location') }}</SheetTitle>

            <form @submit.prevent="submit">
                <AppInput :label="$t('Name')" v-model="form.name" class="mb-1"></AppInput>
                <AppInput :label="$t('Address')" v-model="form.line1" class="mb-1"></AppInput>
                <AppInput :label="$t('Line 2')" v-model="form.line2" class="mb-1"></AppInput>
                <div class="flex space-x-1 mb-1">
                    <div class="w-1/3"><AppInput :label="$t('Postal code')" v-model="form.zip"></AppInput></div>
                    <div class="w-2/3"><AppInput :label="$t('Town/City')" v-model="form.city"></AppInput></div>
                </div>
                <AppSelect v-model="form.country">
                    <option v-for="(country, iso) in countries" :value="iso">{{ country }}</option>
                </AppSelect>


                <FormError />

                <FormButton :loading="form.processing" type="submit">{{ $t('Save') }}</FormButton>
            </form>
        </Sheet>

    </Layout>
</template>

<script>
import ShippingIcon from "../../Shared/Icons/ShippingIcon.vue";
import SheetIcon from "../../Shared/SheetIcon.vue";
import Sheet from "../../Shared/Sheet.vue";
import Layout from "../../Shared/Layout.vue";
import SheetTitle from "../../Shared/SheetTitle.vue";
import FormError from "../../Shared/FormError.vue";
import FormButton from "../../Shared/FormButton.vue";
import AppSelect from "../../Shared/AppSelect.vue";
import AppCheckboxGroup from "../../Shared/AppCheckboxGroup.vue";
import AppCheckbox from "../../Shared/AppCheckbox.vue";
import AppInput from "../../Shared/AppInput.vue";

export default {
    props: ['countries', 'location'],
    components: {
        AppInput,
        AppCheckbox,
        AppCheckboxGroup,
        AppSelect,
        FormButton,
        FormError,
        SheetTitle,
        Layout,
        Sheet,
        SheetIcon,
        ShippingIcon
    },
    data() {
        return {
            form: this.$inertia.form({
                name: this.location.name,
                line1: this.location.line1,
                line2: this.location.line2,
                zip: this.location.zip,
                city: this.location.city,
                country: this.location.country || 'SE',
            }),
        }
    },
    methods: {
        submit() {
            this.$inertia.post(this.route('2.locations.update', { id: this.location.id }), this.form)
        },
    },
}
</script>
